import React, { Component } from "react";
import { Row, Col } from "antd";
import { PageTitle } from "../../../components/PageTitle";
import { get } from 'lodash';

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import Loader from '../../../components/PrimaryLoader';
import ErrorModal from '../../../components/modal/ErrorModal';
import SuccessModal from '../../../components/modal/SuccessModal';
// import ImageUpload from "../../../components/ImageUpload";

// images
// import userImage from "../../../assets/user.svg";

// Style
import "./styles.scss";

//Constants
import { BREADCRUMBS, ROUTES } from '../../../constants';

//Services
import * as DM from '../dataManager';

//Helpers
import { validate, parseError } from '../parser';

class CategoryNew extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      category: {
        name: ""
      }
    };
  }

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificCategoryDetials(editableID);
    }
  }

  getSpecificCategoryDetials = (id) => {
    this._startLoader();
    DM.getCategoryService(id)
      .then((res) => {
        this.setStateValue(res.data.name, "name");
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.category[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.CATEGORIES);
    })
  }

  handleSave = () => {
    const { category } = this.state;

    const checkValidate = validate(category);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    const editableID = this.props.match.params.id;
    this._startLoader();
    if (editableID) {
      DM.patchUpdateCategoryService(editableID, category)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Category updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.postCreateCategoryService(category)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Category created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }

  render() {
    const editableID = this.props.match.params.id;
    const { isLoader, errorModal, succModal, errObj, category } = this.state;
    return (
      <>
        <PageTitle title="Category New" />

        <div className="categorie-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.CATEGORIES,
                  { text: editableID ? "Edit Category" : "Add new Category" },
                ]}
              />
              <h4 className="title4"> {editableID ? "Edit Category" : "Add new category"}</h4>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Category Name <span className="required-text">*</span></p>
                  <InputPrimary
                    value={category.name}
                    placeholder="Enter category name"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name")
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
              </Col>
            </Row>

            {/* <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Cover Image</p>
                  <ImageUpload
                    defaultImage={true}
                    text="(Max file size: 20MB)"
                    subText="Upload Photo"
                    defaultImageUrl={userImage}
                  />
                </div>
              </Col>
            </Row> */}

            <div className="mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CategoryNew;
