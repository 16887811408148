import React, { Component } from 'react';
import "./styles.scss";
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class FileUpload extends Component {

  render() {
    const { onChange, fileList, disabled } = this.props;
    return (
      <>
        <Upload
          accept=".csv"
          fileList={fileList}
          listType="text"
          onChange={onChange}
          disabled={disabled}
          beforeUpload={() => false}
        >
          <Button className="upload-btn" icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </>
    );
  }
}

export default FileUpload;