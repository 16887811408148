import React, { Component } from "react";
import { Input } from "antd";

// Style
import "./styles.scss";

class InputPrimary extends Component {
  render() {
    return (
      <div className="input-primary-section">
        {this.props.type === "password"
          ?
          <Input.Password
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            onPressEnter={this.props.onPressEnter}
          />
          :
          <Input
            type={this.props.type}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            value={this.props.value || undefined}
            onChange={this.props.onChange}
            className="input-primary"
            maxLength={this.props.maxLength}
            disabled={this.props.disabled}
            onInput={this.props.onInput}
          />
        }
        <span className="text-danger">{this.props.errorText}</span>
      </div>
    );
  }
}

export default InputPrimary;
