import { get } from "lodash";
import React, { Component } from "react";
import { GEditorComponent } from "../../../utils";
import ButtonPrimary from "../../buttons/ButtonPrimary";
// import ButtonSecondary from "../../buttons/ButtonSecondary";

// Style
import "./styles.scss";

class GetIdea extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    let { data, globalStoreData } = this.props;
    // console.log(globalStoreData);
    const tabs = get(data, "tabs");
    let content;
    if (tabs) {
      content = {
        ...JSON.parse(get(data, "tabs[0].contents[0].content")),
        ...JSON.parse(get(data, "tabs[0].contents[1].content")),
      };
    }
    if (globalStoreData) content = globalStoreData;
    return (
      <div className="get-idea-container">
        <GEditorComponent
          tabFileUpload={(file) => this.props.tabFileUpload(file)}
          onSaveButtonPress={(data) => this.props.onSaveTabsContent(data)}
          data={content}
          container="#acadru-editor"
        />

        <div className="mt--40 footer-btn-section">
          <ButtonPrimary btnText="Save" onClick={this.props.handleGetIdeaSave} />
          {/* <ButtonSecondary btnText="Add new chapter" /> */}
        </div>
      </div>
    );
  }
}

export default GetIdea;
