import flagIndia from "../assets/country-flag/flag-india.svg";
import flagUAE from "../assets/country-flag/flag-uae.svg";
import flagHongkong from "../assets/country-flag/flag-hongkong.svg";
import flagSingapore from "../assets/country-flag/flag-singapore.svg";
import flagCanada from "../assets/country-flag/flag-canada.svg";
import flagUSA from "../assets/country-flag/flag-usa.svg";

export const countryCodes = [
    {
        value: "India (+91)",
        code: "+91",
        length: [10],
        flag: flagIndia,
    },
    {
        value: "UAE (+971)",
        code: "+971",
        length: [9],
        flag: flagUAE,
    },
    {
        value: "HongKong (+852)",
        code: "+852",
        length: [8],
        flag: flagHongkong,
    },
    {
        value: "Singapore (+65)",
        code: "+65",
        length: [8],
        flag: flagSingapore,
    },
    {
        value: "Canada (+1)",
        code: "+1",
        length: [10],
        flag: flagCanada,
    },
    {
        value: "USA (+1)",
        code: "+1",
        length: [10],
        flag: flagUSA,

    },
];