export const LOGIN = "/";
export const DASHBOARD = "/dashboard";
export const MODULES = "/modules";
export const NEWMODULE = "/new-modules";
export const EDITMODULE = "/edit-modules/:id";
export const VIEWMODULE = "/view-modules/:id";
export const SCHOOLPROJECTS = "/school-projects";
export const NEWSCHOOLPROJECTS = "/school-project/new";
export const EDITSCHOOLPROJECT = "/school-project/:id";

export const LEARNERS = "/user/learners";
export const NEWLEARNER = "/user/learner/new";
export const VIEWLEARNER = "/user/learner/details/:id";
export const EDITLEARNER = "/user/learner/:id";

export const MENTORS = "/user/mentors";
export const NEWMENTOR = "/user/mentor/new";
export const VIEWMENTOR = "/user/mentor/details/:id";
export const EDITMENTOR = "/user/mentor/:id";

export const SUPERMENTORS = "/user/super-mentors";
export const NEWSUPERMENTOR = "/user/super-mentor/new";
export const VIEWSUPERMENTOR = "/user/super-mentor/details/:id";
export const VIEWSUPERMENTORDASHBOARD = "/user/super-mentor/dashboard/:id";
export const EDITSUPERMENTOR = "/user/super-mentor/:id";

export const ADMINS = "/user/admin";
export const NEWADMIN = "/user/admin/new";
export const VIEWADMIN = "/user/admin/details/:id";
export const EDITADMIN = "/user/admin/:id";

export const COLLECTIONS = "/collections";
export const NEWCOLLECTION = "/new-collections";
export const EDITCOLLECTION = "/edit-collections/:id";

export const ENQUIRIES = "/enquiries";
export const ENQUIRIESDETAILS = "/enquiries/details/:id";

export const CAREERS = "/careers";
export const NEWCAREERES = "/career/new";
export const EDITCAREERS = "/career/edit/:id";

export const PAYMENTS = "/payments";

export const ROLE_MANAGEMENT = "/role-management";
export const NEWROLE = "/role/new";
export const EDITROLE = "/role/details/:id";

export const CATEGORIES = "/categories";
export const NEWCATEGORY = "/category/new";
export const EDITCATEGORY = "/category/:id";
export const SUBCATEGORIES = "/sub-categories";
export const NEWSUBCATEGORY = "/sub-categories/new";
export const EDITSUBCATEGORY = "/categories/:category_id/sub-categories/:id";

export const TAGCATEGORY = "/tag-category";
export const NEWTAGCATEGORY = "/tag-category/new";
export const EDITTAGCATEGORY = "/tag-category/:id";
export const TAGS = "/tags";
export const NEWTAG = "/tag/new";
export const EDITTAG = "/tag/:id";

export const MYPROFILE = "/my-profile";

export const BLOGCATEGORY = "/blog-category";
export const NEWBLOGCATEGORY = "/blog-category/new";
export const EDITBLOGCATEGORY = "/blog-category/:id";
export const BLOGS = "/blog";
export const NEWBLOG = "/blog/new";
export const EDITBLOG = "/blog/:id";

export const FEATUREQUESTIONS = "/feature-questions";
export const NEWFEATUREQUESTION = "/feature-questions/new";
export const EDITFEATUREQUESTION = "/feature-questions/:id";

export const PACKAGE = "/packages";
export const NEWPACKAGE = "/package/new";
export const EDITPACKAGE = "/package/:id";
export const VIEWPACKAGE = "/view-package/:id";

export const PAGENOTFOUND = "/404";
export const FORBIDDEN = "/403";

// export const
// export const
// export const
// export const
// export const
// export const
// export const
// export const
