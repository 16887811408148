import { get } from "lodash";
import { getIdsFromArray, getData } from "./helpers";

export const _updateModuleParser = (prevValue, value) => {
  let payload = {
    name: getData(prevValue, value, "name"),
    description: getData(prevValue, value, "description"),
    likes: getData(prevValue, value, "likes"),
    views: getData(prevValue, value, "views"),
    status: getData(prevValue, value, "status"),
    featured: getData(prevValue, value, "featured"),
    banner_text: getData(prevValue, value, "banner_text"),
    reflection_question: getData(prevValue, value, "reflection_question"),
    interest_cloud_text: getData(prevValue, value, "interest_cloud_text"),
    categories: getIdsFromArray(prevValue, value, "categories"),
    careers: getIdsFromArray(prevValue, value, "careers"),
    related_modules: getIdsFromArray(prevValue, value, "related_modules"),
    tags: getIdsFromArray(prevValue, value, "tags"),
    module_level: getData(prevValue, value, "module_level"),
    mainImage: getData(prevValue, value, "mainImage")
  };

  return payload;
};

export const _parseModuleForGlobalStorage = (prevValue, value) => {
  let payload = {
    name: getData(prevValue, value, "name"),
    description: getData(prevValue, value, "description"),
    likes: getData(prevValue, value, "likes"),
    views: getData(prevValue, value, "views"),
    status: getData(prevValue, value, "status"),
    featured: get(value, "featured"),
    banner_text: getData(prevValue, value, "banner_text"),
    reflection_question: getData(prevValue, value, "reflection_question"),
    interest_cloud_text: getData(prevValue, value, "interest_cloud_text"),
    categories: getData(prevValue, value, "categories"),
    careers: getData(prevValue, value, "careers"),
    related_modules: getData(prevValue, value, "related_modules"),
    tags: getData(prevValue, value, "tags"),
    module_level: getData(prevValue, value, "module_level")
  };
  return payload;
};

export const validateModuleDetails = (data) => {
  let errorObj = {};
  if (data && !data.name) {
    errorObj["name"] = "Module name is Required";
  }

  if (data && (!data.categories || data.categories.length === 0)) {
    errorObj["categories"] = "Category is Required";
  }

  if (data && data.likes) {
    if (!data.views) {
      errorObj["views"] = "Views are required if likes are entered.";
    } else if (parseInt(data.likes) > parseInt(data.views)) {
      errorObj["views"] = "Views should be more than likes";
    }
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
}

export const parseErrorModuleDetails = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.name) {
    errorObj["name"] = get(err, "name[0]");
  }
  if (err && err.categories) {
    errorObj["categories"] = get(err, "content[2]");
  }
  if (err && err.module_level) {
    errorObj["module_level"] = get(err, "module_level[0]");
  }
  if (err && err.likes) {
    errorObj["likes"] = get(err, "likes[0]");
  }
  if (err && err.views) {
    errorObj["views"] = get(err, "views[0]");
  }
  if (err && err.banner_text) {
    errorObj["banner_text"] = get(err, "banner_text[0]");
  }
  if (err && err.reflection_question) {
    errorObj["reflection_question"] = get(err, "reflection_question[0]");
  }
  if (err && err.interest_cloud_text) {
    errorObj["interest_cloud_text"] = get(err, "interest_cloud_text[0]");
  }


  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const validate = (data) => {
  let errorObj = {};
  if (data && !data.name) {
    errorObj["name"] = "Project name is Required";
  }
  if (data && !data.content) {
    errorObj["content"] = "Project content is Required";
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const parseError = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.name) {
    errorObj["name"] = get(err, "name[0]");
  }
  if (err && err.content) {
    errorObj["content"] = get(err, "content[0]");
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

