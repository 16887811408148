import React from "react";
import { Layout } from "antd";
import AppRouter from "./AppRouter";
import "./styles/App.scss";

import OfflineComponent from "./components/OfflineComponent/OfflineComponent";

const { Content } = Layout;

function App() {
  return (
    <Content style={{}}>
      <OfflineComponent />
      <AppRouter />
    </Content>
  );
}

export default App;
