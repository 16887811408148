import React, { Component } from "react";
import { Row, Col } from "antd";
import { get } from 'lodash';
import { connect } from 'react-redux';

// Custom Components
import InputPrimary from "../../../../components/InputPrimary";
import ImageUpload from "../../../../components/ImageUpload";
import ButtonPrimary from "../../../../components/buttons/ButtonPrimary";
import SelectInput from "../../../../components/SelectInput";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import Loader from '../../../../components/PrimaryLoader';
import ErrorModal from '../../../../components/modal/ErrorModal';
import SuccessModal from '../../../../components/modal/SuccessModal';
import PhoneNoComponent from '../../../../components/PhoneNoComponent';

// images
import userImage from "../../../../assets/user-grey.svg";

// Style
import "./styles.scss";
import { PageTitle } from "../../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES } from "../../../../constants";

//Services
import * as DM from '../../dataManager';
import * as RDM from '../../../../common/dataManager';

//Helpers
import { validate, parseError } from '../../parser';
import { getMaxLengthForCountryCode } from '../../../../helpers'

class AdminNew extends Component {

  constructor(props) {
    super();
    this.editableID = null;
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      admin: null,
      roles: []
    }
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    this.getRoles();
    if (this.editableID) {
      await this.getSpecificAdminDetials();
    }
  }


  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.ADMINS);
    })
  }

  getRoles = () => {
    this._startLoader();
    RDM.getAllRolesService({ type: "ADMIN" })
      .then(res => {
        this._stopLoader();
        let rolesArr = get(res, "data.results");
        this.setState({ roles: rolesArr })
      })
      .catch(err => {

        this._stopLoader();
      })
  }

  getSpecificAdminDetials = () => {
    this._startLoader();
    DM.getUserService(this.editableID)
      .then((res) => {
        this.setState({ admin: res.data });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  setStateValue = (value, field) => {
    let state = this.state;
    if (!state.admin) state.admin = {};
    state.admin[`${field}`] = value;
    this.setState(state);
  }

  handleSaveButton = () => {
    const { admin } = this.state;
    const checkValidate = validate(admin, this.editableID);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    let { country_code } = admin;
    if (country_code) {
      if (country_code.includes("(")) {
        country_code = country_code.split("(")[1];
        country_code = country_code.substring(0, country_code.length - 1);
      }
      if (!admin.phone || !admin.phone.includes("-"))
        admin.phone = `${country_code}-${admin.phone}`;
    }
    this._startLoader();
    if (admin.role)
      admin.user_roles = [admin.role];
    else
      admin.user_roles = [get(admin, "roles[0].id")];
    if (this.editableID) {
      DM.editUserService(this.editableID, admin)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Admin updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.createUserService(admin)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Admin created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }

  render() {
    const { admin, isLoader, errorModal, errObj, roles, succModal } = this.state;
    const defaultImageUrl = get(admin, "media.path", null);

    if (admin) {
      if (!admin.country_code && admin.phone && admin.phone.includes("-")) {
        admin.country_code = admin.phone.split("-")[0];
      }
    }

    const rolesDropdown = roles.map(role => {
      return (<option value={role.id} key={role.id}>
        {role.name}
      </option>
      )
    })
    return (
      <>
        <PageTitle title="Admin" />
        <div className="admin-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.ADMINS,
                  { text: this.editableID ? "Edit Admin" : "Add new admin" },
                ]}
              />
              <h4 className="title4">
                {this.editableID ? "Edit Admin" : "Add new admin"}
              </h4>
            </div>
          </div>

          <div className="form-section">
            <p className="title">Basic Details</p>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="input-item">
                  <p className="lable">Profile photo</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={defaultImageUrl ? "Change photo" : "Pick a photo from your computer"}
                    text={defaultImageUrl ? get(admin, "media.file_name") : "(Max file size: 10MB)"}
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "avatar");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">First Name <span className="required-text">*</span></p>
                      <InputPrimary
                        value={get(admin, "first_name")}
                        placeholder="John"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "first_name")
                        }}
                        errorText={get(errObj, "first_name")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Last Name <span className="required-text">*</span></p>
                      <InputPrimary placeholder="Dohe" value={get(admin, "last_name")}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "last_name")
                        }}
                        errorText={get(errObj, "last_name")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Phone number</p>
                      <PhoneNoComponent
                        // children={children}
                        codeVal={get(admin, "country_code")}
                        phoneVal={get(admin, "phone")}
                        onChangeCode={(val) => {
                          this.setStateValue(val, "country_code")
                        }}
                        onChangePhone={(e) => {
                          this.setStateValue(e.target.value, "phone")
                        }}
                        maxLength={getMaxLengthForCountryCode(get(admin, "country_code", null))}
                        errorText={get(errObj, "phone")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Email address <span className="required-text">*</span></p>
                      <InputPrimary
                        disabled={this.editableID ? true : false}
                        value={get(admin, "email")}
                        type="email"
                        placeholder="johndohe@gmail.com"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "email")
                        }}
                        errorText={get(errObj, "email")}
                      />
                    </div>
                  </Col>
                </Row>

                {!this.editableID &&
                  <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">Password <span className="required-text">*</span></p>
                        <InputPrimary
                          type="password" placeholder="password" onChange={(e) => {
                            this.setStateValue(e.target.value, "password")
                          }}
                          errorText={get(errObj, "password")}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">Confirm Password <span className="required-text">*</span></p>
                        <InputPrimary
                          type="password"
                          placeholder="Confirm password"
                          onChange={(e) => {
                            this.setStateValue(e.target.value, "confirmPassword")
                          }}
                          errorText={get(errObj, "confirmPassword")}
                        />
                      </div>
                    </Col>
                  </Row>
                }

                <Row gutter={[20]}>
                  <Col span={24}>
                    <div className="input-item">
                      <p className="lable">Select Role <span className="required-text">*</span></p>
                      <SelectInput
                        selectInputStyle="select-absolute shift-down-select"
                        value={get(admin, "role") || get(admin, "roles[0].name")}
                        placeholder="Select Role"
                        child={rolesDropdown}
                        onChange={(val) => {
                          this.setStateValue(val, "role")
                        }}
                        errorText={get(errObj, "role")}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="mt--40 text-right">
              <ButtonPrimary btnText="Save" href="#" onClick={this.handleSaveButton} />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(AdminNew);
