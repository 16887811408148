import { get, debounce } from "lodash";
import React, { Component } from "react";
import { Space, Avatar, Spin } from "antd";
import { connect } from "react-redux";
import defaultImage from "../../assets/blogs.svg";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../constants";

// Custom Components
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import SelectInput from "../../components/SelectInput";
import SearchPrimary from "../../components/SearchPrimary";
// import ToggleMenu from "../../components/ToggleMenu";
import RemoveModal from "../../components/modal/RemoveModal";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import EmptyState from "../../components/EmptyState";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import PrimaryPagination from "../../components/PrimaryPagination";
import Loader from "../../components/PrimaryLoader";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import BulkImportModal from "../../components/modal/BulkImportModal";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";

//Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Services
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

//Actions
import { setAllModules } from "./actions";
import { breakTimestamp, convertCase } from "../../helpers";

class ModuleScreen extends Component {
  constructor(props) {
    super();
    this.handleSearch = debounce(this.handleSearch, SEARCHDELAY.searchDelay);
    this.getAllModulesData = debounce(
      this.getAllModulesData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      top: "topLeft",
      bottom: "bottomRight",
      removeShowModal: false,
      isTableActive: true,
      requestedForRemoveModule: null,
      meta: {
        page: 1,
        limit: 20,
      },
      isLoader: false,
      successShowModal: null,
      errorShowModal: null,
      filters: {
        status: null,
        keyword: null,
        category_id: null,
        super_category_id: null,
        tag_id: null,
        module_level: null,
      },
      fetching: false,
      categories: [],
      subCategories: [],
      tags: [],
      bulkImportToggleModal: null,
    };
  }

  componentDidMount() {
    this.getAllModulesData();
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.filters[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "keyword") isLoaderBlocked = true;
      this.getAllModulesData(isLoaderBlocked);
    });
  };

  _closeremoveModal = () => {
    this.setState({
      removeShowModal: false,
      requestedForRemoveModule: null,
    });
  };
  _openRemoveModal = () => {
    this.setState({
      removeShowModal: true,
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllModulesData();
    });
  };

  handleSearch = (entity, value) => {
    if (value && entity === "sub-category") {
      this.setState({
        subCategories: [],
        fetching: true,
      });
      CDM.getAllSubCategoriesService({ keyword: value })
        .then((res) => {
          let subCategories = get(res, "data.results");
          this.setState({ subCategories, fetching: false });
        })
        .catch((err) => {
          this.setState({ subCategories: [], fetching: false });
        });
    }

    if (value && entity === "category") {
      this.setState({
        categories: [],
        fetching: true,
      });
      CDM.getAllCategoriesService({ keyword: value })
        .then((res) => {
          let categories = get(res, "data.results");
          this.setState({ categories, fetching: false });
        })
        .catch((err) => {
          this.setState({ categories: [], fetching: false });
        });
    }

    if (value && entity === "tags") {
      this.setState({
        tags: [],
        fetching: true,
      });
      CDM.getAllTagsService({ keyword: value, status: "ACTIVE" })
        .then((res) => {
          let tags = get(res, "data.results");
          this.setState({ tags, fetching: false });
        })
        .catch((err) => {
          this.setState({ tags: [], fetching: false });
        });
    }
  };

  getAllModulesData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) this._startLoader();
    const { meta, filters } = this.state;
    let queryObject = {
      search_type: "CUSTOM",
      page: meta.page,
      limit: meta.limit,
      order_by: "DESC",
      include: "createdby",
    };
    const {
      status,
      keyword,
      category_id,
      tag_id,
      module_level,
      super_category_id,
    } = filters;
    if (status && status !== "All")
      queryObject.status = status === "Published" ? "PUBLISH" : "UNPUBLISH";
    if (module_level && module_level !== "ALL")
      queryObject.module_level = module_level;
    if (keyword) {
      queryObject.keyword = keyword;
      delete queryObject.order_by;
    }
    if (category_id) queryObject.category_id = category_id;
    if (super_category_id) queryObject.super_category_id = super_category_id;
    if (tag_id) queryObject.tag_id = tag_id;
    CDM.getAllModulesService(queryObject)
      .then((res) => {
        this.props.setAllModules(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  hanldeRemoveModule = () => {
    this._startLoader();
    const { requestedForRemoveModule } = this.state;
    DM.deleteModuleService(requestedForRemoveModule)
      .then((res) => {
        this._closeremoveModal();
        this.setState({
          successShowModal: {
            msg: "Module Deleted Successfully",
          },
        });
        this.getAllModulesData();
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this._closeremoveModal();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  handleExportList = () => {
    this._startLoader();
    DM.exportModulesList()
      .then((res) => {
        this.setState({
          successShowModal: {
            msg: get(res, "data.message"),
          },
        });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  bulkImportToggleModal = () => {
    this.setState({
      bulkImportModal: this.state.bulkImportModal ? null : {},
    });
  };

  uploadKeyword = (file) => {
    this.bulkImportToggleModal();
    this._startLoader();
    DM.postSeedKeyword({ file: file })
      .then((res) => {
        this.setState({
          successShowModal: {
            msg: get(res, "data.message"),
          },
        });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };
  uploadSIForKeyword = (file) => {
    this.bulkImportToggleModal();
    this._startLoader();
    DM.postSeedSIKeyword({ file: file })
      .then((res) => {
        this.setState({
          successShowModal: {
            msg: get(res, "data.message"),
          },
        });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };
  uploadSIForModule = async (file) => {
    this.bulkImportToggleModal();
    this._startLoader();
    DM.postSimilarityIndex({ file: file })
      .then((res) => {
        this.setState({
          successShowModal: {
            msg: get(res, "data.message"),
          },
        });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      successShowModal,
      errorShowModal,
      // filters,
      meta,
      bulkImportModal,
    } = this.state;

    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "modules.allModules.results", null);
    const totalModules = get(
      this.props,
      "modules.allModules.meta.pagination.total",
      null,
    );
    const limit = get(
      this.props,
      "modules.allModules.meta.pagination.count",
      null,
    );
    const categoryChild =
      this.state.categories &&
      this.state.categories.map((category) => {
        return (
          <option value={category.id} key={category.id}>
            {category.name}
          </option>
        );
      });
    const subcategoryChild =
      this.state.subCategories &&
      this.state.subCategories.map((subCategory) => {
        return (
          <option value={subCategory.id} key={subCategory.id}>
            {subCategory.name}
          </option>
        );
      });
    const tagChild =
      this.state.tags &&
      this.state.tags.map((tag) => {
        return (
          <option value={tag.id} key={tag.id}>
            {tag.name}
          </option>
        );
      });

    return (
      <>
        <PageTitle title="Modules" />
        <div className="module-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Modules" }]} />
              <h4 className="title4"> List of all modules</h4>
            </div>
            <div className="right-section">
              <ButtonPrimary
                btnText="Bulk Import"
                onClick={this.bulkImportToggleModal}
              />
              <ButtonPrimary
                btnText="Export List"
                onClick={() => this.handleExportList()}
              />
              {permissions.MODULES &&
                permissions.MODULES.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new module"
                    href={ROUTES.NEWMODULE}
                  />
                )}
            </div>
          </div>

          <div className="find-section">
            <SelectInput
              style={{ width: 130 }}
              isTitleActive={true}
              title="Filter by status:"
              placeholder="Select status"
              options={["All", "Published", "Unpublished"]}
              defaultValue="All"
              onChange={(val) => {
                this.setStateValue(val, "status");
              }}
            />
            <SelectInput
              selectInputStyle="mr--0-imp"
              style={{ width: 130 }}
              isTitleActive={true}
              title="Filter by level:"
              placeholder="Select level"
              options={["All", "Beginner", "Intermediate", "Advanced"]}
              defaultValue="All"
              onChange={(val) => {
                this.setStateValue(val.toUpperCase(), "module_level");
              }}
            />
          </div>
          <div className="find-section mt--30">
            <div className="search-boxes fx fx--ai-c">
              <SearchPrimary
                placeholder="Search by keyword"
                onSearch={(e) => this.setStateValue(e.target.value, "keyword")}
              />

              <SelectInput
                selectInputStyle="ml--10"
                style={{ width: 170 }}
                allowClear={true}
                placeholder="Search by category"
                showSearch={true}
                // value={filters.super_category_id}
                child={categoryChild}
                onChange={(value) => {
                  this.setStateValue(value, "super_category_id");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("category", value)}
              />

              <SelectInput
                style={{ width: 200 }}
                allowClear={true}
                placeholder="Search by sub-category"
                showSearch={true}
                // value={filters.category_id}
                child={subcategoryChild}
                onChange={(value) => {
                  this.setStateValue(value, "category_id");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("sub-category", value)}
              />

              <SelectInput
                selectInputStyle="mr--0-imp"
                style={{ width: 170 }}
                allowClear={true}
                showSearch={true}
                placeholder="Search by tags"
                // value={defaultTags}
                child={tagChild}
                onChange={(value) => {
                  this.setStateValue(value, "tag_id");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("tags", value)}
              />
            </div>
          </div>

          <div className="table-section mt--30">
            {this.props.isTableActive ||
              !dataColumns ||
              dataColumns.length === 0 ? (
              <EmptyState
                title="Oops!"
                description="No Record Found &#128533;"
              />
            ) : (
              <div className="table-card-style">
                <div className="table-style module-list-style table-responsive">
                  <table className="table-custom-style">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Likes</th>
                      <th>Views</th>
                      <th>Level</th>
                      <th>Category</th>
                      <th>Sub-Category</th>
                      <th>Created by</th>
                      <th>Published on</th>
                      <th>Status</th>
                      <th> Actions </th>
                    </tr>

                    {dataColumns &&
                      dataColumns.map((data, index) => {
                        return (
                          <tr className="data-row table-module" key={index}>
                            <td>
                              <p className="lead">
                                {get(data, "rawid") || "---"}
                              </p>
                            </td>
                            <td>
                              <div className="img-content-style">
                                <Avatar
                                  shape="square img-avatar"
                                  className="mr--20"
                                  src={
                                    get(data, "media[0].path", null) ||
                                    defaultImage
                                  }
                                />
                                <p className="lead module-name trim" title={get(data, "name")}>
                                  {get(data, "name")}
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="lead">
                                {get(data, "likes") ||
                                  "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {get(data, "views") ||
                                  "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {convertCase(get(data, "module_level")) ||
                                  "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {get(data, "categories[0].categories.name") ||
                                  "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {get(data, "categories[0].name")}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {get(data, "createdby.full_name") || "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {(data.publish_at &&
                                  `${breakTimestamp(get(data, "publish_at")).date
                                  } ${breakTimestamp(get(data, "publish_at")).time
                                  }`) ||
                                  "---"}
                              </p>
                            </td>
                            <td>
                              <div className="flex-box">
                                <span
                                  className={`circle ${ReturnStatusColor(
                                    get(data, "status"),
                                  )} mr-2`}></span>
                                <p className="lead mb--0">
                                  {convertCase(get(data, "status")) + "ed"}
                                  {data.featured && " (Featured)"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <Space size="middle">
                                {permissions.MODULES &&
                                  permissions.MODULES.includes(
                                    ACTIONS.UPDATE,
                                  ) && (
                                    <ButtonOutline
                                      btnText={<EditOutlined />}
                                      // btnStyle="btn-sm"
                                      btnStyle="btn edit"
                                      clickHandler={() =>
                                        this.props.history.push(
                                          ROUTES.EDITMODULE.replace(
                                            ":id",
                                            get(data, "id"),
                                          ),
                                        )
                                      }
                                    />
                                  )}
                                <ButtonPrimary
                                  btnText={<EyeOutlined />}
                                  btnPrimaryStyle="btn view"
                                  onClick={() =>
                                    this.props.history.push(
                                      ROUTES.VIEWMODULE.replace(
                                        ":id",
                                        get(data, "id"),
                                      ),
                                    )
                                  }
                                />
                                {permissions.MODULES &&
                                  permissions.MODULES.includes(
                                    ACTIONS.DELETE,
                                  ) && (
                                    <ButtonDanger
                                      type="link"
                                      btnText={<DeleteOutlined />}
                                      btnDangerStyle="btn delete btn-link"
                                      onClick={() => {
                                        this.setState({
                                          requestedForRemoveModule: get(
                                            data,
                                            "id",
                                          ),
                                        });
                                        this._openRemoveModal();
                                      }}>
                                      Remove
                                    </ButtonDanger>
                                  )}

                                {/* <ToggleMenu>
                                     <Button
                                    type="link"
                                    href={`/edit-modules/${get(data, "id")}`}
                                    className="btn-link">
                                    Edit
                                  </Button> */}
                                {/* {data.status === "PUBLISH" && (
                                      <Button type="link" className="btn-link">
                                        Unpublish
                                      </Button>
                                    )}
                                    {data.status === "UNPUBLISH" && (
                                      <Button type="link" className="btn-link">
                                        Published
                                      </Button>
                                    )}
                                  </ToggleMenu> */}
                              </Space>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
                <div className="total-blog-count">
                  {limit + "/" + totalModules} resources
                </div>
                <div className="page-section">
                  <PrimaryPagination
                    pageSize={meta.limit}
                    current={meta.page}
                    total={totalModules}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>

          {isLoader && <Loader />}

          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this._closeremoveModal}
            title="Remove this module"
            description="Are you sure you want to remove this module?"
            // Action button event here
            removeHandler={this.hanldeRemoveModule}
            dontRemoveHandler={this._closeremoveModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}

          <BulkImportModal
            isModalVisible={bulkImportModal}
            onCancel={() => {
              this.setState({ bulkImportModal: null });
            }}
            uploadKeyword={this.uploadKeyword}
            uploadSIForKeyword={this.uploadSIForKeyword}
            uploadSIForModule={this.uploadSIForModule}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};
export default connect(mapStateToProps, {
  setAllModules,
})(ModuleScreen);
