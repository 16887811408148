import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam } from '../../helpers';

export const createPackage = async (payload) => {
    let url = API.POST_CREATE_PACKAGE;
    return await axiosInstance.post(url, payload);
};

export const getAllPackages = async (params) => {
    const url = `${API.GET_ALL_PACKAGES}${makeQueryParam(params)}`
    return await axiosInstance.get(url);
};

export const getPackage = async (id, params) => {
    let url = API.GET_SPECIFIC_PACKAGE.replace(":id", id);
    url = `${url}${makeQueryParam(params)}`
    return await axiosInstance.get(url);
};

export const updatePackage = async (id, payload) => {
    let url = API.PATCH_UPDATE_PACKAGE.replace(":id", id);
    return await axiosInstance.patch(url, payload);
};

export const deletePackage = async (id) => {
    let url = API.DELETE_PACKAGE.replace(":id", id);
    return await axiosInstance.delete(url);
};

export const getAllCurrencies = async (params) => {
    const url = `${API.GET_ALL_CURRENCIES}${makeQueryParam(params)}`
    return await axiosInstance.get(url);
};