import { get, debounce } from "lodash";
import React, { Component } from "react";
import { Row, Col, Button } from "antd";

import defaultImage from "../../../assets/blogs.svg";

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import TextareaPrimary from "../../../components/TextareaPrimary";
import ImageUpload from "../../../components/ImageUpload";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import PrimaryAutoComplete from "../../../components/PrimaryAutoComplete";
import Loader from "../../../components/PrimaryLoader";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";
import { PageTitle } from "../../../components/PageTitle";

// Style
import "./styles.scss";
import ImageModuleCard from "../../../components/ImageModuleCard";

//Data Managers
import * as DM from "../dataManager";
import * as CDM from "../../../common/dataManager";

//Parser
import { validate, parseError } from "../parser";

//Constants
import { BREADCRUMBS, ROUTES, SEARCHDELAY } from "../../../constants";

class NewCallectionScreen extends Component {
  constructor(props) {
    super();
    this.handleAutoComplete = debounce(
      this.handleAutoComplete,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      shown: true,
      isLoader: false,
      successShowModal: null,
      errorShowModal: null,
      originalName: null,
      collection: {
        status: "ACTIVE",
        modules: [],
      },
      errorObj: null,
      autoCompleteVal: "",
      autoCompleteOptions: [],
      moduleImages: [],
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificCollectionId(editableID);
    }
  }

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    state.collection[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handleSizeChange = () => {
    this.setState({ shown: !this.state.shown });
  };

  triggerDelete(task, index) {
    if (window.confirm("Are you sure you want to delete this task?")) {
      let taskList = [...this.state.taskList];
      taskList.splice(index, 1);
      this.setState({ taskList: taskList });
    }
  }

  fillModuleImages = () => {
    let moduleImages = [];

    let modules = get(this.state.collection, "modules");
    modules &&
      modules.map((module) => {
        moduleImages.push({
          imgUrl: get(module, "media.path", null),
          title: module.name,
          id: module.id,
        });
        return null;
      });

    this.setState({ moduleImages });
  };

  //API Calling Function
  getSpecificCollectionId = async (id) => {
    this._startLoader();
    await DM.getSpecificCollectionServies(id)
      .then((res) => {
        this.setState({ collection: res, originalName: get(res, "name") });
        this.fillModuleImages();
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handleAutoComplete = (value) => {
    CDM.getAllModulesService({
      search_type: "CUSTOM",
      status: "PUBLISH",
      page: 1,
      limit: 10,
      keyword: value,
    })
      .then((res) => {
        let modules = get(res, "data.results");
        modules = modules.map((module) => {
          return {
            label: module.name,
            value: [
              module.name,
              get(module, "id", ""),
              get(module, "media[0].path", ""),
            ],
          };
        });
        let selectedModules = this.state.collection.modules;
        modules = modules.filter((module) => {
          return !selectedModules.includes(module.value[1]);
        });
        this.setState({ autoCompleteOptions: modules });
      })
      .catch((err) => {});
  };

  onSelect = (value) => {
    let { moduleImages, collection } = this.state;
    let modulesArr = collection.modules;
    if (modulesArr.includes(value[1])) {
      this.setState({ autoCompleteVal: "" });
      return;
    }
    modulesArr.push(value[1]);
    moduleImages.push({
      imgUrl: value[2],
      title: value[0],
      id: value[1],
    });
    this.setState({
      autoCompleteVal: "",
      collection: { ...this.state.collection, modules: modulesArr },
      moduleImages,
    });
  };

  removeModule = (id) => {
    let selectedModules = this.state.collection.modules;
    let selectedModuleImages = this.state.moduleImages;
    selectedModules = selectedModules.filter((module) => {
      if (typeof module === "object") return module.id !== id;
      return module !== id;
    });
    selectedModuleImages = selectedModuleImages.filter((module) => {
      return module.id !== id;
    });
    this.setState({
      moduleImages: selectedModuleImages,
      collection: { ...this.state.collection, modules: selectedModules },
    });
  };

  handleOnClick = async () => {
    const { collection, originalName } = this.state;

    const checkValidate = validate(collection);

    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorShowModal: { msg: "Validation error occurred" },
      });
      return;
    }

    this._startLoader();
    const editableID = this.props.match.params.id;
    if (editableID) {
      if (collection.name && collection.name === originalName)
        delete collection.name;
      delete collection.media;
      delete collection.id;
      delete collection.no_of_modules;
      collection.modules =
        collection.modules &&
        collection.modules.map((module) => {
          if (typeof module === "object") return module.id;
          return module;
        });
      DM.patchUpdateCollectionService(editableID, collection)
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: { msg: "Collection is updated." },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: { msg: "Validation error occurred" },
          });
        });
    } else {
      await DM.postCreateCollectionService(collection)
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: { msg: "Collection is created." },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: { msg: "Validation error occurred" },
          });
        });
    }
  };

  render() {
    const {
      isLoader,
      collection,
      errorObj,
      successShowModal,
      errorShowModal,
      autoCompleteOptions,
      autoCompleteVal,
    } = this.state;

    const status = get(collection, "status");
    const editableID = this.props.match.params.id;
    let dataColumns = this.state.moduleImages;
    // const dataColumns = [
    //   {
    //     imgUrl:
    //       "https://qnlo.dk/wp-content/uploads/2020/05/pfSNx3Z12K8-1280x720.jpeg",
    //     title: "title",
    //   }
    // ];

    return (
      <>
        <PageTitle title="Add New Collections" />
        <div className="new-callection-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.COLLECTIONS,
                  editableID
                    ? { text: "Edit collection" }
                    : { text: "Add New collection" },
                ]}
              />
              <h4 className="title4">
                {editableID ? "Edit collection" : "Add new collection"}
              </h4>
            </div>
            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${
                    status === "ACTIVE" ? "btn-active" : ""
                  }`}
                  onClick={() => {
                    this.setStateValue("ACTIVE", "status");
                  }}>
                  Active
                </Button>
                <Button
                  className={`btn-toggle ${
                    status === "INACTIVE" ? "btn-active" : ""
                  }`}
                  onClick={() => {
                    this.setStateValue("INACTIVE", "status");
                  }}>
                  Inactive
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="input-item">
              <p className="lable">
                Collection Name <span className="required-text">*</span>
              </p>
              <InputPrimary
                value={get(collection, "name")}
                placeholder="Collection Name"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "name");
                }}
                errorText={get(errorObj, "name")}
              />
            </div>

            <div className="input-item">
              <p className="lable">
                Description <span className="required-text">*</span>
              </p>
              <TextareaPrimary
                value={get(collection, "description")}
                rows={4}
                placeholder="Description"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "description");
                }}
                errorText={get(errorObj, "description")}
              />
            </div>

            <div className="input-item">
              <p className="lable">Cover Image</p>
              <ImageUpload
                defaultImage={true}
                subText={editableID ? "Change Photo" : "Upload Photo"}
                text={
                  editableID
                    ? get(collection, "media[0].file_name")
                    : "(Max file size: 10MB)"
                }
                defaultImageUrl={get(collection, "media[0].path")}
                onChange={(file) => {
                  let originalFileObject = file.originFileObj;
                  this.setStateValue(originalFileObject, "mainImage");
                  this.setStateValue(file, "rawFile");
                }}
              />
            </div>

            <div className="input-item">
              <p className="lable">Add module</p>

              <PrimaryAutoComplete
                value={autoCompleteVal}
                onChange={(val) => {
                  this.setState({
                    autoCompleteVal: val,
                  });
                }}
                placeholder="Search module name…"
                options={autoCompleteOptions}
                onSearch={(value) => {
                  this.handleAutoComplete(value);
                }}
                onSelect={this.onSelect}
                errorText={get(errorObj, "modules")}
              />
              {/* <SearchPrimary
                placeholder="Search module name…"
                enterButton="Search"
                onSearch={(value) => {
                }}
              /> */}
            </div>

            <div className="module-sec">
              <Row gutter={16}>
                {dataColumns.map((data, index) => {
                  return (
                    <Col
                      className="gutter-row mt--50"
                      xs={12}
                      md={4}
                      key={index}>
                      <ImageModuleCard
                        removeModule={() => {
                          this.removeModule(data.id);
                        }}
                        imgUrl={get(data, "imgUrl") || defaultImage}
                        title={data.title}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>

            <div className="mt--40">
              <ButtonPrimary
                btnText="Save"
                onClick={() => {
                  this.handleOnClick();
                }}
              />
            </div>
          </div>

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
                this.props.history.push(ROUTES.COLLECTIONS);
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

export default NewCallectionScreen;
