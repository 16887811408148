import { getWebScrappingDataService } from "../../../../../../../containers/Modules/dataManager";

const classPrefix = "acadru";

export const getEmbedModel = (editor) => {
  // const _this = editor;
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  const defaultModel = defaultType.model;

  return defaultModel.extend(
    {
      defaults: {
        ...defaultModel.prototype.defaults,
        type: "embed",
        tagName: "embed",
        void: 0,
        editable: 1,
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        highlightablw: 0,
        source: [],
        resizable: { ratioDefault: 1 },
        attributes: { allowfullscreen: "allowfullscreen" },
      },

      initialize(o, opt) {
        this.em = opt.em;
        this.updateTraits();
        var attr = this.get("attributes");
        if (attr.src) this.set("src", attr.src);
        this.listenTo(this, "change:src", this.updateSrc);
        defaultModel.prototype.initialize.apply(this, arguments);
      },

      /**
       * Adding Traits on runtime
       * @private
       */
      updateTraits() {
        let tagName = "div";
        let traits = this.getSourceTraits();

        this.set({ tagName }, { silent: 1 }); // avoid break in view
        this.set({ traits });
        this.em.trigger("component:toggled");
      },

      getSourceTraits() {
        return [
          {
            label: "Source",
            type: "text",
            name: "src",
            placeholder: "eg. https://www.google.com",
            changeProp: 1,
          },
        ];
      },

      /**
       * Change src on change src traits
       * @private
       */
      async updateSrc() {
        const src = this.get("src");
        const templete = await this.getWebScappingData(src);
        this.components(templete);
        this.set({ src });
      },

      async getWebScappingData(URL) {
        var templete = "";

        return await getWebScrappingDataService({ url: `${URL}` })
          .then((res) => {
            const {
              title,
              description,
              video,
              image,
              favicon,
              appName,
            } = res.data;

            templete = `
            <div class="${classPrefix}-embedMain" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            <div class="${classPrefix}-embedHead" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            ${favicon
                ? `<div class="${classPrefix}-embedFaviconBox"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
                <img src="${favicon}" alt="" class="${classPrefix}-embedFavicon"   data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" />
              </div>`
                : `<i class="fa fa-globe" aria-hidden="true" style="margin-right: 5px"></i> `
              }
              <div class="${classPrefix}-embedAppName"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
                ${appName.toUpperCase()}
              </div>
            </div>
            <a class="${classPrefix}-embedBoxLink" href="${URL}" target="_blank" data-gjs-draggable="false" data-gjs-removable="false">
        
              ${video
                ? `
              <div class="${classPrefix}-embedVideoBox video" style="min-height: 350px;" height="350px" width="100%" data-gjs-draggable="false" data-gjs-removable="false">
                <div>
                  <iframe data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" width="100%" height="350px" src="${video}" title="${title}"></iframe>
                </div>
              </div>
                  `
                : `
              <div class="${classPrefix}-embedImgBox"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
                <img
                  class="${classPrefix}-embedImg"
                  src="${image}"
                  alt="embed img"
                  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"
                  />
              </div>
                  `
              }
            </a>
            <div class="${classPrefix}-embedTitle"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
              <a href="${URL}" target="_blank"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">${title}</a>
            </div>
            <div class="${classPrefix}-embedDescription"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">${description}</div>
            <div class="${classPrefix}-embedLinkBox"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
              <a href="${URL}" target="_blank" class="${classPrefix}-embedLink"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
               ${video ? "View more" : "Read more"
              }  ${appName.toUpperCase()} <i class="fa fa-chevron-right"></i>
              </a>
            </div>
          </div>
            `;
            return templete;
          })
          .catch((err) => { });
      },
    },
    {
      /**
       * Detect is passed element is a valid component.
       * In case the element is valid an object abstracted
       * form the element will re returened
       * @param {HTMLElement}
       * @return {object}
       * @private
       */
      isComponent: (el) => el.tagName === "EMBED",
    },
  );
};
