export const acadruSpacerBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Spacer 10
  blockManager.add("Spacer10", {
    label: "Spacer 10",
    category: {
      id: "Acadru Spacer",
      label: "Acadru Spacer",
      open: false,
    },
    attributes: { class: "fa fa-arrows-v" },
    content: `
    <div class="${classPreFix}-divider-box">
      ${rowCol6Start}
        <div class="${classPreFix}-divider spacer-10" data-gjs-droppable="false" data-gjs-draggable="false">
          <div class="spacer" data-gjs-droppable="false" data-gjs-draggable="false"></div>
        </div>
      ${rowCol6End}
    </div>
    `,
  });
  // Spacer 20
  blockManager.add("Spacer20", {
    label: "Spacer 20",
    category: "Acadru Spacer",
    attributes: { class: "fa fa-arrows-v" },
    content: `
    <div class="${classPreFix}-divider-box">
      ${rowCol6Start}
        <div class="${classPreFix}-divider spacer-20" data-gjs-droppable="false" data-gjs-draggable="false">
          <div class="spacer" data-gjs-droppable="false" data-gjs-draggable="false"></div>
        </div>
      ${rowCol6End}
    </div>
    `,
  });
  // Spacer 30
  blockManager.add("Spacer30", {
    label: "Spacer 30",
    category: "Acadru Spacer",
    attributes: { class: "fa fa-arrows-v" },
    content: `
    <div class="${classPreFix}-divider-box">
      ${rowCol6Start}
        <div class="${classPreFix}-divider spacer-30" data-gjs-droppable="false" data-gjs-draggable="false">
          <div class="spacer" data-gjs-droppable="false" data-gjs-draggable="false"></div>
        </div>
      ${rowCol6End}
    </div>
    `,
  });
  // Spacer 40
  blockManager.add("Spacer40", {
    label: "Spacer 40",
    category: "Acadru Spacer",
    attributes: { class: "fa fa-arrows-v" },
    content: `
    <div class="${classPreFix}-divider-box">
      ${rowCol6Start}
        <div class="${classPreFix}-divider spacer-40" data-gjs-droppable="false" data-gjs-draggable="false">
          <div class="spacer" data-gjs-droppable="false" data-gjs-draggable="false"></div>
        </div>
      ${rowCol6End}
    </div>
    `,
  });
  // Spacer 50
  blockManager.add("Spacer50", {
    label: "Spacer 50",
    category: "Acadru Spacer",
    attributes: { class: "fa fa-arrows-v" },
    content: `
    <div class="${classPreFix}-divider-box">
      ${rowCol6Start}
        <div class="${classPreFix}-divider spacer-50" data-gjs-droppable="false" data-gjs-draggable="false">
          <div class="spacer" data-gjs-droppable="false" data-gjs-draggable="false"></div>
        </div>
      ${rowCol6End}
    </div>
    `,
  });
};
