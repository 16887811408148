import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam, objectToFormdata } from "../../helpers";

export const getAllBlogs = async (params) => {
  let url = `${API.GET_ALL_BLOGS}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
};

export const getSpecificBlogs = async (id) => {
  let url = API.GET_BLOG.replace(":id", id);
  return await axiosInstance.get(url);
};

export const createBlog = async (payload) => {
  let url = API.POST_CREATE_BLOG;
  return await axiosInstance.post(url, objectToFormdata(payload));
};

export const updateBlog = async (id, payload) => {
  let url = API.PATCH_UPDATE_BLOG.replace(":id", id);
  return await axiosInstance.patch(url, objectToFormdata(payload));
};

export const deleteBlog = async (id) => {
  let url = API.DELETE_BLOG.replace(":id", id);
  return await axiosInstance.delete(url);
};
