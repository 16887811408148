import React, { Component } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;

class PrimaryLoader extends Component {
  render() {
    return (
      <div className="loader-style">
        <Spin indicator={antIcon} />
      </div>
    );
  }
}

export default PrimaryLoader;
