import { get } from 'lodash';

export const validate = (data) => {
    let errorObj = {};
    if (!data.name) {
        errorObj["name"] = "Name is Required";
    }
    if (data.permissions && data.permissions.length === 0)
        errorObj["permissions"] = "At least one permission is Required";

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};

export const parseError = (Error) => {
    const err = get(Error, "response.data.error");
    let errorObj = {};

    if (err && err.name) {
        errorObj["name"] = get(err, "name[0]");
    }

    if (get(Error, "response.data.message", null)) {
        errorObj["permissions"] = get(Error, "response.data.message");
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};