import React, { useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { getMonthNameFromNumber } from '../../helpers/handleDates';
import "./styles.scss";
const LineChart = ({ data, month1, month2, month3 }) => {
    const month1Name = getMonthNameFromNumber(month1.month, 'month1');
    const month2Name = getMonthNameFromNumber(month2.month, 'month2');
    const month3Name = getMonthNameFromNumber(month3.month, 'month3');
    const [year1, year2, year3] = [(month1.year) % 100, (month2.year) % 100, (month3.year) % 100];
    let mins = [];
    let vTicks = [];
    for (let i = 0; i < data.length; i++) {
        mins.push(data[i].mins);
    }
    if (mins.length !== 0) {
        const maxTimeInHours = Math.max.apply(null, mins) / 60;
        const res = Math.round(Math.ceil(maxTimeInHours / 10)) * 10;
        for (let i = 1; i <= 5; i++) {
            vTicks.push((i / 5) * res);
        }
    } else {
        vTicks = [0, 5, 10, 15, 20, 25, 30];
    }

    const result = [['Day', month1Name, month2Name, month3Name]];

    for (let day = 1; day <= 31; day++) {
        let dailyData = [day, 0, 0, 0];

        data.forEach(item => {
            if (
                item.year === month1.year &&
                item.month === month1.month &&
                item.day === day
            ) {
                dailyData[1] += item.mins / 60;
            } else if (
                item.year === month2.year &&
                item.month === month2.month &&
                item.day === day
            ) {
                dailyData[2] += item.mins / 60;
            } else if (
                item.year === month3.year &&
                item.month === month3.month &&
                item.day === day
            ) {
                dailyData[3] += item.mins / 60;
            }
        });

        result.push(dailyData);
    }
    let [month1total, month2total, month3total] = [0, 0, 0]
    for (let i = 1; i < result.length; i++) {
        result[i][1] = Number(result[i][1]);
        result[i][2] = Number(result[i][2]);
        result[i][3] = Number(result[i][3]);

        month1total += result[i][1];
        month2total += result[i][2];
        month3total += result[i][3];

        result[i][1] = Number(result[i][1].toFixed(2));
        result[i][2] = Number(result[i][2].toFixed(2));
        result[i][3] = Number(result[i][3].toFixed(2));
    }

    const seriesNames = [`${month1Name} ${year1 || ''} (${Number(month1total.toFixed(2)) || 0} hrs)`, `${month2Name} ${year2 || ''} (${Number(month2total.toFixed(2)) || 0} hrs)`, `${month3Name} ${year3 || ''} (${Number(month3total.toFixed(2)) || 0} hrs)`];
    const colors = ['blue', 'green', 'orange'];

    const options = {
        hAxis: {
            title: 'Days',
            logScale: false,
        },
        vAxis: {
            title: 'Number of Hours',
            logScale: false,
            ticks: vTicks,
        },
        colors: colors,
        legend: { position: 'none' },
    };

    useEffect(() => {
        const chartWrapper = document.getElementById('chart-wrapper');
        const tooltipDiv = document.getElementById('chart-tooltip');

        if (chartWrapper && chartWrapper.children.length === 1) {
            const legendDiv = document.createElement('div');
            legendDiv.id = 'legend-wrapper';
            legendDiv.className = 'legend-wrapper';
            seriesNames.forEach((name, index) => {
                const legendItem = document.createElement('div');
                legendItem.className = 'legend-item';

                const colorLine = document.createElement('div');
                colorLine.style.backgroundColor = colors[index];
                colorLine.className = 'color-line';

                legendItem.appendChild(colorLine);

                const text = document.createElement('span');
                text.textContent = name;
                text.className = 'span'

                legendItem.appendChild(text);
                legendDiv.appendChild(legendItem);
            });
            chartWrapper.appendChild(legendDiv);
        }
    }, [colors, seriesNames]);

    return (
        <div className="chart-wrapper">
            <div id="chart-wrapper" className="chart-wrapper">
                <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="LineChart"
                    data={result}
                    options={options}
                />
                <div id="chart-tooltip" className="chart-tooltip" />
            </div>
        </div>
    );
};

export default LineChart;
