import React, { Component } from 'react';
import InputPrimary from '../InputPrimary';
import SelectInput from '../SelectInput';
import "./styles.scss";

import { CODES } from "../../constants";

class PhoneNoComponent extends Component {
  render() {

    let {
      codeVal,
      phoneVal,
      onChangeCode,
      onChangePhone,
      maxLength,
      errorText
    } = this.props;

    if (phoneVal && phoneVal.includes("-")) phoneVal = phoneVal.split("-")[1];


    const phoneOptions = CODES.countryCodes;
    const children = phoneOptions.map(code => {
      return (<option value={code.value} key={code.value}>
        <span className="country-flag">
          <img className="flag-icon" src={code.flag} alt="flag" />
        </span>
        <span className="country-code">{code.value}</span>
      </option>
      )
    });

    return (
      <>
        <div className="phone-no-box fx fx--ai-c">
          <div className="country-code-box">
            <SelectInput
              selectInputStyle="select-absolute shift-up-phone"
              value={codeVal}
              child={children}
              onChange={onChangeCode}
              placeholder="Country Code"
            />
          </div>
          <div className="tel-box">
            <InputPrimary
              value={phoneVal}
              type="tel"
              maxLength={maxLength}
              placeholder="91xxxxxx00"
              onChange={onChangePhone}
              errorText={errorText}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9]/g, '')
                  .replace(/(\..*?)\..*/g, '$1')
                  .replace(/^0[^.]/, '0');
              }}
            />
          </div>

        </div>
      </>
    );
  }
}

export default PhoneNoComponent;