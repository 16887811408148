import React, { Component } from "react";
import { DeleteFilled } from "@ant-design/icons";

// Style
import "./styles.scss";

class ImageModuleCard extends Component {
  render() {
    const { title, imgUrl, removeModule } = this.props;

    return (
      <div className="image-module-card-style">
        <div className="module-box">
          <img className="module-image" src={imgUrl} alt="module_img" />
          <button onClick={removeModule} className="delete-btn" title="Delete">
            <DeleteFilled />
          </button>
        </div>
        <p className="lead mt--10">{title}</p>
      </div>
    );
  }
}

export default ImageModuleCard;
