export const getIFrameModel = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  const defaultModel = defaultType.model;

  return defaultModel.extend(
    {
      defaults: {
        ...defaultModel.prototype.defaults,
        type: "iframe",
        tagName: "iframe",
        resizable: { ratioDefault: 1 },
        attributes: { allowfullscreen: "allowfullscreen" },
        traits: [
          {
            type: "text",
            label: "src",
            name: "src",
          },
        ],
      },

      src: "",

      initialize(o, opt) {
        defaultModel.prototype.initialize.apply(this, arguments);
        var attr = this.get("attributes");
        if (attr.src) this.set("src", attr.src);
      },
    },
    {
      /**
       * Detect is passed element is a valid component.
       * In case the element is valid an object abstracted
       * form the element will re returened
       * @param {HTMLElement}
       * @return {object}
       * @private
       */
      isComponent: (el) => el.tagName === "IFRAME",
    },
  );
};
