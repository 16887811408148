import { get, isEmpty } from "lodash";
import { CODES } from "../../constants";
import { ENUMS } from "./Constants";

export const validate = (data, isEdit, type = false, key = '') => {
  let errorObj = {};
  if (!data.first_name) {
    errorObj["first_name"] = "First Name is Required";
  }
  if (!data.last_name) {
    errorObj["last_name"] = "Last Name is Required";
  }
  if (!data.email) {
    errorObj["email"] = "Email is Required";
  }
  if (!isEdit) {
    if (!data.password) {
      errorObj["password"] = "Password is Required";
    }
    if (!data.confirmPassword) {
      errorObj["confirmPassword"] = "Confirm Password is Required";
    } else if (data.confirmPassword !== data.password) {
      errorObj["confirmPassword"] = "Confirm Password should be same.";
    }
  }

  if (data.role && data.role === "") {
    errorObj["role"] = "Role is Required";
  }

  if (data.phone && !data.country_code) {
    errorObj["phone"] = "Country code is required with phone number";
  }

  if (type && type === ENUMS.LIBRARY) {
    if (data?.meta?.location[0]?.latitude === 0 || isNaN(data?.meta?.location[0]?.latitude)) errorObj["latitude"] = "Latitude is required and must be a number greater than 0";

    if (data?.meta?.location[0]?.longitude === 0 || isNaN(data?.meta?.location[0]?.longitude)) errorObj["longitude"] = "Longitude is required and must be a number greater than 0";

    if (data?.max_login_sessions === 0 || isNaN(data?.max_login_sessions)) errorObj["max_login_sessions"] = "Session count is required and must be a number greater than 0";

    if (data?.meta?.distance === 0 || isNaN(data?.meta?.distance)) errorObj["distance"] = "Distance is required and must be a number greater than 0";
  }

  if (!type) {
    if (!data?.meta?.max_invites && data?.meta?.max_invites !== 0) {
      errorObj["max_invites"] = "Max invite count is required";
    }

    if (data?.meta?.max_invites && data?.meta?.max_invites < -1) {
      errorObj["max_invites"] = "Max invite count must be greater than or equal to -1";
    }

    if (!data?.meta?.total_invites && data?.meta?.total_invites !== 0) {
      errorObj["total_invites"] = "Total invite count is required";
    }

    if (data?.meta?.total_invites && data?.meta?.total_invites < -1) {
      errorObj["total_invites"] = "Total invite count must be greater than or equal to -1";
    }

    if (key !== 'super_mentor') {
      if (!data?.meta?.allowed_modules && data?.meta?.allowed_modules !== 0) {
        errorObj["allowed_modules"] = "Allowed Modules count is required";
      }

      if (data?.meta?.allowed_modules && data?.meta?.allowed_modules < -1) {
        errorObj["allowed_modules"] = "Allowed Modules count must be greater than or equal to -1";
      }
    }
  }

  if (data.phone) {
    if (data.phone.includes("-")) data.phone = data.phone.split("-")[1];
    const len = data.phone.length;
    const codes = CODES.countryCodes;
    for (let i = 0; i < codes.length; i++) {
      if (
        codes[i].value === data.country_code ||
        codes[i].code === data.country_code
      ) {
        if (!codes[i].length.includes(len)) {
          errorObj["phone"] = "Phone number length is invalid";
          break;
        }
      }
    }
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const parseError = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.first_name) {
    errorObj["first_name"] = get(err, "first_name[0]");
  }
  if (err && err.last_name) {
    errorObj["last_name"] = get(err, "last_name[0]");
  }
  if (err && err.phone) {
    errorObj["phone"] = get(err, "phone[0]");
  }
  if (err && err.email) {
    errorObj["email"] = get(err, "email[0]");
  }
  if (err && err.password) {
    errorObj["password"] = get(err, "password[0]");
  }
  if (err && err.dob) {
    errorObj["dob"] = get(err, "dob[0]");
  }

  if (err && err.about) {
    errorObj["about"] = get(err, "about[0]");
  }

  if (err && err.organization) {
    errorObj["organization"] = get(err, "organization[0]");
  }

  if (err && err.profile_link) {
    errorObj["profile_link"] = get(err, "profile_link[0]");
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};
