import { TYPES } from "../../containers/Users/Constants";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_LEARNERS:
      return {
        ...state,
        allLearners: action.payload,
        error: {},
      };
    case TYPES.GET_ALL_MENTORS:
      return {
        ...state,
        allMentors: action.payload,
        error: {},
      };
    case TYPES.GET_ALL_SUPER_MENTORS:
      return {
        ...state,
        allSuperMentors: action.payload,
        error: {},
      };
    case TYPES.GET_ALL_CHILD_MENTORS_LIST:
      return {
        ...state,
        allChildMentorsList: action.payload,
        error: {},
      };
    case TYPES.GET_ALL_ADMINS:
      return {
        ...state,
        allAdmins: action.payload,
        error: {},
      };
    default:
      return state;
  }
};
