import React, { Component } from "react";
import { get, debounce } from "lodash";
import { Avatar, Button, Checkbox } from "antd";
import { connect } from "react-redux";
import defaultImg from "../../../assets/user-grey.svg";

// Custom Components
// import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import SelectInput from "../../../components/SelectInput";
import SearchPrimary from "../../../components/SearchPrimary";
import PrimaryPagination from "../../../components/PrimaryPagination";
import RemoveModal from "../../../components/modal/RemoveModal";
import AssignMentorModal from "../../../components/modal/AssignMentorModal";
import RemoveMentorModal from "../../../components/modal/RemoveMentorModal";
import ToggleMenu from "../../../components/ToggleMenu";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import Loader from "../../../components/PrimaryLoader";
import EmptyState from "../../../components/EmptyState";
import ErrorModal from "../../../components/modal/ErrorModal";
import SuccessModal from "../../../components/modal/SuccessModal";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../../constants";

// Style
import "./styles.scss";
// import ToggleCheckbox from "../../../components/ToggleCheckbox";
import { PageTitle } from "../../../components/PageTitle";

//Services
import * as DM from "../dataManager";

//Actions
import { getAllLearners } from "../actions";

//Helpers
import { breakTimestamp, convertCase } from "../../../helpers";
import AssignPackageModal from "../../../components/modal/AssignPackageModal";

class LearnersScreen extends Component {
  constructor(props) {
    super();
    this.getAllLearnersData = debounce(
      this.getAllLearnersData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      assignMentorShowModal: false,
      assignPackageShowModal: false,
      removeMentorModal: false,
      removeMentorShowModal: false,
      toggleActive: true,
      checked: [],
      learnerRoleId: null,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
      },
      errorModal: null,
      succModal: null,
      learners: {
        isMentorAssigned: "All",
        packageType: "ALL",
        searchQuery: null,
      },
      passedMentors: null
    };
  }

  componentDidMount() {
    this.getAllLearnersData();
  }

  getAllLearnersData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { isMentorAssigned, packageType, searchQuery } = this.state.learners;
    const { page, limit } = this.state.meta;
    const queryObject = {
      type: "LEARNER",
      status: "ACTIVE",
      limit: limit,
      page: page,
    };
    if (isMentorAssigned !== "All")
      queryObject.is_mentor_assign = isMentorAssigned === "Yes";
    if (packageType !== "ALL") queryObject.package_type = packageType;
    if (searchQuery) {
      queryObject.keyword = searchQuery;
    }
    DM.getAllUsersService(queryObject)
      .then((res) => {
        this.setState({
          learnerRoleId: res?.data?.results[0]?.roles[0]?.id
        })
        this.props.getAllLearners(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.learners[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "searchQuery") isLoaderBlocked = true;
      this.getAllLearnersData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllLearnersData();
      this.clearCheckboxes();
    });
  };

  removeToggleModal = () => {
    this.setState({
      removeShowModal: !this.state.removeShowModal,
    });
  };

  assignMentorToggleModal = () => {
    this.setState({
      assignMentorShowModal: !this.state.assignMentorShowModal,
    });
  };

  assignPackageToggleModal = () => {
    this.setState({
      assignPackageShowModal: !this.state.assignPackageShowModal
    })
  }

  removeMentorToggleModal = () => {
    const currentId = this.state.checked[0];
    if (!currentId) return;
    const dataColumns = get(this.props, "users.allLearners.results", []);
    const currentRecord = dataColumns.find(item => item.id === currentId);

    this.setState({
      passedMentors: currentRecord.mentor
    }, () => {
      this.setState({ removeMentorModal: !this.state.removeMentorModal })
    })
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  clearCheckboxes = () => {
    this.setState({
      checked: [],
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  assignMentor = (mentors) => {
    this._startLoader();
    DM.assignMentorService({
      mentors: mentors,
      learners: this.state.checked,
    })
      .then((res) => {
        this._stopLoader();
        this.getAllLearnersData();
        this.assignMentorToggleModal();
        this.setState({
          succModal: {
            title: "Success",
            description: "Mentor assigned successfully.",
          },
        });
        this.clearCheckboxes();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.assignMentorToggleModal();
        this.clearCheckboxes();
      });
  };

  assignPackage = (packages) => {
    const payload = {
      packageId: packages,
      learnerId: this.state.checked,
      userRoles: [this.state.learnerRoleId]
    }
    this._startLoader();
    DM.updatepackages(payload).then((res) => {
      this._stopLoader();
      this.getAllLearnersData();
      this.assignPackageToggleModal();
      this.setState({
        succModal: {
          title: "Success",
          description: "Package assigned successfully.",
        },
      });
      this.clearCheckboxes();
    }).catch((err) => {
      this.setState({
        errorModal: {
          title: "ERROR",
          description: get(err, "response.data.message", ""),
        },
      });
      this._stopLoader();
      this.assignPackageToggleModal();
      this.clearCheckboxes();
    });
  }

  removeMentor = (data) => {
    this._startLoader();
    const learner = this.state.checked[0];

    if (!learner) return this._stopLoader();
    if (!data || data.length === 0) return this._stopLoader();

    const mentors = data.map((mentor) => {
      return DM.removeAssignedMentorOneService(learner, mentor);
    })

    Promise.all(mentors)
      .then((res) => {
        this._stopLoader();
        this.setState({
          passedMentors: null,
          succModal: {
            title: "Success",
            description: "Mentor removed successfully.",
          },
        });
        this.getAllLearnersData();
        this.removeMentorToggleModal();
        this.clearCheckboxes();
      })
      .catch((err) => {
        this.setState({
          passedMentors: null,
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeMentorToggleModal();
        this.clearCheckboxes();
      });
  };

  removeLearner = () => {
    this._startLoader();
    let learners = this.state.checked;
    learners = learners.map((learner) => {
      return DM.removeUserService(learner, { status: "INACTIVE" });
    });

    Promise.all(learners)
      .then((res) => {
        this._stopLoader();
        this.getAllLearnersData();
        this.removeToggleModal();
        this.setState({
          succModal: {
            title: "Success",
            description: "Learner removed successfully.",
          },
        });
        this.clearCheckboxes();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
        this.clearCheckboxes();
      });
  };

  handlePerPageChange = (value) => {
    const { meta } = this.state
    meta.limit = value
    this.setState({
      meta: meta
    })
    this.getAllLearnersData();
  }

  render() {
    const {
      removeShowModal,
      assignMentorShowModal,
      assignPackageShowModal,
      removeMentorShowModal,
      removeMentorModal,
      isLoader,
      checked,
      errorModal,
      meta,
      succModal,
      limit
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const checkedCount = checked.length;
    const dataColumns = get(this.props, "users.allLearners.results", null);
    const totalLearners = get(
      this.props,
      "users.allLearners.meta.pagination.total",
      0,
    );

    return (
      <>
        <PageTitle title="Learners" />
        <div className="learner-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[{ text: "User" }, { text: "Learners" }]}
              />
              <h4 className="title4"> List of all learners</h4>
            </div>
            <div className="right-section">
              {/* <ButtonPrimary btnText="Export List" /> */}
              {permissions.USERS && permissions.USERS.includes(ACTIONS.CREATE) && (
                <ButtonSecondary
                  btnText="Add new learner"
                  onClick={() => {
                    this.props.history.push(ROUTES.NEWLEARNER);
                  }}
                />
              )}
            </div>
          </div>

          <div className="find-section-left mb--20">
            <SearchPrimary
              placeholder="Search learner"
              onSearch={(e) =>
                this.setStateValue(e.target.value, "searchQuery")
              }
            />
            <div className="d-flex">
              <SelectInput
                defaultValue={limit}
                value={limit}
                isTitleActive
                title="Limit:"
                placeholder="Select"
                options={[10, 25, 50, 100]}
                onChange={(value) => this.handlePerPageChange(value)}
              />
              <SelectInput
                style={{ width: 100 }}
                isTitleActive={true}
                title="Package Type:"
                placeholder="Select"
                options={["All", "Free", "Starter", "Premium", "Elite"]}
                defaultValue="All"
                onChange={(val) => {
                  this.setStateValue(val.toUpperCase(), "packageType");
                }}
              />
              <SelectInput
                style={{ width: 80 }}
                isTitleActive={true}
                title="Mentor Assigned:"
                placeholder="Select"
                options={["All", "Yes", "No"]}
                defaultValue="All"
                onChange={(val) => {
                  this.setStateValue(val, "isMentorAssigned");
                }}
              />

              <ToggleMenu
                isToggleBtnActive={true}
                btnText="Action"
                disabled={checkedCount === 0 ? true : false}>
                {checkedCount === 1 && (
                  <Button
                    type="link"
                    onClick={() => {
                      this.props.history.push(
                        ROUTES.VIEWLEARNER.replace(":id", checked[0]),
                      );
                    }}
                    className="btn-link">
                    View Details
                  </Button>
                )}
                {permissions.USERS &&
                  permissions.USERS.includes(ACTIONS.UPDATE) && (
                    <>
                      <Button
                        type="link"
                        onClick={this.assignMentorToggleModal}
                        className="btn-link">
                        Assign Mentor
                      </Button>
                      <Button
                        type="link"
                        onClick={this.assignPackageToggleModal}
                        className="btn-link">
                        Assign Package
                      </Button>
                      {checkedCount === 1 &&
                        (<>
                          <Button
                            type="link"
                            onClick={this.removeMentorToggleModal}
                            className="btn-link">
                            Remove Mentor
                          </Button>
                          <Button
                            type="link"
                            onClick={() => {
                              this.props.history.push(
                                ROUTES.EDITLEARNER.replace(":id", checked[0]),
                              );
                            }}
                            className="btn-link">
                            Edit Details
                          </Button>
                        </>)
                      }
                    </>
                  )}
                {permissions.USERS &&
                  permissions.USERS.includes(ACTIONS.DELETE) && (
                    <Button
                      type="link"
                      className="text-danger btn-link"
                      onClick={this.removeToggleModal}>
                      Remove
                    </Button>
                  )}
              </ToggleMenu>
            </div>
          </div>

          {isLoader && <Loader />}

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <>
              <div className="table-card-style">
                <div className="table-style">
                  <table className="table-custom-style">
                    <tr>
                      <th>
                        {/* <ToggleCheckbox>
                          <Button
                            type="link"
                            className="text-danger btn-link"
                            onClick={this.removeToggleModal}>
                            Remove All
                      </Button>
                        </ToggleCheckbox> */}
                        <Checkbox
                          onChange={(e) => {
                            let checked = [];
                            if (e.target.checked) {
                              dataColumns.map((data) => {
                                checked.push(data.id);
                                return null;
                              });
                            }
                            this.setState({ checked });
                          }}></Checkbox>
                      </th>
                      <th>Learner Name</th>
                      <th>Learner Email</th>
                      <th>Organization</th>
                      <th>Package Type </th>
                      <th>Signup Date</th>
                      <th>Mentor Assigned</th>
                      {/* <th>Action</th> */}
                    </tr>
                    {dataColumns &&
                      dataColumns.map((data, index) => {
                        return (
                          <tr className="data-row table-learner" key={index}>
                            <td>
                              <Checkbox
                                onChange={this.onChange}
                                value={data.id}
                                checked={checked.includes(data.id)}
                              />
                            </td>
                            <td>
                              <div className="img-heading">
                                <Avatar
                                  className="img-sec"
                                  size={32}
                                  src={
                                    get(data, "media.path", null) || defaultImg
                                  }
                                />
                                <p className="lead trim-field">
                                  <a
                                    className="lead link-text"
                                    href={ROUTES.VIEWLEARNER.replace(
                                      ":id",
                                      get(data, "id"),
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {data.full_name}
                                  </a>
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="lead">{get(data, "email")}</p>
                            </td>
                            <td>
                              <p className="lead trim-field">
                                {get(data, "organization") || "---"}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {convertCase(
                                  get(data, "userpackages.packages.name", null),
                                )}
                              </p>
                            </td>
                            <td>
                              <p className="lead">
                                {breakTimestamp(data.created_at).date}
                              </p>
                            </td>
                            <td style={{ width: "10%" }}>
                              <p className="lead">
                                {data?.mentor && data?.mentor?.length > 0 ? "Yes" : "No"}
                              </p>
                            </td>

                          </tr>
                        );
                      })}
                  </table>
                </div>
              </div>

              {assignMentorShowModal && (
                <AssignMentorModal
                  modalTitle="Assign a mentor"
                  isModalVisible={assignMentorShowModal}
                  onClose={this.assignMentorToggleModal}
                  onClick={this.assignMentor}
                />
              )}

              {assignPackageShowModal && (
                <AssignPackageModal
                  modalTitle="Assign a package"
                  isModalVisible={assignPackageShowModal}
                  onClose={this.assignPackageToggleModal}
                  onClick={this.assignPackage}

                />
              )}

              {removeShowModal && (
                <RemoveModal
                  isModalVisible={removeShowModal}
                  onClose={this.removeToggleModal}
                  title="Remove this Learner"
                  description="Are you sure you want to Remove learner?"
                  // Action button event here
                  removeHandler={this.removeLearner}
                  dontRemoveHandler={this.removeToggleModal}
                  // Action button text here
                  removeBtnText="Yes, remove"
                  dontRemoveBtnText="Don't remove"
                />
              )}

              {removeMentorShowModal && (
                <RemoveModal
                  isModalVisible={removeMentorShowModal}
                  onClose={this.removeMentorToggleModal}
                  title="Remove this Mentor"
                  description="Are you sure you want to Remove mentor?"
                  // Action button event here
                  removeHandler={this.removeMentor}
                  dontRemoveHandler={this.removeMentorToggleModal}
                  // Action button text here
                  removeBtnText="Yes, remove"
                  dontRemoveBtnText="Don't remove"
                />
              )}

              {removeMentorModal && (
                <RemoveMentorModal
                  isModalVisible={removeMentorModal}
                  title="Select mentor to remove"
                  mentors={this.state.passedMentors}
                  // Action button event here
                  onClose={this.removeMentorToggleModal}
                  onClick={this.removeMentor}
                />
              )}

              {errorModal && (
                <ErrorModal
                  isModalVisible={errorModal}
                  onClose={this.errorToggleModal}
                  title={get(errorModal, "title")}
                  description={get(errorModal, "description")}
                />
              )}

              {succModal && (
                <SuccessModal
                  isModalVisible={succModal}
                  onClose={this.successToggleModal}
                  title={get(succModal, "title")}
                  description={get(succModal, "description")}
                />
              )}

              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalLearners}
                  onChange={this.handlePageChange}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  getAllLearners,
})(LearnersScreen);
