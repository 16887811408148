export const acadruListBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false"><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Numbered List
  blockManager.add("NumberedList", {
    label: "Numbered List",
    category: {
      id: "Acadru List",
      label: "Acadru List",
      open: false,
    },
    attributes: { class: "fa fa-list-ol" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-list-content numbered" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <ol data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
        </ol>
      </div>
    ${rowCol6End}
    `,
  });

  // Checkbox List
  blockManager.add("CheckboxList", {
    label: "Checkbox List",
    category: "Acadru List",
    attributes: { class: "fa fa-check" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-list-content checkbox" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <ul data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="true">
          <li data-gjs-droppable="false" data-gjs-draggable="false">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
            <input type="checkbox" checked>
            <span class="checkmark"></span>
          </li>
          <li data-gjs-droppable="false" data-gjs-draggable="false">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
            <input type="checkbox">
            <span class="checkmark"></span>
          </li>
          <li data-gjs-droppable="false" data-gjs-draggable="false">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
            <input type="checkbox" checked>
            <span class="checkmark"></span>
          </li>
          <li data-gjs-droppable="false" data-gjs-draggable="false">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
            <input type="checkbox">
            <span class="checkmark"></span>
          </li>
          <li data-gjs-droppable="false" data-gjs-draggable="false">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
            <input type="checkbox" checked>
            <span class="checkmark"></span>
          </li>
        </ul>
      </div>
    ${rowCol6End}
    `,
  });

  // Bullet List
  blockManager.add("BulletList", {
    label: "Bullet List",
    category: "Acadru List",
    attributes: { class: "fa fa-list" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-list-content bullet" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <ul data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
          <li data-gjs-droppable="false" data-gjs-draggable="false"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p></li>
        </ul>
      </div>
    ${rowCol6End}
    `,
  });
};
