import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam } from "../../helpers";

export const createRole = async (payload) => {
    let url = API.POST_CREATE_ROLE;
    return await axiosInstance.post(url, payload);
}

export const getAllPermissions = async (params) => {
    let url = `${API.GET_ALL_PERMISSIONS}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
}

export const deleteRole = async (id) => {
    let url = API.DELETE_ROLE.replace(":id", id);
    return await axiosInstance.delete(url);
}

export const updateRole = async (id, payload) => {
    let url = API.PATCH_UPDATE_ROLE.replace(":id", id);
    return await axiosInstance.patch(url, payload);
}