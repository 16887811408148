import { get } from "lodash";

export const validate = (data) => {
  let errorObj = {};
  if (!data.name) {
    errorObj["name"] = "Name is Required";
  }

  if (
    !data.number_of_accessable_module &&
    data.number_of_accessable_module !== 0
  ) {
    errorObj["number_of_accessable_module"] =
      "No. of accessable modules is Required";
  }
  if (!data.number_of_session && data.number_of_session !== 0) {
    errorObj["number_of_session"] = "No. of mentor sessions is Required";
  }
  if (!data.duration) {
    errorObj["duration"] = "Duration is Required";
  }
  if (!data.prices || data.prices.length === 0) {
    errorObj["prices"] = "At least one price is Required";
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const parseError = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.name) {
    errorObj["name"] = get(err, "name[0]");
  }
  if (err && err.number_of_accessable_module) {
    errorObj["number_of_accessable_module"] = get(
      err,
      "number_of_accessable_module[0]",
    );
  }
  if (err && err.number_of_session) {
    errorObj["number_of_session"] = get(err, "number_of_session[0]");
  }
  if (err && err.prices) {
    errorObj["prices"] = get(err, "prices[0]");
  }
  if (err && err.duration) {
    errorObj["duration"] = get(err, "duration[0]");
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};
