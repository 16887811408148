import React, { Component } from 'react';
import { Row, Col, Checkbox, Select, Button, Space, Tooltip } from "antd";
import { get } from "lodash";
// Style
import "./styles.scss";

import addIcon from "../../../assets/round-plus-icon.svg";

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import SelectInput from "../../../components/SelectInput";
import { PageTitle } from "../../../components/PageTitle";
import Loader from '../../../components/PrimaryLoader';
import ErrorModal from '../../../components/modal/ErrorModal';
import SuccessModal from '../../../components/modal/SuccessModal';
import ButtonDanger from "../../../components/buttons/ButtonDanger";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

//Constants
import { BREADCRUMBS, ROUTES } from '../../../constants';

//Services
import * as DM from '../dataManager';

//Parsers
import { validate, parseError } from "../parser";

const { Option } = Select;

class PackageNew extends Component {

  constructor(props) {
    super();
    this.editableID = null;
    this.priceIdx = 1;
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      // originalPackageName: null,
      pack: {
        name: "",
        status: "Active",
        ai_guided_recommendation: 0,
        ai_based_profiile_building_support: 0,
        can_access_to_project: 0,
        can_access_collection: 0,
        can_submit_project: 0,
        is_free: 0,
        is_fixed: 0,
        prices: []
      },
      currencyDropdown: [],
      priceComponent: []
    };
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    this.getCurrencies();
    if (this.editableID) {
      await this.getSpecificPackageDetials();
    }
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.pack[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.PACKAGE);
    })
  }

  handlePrice = (val, entity, index) => {
    const { priceComponent } = this.state
    let { prices } = this.state.pack;
    let component = prices && prices.filter(comp => {
      return comp.index === index;
    })
    if (component.length > 0) {
      component = component[0];
      component[entity] = parseInt(val);
    } else {
      component = {};
      component[entity] = parseInt(val);
      component.index = index;
      prices.push(component);
    }
    this.setStateValue(prices, "prices");
    let tempComponent = priceComponent && priceComponent.map((comp) => {
      if (comp && parseInt(comp.key) === index) return this.givePriceContainter(index, component)
      return comp;
    })
    this.setState({ priceComponent: tempComponent })
  }

  givePriceContainter = (index, val) => {
    return (
      <div className="add-package-price-box dynamic" key={index}>
        <Row gutter={[20]}>
          <Col xs={24} sm={24} md={12}>
            <div className="input-item">
              <SelectInput
                value={get(val, "currency.id")}
                placeholder="Select currency"
                child={this.state.currencyDropdown}
                onChange={(val) => this.handlePrice(val, "currency", index)}
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <div className="input-item input-with-btn-box fx fx--ai-c fx--jc-sb">
              <InputPrimary
                value={get(this.state.pack, `prices[${index}].price`)}
                type="number"
                placeholder="Enter package price"
                onChange={(e) => this.handlePrice(e.target.value, "price", index)}
              />
              <div className="btn-box ml--20">
                <Space size="middle">
                  <ButtonDanger
                    type="link"
                    btnText={<DeleteOutlined />}
                    btnDangerStyle="btn delete btn-link"
                    onClick={() => this.removePriceComponent(index)}
                  />
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  addPriceComponent = () => {
    let { priceComponent, currencyDropdown } = this.state;
    if (priceComponent.length < currencyDropdown.length - 1) {
      priceComponent.push(this.givePriceContainter(this.priceIdx++));
      this.setState({ priceComponent });
    }
  }

  fillPriceComponent = () => {
    let { prices } = this.state.pack;
    let { priceComponent } = this.state;
    if (prices.length > 0) prices[0].index = 0;
    for (let i = 1; i < prices.length; i++) {
      let val = prices[i];
      val["index"] = this.priceIdx;
      prices[i] = val;
      if (val.price) val.price = parseInt(val.price.replace(/,/g, '')).toString();
      priceComponent.push(this.givePriceContainter(this.priceIdx++, val));
    }

    this.setState({ priceComponent });
    this.setStateValue(prices, "prices");
  }

  removePriceComponent = (index) => {
    let { pack, priceComponent } = this.state;
    let { prices } = pack;
    priceComponent = priceComponent.filter(item => {
      return index !== parseInt(item.key);
    })
    prices = prices.filter(item => {
      return item.index !== index;
    })
    this.setStateValue(prices, "prices");
    this.setState({ priceComponent });
  }

  parseCurrencyForUpdate = () => {
    let prices = this.state.pack.prices;
    prices = prices.map(val => {
      return {
        currency: get(val, "currency.id") || get(val, "currency"),
        price: +get(val, "price")
      }
    })
    return prices;
  }

  getSpecificPackageDetials = () => {
    this._startLoader();
    DM.getSpecificPackageService(this.editableID)
      .then((res) => {
        if (res?.data?.prices[0]?.price) {
          const parsedPrice = parseInt(res.data.prices[0].price.replace(/,/g, ''), 10).toString();
          res.data.prices[0].price = parsedPrice;
        }
        this.setState({
          pack: res.data,
        }, () => {
          this.fillPriceComponent();
        })
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  }

  getCurrencies = () => {
    this._startLoader();
    DM.getAllCurrenciesService()
      .then(res => {
        this._stopLoader();
        const currencies = get(res, "data.results");
        const currencyDropdown = currencies.map(currency => {
          return (<Option value={currency.id} key={currency.id}>
            {currency.name}
          </Option>
          )
        })
        this.setState({ currencyDropdown })
      })
      .catch(err => {
        this._stopLoader();
      })
  }

  handleSave = () => {
    const { pack } = this.state;

    const checkValidate = validate(pack);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    this._startLoader();
    if (this.editableID) {
      pack.prices = this.parseCurrencyForUpdate();
      DM.patchUpdatePackageService(this.editableID, pack)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Package updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.postCreatePackageService(pack)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Package created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }

  render() {

    const { isLoader, errorModal, succModal, errObj, pack, currencyDropdown, priceComponent } = this.state;
    const status = get(pack, "status");

    return (
      <>
        <PageTitle title="Add Package" />
        <div className="package-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.PACKAGE,
                  { text: this.editableID ? "Edit package" : "Add new Package" },
                ]}
              />
              <h4 className="title4">{this.editableID ? "Edit Package" : "Add new Package"}</h4>
            </div>
            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${status === "Active" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("Active", "status");
                  }}
                >
                  Active
                </Button>
                <Button
                  className={`btn-toggle ${status === "Inactive" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("Inactive", "status");
                  }}>
                  Inactive
                </Button>
              </div>
            </div>
          </div>
          <div className="form-section">
            <Row gutter={[40]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--0-imp">
                  <p className="lable">Package Name <span className="required-text">*</span></p>
                  <InputPrimary
                    type="text"
                    placeholder="Enter package name"
                    value={get(pack, "name")}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name")
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
                <div className="input-item">
                  <p className="lable">No. of Accessable modules <span className="required-text">* {" "}</span>
                    <Tooltip title="Enter -1 for Unlimited"><InfoCircleOutlined /></Tooltip>
                  </p>
                  <InputPrimary
                    type="number"
                    placeholder="Enter accessable module"
                    value={get(pack, "number_of_accessable_module")}
                    onChange={(e) => {
                      this.setStateValue(parseInt(e.target.value), "number_of_accessable_module")
                    }}
                    errorText={get(errObj, "number_of_accessable_module")}
                  />
                </div>
                <div className="input-item">
                  <p className="lable">Mentor Sessions <span className="required-text">*</span></p>
                  <InputPrimary
                    type="number"
                    placeholder="Enter mentor sessions"
                    value={get(pack, "number_of_session")}
                    onChange={(e) => {
                      this.setStateValue(parseInt(e.target.value), "number_of_session")
                    }}
                    errorText={get(errObj, "number_of_session")}
                  />
                </div>
                <div className="input-item">
                  <p className="lable">Duration<span className="required-text">*</span> (in days)</p>
                  <InputPrimary
                    type="number"
                    placeholder="Enter duration of package"
                    value={get(pack, "duration")}
                    onChange={(e) => {
                      this.setStateValue(parseInt(e.target.value), "duration")
                    }}
                    errorText={get(errObj, "duration")}
                  />
                </div>

                <div className="package-box">
                  <div className="heading-box fx fx--ai-c fx--jc-sb">
                    <div className="input-item">
                      <p className="lable">Package price <span className="required-text">*</span></p>
                    </div>
                    {priceComponent.length < currencyDropdown.length - 1 &&
                      <div className="icon-box" onClick={this.addPriceComponent}>
                        <img class="icon" src={addIcon} alt="icon" />
                      </div>
                    }
                  </div>
                  <div className="add-package-price-box">
                    <Row gutter={[20]}>
                      <Col xs={24} sm={24} md={12}>
                        <div className="input-item mt--0-imp">
                          <SelectInput
                            value={get(pack, "prices[0].currency.id")}
                            placeholder="Select currency"
                            child={currencyDropdown}
                            onChange={(val) => this.handlePrice(val, "currency", 0)}
                            errorText={get(errObj, "prices")}
                          />
                        </div>
                      </Col>

                      <Col xs={24} sm={24} md={12}>
                        <div className="input-item mt--0-imp">
                          <InputPrimary
                            type="number"
                            placeholder="Enter package price"
                            value={get(pack, "prices[0].price")}
                            onChange={(e) => this.handlePrice(e.target.value, "price", 0)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Dynamic add box */}
                  <div>
                    {priceComponent}
                  </div>

                </div>

              </Col>

              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Package Facilities <span className="required-text">*</span></p>
                  <div
                    className="checkbox-column-style">
                    <Row gutter={[20]}>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "can_access_to_project")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "can_access_to_project")}
                        >
                          Project Access
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "ai_guided_recommendation")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "ai_guided_recommendation")}
                        >
                          AI Guided Recommendation
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "can_access_collection")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "can_access_collection")}
                        >
                          Access Collection
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "ai_based_profiile_building_support")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "ai_based_profiile_building_support")}
                        >
                          AI Profile Building
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "can_submit_project")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "can_submit_project")}
                        >
                          Submit Project
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "is_free")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "is_free")}
                        >
                          Is package free
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Checkbox
                          className="checkbox-style mb--40"
                          checked={get(pack, "is_fixed")}
                          onChange={(e) => this.setStateValue(e.target.checked ? 1 : 0, "is_fixed")}
                        >
                          Is package fixed
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="btn-box mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

          </div>
        </div>
        {isLoader && <Loader />}
        {errorModal &&
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
        }
        {succModal &&
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />
        }
      </>
    );
  }
}

export default PackageNew;