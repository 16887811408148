import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam } from '../../helpers';

export const createTag = async (payload) => {
    let url = API.POST_CREATE_TAG;
    return await axiosInstance.post(url, payload);
};

export const getTag = async (id, params) => {
    let url = API.GET_TAG.replace(":id", id);
    url = `${url}${makeQueryParam(params)}`
    return await axiosInstance.get(url);
};

export const updateTag = async (id, payload) => {
    let url = API.PATCH_UPDATE_TAG.replace(":id", id);
    return await axiosInstance.patch(url, payload);
};

export const deleteTag = async (id) => {
    let url = API.DELETE_TAG.replace(":id", id);
    return await axiosInstance.delete(url);
};

