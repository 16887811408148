const isFunction = (functionToCheck) => {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

export const getVideoView = (editor) => {
  return {
    events: {
      "click [data-toggle=asset-remove]": "onRemove",
      click: "onClick",
      dblclick: "onDblClick",
    },

    getPreview() {
      const pfx = this.pfx;
      const src = this.model.get("src");
      return `
        <div>
        <video class="gjs-no-pointer ${pfx}preview" style="height: 100%; width: 100%;" src="${src}"> </video>
        </div>`;
    },

    getInfo() {
      const pfx = this.pfx;
      const model = this.model;
      let name = model.get("name");
      name = name || model.getFilename();
      return `
      <div class="${pfx}name">${name}</div>
    `;
    },

    init(o) {
      const pfx = this.pfx;
      this.className += ` ${pfx}asset-video`;
    },

    /**
     * Triggered when the asset is clicked
     * @private
     * */
    onClick() {
      var onClick = this.config.onClick;
      var model = this.model;
      this.collection.trigger("deselectAll");
      this.$el.addClass(this.pfx + "highlight");

      if (isFunction(onClick)) {
        onClick(model);
      } else {
        this.updateTarget(this.collection.target);
      }
    },

    /**
     * Triggered when the asset is double clicked
     * @private
     * */
    onDblClick() {
      const { em, model } = this;
      const onDblClick = this.config.onDblClick;

      if (isFunction(onDblClick)) {
        onDblClick(model);
      } else {
        this.updateTarget(this.collection.target);
        em && em.get("Modal").close();
      }

      var onSelect = this.collection.onSelect;
      isFunction(onSelect) && onSelect(model);
    },

    /**
     * Remove asset from collection
     * @private
     * */
    onRemove(e) {
      e.stopImmediatePropagation();
      this.model.collection.remove(this.model);
    },
  };
};
