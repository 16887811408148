import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam, objectToFormdata } from "../../helpers";

export const getAllCollections = async (params) => {
  return await axiosInstance.get(
    `${API.GET_ALL_COLLECTIONS}${makeQueryParam(params)}`,
  );
};

export const getSpecificCollection = async (id) => {
  let url = API.GET_SPECIFIC_COLLECTION.replace(":id", id);
  return await axiosInstance.get(url);
};

export const postCreateCollection = async (payload) => {
  return await axiosInstance.post(
    API.POST_CREATE_COLLECTION,
    objectToFormdata(payload),
  );
};

export const patchUpdateColletion = async (id, payload) => {
  let url = API.PATCH_UPDATE_COLLECTION.replace(":id", id);
  return await axiosInstance.patch(url, objectToFormdata(payload));
};

export const deleteCollection = async (id) => {
  let url = API.DELETE_COLLECTION.replace(":id", id);
  return await axiosInstance.delete(url);
};
