import { get } from 'lodash';

export const validate = (data) => {
    let errorObj = {};
    if (!data.name) {
        errorObj["name"] = "Name is Required";
    }

    if (!data.tag_category) {
        errorObj["tag_category"] = "Tag Category is Required";
    }
    if (!data.tag_purpose) {
        errorObj["tag_purpose"] = "Tag Purpose is Required";
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};

export const parseError = (Error) => {
    const err = get(Error, "response.data.error");
    let errorObj = {};

    if (err && err.name) {
        errorObj["name"] = get(err, "name[0]");
    }
    if (err && err.tag_category) {
        errorObj["tag_category"] = get(err, "tag_category[0]");
    }
    if (err && err.tag_purpose) {
        errorObj["tag_purpose"] = get(err, "tag_purpose[0]");
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};