import React, { Component } from "react";
import { connect } from "react-redux";
import './styles.scss';
import { PageTitle } from "../../components/PageTitle";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import SearchPrimary from "../../components/SearchPrimary";
import EmptyState from "../../components/EmptyState";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import SelectInput from "../../components/SelectInput";
import { Avatar, Space, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import * as DM from "./dataManager";
import { debounce, get } from "lodash";
import defaultImage from "../../assets/blogs.svg";
import ReturnStatusColor from "../../helpers/returnStatusColor";
import { convertCase, breakTimestamp } from "../../helpers";
import categories from "../../store/reducers/categories";
import Loader from '../../components/PrimaryLoader';
import { ROUTES, SEARCHDELAY } from "../../constants";
import RemoveModal from "../../components/modal/RemoveModal";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import PrimaryPagination from "../../components/PrimaryPagination";

import { setAllSchoolProjects } from "./actions";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";

class SchoolProjectPage extends Component {
    constructor(props) {
        super();
        this.getAllSchoolProjectsData = debounce(
            this.getAllSchoolProjectsData,
            SEARCHDELAY.schoolProjectSearchDelay,
        );
        this.state = {
            value: null,
            removeShowModal: false,
            isTableActive: true,
            requestedForRemoveModule: null,
            meta: {
                page: 1,
                limit: 20,
            },
            successShowModal: null,
            errorShowModal: null,
            filters: {
                status: null,
                category_name: null,
                titleKeyword: null,
                studentKeyword: null
            },
            fetching: false,
            isLoader: false,
            // data: null,
            categoryOptions: [],
        }
    }

    componentDidMount() {
        this.getAllSchoolProjectsData();
        this.getAllCategories();
    }

    setStateValue = (value, field) => {
        let state = this.state;
        state.filters[`${field}`] = value;
        state.meta.page = 1;
        this.setState(state, () => {
            let isLoaderBlocked = false;
            if (field === "keyword") isLoaderBlocked = true;
            this.getAllSchoolProjectsData(isLoaderBlocked);
        });
    };

    _closeremoveModal = () => {
        this.setState({
            removeShowModal: false,
            requestedForRemoveModule: null,
        });
    };
    _openRemoveModal = () => {
        this.setState({
            removeShowModal: true,
        });
    };

    handlePageChange = (page) => {
        let meta = this.state.meta;
        meta.page = page;
        this.setState({ meta }, () => {
            this.getAllSchoolProjectsData();
        })
    }


    getAllSchoolProjectsData = (isLoaderBlocked) => {
        window.scrollTo(0, 0);
        if (!isLoaderBlocked) this._startLoader();
        const { meta, filters } = this.state;
        let queryObject = {
            page: meta.page,
            limit: meta.limit,
            order_by: "DESC",
            include: "projectmedia"
        };
        const { status, category_name, studentKeyword, titleKeyword } = filters;
        if (status && status !== 'All') queryObject.status = status === "Published" ? "PUBLISH" : "UNPUBLISH";
        if (studentKeyword) {
            queryObject.studentKeyword = studentKeyword;
            delete queryObject.order_by;
        }
        if (titleKeyword) {
            queryObject.titleKeyword = titleKeyword;
            delete queryObject.order_by;
        }
        if (category_name && category_name !== 'All') queryObject.category_name = category_name;
        DM.getAllSchoolProjects(queryObject).then((res) => {
            this.props.setAllSchoolProjects(res.data)
            this._stopLoader();
        }).catch((err) => {
            this._stopLoader();
        });
    }

    getAllCategories = () => {
        DM.getAllCategories().then((res) => {
            this.setState({ categoryOptions: res.data });
        }).catch((err) => {
            this._stopLoader();
        });
    }

    _startLoader = () => {
        this.setState({ isLoader: true });
    };

    _stopLoader = () => {
        this.setState({ isLoader: false });
    };

    handleRemoveSchoolProject = () => {
        this._startLoader();
        const { requestedForRemoveModule } = this.state;
        DM.deleteSchoolProject(requestedForRemoveModule).then((res) => {
            this._closeremoveModal();
            this.setState({
                successShowModal: {
                    msg: "School Project Deleted Successfully",
                },
            });
            this.getAllSchoolProjectsData();
            this._stopLoader();
        }).catch((err) => {
            this._stopLoader();
            this._closeremoveModal();
            this.setState({
                errorShowModal: {
                    msg: get(err, "response.data.message"),
                },
            });
        })
    }

    render() {
        const { categoryOptions, isLoader, removeShowModal, successShowModal, errorShowModal, meta } = this.state;
        const totalSchoolProjects = get(this.props, "schoolProjects.allSchoolProjects.meta.pagination.total");
        const limit = get(this.props, "schoolProjects.allSchoolProjects.meta.pagination.count", null)

        const data = get(this.props, "schoolProjects.allSchoolProjects.results")
        return (
            <div>
                <PageTitle title="School Projects" />
                <div className="school-project-container-style">
                    <div className="header-style">
                        <div className="title-header">
                            <PrimaryBreadcrumb
                                breadcrumbs={[{ text: "School Projects" }]}
                            />
                            <h4 className="title4">All School Projects</h4>
                        </div>
                        <div className="right-section">
                            <ButtonSecondary
                                btnText="Add new"
                                href={'/school-project/new'}
                            />
                        </div>
                    </div>
                    <div className="find-section">
                        <SelectInput
                            style={{ width: 170 }}
                            isTitleActive={true}
                            title="Filter by status:"
                            placeholder="Select status"
                            options={["All", "Published", "Unpublished"]}
                            defaultValue="All"
                            onChange={(val) => {
                                this.setStateValue(val, "status");
                            }}
                        />
                        <SelectInput
                            style={{ width: 170 }}
                            isTitleActive={true}
                            title="Filter by Category:"
                            placeholder="Select"
                            options={categoryOptions}
                            defaultValue="All"
                            onChange={(val) => {
                                this.setStateValue(val, "category_name");
                            }}
                        />
                    </div>

                    <div className="find-section mt--30">
                        <SearchPrimary
                            placeholder="Search by title"
                            onSearch={(e) => this.setStateValue(e.target.value, "titleKeyword")}
                        />
                        <SearchPrimary

                            placeholder="Search by student"
                            onSearch={(e) => this.setStateValue(e.target.value, "studentKeyword")}
                        />
                    </div>

                    <div className="table-section mt--30">
                        {this.props.isTableActive ||
                            !data ||
                            data.length === 0 ? (
                            <EmptyState
                                title="Oops!"
                                description="No Record Found &#128533;"
                            />
                        )
                            : (<div className="table-card-style mt--40">
                                <div className="table-style module-list-style table-responsive">
                                    <table className="table-custom-style">
                                        <tr>
                                            <th>ID</th>
                                            <th>Thumbnail & Title</th>
                                            <th>Category</th>
                                            <th>Views</th>
                                            <th>Likes</th>
                                            <th>Status</th>
                                            <th>Published at</th>
                                            <th>Student Name</th>
                                            <th>School/Self</th>
                                            <th>Action</th>
                                        </tr>
                                        {data &&
                                            data.map((data, index) => {
                                                return (
                                                    <tr className="data-row table-tags" key={index}>
                                                        <td>
                                                            <p className="lead">
                                                                {index + 1 || "---"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="img-content-style">
                                                                <Avatar
                                                                    shape="square img-avatar"
                                                                    className="mr--20"
                                                                    src={
                                                                        get(data, "projectmedia.path", null) ||
                                                                        defaultImage
                                                                    }
                                                                />
                                                                <div className="module-description" title={get(data, "title")}>
                                                                    {get(data, "title")}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="lead">
                                                                {get(data, "category") ||
                                                                    "---"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="lead">
                                                                {get(data, "views_count") ||
                                                                    "---"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className="lead">
                                                                {get(data, "likes_count") ||
                                                                    "---"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="flex-box">
                                                                <span
                                                                    className={`circle ${ReturnStatusColor(
                                                                        get(data, "status"),
                                                                    )} mr-2`}></span>
                                                                <p className="lead mb--0">
                                                                    {convertCase(get(data, "status")) + "ed"}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="lead">
                                                                {(data.publish_at &&
                                                                    `${breakTimestamp(get(data, "publish_at")).date
                                                                    } ${breakTimestamp(get(data, "publish_at")).time
                                                                    }`) ||
                                                                    "---"}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="module-description" title={get(data, "student_name") || get(data, 'student.full_name') || "Deleted User"}>
                                                                {get(data, "student_name") || get(data, 'student.full_name') ||
                                                                    "Deleted User"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {data.student ? (
                                                                <div className="module-description" title={get(data, "student.school_name") || '---'}>
                                                                    {get(data, 'student.school_name') || '---'}
                                                                </div>
                                                            ) : (
                                                                <div className="module-description" title={get(data, "school_name") || '---'}>
                                                                    {(get(data, "school_name")) || '---'}
                                                                </div>)}
                                                        </td>
                                                        <td>
                                                            <Space size="middle">

                                                                <ButtonOutline
                                                                    btnText={<EditOutlined />}
                                                                    btnStyle="btn edit"
                                                                    clickHandler={() =>
                                                                        this.props.history.push(
                                                                            ROUTES.EDITSCHOOLPROJECT.replace(
                                                                                ":id",
                                                                                get(data, "id"),
                                                                            ),
                                                                        )
                                                                    }
                                                                />
                                                                <ButtonDanger
                                                                    type="link"
                                                                    btnText={<DeleteOutlined />}
                                                                    btnDangerStyle="btn delete btn-link"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            requestedForRemoveModule: get(
                                                                                data,
                                                                                "id",
                                                                            ),
                                                                        });
                                                                        this._openRemoveModal();
                                                                    }}>
                                                                    Remove
                                                                </ButtonDanger>
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </table>
                                </div>
                                <div className="total-blog-count">
                                    {limit + "/" + totalSchoolProjects} resources
                                </div>
                                <div className="page-section">
                                    <PrimaryPagination
                                        pageSize={meta.limit}
                                        current={meta.page}
                                        total={totalSchoolProjects}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                            )}
                    </div>

                    {isLoader && <Loader />}

                    <RemoveModal
                        isModalVisible={removeShowModal}
                        onClose={this._closeremoveModal}
                        title="Remove this school Project"
                        description="Are you sure you want to remove this school project?"
                        removeHandler={this.handleRemoveSchoolProject}
                        dontRemoveHandler={this._closeremoveModal}
                        removeBtnText="Yes, Remove"
                        dontRemoveBtnText="Don't Remove"
                    />
                    {successShowModal && (
                        <SuccessModal
                            isModalVisible={successShowModal ? true : false}
                            onClose={() => {
                                this.setState({ successShowModal: null });
                            }}
                            title={
                                successShowModal.title ? successShowModal.title : "Success!"
                            }
                            description={successShowModal.msg}
                        />
                    )}

                    {errorShowModal && (
                        <ErrorModal
                            isModalVisible={errorShowModal ? true : false}
                            onClose={() => {
                                this.setState({ errorShowModal: null });
                            }}
                            title={errorShowModal.title ? errorShowModal.title : "Error!"}
                            description={errorShowModal.msg}
                        />
                    )}

                    {/* {dataColumns && dataColumns.length > 0 && ( */}
                    {/* <div className="page-section">
                            <PrimaryPagination
                                pageSize={meta.limit}
                                current={meta.page}
                                total={totalFeatureQuestions}
                                onChange={this.handlePageChange}
                            />
                        </div> */}
                    {/* )} */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return store;
}

export default connect(mapStateToProps, { setAllSchoolProjects })(SchoolProjectPage);