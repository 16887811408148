import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { objectToFormdata } from '../../helpers';

export const createSubCategory = async (category_id, payload) => {
    let url = API.POST_CREATE_SUBCATEGORY.replace(":category_id", category_id);
    return await axiosInstance.post(url, objectToFormdata(payload));
};

export const getSubCategory = async (category_id, id) => {
    let url = API.GET_SUBCATEGORY.replace(":category_id", category_id);
    url = url.replace(":id", id);
    return await axiosInstance.get(url);
};

export const updateSubCategory = async (category_id, id, payload) => {
    let url = API.PATCH_UPDATE_SUBCATEGORY.replace(":category_id", category_id);
    url = url.replace(":id", id);
    return await axiosInstance.patch(url, objectToFormdata(payload));
};

export const deleteSubCategory = async (category_id, id) => {
    let url = API.DELETE_SUBCATEGORY.replace(":category_id", category_id);
    url = url.replace(":id", id);
    return await axiosInstance.delete(url);
};

