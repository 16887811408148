import React, { Component } from "react";
import { Modal, Avatar, Checkbox, Spin } from "antd";
import SearchPrimary from "../../SearchPrimary";
import ButtonDanger from "../../buttons/ButtonDanger";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import SelectInput from "../../SelectInput";
import defaultImg from '../../../assets/user-grey.svg';
import { connect } from 'react-redux'
import { get, debounce } from 'lodash';

import EmptyState from '../../EmptyState';

// Style
import "./styles.scss";

//Actions
import { getAllChildMentorsList } from '../../../containers/Users/actions';

//Services
import * as DM from '../../../containers/Users/dataManager';

class AssignChildMentorModal extends Component {
  constructor(props) {
    super();
    this.getAllMentorData = debounce(this.getAllMentorData, 800);
    this.state = {
      isLoaded: false,
      top: "topLeft",
      bottom: "bottomRight",
      checked: [],
      allChecked: false,
      searchQuery: null,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAllMentorData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isModalVisible && this.props.isModalVisible)
      this.getAllMentorData();
  }

  getAllMentorData = () => {
    let { searchQuery, limit } = this.state;
    const currentUser = this.props.currentUser;
    let queryObj = {
      type: "SUPER_MENTOR,MENTOR",
      status: "ACTIVE",
      limit,
      excludeMentees: currentUser
    };
    if (searchQuery) queryObj.keyword = searchQuery;
    DM.getAllUsersService(queryObj)
      .then(res => {
        if (currentUser) {
          const results = res?.data?.results || [];
          this.props.getAllChildMentorsList({ results });
          this.setState({ isLoaded: true })
        }
      })
      .catch(err => {
      })
  }

  handleSearch = (e) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.getAllMentorData();
    })
  }

  clearCheckboxes = () => {
    this.setState({
      allChecked: false,
      checked: []
    })
  }

  checkAll = () => {
    if (this.state.allChecked) {
      return this.clearCheckboxes();
    }
    const learners = get(this.props, "users.allChildMentorsList.results", null);
    if (!learners || learners.length === 0) return;
    const checked = learners.map(item => item.id);
    this.setState({ checked, allChecked: true })
  }

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  handlePerPageChange = (limit) => {
    this.setState({ limit }, () => { this.getAllMentorData() });
  }

  render() {
    const { isModalVisible, onClose, modalTitle, onClick } = this.props;
    const { checked, allChecked, limit, isLoaded } = this.state;
    const learners = get(this.props, "users.allChildMentorsList.results", null);

    return (
      <div className="assign-student-modal">
        <Modal
          visible={isModalVisible}
          // centered
          footer={null}
          // width="350"
          className="assign-learner-modal-style">
          <div className="remove-modal">
            <div className="assign-mentor-modal-header">
              <h3 className="title3">{modalTitle}</h3>
              <SelectInput
                defaultValue={limit}
                value={limit}
                isTitleActive
                title="Limit:"
                placeholder="Select"
                options={[10, 25, 50, 100]}
                onChange={(value) => this.handlePerPageChange(value)}
              />
            </div>
            <div className="modal-body">

              <div className="search-box">
                <SearchPrimary placeholder="Search mentor" onSearch={this.handleSearch} />
              </div>
              {(!learners || learners.length === 0) ?
                (isLoaded ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <div className="spinner-container-acm-modal"><Spin /></div>) :
                <div className="checkbox-section">
                  <div className="select-all-box-sm">
                    <p className="select-all-text-sm">Select All</p>
                    <Checkbox onChange={this.checkAll} checked={allChecked} />
                  </div>
                  <div className="ant-checkbox-group">
                    {learners && learners.map((learner, index) => {
                      return (
                        <Checkbox className="checkbox-style" onChange={this.onChange} value={learner.id} key={index} checked={checked.includes(learner.id)}>
                          <div className="fx fx--ai-fs mr--20 wrapper">
                            <Avatar
                              className="img-sec"
                              size={32}
                              src={get(learner, "media.path", null) || defaultImg}
                            />
                            <div className="learner-info-box fx fx--ai-fs fd-c">
                              <span className="learner-name wrap-modal-field">{learner.full_name}</span>
                              {learner.organization && <span className="id-style wrap-modal-field"><b>Organization: </b>{learner.organization}</span>}
                              <div>
                                <span className="id-style role">{learner?.roles[0]?.name || "--"}</span>
                                <span className="id-style">{learner.email}</span>
                              </div>
                            </div>
                          </div>
                        </Checkbox>
                      )
                    })}
                  </div>
                  <div className='text-center-sm'>And More...</div>
                </div>
              }
              <div className="text-right mt--10 footer-section">
                <ButtonDanger
                  onClick={onClose}
                  btnText="Cancel"
                  btnDangerStyle="mr--20"
                />
                <ButtonPrimary onClick={() => { onClick(checked) }} btnText="Assign" disabled={checked.length === 0} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, { getAllChildMentorsList })(AssignChildMentorModal);
