import React, { Component } from "react";
import { Avatar } from "antd";
import { connect } from "react-redux";
import { get, debounce } from "lodash";
import defaultImg from "../../assets/blogs.svg";

// Custom Components
import SearchPrimary from "../../components/SearchPrimary";
import RemoveModal from "../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import PrimaryPagination from "../../components/PrimaryPagination";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import SelectInput from "../../components/SelectInput";
import Loader from "../../components/PrimaryLoader";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";
import { breakTimestamp, convertCase } from "../../helpers";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";
import ButtonOutline from "../../components/buttons/ButtonOutline";

//Services
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

//Actions
import { setAllBlogs } from "./actions";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../constants";

class BlogManagement extends Component {
  constructor(props) {
    super();
    this.getAllBlogsData = debounce(
      this.getAllBlogsData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      isLoader: false,
      meta: {
        page: 1,
        limit: 20,
      },
      errorModal: null,
      succModal: null,
      filters: {
        status: "All",
        category_id: null,
        searchQuery: null,
        searchAuthor: null,
      },
      selected_id: "",
      categories: [],
    };
  }

  componentDidMount() {
    this.getAllBlogCategoriesData();
    this.getAllBlogsData();
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.filters[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "searchQuery" || field === "searchAuthor")
        isLoaderBlocked = true;
      this.getAllBlogsData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllBlogsData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  getAllBlogsData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const {
      status,
      searchQuery,
      searchAuthor,
      category_id,
    } = this.state.filters;
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    if (status !== "All")
      queryObject.status = status === "Published" ? "PUBLISH" : "UNPUBLISH";
    if (category_id) queryObject.category = category_id;
    if (searchQuery) queryObject.keyword = searchQuery;
    if (searchAuthor) queryObject.author = searchAuthor;
    DM.getALLBlogsService(queryObject)
      .then((res) => {
        this.props.setAllBlogs(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getAllBlogCategoriesData = () => {
    this._startLoader();
    const queryObject = {
      // limit: 20,
      // page: 1
    };
    CDM.getAllBlogCategoriesService(queryObject)
      .then((res) => {
        this.setState({ categories: get(res, "data.results") });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  removeBlog = () => {
    this._startLoader();
    const id = this.state.selected_id;
    DM.deleteBlogService(id)
      .then((res) => {
        this._stopLoader();
        this.getAllBlogsData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Blog removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
      categories,
      filters,
    } = this.state;
    const dataColumns = get(this.props, "blogs.allBlogs.results", null);
    const permissions = get(this.props, "auth.permissions");
    const totalBlogs = get(
      this.props,
      "blogs.allBlogs.meta.pagination.total",
      null,
    );
    const limit = get(this.props, "blogs.allBlogs.meta.pagination.count", null);

    const blogCategoriesDropdown = [];
    blogCategoriesDropdown.push(
      <option value="" key="0">
        All
      </option>,
    );
    categories.map((category) => {
      blogCategoriesDropdown.push(
        <option value={category.uuid} key={category.id}>
          {category.name}
        </option>,
      );
      return null;
    });

    return (
      <>
        <PageTitle title="Blog Management" />
        <div className="payments-container-style">
          <div className="header-style mb--30">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Blog Management" }]} />
              <h4 className="title4">All Blogs</h4>
            </div>
            <div className="right-section">
              {permissions.BLOG &&
                permissions.BLOG.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary btnText="Add new" href={ROUTES.NEWBLOG} />
                )}
            </div>
          </div>

          <div className="find-section sm-fd-c mb--20">
            <SelectInput
              style={{ width: 130 }}
              isTitleActive={true}
              title="Filter by status:"
              placeholder="Select"
              options={["All", "Published", "Unpublished"]}
              defaultValue="All"
              onChange={(val) => {
                this.setStateValue(val, "status");
              }}
            />
            <SelectInput
              value={get(filters, "category_id")}
              style={{ width: 180 }}
              child={blogCategoriesDropdown}
              isTitleActive={true}
              title="Filter by category:"
              placeholder="Select blog category"
              onChange={(val) => {
                this.setStateValue(val, "category_id");
              }}
            />

            <div className="search-box fx fx--ai-c">
              <SearchPrimary
                placeholder="Search by title"
                onSearch={(e) =>
                  this.setStateValue(e.target.value, "searchQuery")
                }
              />
              <SearchPrimary
                placeholder="Search by author"
                onSearch={(e) =>
                  this.setStateValue(e.target.value, "searchAuthor")
                }
              />
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style">
              <div className="table-style table-responsive">
                <table className="table-custom-style">
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Category</th>
                    <th>Link</th>
                    <th>View count</th>
                    <th>Status</th>
                    <th>Published at</th>
                    <th>Author</th>
                    <th>Slug</th>
                    {permissions.BLOG &&
                      (permissions.BLOG.includes(ACTIONS.UPDATE) ||
                        permissions.BLOG.includes(ACTIONS.DELETE)) && (
                        <th>Action</th>
                      )}
                  </tr>

                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-payment" key={index}>
                          <td>
                            <p className="lead">{get(data, "id") || "---"}</p>
                          </td>
                          <td>
                            <div className="image-title-section">
                              {get(data, "title") || "---"}
                            </div>
                          </td>
                          <td>
                            <Avatar
                              shape="square"
                              className="img-sec"
                              size={64}
                              src={get(data, "media.path", null) || defaultImg}
                            />
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "category.name") || "---"}
                            </p>
                          </td>
                          <td>
                            <a
                              href={get(data, "link")}
                              target="_blank"
                              rel="noopener noreferrer">
                              <p className="lead link-text">
                                {get(data, "link") || "---"}
                              </p>
                            </a>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "viewCounts") || 0}
                            </p>
                          </td>
                          <td>
                            <div className="flex-box">
                              <span
                                className={`circle ${ReturnStatusColor(
                                  get(data, "status"),
                                )} mr-2`}></span>
                              <p className="lead mb--0">
                                {convertCase(get(data, "status")) + "ed"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "publish_at", null)
                                ? breakTimestamp(get(data, "publish_at")).date
                                : "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "createdby.full_name") || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">{get(data, "slug") || "---"}</p>
                          </td>
                          {permissions.BLOG &&
                            (permissions.BLOG.includes(ACTIONS.UPDATE) ||
                              permissions.BLOG.includes(ACTIONS.DELETE)) && (
                              <td>
                                <div className="flex-box">
                                  {permissions.BLOG &&
                                    permissions.BLOG.includes(
                                      ACTIONS.UPDATE,
                                    ) && (
                                      <ButtonOutline
                                        btnText={<EditOutlined />}
                                        // btnStyle="btn-sm"
                                        btnStyle="btn edit square-edit mr--10"
                                        clickHandler={() =>
                                          this.props.history.push(
                                            ROUTES.EDITBLOG.replace(
                                              ":id",
                                              data.uuid,
                                            ),
                                          )
                                        }
                                      />
                                    )}
                                  {permissions.BLOG &&
                                    permissions.BLOG.includes(
                                      ACTIONS.DELETE,
                                    ) && (
                                      <ButtonDanger
                                        type="link"
                                        btnText={<DeleteOutlined />}
                                        btnDangerStyle="btn delete btn-link"
                                        onClick={() =>
                                          this.removeToggleModal(data.uuid)
                                        }>
                                        Remove
                                      </ButtonDanger>
                                    )}
                                </div>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="total-blog-count">
                {limit + "/" + totalBlogs} resources
              </div>
              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalBlogs}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          )}

          {isLoader && <Loader />}

          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this module"
              description="Are you sure you want to remove this module?"
              // Action button event here
              removeHandler={this.removeBlog}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}

          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllBlogs,
})(BlogManagement);
