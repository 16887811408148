import React, { Component } from 'react';
import { Modal, Avatar, Checkbox, Spin } from 'antd';
import SearchPrimary from '../../SearchPrimary';
import ButtonDanger from '../../buttons/ButtonDanger';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import SelectInput from '../../SelectInput';
import defaultImg from '../../../assets/user-grey.svg';
import { connect } from 'react-redux';
import { get, debounce } from 'lodash'
import EmptyState from '../../EmptyState';

// Style
import "./styles.scss"

//Actions
import { getAllMentors } from '../../../containers/Users/actions';

//Services
import * as DM from '../../../containers/Users/dataManager';

class AssignMentorModal extends Component {
  constructor(props) {
    super();
    this.getAllMentorsData = debounce(this.getAllMentorsData, 800);
    this.state = {
      isLoaded: false,
      top: 'topLeft',
      bottom: 'bottomRight',
      checked: [],
      searchQuery: null,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAllMentorsData();
  }

  getAllMentorsData = () => {
    let { searchQuery, limit } = this.state;
    let queryObj = { type: "MENTOR,SUPER_MENTOR", status: "ACTIVE", limit };
    if (searchQuery) queryObj.keyword = searchQuery;
    DM.getAllUsersService(queryObj)
      .then(res => {
        this.props.getAllMentors(res.data);
        this.setState({ isLoaded: true });
      })
      .catch(err => {
      })
  }

  handleSearch = (e) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.getAllMentorsData();
    })
  }

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  handlePerPageChange = (limit) => {
    this.setState({ limit }, () => { this.getAllMentorsData() });
  }

  render() {
    const { isModalVisible, onClose, modalTitle, onClick } = this.props;
    const { checked, limit, isLoaded } = this.state;
    const mentors = get(this.props, "users.allMentors.results", null);

    return (
      <div className="assign-mentor-modal">
        <Modal visible={isModalVisible} footer={null} width="350" className="assign-mentor-modal-style">
          <div className="remove-modal">
            <div className="assign-mentor-modal-header">
              <h3 className="title3">{modalTitle}</h3>
              <SelectInput
                defaultValue={limit}
                value={limit}
                isTitleActive
                title="Limit:"
                placeholder="Select"
                options={[10, 25, 50, 100]}
                onChange={(value) => this.handlePerPageChange(value)}
              />
            </div>
            <div className="modal-body">
              <div className="search-box">
                <SearchPrimary placeholder="Search mentor" onSearch={this.handleSearch} />
              </div>
              {(!mentors || mentors.length === 0) ?
                (isLoaded ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <div className='spinner-container-am-modal'><Spin /></div>) :
                <div className="checkbox-section">
                  <Checkbox.Group>
                    {mentors && mentors.map((mentor, idx) => {
                      return (
                        <Checkbox className="checkbox-style" key={idx} value={mentor.id} checked={checked.includes(mentor.id)} onChange={this.onChange}>
                          <div className="fx fx--ai-fs mr--20">
                            <Avatar
                              className="img-sec"
                              size={32}
                              src={get(mentor, "media.path", null) || defaultImg}
                            />
                            <div className="learner-info-box fx fx--ai-fs fd-c card-box">
                              <span className="learner-name wrap-modal-field">{mentor.full_name}</span>
                              <span className="id-style">{mentor.email}</span>
                            </div>
                          </div>
                        </Checkbox>
                      )
                    })}
                  </Checkbox.Group>
                  <div className='text-center-m'>And More...</div>
                </div>
              }

              <div className="text-right mt--10 footer-section">
                <ButtonDanger onClick={onClose} btnText="Cancel" btnDangerStyle="mr--20" />
                <ButtonPrimary onClick={() => onClick(checked)} btnText="Assign" disabled={checked.length === 0} />
              </div>
            </div>
          </div>
        </Modal >
      </div >
    );
  }
}


const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, { getAllMentors })(AssignMentorModal);
