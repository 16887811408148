import { API } from "./Constants";
import { axiosInstance } from "../../tools";

export const setTokenToAPIInstance = async (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = `bearer ${token}`;
};

export const getTokenToAPIInstance = async () => {
  return axiosInstance.defaults.headers["Authorization"];
};

export const login = async (payload) => {
  return await axiosInstance.post(API.POST_LOGIN, payload);
};

export const usersNotificationSubscribe = async (payload) => {
  return await axiosInstance.post(
    API.REGISTER_USERS_NOTIFICATION_SUBSCRIBE,
    payload,
  );
};
