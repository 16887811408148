import { Tabs } from "antd";
import { get } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";

// Style
import "./styles.scss";

// Custom Components
// import ButtonDanger from "../../../components/buttons/ButtonDanger";
import RemoveModal from "../../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ModuleDetails from "../../../components/moduleCards/ModuleDetails";
import GetIdea from "../../../components/moduleCards/GetIdea";
import MiniProjects from "../../../components/moduleCards/MiniProjects";
import SkillBuilding from "../../../components/moduleCards/SkillBuilding";
import Loader from "../../../components/PrimaryLoader";
// import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
// import TextareaPrimary from "../../../components/TextareaPrimary";
import { PageTitle } from "../../../components/PageTitle";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";

//Services
import * as DM from "../dataManager";
import { BREADCRUMBS, ROUTES } from "../../../constants";

//Actions
import {
  saveNewModule,
  clearNewModule,
  editModule,
  clearEditModule,
  newMiniProject,
  newSkillBuildingProject,
  clearNewMiniProject,
  clearNewSkillBuildingProject,
  editProject,
  clearEditProject,
} from "../actions";

//Parser
import {
  // _updateModuleParser,
  _parseModuleForGlobalStorage,
  validate,
  parseError,
  validateModuleDetails,
  parseErrorModuleDetails,
} from "../parser";

const { TabPane } = Tabs;

class NewModuleScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      top: "topLeft",
      bottom: "bottomRight",
      removeShowModal: false,
      defaultActiveKey: "1",
      shown: true,
      isReflectionActive: false,
      editableModule: null,
      successShowModal: null,
      errorShowModal: null,
      module: {
        reflection_question: null,
        name: null,
        description: "",
        interest_cloud_text: null,
        category: null,
        likes: null,
        views: null,
        featured: false,
        status: "UNPUBLISH",
        categories: [],
        careers: [],
        related_modules: [],
        tags: [],
        mainImage: null,
        banner_text: null,
        module_level: null,
      },
      errorObj: null,
      miniProjectsInfo: {
        selectedProject: null,
        projects: [],
        projectIdForRemove: "",
        isModalVisible: false,
      },
      skillBuildingProjectsInfo: {
        selectedProject: null,
        projects: [],
        projectIdForRemove: "",
        isModalVisible: false,
      },
      isRefreshPage: false,
    };
    this.editableID = null;
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    window.scrollTo(0, 0);
    // this.props.clearNewModule();
    // this.props.clearNewProject(this.editableID);
    // this.props.clearEditProject();
    if (this.editableID) {
      await this.getSpecificModuleDetails(this.editableID);
    }
  }

  // Helper Functions

  _closeremoveModal = () => {
    this.setState({
      removeShowModal: false,
      requestedForRemoveModule: null,
    });
  };

  _openRemoveModal = (id) => {
    this.setState({
      removeShowModal: true,
      projectIdForRemove: typeof id === "string" ? id : "",
    });
  };

  handleDelete = () => {
    const { projectIdForRemove } = this.state;
    if (projectIdForRemove) this.deleteProject();
    else this.hanldeRemoveModule();
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handleSizeChange = () => {
    this.setState({ shown: !this.state.shown });
  };

  isReflectionActiveToggle = () => {
    this.setState({
      isReflectionActive: !this.state.isReflectionActive,
    });
  };

  toggleMiniProjectModal = (data, type) => {
    let { miniProjectsInfo } = this.state;
    miniProjectsInfo.isModalVisible = !miniProjectsInfo.isModalVisible;
    miniProjectsInfo.selectedProject = data;
    this.setState(
      {
        miniProjectsInfo,
      },
      () => {
        if (data) {
          this.setGlobalStoreProjectsValue(data, data.id, type);
        }
      },
    );
  };

  toggleSkillBuildingProjectModal = (data, type) => {
    let { skillBuildingProjectsInfo } = this.state;
    skillBuildingProjectsInfo.isModalVisible = !skillBuildingProjectsInfo.isModalVisible;
    skillBuildingProjectsInfo.selectedProject = data;
    this.setState(
      {
        skillBuildingProjectsInfo,
      },
      () => {
        if (data) {
          this.setGlobalStoreProjectsValue(data, data.id, type);
        }
      },
    );
  };

  setGlobalStoreModuleDetailsValue = () => {
    const { editableModule, module } = this.state;
    if (this.editableID) {
      const payload = {
        moduleDetails: _parseModuleForGlobalStorage(editableModule, module),
        id: this.editableID,
      };
      this.props.editModule(payload);
    } else {
      this.props.saveNewModule(
        _parseModuleForGlobalStorage(
          get(this.props, "autoSave.newModule", {}),
          module,
        ),
      );
    }
  };

  setGlobalStoreGetIdeaValue = (data) => {
    let editModule = get(this.props, `autoSave.editModule.${this.editableID}`);
    // console.log(editModule);
    if (!editModule) editModule = { id: this.editableID };
    editModule.getTheIdea = data;
    this.props.editModule(editModule);
  };

  setGlobalStoreProjectsValue = (data, id, type) => {
    if (id) {
      let editProject = get(this.props, `autoSave.editProject`);
      if (!editProject) editProject = {};
      editProject[`${id}`] = { ...editProject[`${id}`], ...data };
      this.props.editProject(editProject[`${id}`]);
    } else {
      if (type === "mini") {
        let newMiniProject = get(this.props, "autoSave.newMiniProject");
        if (!newMiniProject) newMiniProject = {};
        newMiniProject[`${this.editableID}`] = {
          ...newMiniProject[`${this.editableID}`],
          ...data,
        };
        this.props.newMiniProject(newMiniProject);
      } else {
        let newSkillBuildingProject = get(
          this.props,
          "autoSave.newSkillBuildingProject",
        );
        if (!newSkillBuildingProject) newSkillBuildingProject = {};
        newSkillBuildingProject[`${this.editableID}`] = {
          ...newSkillBuildingProject[`${this.editableID}`],
          ...data,
        };
        this.props.newSkillBuildingProject(newSkillBuildingProject);
      }
    }
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.module[`${field}`] = value;
    this.setState(state, () => {
      this.setGlobalStoreModuleDetailsValue();
    });
  };

  //API Executable Functions

  getCategories = () => {};

  tabFileUpload = async (file, module_id) => {
    this._startLoader();
    const data = await DM.modulesTabFileUploadService(module_id, file)
      .then((res) => {
        this._stopLoader();
        return res;
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
    this._stopLoader();

    return data;
  };

  onSaveTabsContent = async (data, moduleData) => {
    this._startLoader();
    let checkTabsIsExist = get(moduleData, "tabs");
    if (checkTabsIsExist) {
      await DM.patchUpdateModuleTabsService(
        get(moduleData, "id"),
        checkTabsIsExist[0].id,
        data,
      )
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: {
              msg: "Module Updated successfully",
            },
          });
          return res;
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorShowModal: {
              msg: get(err, "response.data.message"),
            },
          });
        });
    } else {
      await DM.createModuleTabService(get(moduleData, "id"), data)
        .then((res) => {
          this.getSpecificModuleDetails(get(moduleData, "id"));
          this._stopLoader();
          this.setState({
            successShowModal: {
              msg: "Module Updated successfully",
            },
          });
          return res;
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorShowModal: {
              msg: get(err, "response.data.message"),
            },
          });
        });
    }
  };

  saveProjects = async (type, project_id) => {
    let payload, projectsInfo, action, updateStateVar;
    if (type === "MINI_PROJECT") {
      payload = project_id
        ? get(this.props, `autoSave.editProject.${project_id}`)
        : get(this.props, `autoSave.newMiniProject.${this.editableID}`);
      projectsInfo = this.state.miniProjectsInfo;
      action = this.props.clearNewMiniProject;
      updateStateVar = "miniProjectsInfo";
    } else {
      payload = project_id
        ? get(this.props, `autoSave.editProject.${project_id}`)
        : get(
            this.props,
            `autoSave.newSkillBuildingProject.${this.editableID}`,
          );
      projectsInfo = this.state.skillBuildingProjectsInfo;
      action = this.props.clearNewSkillBuildingProject;
      updateStateVar = "skillBuildingProjectsInfo";
    }
    if (payload) payload.type = type;

    const checkValidate = validate(payload);
    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorShowModal: { msg: "Validation Error Occured" },
      });
      return;
    }

    this._startLoader();
    if (project_id) {
      await DM.patchUpdateProjectService(this.editableID, project_id, payload)
        .then((res) => {
          this._stopLoader();
          projectsInfo.isModalVisible = false;
          this.setState({
            successShowModal: {
              msg: "Project updated successfully",
              action: () => this.props.clearEditProject(project_id),
            },
            errorObj: null,
            [updateStateVar]: projectsInfo,
            isRefreshPage: true,
          });
          this.getSpecificModuleDetails(this.editableID);
          return res;
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: {
              msg: get(err, "response.data.message"),
            },
          });
        });
    } else {
      await DM.postCreateProjectService(this.editableID, payload)
        .then((res) => {
          this._stopLoader();
          projectsInfo.isModalVisible = false;
          this.setState({
            successShowModal: {
              msg: "Project Created successfully",
              action: () => action(this.editableID),
            },
            errorObj: null,
            [updateStateVar]: projectsInfo,
            isRefreshPage: true,
          });
          this.getSpecificModuleDetails(this.editableID);
          return res;
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: {
              msg: get(err, "response.data.message"),
            },
          });
        });
    }
  };

  getSpecificModuleDetails = async (id) => {
    this._startLoader();
    await DM.getModuleDetailsService(id)
      .then((res) => {
        let { miniProjectsInfo, skillBuildingProjectsInfo } = this.state;
        miniProjectsInfo.projects = get(res, "projects");
        skillBuildingProjectsInfo.projects = get(res, "projects");
        this.setState({
          module: res,
          editableModule: res,
          miniProjectsInfo,
          skillBuildingProjectsInfo,
        });
        this.setGlobalStoreModuleDetailsValue();
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  hanldeRemoveModule = async () => {
    const editableID = this.props.match.params.id;
    this._startLoader();
    await DM.deleteModuleService(editableID)
      .then((res) => {
        this._closeremoveModal();
        this._stopLoader();
        this.setState({
          successShowModal: {
            msg: "Module Deleted Successfully",
            redirect: ROUTES.DASHBOARD,
          },
        });
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  refreshPage = () => {
    this.setState({ isRefreshPage: false }, () =>
      window.location.reload(false),
    );
  };

  handleSaveButton = async (data) => {
    let { module, editableModule } = this.state;
    let newModule = this.editableID
      ? get(this.props, `autoSave.editModule.${this.editableID}`)
      : get(this.props, "autoSave.newModule");

    if (module.mainImage) newModule.mainImage = module.mainImage;

    let checkValidate;
    if (this.editableID)
      checkValidate = validateModuleDetails(get(newModule, "moduleDetails"));
    else checkValidate = validateModuleDetails(newModule);
    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorShowModal: { msg: "Validation Error Occured" },
      });
      return;
    }

    this._startLoader();

    if (this.editableID) {
      //call Update module Function
      if (get(newModule, "moduleDetails.module_level")) {
        newModule.moduleDetails.module_level = newModule.moduleDetails.module_level.toUpperCase();
      }
      if (get(newModule, "moduleDetails.banner_text")) {
        let temp = newModule.moduleDetails.banner_text;
        temp = temp.toUpperCase();
        if (temp.includes(" ")) temp = temp.replace(" ", "_");
        newModule.moduleDetails.banner_text = temp;
      }
      return await DM.updateModuleService(
        editableModule,
        get(newModule, "moduleDetails"),
        this.editableID,
      )
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: {
              msg: "Module Updated successfully",
              redirect: ROUTES.EDITMODULE.replace(":id", get(res, "data.id")),
              action: () => this.props.clearEditModule(this.editableID),
            },
          });
          return res;
        })
        .catch((err) => {
          let state = this.state;
          state.errorObj = parseErrorModuleDetails(err);
          state.errorShowModal = {
            msg: get(err, "response.data.message"),
          };
          if (state.errorObj && state.errorObj.reflection_question) {
            state.isReflectionActive = true;
          }
          this.setState(state);
          this._stopLoader();
        });
    } else {
      //call Create Module Function
      if (get(newModule, "module_level")) {
        newModule.module_level = newModule.module_level.toUpperCase();
      }
      if (get(newModule, "banner_text")) {
        let temp = newModule.banner_text;
        temp = temp.toUpperCase();
        if (temp.includes(" ")) temp = temp.replace(" ", "_");
        newModule.banner_text = temp;
      }
      return await DM.postCreateModuleService(newModule)
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: {
              msg: "Module Created successfully",
              redirect: ROUTES.EDITMODULE.replace(":id", get(res, "data.id")),
              action: this.props.clearNewModule,
            },
            isRefreshPage: true,
          });
          return res;
        })
        .catch((err) => {
          let state = this.state;
          state.errorObj = parseErrorModuleDetails(err);
          state.errorShowModal = {
            msg: get(err, "response.data.message"),
          };
          if (state.errorObj && state.errorObj.reflection_question) {
            state.isReflectionActive = true;
          }
          this.setState(state);
          this._stopLoader();
        });
    }
  };

  handleGetIdeaSave = async () => {
    const { editableModule } = this.state;
    const checkData = get(
      this.props,
      `autoSave.editModule.${this.editableID}.getTheIdea`,
    );

    const tabs = get(editableModule, "tabs");
    let content;
    if (tabs) {
      content = {
        ...JSON.parse(get(editableModule, "tabs[0].contents[0].content")),
        ...JSON.parse(get(editableModule, "tabs[0].contents[1].content")),
      };
    }

    await this.onSaveTabsContent(
      checkData ? checkData : content,
      editableModule,
    );
  };

  deleteProject = () => {
    this._startLoader();
    const id = this.state.projectIdForRemove;
    DM.deleteProjectService(this.editableID, id)
      .then((res) => {
        this._stopLoader();
        this.setState({
          successShowModal: {
            msg: "Project Deleted Successfully",
          },
          isRefreshPage: true,
        });
        this._closeremoveModal();
      })
      .catch((err) => {
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
        this._stopLoader();
        this._closeremoveModal();
      });
  };

  onChange = (defaultActiveKey) => {
    this.setState({ defaultActiveKey });
  };

  render() {
    const {
      removeShowModal,
      editableModule,
      // isReflectionActive,
      module,
      isLoader,
      errorObj,
      successShowModal,
      errorShowModal,
      defaultActiveKey,
      miniProjectsInfo,
      skillBuildingProjectsInfo,
    } = this.state;

    let newModule = get(this.props, "autoSave.newModule");
    let imgUrl;
    if (this.editableID) {
      imgUrl = get(editableModule, "media.path");
      newModule = get(
        this.props,
        `autoSave.editModule.${this.editableID}.moduleDetails`,
      );
    }

    return (
      <>
        <PageTitle title="Module" />
        <div className="new-module-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.MODULES,
                  {
                    text: this.editableID ? "Edit Module" : "Add new modules",
                  },
                ]}
              />
              <h4 className="title4">
                {this.editableID ? "Edit module" : "Add New modules"}
              </h4>
            </div>
            {/* {this.editableID && (
              <div className="right-section">
                <ButtonDanger
                  btnText="Remove"
                  btnDangerStyle="mr--10"
                  onClick={this._openRemoveModal}
                />
                <ButtonPrimary
                onClick={this.isReflectionActiveToggle}
                btnText="Reflection Question"
              />
              </div>
            )} */}
          </div>

          {/* {isReflectionActive ? (
            <div className="form-section">
              <div className="input-item">
                <p className="lable">Reflection Question</p>
                <TextareaPrimary
                  rows={4}
                  value={
                    module.reflection_question ||
                    get(editableModule, "reflection_question")
                  }
                  onChange={(e) => {
                    this.setStateValue(e.target.value, "reflection_question");
                  }}
                  placeholder="Question text here"
                  errorText={get(errorObj, "reflection_question[0]")}
                />
              </div>

              <div className="mt--40">
                <ButtonPrimary
                  btnText="Save"
                  onClick={this.isReflectionActiveToggle}
                />
              </div>
            </div>
          ) : ( */}
          <div className="tab-section">
            <Tabs
              defaultActiveKey="1"
              type="card"
              onChange={this.onChange}
              activeKey={defaultActiveKey}>
              <TabPane tab="Module Details" key="1">
                <ModuleDetails
                  data={newModule}
                  imgUrl={imgUrl}
                  onClick={(data) => {
                    this.handleSaveButton(data);
                  }}
                  setStateValue={(value, field) => {
                    this.setStateValue(value, field);
                  }}
                  module={module}
                  errorObj={errorObj}
                />
              </TabPane>
              <TabPane
                tab="Get the idea"
                disabled={this.editableID ? false : true}
                key="2">
                <GetIdea
                  tabFileUpload={(file) => {
                    return this.tabFileUpload(file, this.editableID);
                  }}
                  onSaveTabsContent={this.setGlobalStoreGetIdeaValue}
                  handleGetIdeaSave={this.handleGetIdeaSave}
                  data={editableModule}
                  globalStoreData={get(
                    this.props,
                    `autoSave.editModule.${this.editableID}.getTheIdea`,
                  )}
                />
              </TabPane>
              <TabPane
                tab="Mini Projects"
                disabled={this.editableID ? false : true}
                key="3">
                <MiniProjects
                  tabFileUpload={(file) => {
                    return this.tabFileUpload(file, this.editableID);
                  }}
                  onSaveTabsContent={(data, id) => {
                    this.setGlobalStoreProjectsValue(data, id, "mini");
                  }}
                  handleSave={(id) => {
                    this.saveProjects("MINI_PROJECT", id);
                  }}
                  data={editableModule}
                  errorObj={errorObj}
                  globalStoreData={get(this.props, "autoSave")}
                  openRemoveModal={this._openRemoveModal}
                  projectsInfo={miniProjectsInfo}
                  toggleProjectModal={this.toggleMiniProjectModal}
                />
              </TabPane>
              <TabPane
                tab="Skill Building projects"
                disabled={this.editableID ? false : true}
                key="4">
                <SkillBuilding
                  tabFileUpload={(file) => {
                    return this.tabFileUpload(file, this.editableID);
                  }}
                  onSaveTabsContent={(data, id) => {
                    this.setGlobalStoreProjectsValue(data, id, "skill");
                  }}
                  handleSave={(id) => {
                    this.saveProjects("SKILL_BUILDING_PROJECT", id);
                  }}
                  data={editableModule}
                  errorObj={errorObj}
                  globalStoreData={get(this.props, "autoSave")}
                  openRemoveModal={this._openRemoveModal}
                  projectsInfo={skillBuildingProjectsInfo}
                  toggleProjectModal={this.toggleSkillBuildingProjectModal}
                />
              </TabPane>
            </Tabs>
          </div>
          {/* ) */}

          {isLoader && <Loader />}

          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this._closeremoveModal}
            title="Remove this module"
            description="Are you sure you want to remove this module?"
            // Action button event here
            removeHandler={this.handleDelete}
            dontRemoveHandler={this._closeremoveModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                successShowModal.redirect &&
                  this.props.history.push(successShowModal.redirect);
                successShowModal.action && successShowModal.action();
                this.setState({ successShowModal: null }, () => {
                  if (this.state.isRefreshPage) this.refreshPage();
                });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  saveNewModule,
  clearNewModule,
  editModule,
  clearEditModule,
  newMiniProject,
  newSkillBuildingProject,
  clearNewMiniProject,
  clearNewSkillBuildingProject,
  editProject,
  clearEditProject,
})(NewModuleScreen);
