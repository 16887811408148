import React, { Component } from "react";
import { Card, Row, Col } from "antd";

// Style
import "./styles.scss";
import { get } from "lodash";

//Helpers
import { breakTimestamp, convertCase } from "../../helpers";

class EnquiryDetailsCard extends Component {
  render() {
    const { data } = this.props;
    const dateTime = data && breakTimestamp(get(data, "created_at"));

    let topic = get(data, "topic");
    if (topic) {
      topic = topic.replace("_", " ");
      topic = convertCase(topic);
    }
    return (
      <div className="profile-info-section">
        <Card>
          <div className="section mb--20">
            <h5 className="title5 mb--10">Contact Details:</h5>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} md={5}>
                <div className="info-box">
                  <div className="title"> Name</div>
                  <p className="desciption">{get(data, "name")}</p>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} md={5}>
                <div className="info-box">
                  <div className="title">Phone</div>
                  <p className="desciption">{get(data, "phone")}</p>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} md={6}>
                <div className="info-box">
                  <div className="title"> Email</div>
                  <p className="desciption">{get(data, "email")}</p>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} md={6}>
                <div className="info-box">
                  <div className="title"> Date & Time</div>
                  <p className="desciption">
                    {get(dateTime, "date") ?
                      `${get(dateTime, "date")} - ${get(dateTime, "time")}`
                      :
                      "---"
                    }
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section">
            <h5 className="title5 mb--10">Enquiry Details:</h5>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <div className="info-box">
                  <div className="title"> Need help with:</div>
                  <p className="desciption">{topic || "---"}</p>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="info-box">
                  <div className="title">Message:</div>
                  <p className="desciption">{get(data, "message") || "---"}</p>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="info-box">
                  <div className="title">Replied Message:</div>
                  <p className="desciption">{get(data, "reply") || "---"}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default EnquiryDetailsCard;
