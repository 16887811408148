import * as SERVICE from "./service";

export const getAllEnquiriesServices = async (params) => {
  return await SERVICE.getAllEnquiries(params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSpecificEnquiriesServies = async (id) => {
  return await SERVICE.getSpecificEnquiries(id)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postCreateEnquiriesService = async (payload) => {
  return await SERVICE.postCreateEnquiries(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateEnquiriesService = async (id, payload) => {
  return await SERVICE.patchUpdateEnquiries(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteEnquiriesServies = async (id) => {
  return await SERVICE.deleteEnquiries(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
