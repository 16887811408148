import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./styles.scss";

class ButtonSecondary extends Component {

  render() {
    const { onClick, href, btnText, btnStyle } = this.props;
    return (
      <Link
        className={`btn-secondary ${btnStyle ? btnStyle : ''}`}
        onClick={onClick}
        to={href ? href : '#'}
      >
        {btnText}
      </Link>
    );
  }
}

export default ButtonSecondary;