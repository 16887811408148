import { getDocumentsModel } from "./model";
import { getDocumentView } from "./view";

export const addDocumentComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("file", {
    model: getDocumentsModel(editor),
    view: getDocumentView(editor),
  });
};
