// import { addComponents } from "../custom";
import grapesjsLorySlider from "grapesjs-lory-slider";
import grapesjsTabs from "grapesjs-tabs";
import grapesjsCustomCode from "grapesjs-custom-code";
// import grapesjsTouch from "grapesjs-touch";
// import grapesjsParserPostcss from "grapesjs-parser-postcss";
// import grapesjsTooltip from "grapesjs-tooltip";
// import grapesjsTuiImageEditor from "grapesjs-tui-image-editor";
import grapesjsTyped from "grapesjs-typed";
// import grapesjsStyleBg from "grapesjs-style-bg";
import webpage from "grapesjs-preset-webpage";
import accordion from "grapesjs-accordion";
import grapesjsTable from "grapesjs-table";
import swiperSlider from "grapesjs-swiper-slider";
import grapesjsRTEExtention from "grapesjs-rte-extensions";
import grapesjsCarousel from 'grapesjs-carousel'


var classPreFix = "acadru";
var rowCol6Start = `
  <drors class="${classPreFix}-row">
    <dradrors class="${classPreFix}-column column-6">`;
var rowCol6End = `</dradrors></drors>`;

const attrTab = `data-tab`;
// const attrTabs = `data-tabs`;
const attrTabContent = `data-tab-content`;
const attrTabContainer = `data-tab-container`;

const attrAccordion = "data-accordion";
// const attrAccordions = "data-accordions";
const attrAccordionContent = "data-accordion-content";
const attrAccordionContainer = "data-accordion-container";

// const appendToContent = (target, content) => {
//   if (content instanceof HTMLElement) {
//     target.appendChild(content);
//   } else if (content) {
//     target.insertAdjacentHTML("beforeend", content);
//   }
// };

export const pluginOpt = {
  // grapesjsLorySlider
  [grapesjsLorySlider]: {
    sliderBlock: {
      category: {
        id: "Extra",
        label: "Extra",
        open: false,
      },
    },
    sliderProps: {
      classes: [`${classPreFix}-slider-container`],
    },
    classFrame: `gjs-lory-frame ${classPreFix}-slider`,
  },

  // grapesjsCustomCode
  [grapesjsCustomCode]: {
    propsCustomCode: {
      removable: false,
      draggable: false,
      droppable: false,
      copyable: false,
    },
    commandCustomCode: {
      removable: false,
      draggable: false,
      droppable: false,
      copyable: false,
    },
  },

  // grapesjsTable
  [grapesjsTable]: {
    tableBlock: {
      category: {
        id: "Extra",
        label: "Extra",
        open: false,
      },
    },
    tableProps: {
      classes: [`${classPreFix}-table`],
      rows: 3,
      columns: 3,
      header: true,
      footer: true,
    },
  },

  // grapesjsTabs
  [grapesjsTabs]: {
    tabsBlock: {
      category: {
        id: "Acadru Interactive",
        label: "Acadru Interactive",
        open: false,
      },
    },
    tabsProps: {
      classes: [`${classPreFix}-tabs-container`],
      editable: false,
      // draggable: false,
      droppable: false,
      // selectable: false,
      copyable: false,
    },
    tabContentProps: {
      droppable: false,
      selectable: false,
    },
    template: `
      <drors class="${classPreFix}-row">
        <dradrors class="${classPreFix}-column column-6" style="margin-bottom: 0px;">
          <nav ${attrTabContainer} data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            <a href="#tab1" ${attrTab} onclick="return false" data-gjs-draggable="false">Tab 1</a>
            <a href="#tab2" ${attrTab} onclick="return false" data-gjs-draggable="false">Tab 2</a>
            <a href="#tab3" ${attrTab} onclick="return false" data-gjs-draggable="false">Tab 3</a>
          </nav>
        </dradrors>
      </drors>
      <dradrors>
        <div id="tab1" ${attrTabContent} data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"  data-gjs-droppable="false">
          ${rowCol6Start}
            <div class="tab-content-box" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false">Tab 1 Content</div>
          ${rowCol6End}
        </div>
        <div id="tab2" ${attrTabContent} data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"  data-gjs-droppable="false">
          ${rowCol6Start}
            <div class="tab-content-box" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">Tab 2 Content</div>
          ${rowCol6End}
        </div>
        <div id="tab3" ${attrTabContent} data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"  data-gjs-droppable="false">
          ${rowCol6Start}
            <div class="tab-content-box" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">Tab 3 Content</div>
          ${rowCol6End}
        </div>
      </dradrors>
    `,

    templateTabContent: `
      ${rowCol6Start}
        <div class="tab-content-box" data-gjs-droppable="true">new tab content</div>
      ${rowCol6End}
    `,
    classTab: `
      ${classPreFix}-tab
    `,
    classTabContent: `
      ${classPreFix}-tab-content
    `,
    classTabContainer: `
      ${classPreFix}-tabs
    `,
  },

  // grapesjs accrodion
  [accordion]: {
    accordionsBlock: {
      category: "Acadru Interactive",
    },
    accordionsProps: {
      classes: [`${classPreFix}-accordion-container`],
      "data-gjs-droppable": false,
    },
    template: `
    ${rowCol6Start}
      <div ${attrAccordionContainer} data-gjs-draggable="false">
        <a ${attrAccordion} onclick="return false" data-gjs-draggable="false">Accordion 1</a>
        <div ${attrAccordionContent} data-gjs-draggable="false">
          <div data-gjs-draggable="false">Accordion 1 Content</div>
        </div>
      </div>
      <div ${attrAccordionContainer} data-gjs-draggable="false">
        <a ${attrAccordion} onclick="return false" data-gjs-draggable="false">Accordion 2</a>
        <div ${attrAccordionContent} data-gjs-draggable="false">
          <div data-gjs-draggable="false">Accordion 2 Content</div>
        </div>
      </div>
      <div ${attrAccordionContainer} data-gjs-draggable="false">
        <a ${attrAccordion} onclick="return false" data-gjs-draggable="false">Accordion 3</a>
        <div ${attrAccordionContent} data-gjs-draggable="false">
          <div data-gjs-draggable="false">Accordion 3 Content</div>
        </div>
      </div>
    ${rowCol6End}
    `,

    templateAccordionContent: `
      <div class="accordion-content-box" data-gjs-droppable="true">new accordion content</div>
    `,
    classAccordion: `
      ${classPreFix}-accordion
    `,
    classAccordionContent: `
      ${classPreFix}-accordion-content
    `,
    classAccordionContainer: `
      ${classPreFix}-accordions
    `,
  },

  [grapesjsCarousel]: {
    gridsCategory : "Acadru Interactive"
  },

  // webpage
  [webpage]: {
    modalImportTitle: "Import Template",
    modalImportLabel:
      '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
    modalImportContent: function (editor) {
      return editor.getHtml() + "<style>" + editor.getCss() + "</style>";
    },
    filestackOpts: null, //{ key: 'AYmqZc2e8RLGLE7TGkX3Hz' },
    aviaryOpts: false,
    formsOpts: false,
    blocksBasicOpts: {
      flexGrid: 1,
      category: {
        id: "Basic",
        label: "Basic",
        open: false,
      },
    },
    customStyleManager: [
      {
        name: "General",
        buildProps: [
          "float",
          "display",
          "position",
          "top",
          "right",
          "left",
          "bottom",
        ],
        properties: [
          {
            name: "Alignment",
            property: "float",
            type: "radio",
            defaults: "none",
            list: [
              { value: "none", className: "fa fa-times" },
              { value: "left", className: "fa fa-align-left" },
              { value: "right", className: "fa fa-align-right" },
            ],
          },
          { property: "position", type: "select" },
        ],
      },
      {
        name: "Dimension",
        open: false,
        buildProps: [
          "width",
          "flex-width",
          "height",
          "max-width",
          "min-height",
          "margin",
          "padding",
        ],
        properties: [
          {
            id: "flex-width",
            type: "integer",
            name: "Width",
            units: ["px", "%"],
            property: "flex-basis",
            toRequire: 1,
          },
          {
            property: "margin",
            properties: [
              { name: "Top", property: "margin-top" },
              { name: "Right", property: "margin-right" },
              { name: "Bottom", property: "margin-bottom" },
              { name: "Left", property: "margin-left" },
            ],
          },
          {
            property: "padding",
            properties: [
              { name: "Top", property: "padding-top" },
              { name: "Right", property: "padding-right" },
              { name: "Bottom", property: "padding-bottom" },
              { name: "Left", property: "padding-left" },
            ],
          },
        ],
      },
      {
        name: "Typography",
        open: false,
        buildProps: [
          "font-family",
          "font-size",
          "font-weight",
          "letter-spacing",
          "color",
          "line-height",
          "text-align",
          "text-decoration",
          "text-shadow",
        ],
        properties: [
          { name: "Font", property: "font-family" },
          { name: "Weight", property: "font-weight" },
          { name: "Font color", property: "color" },
          {
            property: "text-align",
            type: "radio",
            defaults: "left",
            list: [
              {
                value: "left",
                name: "Left",
                className: "fa fa-align-left",
              },
              {
                value: "center",
                name: "Center",
                className: "fa fa-align-center",
              },
              {
                value: "right",
                name: "Right",
                className: "fa fa-align-right",
              },
              {
                value: "justify",
                name: "Justify",
                className: "fa fa-align-justify",
              },
            ],
          },
          {
            property: "text-decoration",
            type: "radio",
            defaults: "none",
            list: [
              { value: "none", name: "None", className: "fa fa-times" },
              {
                value: "underline",
                name: "underline",
                className: "fa fa-underline",
              },
              {
                value: "line-through",
                name: "Line-through",
                className: "fa fa-strikethrough",
              },
            ],
          },
          {
            property: "text-shadow",
            properties: [
              { name: "X position", property: "text-shadow-h" },
              { name: "Y position", property: "text-shadow-v" },
              { name: "Blur", property: "text-shadow-blur" },
              { name: "Color", property: "text-shadow-color" },
            ],
          },
        ],
      },
      {
        name: "Decorations",
        open: false,
        buildProps: [
          "opacity",
          "border-radius",
          "border",
          "box-shadow",
          "background-bg",
        ],
        properties: [
          {
            type: "slider",
            property: "opacity",
            defaults: 1,
            step: 0.01,
            max: 1,
            min: 0,
          },
          {
            property: "border-radius",
            properties: [
              { name: "Top", property: "border-top-left-radius" },
              { name: "Right", property: "border-top-right-radius" },
              { name: "Bottom", property: "border-bottom-left-radius" },
              { name: "Left", property: "border-bottom-right-radius" },
            ],
          },
          {
            property: "box-shadow",
            properties: [
              { name: "X position", property: "box-shadow-h" },
              { name: "Y position", property: "box-shadow-v" },
              { name: "Blur", property: "box-shadow-blur" },
              { name: "Spread", property: "box-shadow-spread" },
              { name: "Color", property: "box-shadow-color" },
              { name: "Shadow type", property: "box-shadow-type" },
            ],
          },
          {
            id: "background-bg",
            property: "background",
            type: "bg",
          },
        ],
      },
      {
        name: "Extra",
        open: false,
        buildProps: ["transition", "perspective", "transform"],
        properties: [
          {
            property: "transition",
            properties: [
              { name: "Property", property: "transition-property" },
              { name: "Duration", property: "transition-duration" },
              { name: "Easing", property: "transition-timing-function" },
            ],
          },
          {
            property: "transform",
            properties: [
              { name: "Rotate X", property: "transform-rotate-x" },
              { name: "Rotate Y", property: "transform-rotate-y" },
              { name: "Rotate Z", property: "transform-rotate-z" },
              { name: "Scale X", property: "transform-scale-x" },
              { name: "Scale Y", property: "transform-scale-y" },
              { name: "Scale Z", property: "transform-scale-z" },
            ],
          },
        ],
      },
      {
        name: "Flex",
        open: false,
        properties: [
          {
            name: "Flex Container",
            property: "display",
            type: "select",
            defaults: "block",
            list: [
              { value: "block", name: "Disable" },
              { value: "flex", name: "Enable" },
            ],
          },
          {
            name: "Flex Parent",
            property: "label-parent-flex",
            type: "integer",
          },
          {
            name: "Direction",
            property: "flex-direction",
            type: "radio",
            defaults: "row",
            list: [
              {
                value: "row",
                name: "Row",
                className: "icons-flex icon-dir-row",
                title: "Row",
              },
              {
                value: "row-reverse",
                name: "Row reverse",
                className: "icons-flex icon-dir-row-rev",
                title: "Row reverse",
              },
              {
                value: "column",
                name: "Column",
                title: "Column",
                className: "icons-flex icon-dir-col",
              },
              {
                value: "column-reverse",
                name: "Column reverse",
                title: "Column reverse",
                className: "icons-flex icon-dir-col-rev",
              },
            ],
          },
          {
            name: "Justify",
            property: "justify-content",
            type: "radio",
            defaults: "flex-start",
            list: [
              {
                value: "flex-start",
                className: "icons-flex icon-just-start",
                title: "Start",
              },
              {
                value: "flex-end",
                title: "End",
                className: "icons-flex icon-just-end",
              },
              {
                value: "space-between",
                title: "Space between",
                className: "icons-flex icon-just-sp-bet",
              },
              {
                value: "space-around",
                title: "Space around",
                className: "icons-flex icon-just-sp-ar",
              },
              {
                value: "center",
                title: "Center",
                className: "icons-flex icon-just-sp-cent",
              },
            ],
          },
          {
            name: "Align",
            property: "align-items",
            type: "radio",
            defaults: "center",
            list: [
              {
                value: "flex-start",
                title: "Start",
                className: "icons-flex icon-al-start",
              },
              {
                value: "flex-end",
                title: "End",
                className: "icons-flex icon-al-end",
              },
              {
                value: "stretch",
                title: "Stretch",
                className: "icons-flex icon-al-str",
              },
              {
                value: "center",
                title: "Center",
                className: "icons-flex icon-al-center",
              },
            ],
          },
          {
            name: "Flex Children",
            property: "label-parent-flex",
            type: "integer",
          },
          {
            name: "Order",
            property: "order",
            type: "integer",
            defaults: 0,
            min: 0,
          },
          {
            name: "Flex",
            property: "flex",
            type: "composite",
            properties: [
              {
                name: "Grow",
                property: "flex-grow",
                type: "integer",
                defaults: 0,
                min: 0,
              },
              {
                name: "Shrink",
                property: "flex-shrink",
                type: "integer",
                defaults: 0,
                min: 0,
              },
              {
                name: "Basis",
                property: "flex-basis",
                type: "integer",
                units: ["px", "%", ""],
                unit: "",
                defaults: "auto",
              },
            ],
          },
          {
            name: "Align",
            property: "align-self",
            type: "radio",
            defaults: "auto",
            list: [
              {
                value: "auto",
                name: "Auto",
              },
              {
                value: "flex-start",
                title: "Start",
                className: "icons-flex icon-al-start",
              },
              {
                value: "flex-end",
                title: "End",
                className: "icons-flex icon-al-end",
              },
              {
                value: "stretch",
                title: "Stretch",
                className: "icons-flex icon-al-str",
              },
              {
                value: "center",
                title: "Center",
                className: "icons-flex icon-al-center",
              },
            ],
          },
        ],
      },
    ],
  },

  // grapesjsTyped
  [grapesjsTyped]: {
    block: {
      category: {
        id: "Extra",
        label: "Extra",
        open: false,
      },
      content: {
        type: "typed",
        "type-speed": 40,
        strings: ["Text row one", "Text row two", "Text row three"],
      },
    },
  },

  // swiperSlider
  [swiperSlider]: {
    sliderBlock: {
      category: {
        id: "Extra",
        label: "Extra",
        open: false,
      },
    },
    sliderProps: {
      autoplay: "false",
      pagination: "bullets",
      style: {
        position: "relative",
      },
      slidesPerView: 1.1,
      spaceBetween: "20",
      // slidesPerColumn: '1',
      // slidesPerGroup: '1',
      centeredSlides: true,
    },
    scrollbarProps: { draggable: false },
    wrapperProps: {
      style: {
        height: "auto",
      },
    },
    slideEls: `
      <div class="swiper-slide">
        <p class="step-no" data-gjs-editable="true" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">step 1</p>
        <div class="stepSlider-content" data-gjs-editable="true" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false"></div>
      </div>
      <div class="swiper-slide">
        <p class="step-no" data-gjs-editable="true" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">step 2</p>
        <div class="stepSlider-content" data-gjs-editable="true" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false"></div>
      </div>
      <div class="swiper-slide">
        <p class="step-no" data-gjs-editable="true" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" >step 3</p>
        <div class="stepSlider-content" data-gjs-editable="true" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false"></div>
      </div>
    `,
  },

  //grapesjsRTEExtention
  [grapesjsRTEExtention]: {
    // default options
    base: {
      bold: true,
      italic: true,
      underline: true,
      strikethrough: true,
      link: true,
    },
    fonts: {
      fontColor: [
        "#f99525",
        "#3e3d3d",
        "#1abc9c",
        "#2ecc71",
        "#3498db",
        "#9b59b6",
        "#34495e",
        "#16a085",
        "#27ae60",
        "#2980b9",
        "#8e44ad",
        "#2c3e50",
        "#f1c40f",
        "#e67e22",
        "#e74c3c",
        "#ecf0f1",
        "#95a5a6",
        "#f39c12",
        "#d35400",
        "#c0392b",
        "#ffffff",
        "#000000",
      ],
      hilite: [
        "#f99525",
        "#3e3d3d",
        "#1abc9c",
        "#2ecc71",
        "#3498db",
        "#9b59b6",
        "#34495e",
        "#16a085",
        "#27ae60",
        "#2980b9",
        "#8e44ad",
        "#2c3e50",
        "#f1c40f",
        "#e67e22",
        "#e74c3c",
        "#ecf0f1",
        "#95a5a6",
        "#f39c12",
        "#d35400",
        "#c0392b",
        "#ffffff",
        "#000000",
      ],
      fontSize: true,
    },
    format: {
      // heading1: true,
      // heading2: true,
      // heading3: true,
      clearFormatting: true,
    },
    subscriptSuperscript: true,
    indentOutdent: true,
    list: true,
    align: true,
    // actions: {
    //   copy: true,
    //   cut: true,
    //   paste: true,
    //   delete: true,
    // },
    darkColorPicker: true,
    maxWidth: "auto",
  },
};
