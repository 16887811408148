import {
    postCreateUser,
    getAllUsers,
    getUser,
    getUserModules,
    postAssignMentor,
    deleteAssignedMentor,
    patchRemoveUser,
    patchEditUser,
    postAssignLearners,
    getLearnersOfMentor,
    getMentorsOfSuperMentor,
    postAssignChildMentors,
    deleteAssignedSuperMentor,
    deleteAssignedSuperMentorOne,
    deleteAssignedMentorOne,
    getSuperMentorDashboard,
    getSchoolGradeForSuperMentor,
    updateLearnerPackages
} from "./services";

export const createUserService = async (payload) => {
    return await postCreateUser(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllUsersService = async (params) => {
    return await getAllUsers(params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getUserService = async (id) => {
    return await getUser(id)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const getUserModulesService = async (id, params) => {
    return await getUserModules(id, params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })

}

export const assignMentorService = async (payload) => {
    return await postAssignMentor(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const assignLearnersService = async (payload) => {
    return await postAssignLearners(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const assignChildMentorsService = async (payload) => {
    return await postAssignChildMentors(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const removeAssignedMentorService = async (id) => {
    return await deleteAssignedMentor(id)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const removeAssignedMentorOneService = async (learnerId, mentorId) => {
    return await deleteAssignedMentorOne(learnerId, mentorId)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const removeAssignedSuperMentorService = async (id) => {
    return await deleteAssignedSuperMentor(id)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const removeAssignedSuperMentorOneService = async (mentorId, superMentorId) => {
    return await deleteAssignedSuperMentorOne(mentorId, superMentorId)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const removeUserService = async (id, payload) => {
    return await patchRemoveUser(id, payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const editUserService = async (id, payload) => {
    return await patchEditUser(id, payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const getMentorLearnersService = async (id, params) => {
    return await getLearnersOfMentor(id, params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const getSuperMentorMentorsService = async (id, params) => {
    return await getMentorsOfSuperMentor(id, params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        })
}

export const getSuperMentorDashboardService = async (payload) => {
    return await getSuperMentorDashboard(payload).then((result) => {
        return result.data;
    }).catch((err) => {
        throw err;
    })
}

export const getSchoolAndGrade = async () => {
    return await getSchoolGradeForSuperMentor().then((result) => {
        return result.data;
    }).catch((err) => {
        throw err
    });
}

export const updatepackages = async (payload) => {
    return await updateLearnerPackages(payload).then((result) => {
        return result.data;
    }).catch((err) => {
        throw err;
    })
}