import React, { Component } from "react";
import { Space } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

//Helpers
import { convertCase } from "../../helpers";

//Actions
import { setAllTagCategories } from "./actions";

//Service
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

class TagCategory extends Component {
  state = {
    removeShowModal: false,
    isLoader: false,
    errorModal: null,
    succModal: null,
    meta: {
      page: 1,
      limit: 10,
    },
    selected_id: "",
  };

  componentDidMount() {
    this.getAllTagCategoriesData();
  }

  getAllTagCategoriesData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    CDM.getAllTagCategoriesService(queryObject)
      .then((res) => {
        this.props.setAllTagCategories(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllTagCategoriesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  removeTagCategory = () => {
    this._startLoader();
    const id = this.state.selected_id;
    DM.deleteTagCategoryService(id)
      .then((res) => {
        this._stopLoader();
        this.getAllTagCategoriesData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Tag category removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "tags.tagCategories.results", null);
    const totalTagCategories = get(
      this.props,
      "tags.tagCategories.meta.pagination.total",
      null,
    );

    return (
      <>
        <PageTitle title="Tag Category" />
        <div className="tag-Category-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Tag Categories" }]} />
              <h4 className="title4"> List of all Tag Categories</h4>
            </div>
            <div className="right-section">
              {permissions.TAGS &&
                permissions.TAGS.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new"
                    href={ROUTES.NEWTAGCATEGORY}
                  />
                )}
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style mt--40">
              <div className="table-style">
                <table className="table-custom-style">
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    {permissions.TAGS &&
                      (permissions.TAGS.includes(ACTIONS.UPDATE) ||
                        permissions.TAGS.includes(ACTIONS.DELETE)) && (
                        <th>Actions</th>
                      )}
                  </tr>

                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-tags" key={index}>
                          <td>
                            <p className="lead">{get(data, "name")}</p>
                          </td>
                          <td>
                            <div className="flex-tag">
                              <span
                                className={`circle ${ReturnStatusColor(
                                  data.status,
                                )} mr-2`}></span>
                              <p className="lead mb--0">
                                {convertCase(get(data, "status"))}
                              </p>
                            </div>
                          </td>
                          {permissions.TAGS &&
                            (permissions.TAGS.includes(ACTIONS.UPDATE) ||
                              permissions.TAGS.includes(ACTIONS.DELETE)) && (
                              <td>
                                <Space size="middle">
                                  {permissions.TAGS &&
                                    permissions.TAGS.includes(
                                      ACTIONS.UPDATE,
                                    ) && (
                                      <ButtonOutline
                                        btnText={<EditOutlined />}
                                        // btnStyle="btn-sm"
                                        btnStyle="btn edit square-edit"
                                        clickHandler={() => {
                                          this.props.history.push(
                                            ROUTES.EDITTAGCATEGORY.replace(
                                              ":id",
                                              data.id,
                                            ),
                                          );
                                        }}
                                      />
                                    )}
                                  {permissions.TAGS &&
                                    permissions.TAGS.includes(
                                      ACTIONS.DELETE,
                                    ) && (
                                      <ButtonDanger
                                        type="link"
                                        btnText={<DeleteOutlined />}
                                        btnDangerStyle="btn delete btn-link"
                                        onClick={() =>
                                          this.removeToggleModal(data.id)
                                        }>
                                        Remove
                                      </ButtonDanger>
                                    )}
                                </Space>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalTagCategories}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          )}

          {isLoader && <Loader />}
          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this category"
            description="Are you sure you want to remove this tag category?"
            // Action button event here
            removeHandler={this.removeTagCategory}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, remove"
            dontRemoveBtnText="Don't remove"
          />
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllTagCategories,
})(TagCategory);
