export const getDocumentsModel = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  const defaultModel = defaultType.model;

  return defaultModel.extend(
    {
      defaults: {
        ...defaultModel.prototype.defaults,
        type: "file",
        tagName: "file",
        void: 1,
        loop: 0,
        muted: 0,
        poster: "",
        autoplay: 0,
        controls: 1,
        editable: 1,
        removable: false,
        draggable: false,
        droppable: false,
        copyable: false,
        highlightablw: 0,
        traits: [
          {
            type: "text",
            label: "src",
            name: "src",
          },
          {
            type: "select",
            name: "iconType",
            options: [
              { id: "1", name: "PDF" },
              { id: "2", name: "Image" },
              { id: "3", name: "Doc" },
            ],
          },
        ],
      },

      src: "",

      //fallback file in case the src can't be loaded
      fallback: "",

      initialize(o, opt) {
        defaultModel.prototype.initialize.apply(this, arguments);
        var attr = this.get("attributes");
        if (attr.src) this.set("src", attr.src);
      },

      initToolbar(...args) {
        defaultModel.prototype.initToolbar.apply(this, args);
        const em = this.em;

        if (em) {
          var cmd = em.get("Commands");
          var cmdName = "document-editor";

          //Add Audio Editor Button only if the default command exists
          if (cmd.has(cmdName)) {
            let hasButtonBool = false;
            var tb = this.get("toolbar");

            for (let i = 0; i < tb.length; i++) {
              if (tb[i].command === "document-editor") {
                hasButtonBool = true;
                break;
              }
            }

            if (!hasButtonBool) {
              tb.push({
                attributes: { class: "fa fa-file" },
                command: cmdName,
              });
              this.set("toolbar", tb);
            }
          }
        }
      },

      /**
       * Return object of attribute for html
       * @return {Object}
       * @private
       */

      getAttrToHtml(...args) {
        const attr = defaultModel.prototype.getAttrToHtml.apply(this.args);
        const src = this.getSrcResult();
        if (src) attr.src = src;
        return attr;
      },

      getSrcResult(opt = {}) {
        const src = this.get(opt.fallback ? "fallback" : "src") || "";
        let result = src;

        if (src && src.substr(0, 6) === "<input") {
          result = `data:@file/*;base64,${window.btoa(src)}`;
        }
        return result;
      },

      isDefaultSrc() {
        return this.get("src") === "";
      },

      /**
       * Parse uri
       * @param {string} uri
       * @return {object}
       * @private
       */
      parseUri(uri) {
        var el = document.createElement("a");
        el.href = uri;
        var query = {};
        var qrs = el.search.substring(1).split("&");
        for (var i = 0; i < qrs.length; i++) {
          var pair = qrs[i].split("=");
          var name = decodeURIComponent(pair[0]);
          if (name) query[name] = decodeURIComponent(pair[1]);
        }

        return {
          hostname: el.hostname,
          pathname: el.pathname,
          protocol: el.protocol,
          search: el.search,
          hash: el.hash,
          port: el.port,
          query,
        };
      },
    },
    {
      /**
       * Detect is passed element is a valid component.
       * In case the element is valid an object abstracted
       * form the element will re returened
       * @param {HTMLElement}
       * @return {object}
       * @private
       */
      isComponent(el) {
        var result = "";
        if (el.tagName === "FILE") {
          result = { type: "file" };
        }
        return result;
      },
    },
  );
};
