import { API } from "./Constants";
import { axiosInstance } from "../../tools";

export const createCategory = async (payload) => {
  let url = API.POST_CREATE_CATEGORY;
  return await axiosInstance.post(url, payload);
};

export const getCategory = async (id) => {
  let url = API.GET_CATEGORY.replace(":id", id);
  return await axiosInstance.get(url);
};

export const updateCategory = async (id, payload) => {
  let url = API.PATCH_UPDATE_CATEGORY.replace(":id", id);
  return await axiosInstance.patch(url, payload);
};

export const deleteCategory = async (id) => {
  let url = API.DELETE_CATEGORY.replace(":id", id);
  return await axiosInstance.delete(url);
};

