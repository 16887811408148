import { TYPES } from "../../containers/FeatureQuestions/Constants";

export default (state = {}, action) => {
    switch (action.type) {
        case TYPES.SET_ALL_FEATURE_QUESTIONS:
            return {
                ...state,
                allFeatureQuestions: action.payload,
                error: {},
            };

        default:
            return state;
    }
};
