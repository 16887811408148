import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { objectToFormdata } from "../../helpers";

export const getSpecificCareer = async (id) => {
  let url = API.GET_SPECIFIC_CAREERS.replace(":id", id);
  return await axiosInstance.get(url);
};

export const postCreateCareer = async (payload) => {
  return await axiosInstance.post(
    API.POST_CREATE_CAREERS,
    objectToFormdata(payload),
  );
};

export const patchUpdateCareer = async (id, payload) => {
  let url = API.PATCH_UPDATE_CAREERS.replace(":id", id);
  return await axiosInstance.patch(url, objectToFormdata(payload));
};

export const deleteCareer = async (id) => {
  let url = API.DELETE_CAREERS.replace(":id", id);
  return await axiosInstance.delete(url);
};
