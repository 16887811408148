import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { objectToFormdata, makeQueryParam } from "../../helpers";

export const postCreateUser = async (payload) => {
  const formData = objectToFormdata(payload);
  if (payload?.meta?.location) payload?.meta?.location[0]?.latitude && formData.append('latitude', payload.meta.location[0].latitude)
  if (payload?.meta?.location) payload?.meta?.location[0]?.longitude && formData.append('longitude', payload.meta.location[0].longitude);
  if (payload?.meta?.distance) formData.append('distance', payload.meta.distance);
  if (payload?.meta?.max_invites || payload?.meta?.max_invites === 0) formData.append('max_invites', payload.meta.max_invites);
  if (payload?.meta?.total_invites || payload?.meta?.total_invites === 0) formData.append('total_invites', payload.meta.total_invites);
  if (payload?.meta?.allowed_modules || payload?.meta?.allowed_modules === 0) formData.append('allowed_modules', payload.meta.allowed_modules);
  return await axiosInstance.post(
    API.POST_CREATE_USER,
    formData,
  );
};

export const getAllUsers = async (params) => {
  let url = `${API.GET_ALL_USERS}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
};

export const getUser = async (id) => {
  let url = API.GET_USER.replace(":id", id);
  return await axiosInstance.get(url);
}

export const getUserModules = async (id, params) => {
  let url = API.GET_USER_MODULES.replace(":id", id);
  if (params) url = `${url}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
}

export const postAssignMentor = async (payload) => {
  return await axiosInstance.post(
    API.POST_ASSIGN_MENTOR,
    payload
  )
}

export const deleteAssignedMentor = async (id) => {
  let url = API.DELETE_ASSIGNED_MENTOR.replace(":id", id);
  return await axiosInstance.delete(url);
}

export const deleteAssignedMentorOne = async (learnerId, mentorId) => {
  let url = API.DELETE_ASSIGNED_MENTOR_ONE.replace(":id", learnerId).replace(":mentorId", mentorId);
  return await axiosInstance.delete(url);
}

export const deleteAssignedSuperMentor = async (id) => {
  let url = API.DELETE_ASSIGNED_SUPER_MENTOR.replace(":id", id);
  return await axiosInstance.delete(url);
}

export const deleteAssignedSuperMentorOne = async (mentorId, superMentorId) => {
  let url = API.DELETE_ASSIGNED_SUPER_MENTOR_ONE.replace(":id", mentorId).replace(":superMentorId", superMentorId);
  return await axiosInstance.delete(url);
}

export const patchRemoveUser = async (id, payload) => {
  let url = API.PATCH_REMOVE_USER.replace(":id", id);
  return await axiosInstance.patch(url, payload);
}

export const patchEditUser = async (id, payload) => {
  const formData = objectToFormdata(payload);
  if (payload?.meta?.location) payload?.meta?.location[0]?.latitude && formData.append('latitude', payload.meta.location[0].latitude)
  if (payload?.meta?.location) payload?.meta?.location[0]?.longitude && formData.append('longitude', payload.meta.location[0].longitude);
  if (payload?.meta?.distance) payload?.meta?.distance && formData.append('distance', payload.meta.distance);
  if (payload?.meta?.max_invites || payload?.meta?.max_invites === 0) formData.append('max_invites', payload.meta.max_invites);
  if (payload?.meta?.total_invites || payload?.meta?.total_invites === 0) formData.append('total_invites', payload.meta.total_invites);
  if (payload?.meta?.allowed_modules || payload?.meta?.allowed_modules === 0) formData.append('allowed_modules', payload.meta.allowed_modules);
  let url = API.PATCH_EDIT_USER.replace(":id", id);
  return await axiosInstance.patch(url, formData);
}

export const postAssignLearners = async (payload) => {
  return await axiosInstance.post(API.POST_ASSIGN_LEARNERS, payload);
}

export const postAssignChildMentors = async (payload) => {
  return await axiosInstance.post(API.POST_ASSIGN_CHILD_MENTORS, payload);
}

export const getLearnersOfMentor = async (id, params) => {
  let url = API.GET_MENTOR_LEARNERS.replace(":id", id);
  url = `${url}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
}

export const getMentorsOfSuperMentor = async (id, params) => {
  let url = API.GET_SUPERMENTOR_MENTORS.replace(":id", id);
  url = `${url}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
}

export const getSuperMentorDashboard = async (payload) => {
  return await axiosInstance.post(API.POST_SUPERMENTOR_DASHBOARD, payload);
}

export const getSchoolGradeForSuperMentor = async () => {
  return await axiosInstance.get(API.GET_GRADE_SCHOOLS_FOR_SUPERMENTOR)
}

export const updateLearnerPackages = async (payload) => {
  return await axiosInstance.patch(API.UPDATE_LEARNER_PACKAGES, payload);
}
