export const addFont = async (editor) => {
  const prop = editor.StyleManager.getProperty("typography", "font-family");
  prop.set("options", [
    { value: "'Merriweather', Merriweather", name: "Merriweather" },
    { value: "'proxima-nova',sans-serif", name: "proxima-nova" },
    {
      value: "'proxima-nova-condense',sans-serif",
      name: "proxima-nova-condense",
    },
    {
      value: "'proxima-nova-extra-condensed',sans-serif",
      name: "proxima-nova-extra-condensed",
    },
  ]);
};
