import React, { Component } from "react";
import { Input } from "antd";

import "./styles.scss";

const { Search } = Input;

class SearchPrimary extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="search-input-style">
        <Search
          placeholder={this.props.placeholder}
          onSearch={(value) => {
            this.props.onSearch(value);
          }}
          onPressEnter={(value) => {
            this.props.onSearch(value);
          }}
          className="search-primary"
          enterButton={this.props.enterButton}
          onChange={this.props.onSearch}
        />
      </div>
    );
  }
}

export default SearchPrimary;
