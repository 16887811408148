import React, { Component } from "react";
import { Avatar, Card, Space } from "antd";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { get } from 'lodash';

// Custom Components
import ProfileMentorCard from "../../../../components/ProfileMentorCard";
import Loader from '../../../../components/PrimaryLoader';
import EmptyState from '../../../../components/EmptyState';
import PrimaryPagination from "../../../../components/PrimaryPagination";

//Constants
import { ACTIONS, BREADCRUMBS, ROUTES } from '../../../../constants';

//Service
import * as DM from '../../dataManager';
import * as MDM from '../../../Modules/dataManager';

//Helpers
import { convertCase } from '../../../../helpers'

// Style
import "./styles.scss";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../../../components/PageTitle";
import { connect } from "react-redux";
import RemoveModal from "../../../../components/modal/RemoveModal";
import ErrorModal from "../../../../components/modal/ErrorModal";
import SuccessModal from "../../../../components/modal/SuccessModal";
import ButtonOutline from "../../../../components/buttons/ButtonOutline";
import ButtonDanger from "../../../../components/buttons/ButtonDanger";
import { USERSTATUSENUM } from "../../Constants";
import SelectInput from "../../../../components/SelectInput";
import ViewedModules from "../../../../components/ViewedModules";

class MentorDetails extends Component {

  constructor(props) {
    super();
    this.state = {
      errorModal: null,
      succModal: null,
      meta: {
        page: 1,
        limit: 10
      },
      meta2: {
        page: 1,
        limit: 10
      },
      mentor: {},
      learners: [],
      modules: [],
      totalLearners: 0,
      isLoader: false,
      removeShowModal: false
    }
  }

  componentDidMount() {
    this.getMentorData();
    this.getMentorLearnersData();
    this.getMentorModulesData();
  }

  getMentorModulesData() {
    const id = this.props.match.params.id;
    this._startLoader();
    const { page, limit } = this.state.meta2;
    MDM.getMyViewedModulesService({ id, page, limit })
      .then((res) => {
        this._stopLoader();
        this.setState({
          modules: res.data
        })
      })
      .catch((err) => {
        this._stopLoader();
      })
  }

  getMentorData = () => {
    const mentor_id = this.props.match.params.id;
    this._startLoader();
    DM.getUserService(mentor_id)
      .then((res) => {
        this._stopLoader();

        this.setState({
          mentor: res.data
        })
      })
      .catch((err) => {

        this._stopLoader();
      })
  }

  handlePerPageChange = (limit) => {
    const { meta } = this.state
    this.setState({ meta: { ...meta, limit: limit, page: 1 }, isLoader: true }, () => { this.getMentorLearnersData() });
  }

  handleModulePageChange = (page) => {
    let meta2 = this.state.meta2;
    meta2.page = page;
    this.setState({ meta2: meta2 }, () => {
      this.getMentorModulesData();
    })
  }

  getMentorLearnersData = () => {
    const mentor_id = this.props.match.params.id;
    const { page, limit } = this.state.meta;
    DM.getMentorLearnersService(mentor_id, { page, limit, type: "LEARNER" })
      .then((res) => {
        this._stopLoader();
        this.setState({
          learners: get(res, "data.results"),
          totalLearners: get(res, "data.meta.pagination.total")
        })
      })
      .catch((err) => {

        this._stopLoader();
      })
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getMentorLearnersData();
    })
  }

  removeToggleModal = (id) => {
    this.setState({
      deleteSMId: id,
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    })
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeSuperMentor = () => {
    this._startLoader();
    const mentorId = this.props.match.params.id;
    const superMentorId = this.state.deleteSMId;
    DM.removeAssignedSuperMentorOneService(mentorId, superMentorId)
      .then((res) => {
        this._stopLoader();
        this.removeToggleModal();
        this.setState({
          deleteSMId: null,
          succModal: { title: "Success", description: "Super mentor removed successfully." }
        })
        this.getMentorData();
      })
      .catch((err) => {
        this.setState({
          deleteSMId: null,
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", "")
          }
        })
        this._stopLoader();
        this.removeToggleModal();
      })
  }

  render() {
    const permissions = get(this.props, "auth.permissions");
    const { mentor, isLoader, learners, totalLearners, meta, removeShowModal, errorModal, succModal, modules, meta2 } = this.state;
    const dataColumns = learners.map(learner => {
      return learner.student;
    });
    const moduleDataColumns = get(modules, "results")
    const totalModules = get(modules, "meta.pagination.total");

    return (
      <>
        <PageTitle title="Mentor" />
        <div className="mentor-details-style">
          <div className="header-style mb--30 mt--10">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.MENTORS,
                  { text: "View Details" },
                ]}
              />
              <h4 className="title4"> View Details</h4>
            </div>
          </div>

          {mentor?.status === USERSTATUSENUM.UserStatus.INACTIVE ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <ProfileMentorCard mentor={mentor} />}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && get(mentor, "superMentor")) &&
            <Card className="mt--50">
              <div className="table-container">
                <div className="mb--20">
                  <h4 className="title4">Assigned super mentors</h4>
                </div>

                <div className="table-card-style">
                  <div className="table-style">
                    <table className="table-custom-style">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Actions</th>
                      </tr>
                      {get(mentor, "superMentor").map((data, index) => {
                        return (
                          <tr className="data-row table-module" key={index}>
                            <td>
                              <p className="lead">{get(data, "rawid") || "---"}</p>
                            </td>
                            <td>
                              <div className="img-content-style-m flex-col">
                                {get(data, "media.path", null) ?
                                  <Avatar size={40} src={get(data, "media.path", null)} /> :
                                  <Avatar size={40} icon={<UserOutlined />} />
                                }
                                <p className="lead trim-sm">
                                  <a className="lead link-text"
                                    href={
                                      ROUTES.VIEWSUPERMENTOR.replace(":id", get(data, "id"))
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {data.full_name}
                                  </a>
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="lead">{get(data, "email") || "---"}</p>
                            </td>

                            <td>
                              <p className="lead">{get(data, "phone") || "---"}</p>
                            </td>
                            <td>
                              {permissions.USERS && permissions.USERS.includes(ACTIONS.UPDATE) &&
                                <Space size="middle">
                                  <ButtonOutline
                                    btnText={<EditOutlined />}
                                    btnStyle="btn edit square-edit"
                                    clickHandler={() =>
                                      this.props.history.push(
                                        ROUTES.EDITSUPERMENTOR.replace(
                                          ":id",
                                          data.id,
                                        ),
                                      )
                                    }
                                  />
                                  <ButtonDanger
                                    type="link"
                                    btnText={<DeleteOutlined />}
                                    btnDangerStyle="btn delete btn-link"
                                    onClick={() => { this.removeToggleModal(data.id) }
                                    }>
                                    Remove
                                  </ButtonDanger>
                                </Space>
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          }

          {/* <div className="assigned-mentor mt--35">
          <h4 className="title4 mb--20">Assigned learner</h4>

          <Card className="mentor-card">
            <Avatar size={40} icon={<UserOutlined />} />
            <h6 className="title6">Matthew Stewart</h6>
          </Card>
        </div> */}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && dataColumns.length > 0) && <Card className="mt--50">
            <div className="table-container">
              <div className="filter-head">
                <div>
                  <h4 className="title4 mb--10">Assigned learners</h4>
                  <h4 className="sub-heading mb--20">Total:  {totalLearners ? totalLearners : 0}</h4>
                </div>
                <SelectInput
                  defaultValue={meta.limit}
                  value={meta.limit}
                  isTitleActive
                  title="Limit:"
                  placeholder="Select"
                  options={[10, 25, 50, 100]}
                  onChange={(value) => this.handlePerPageChange(value)}
                />
              </div>
              {(!dataColumns || dataColumns.length === 0) ? <EmptyState title="No learners" description="No learners assigned for this mentor." /> :
                <>
                  <div className="table-card-style">
                    <div className="table-style">
                      <table className="table-custom-style">
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Organization</th>
                          <th>School</th>
                          <th>Package</th>
                        </tr>
                        {dataColumns && dataColumns.map((data, index) => {
                          return (
                            <tr className="data-row table-module" key={index}>
                              <td>
                                <p className="lead">{get(data, "rawid") || "---"}</p>
                              </td>
                              <td>
                                <div className="img-content-style">
                                  <p className="lead trim-sm">
                                    <a className="lead link-text" href={ROUTES.VIEWLEARNER.replace(":id", get(data, "id"))} target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {data.full_name}
                                    </a>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="lead">{get(data, "email") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{get(data, "organization") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{get(data, "current_school_uni") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{convertCase(get(data, "userpackages.packages.name")) || "---"}</p>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="page-section">
                    <PrimaryPagination pageSize={meta.limit} current={meta.page} total={totalLearners} onChange={this.handlePageChange} />
                  </div>
                </>
              }
            </div>
          </Card>}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && moduleDataColumns?.length > 0) &&
            <ViewedModules
              dataColumns={moduleDataColumns}
              meta={meta2}
              totalModules={totalModules}
              isLearner={false}
              handlePageChange={this.handleModulePageChange}
            />
          }

        </div>
        {isLoader && <Loader />}
        {removeShowModal &&
          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this super mentor"
            description="Are you sure to remove this super mentor?"
            // Action button event here
            removeHandler={this.removeSuperMentor}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
        }

        {errorModal &&
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
        }

        {succModal &&
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(MentorDetails);
