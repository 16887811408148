export const getImageForVideoModel = (editor) => {
    //Get Component Object
    const comps = editor.DomComponents;

    //Get Default Component Object
    var defaultType = comps.getType("default");
    const defaultModel = defaultType.model;

    return defaultModel.extend(
        {
            defaults: {
                ...defaultModel.prototype.defaults,
                type: 'image-for-video',
                tagName: 'image-for-video',
                traits: [{
                    type: "href",
                    label: "href",
                    name: "href",
                },
                {
                    type: "select",
                    label: "Target",
                    name: "target",
                    options: [
                        { value: "_self", name: "This Window" },
                        { value: "_blank", name: "New Window" },
                    ],
                }],
            },
        },
        {
            /**
             * Detect is passed element is a valid component.
             * In case the element is valid an object abstracted
             * form the element will re returened
             * @param {HTMLElement}
             * @return {object}
             * @private
             */
            isComponent: (el) => {
                return el.tagName === "IMAGE-FOR-VIDEO"
            }
        },
    );
};