import React, { Component } from "react";
import { Button, Col, Row } from "antd";
import { get } from 'lodash'

// Style
import "./styles.scss";
import ButtonSecondary from "../../buttons/ButtonSecondary";
import LearnersCard from "../../LearnersCard";
import ButtonOutline from "../../buttons/ButtonOutline";
import ToggleMenu from "../../ToggleMenu";
import ModuleProjectModal from "../../modal/ModuleProjectModal";
import EmptyState from '../../EmptyState';

// const MiniProjectGrrapesJs = new GEditorComponent();

class MiniProjects extends Component {
  constructor(props) {
    super();
    this.state = {
      shown: true,
      projects: []
    };
  }

  componentDidMount() {
    this.setAllProjects();
  }

  setAllProjects = () => {
    const { projectsInfo } = this.props;
    let { projects } = projectsInfo;
    if (projects && projects.length) {
      projects = projects.filter(project => {
        return project.type === "MINI_PROJECT";
      })
    }
    if (projects) this.setState({ projects })
  }

  render() {
    const { data, globalStoreData, errorObj, handleSave, onSaveTabsContent, tabFileUpload, projectsInfo, toggleProjectModal } = this.props;
    const { selectedProject } = projectsInfo;
    const { projects } = this.state;

    let defaultContent = get(selectedProject, "content");
    let title = get(selectedProject, "name");
    if (selectedProject) {
      let globalData = get(globalStoreData, `editProject.${selectedProject.id}`)
      if (globalData && get(globalData, "name") !== undefined)
        title = get(globalData, "name");

      if (globalData && get(globalData, "content")) {
        defaultContent = get(globalData, "content");
      }
    } else {
      let globalData = get(globalStoreData, `newMiniProject.${data.id}`)
      if (globalData && get(globalData, "name") !== undefined)
        title = get(globalData, "name");

      if (globalData && get(globalData, "content"))
        defaultContent = get(globalData, "content");
    }
    if (typeof defaultContent === "string") {
      defaultContent = { ...JSON.parse(defaultContent) }
    }

    let cardCol1 = [], cardCol2 = [];
    projects && projects.map((data, index) => {
      if (index % 2 === 0) {
        cardCol1.push(
          <LearnersCard key={index}>
            <div className="img-heading">
              <h5 className="title5">{get(data, "name")}</h5>
            </div>
            <div className="right-sec">
              <ButtonOutline
                btnText="Edit"
                btnStyle="btn-sm"
                className="button-outline"
                clickHandler={() => {
                  toggleProjectModal(data, "mini");
                }}
              />

              <ToggleMenu>
                <Button
                  type="link"
                  className="text-danger"
                  onClick={() => this.props.openRemoveModal(data.id)}
                >
                  Remove
               </Button>
              </ToggleMenu>
            </div>
          </LearnersCard>
        )
      } else {
        cardCol2.push(
          <LearnersCard key={index}>
            <div className="img-heading">
              <h5 className="title5">{get(data, "name")}</h5>
            </div>
            <div className="right-sec">
              <ButtonOutline
                btnText="Edit"
                btnStyle="btn-sm"
                className="button-outline"
                clickHandler={() => {
                  toggleProjectModal(data, "mini");
                }}
              />

              <ToggleMenu>
                <Button
                  type="link"
                  className="text-danger"
                  onClick={() => this.props.openRemoveModal(data.id)}
                >
                  Remove
             </Button>
              </ToggleMenu>
            </div>
          </LearnersCard>
        )
      }
      return null;
    })


    return (
      <div className="mini-project-container">
        <div className="container-style">
          <div className="header-style">
            <div className="title-header">
              <h4 className="title4"> List of All Mini Projects</h4>
            </div>
            <div className="right-section">
              <ButtonSecondary
                btnText="Add new Project"
                onClick={() => toggleProjectModal(null)}
              />
            </div>
          </div>

          {projectsInfo.isModalVisible &&
            <ModuleProjectModal
              isModalVisible={projectsInfo.isModalVisible}
              onClose={() => toggleProjectModal(null)}
              containerId={`#acadru-editor-${get(selectedProject, "id", "new")}`}
              tabFileUpload={tabFileUpload}
              onSaveTabsContent={(data) => {
                onSaveTabsContent(data, get(selectedProject, "id", null));
              }}
              title={title}
              content={defaultContent}
              errorObj={errorObj}
              handleSave={() => handleSave(get(selectedProject, "id", null))}
            />
          }

          {cardCol1.length === 0 ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> :
            <div className="list-style list-card-style mt--30 border-top-style">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <p className="lead">Project Name</p>
                    <p className="lead right-sec-panel">Action</p>
                  </div>
                  {cardCol1}
                </Col>
                {cardCol2.length > 0 &&
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <p className="lead">Project Name</p>
                      <p className="lead right-sec-panel">Action</p>
                    </div>
                    {cardCol2}
                  </Col>
                }
              </Row>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default MiniProjects;