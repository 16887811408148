import React, { Component } from "react";
import { Space } from "antd";
import { get } from "lodash";
// Style
import "./styles.scss";

import { PageTitle } from "../../components/PageTitle";

// Custom Components
import RemoveModal from "../../components/modal/RemoveModal";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import PrimaryPagination from "../../components/PrimaryPagination";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";

//Constants
import { ROUTES } from "../../constants";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";

//Data Manager
import * as DM from "./dataManager";

class PackageDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      packages: [],
      removeShowModal: false,
      isLoader: false,
      errorModal: null,
      succModal: null,
      meta: {
        page: 1,
        limit: 10,
      },
      selected_id: "",
    };
  }

  componentDidMount() {
    this.getAllPackagesData();
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllPackagesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  getAllPackagesData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    DM.getAllPackagesService(queryObject)
      .then((res) => {
        this.setState({ packages: res.data });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  removePackage = () => {
    this._startLoader();
    const id = this.state.selected_id;
    DM.deletePackageService(id)
      .then((res) => {
        this._stopLoader();
        this.getAllPackagesData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Package removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      packages,
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const dataColumns = get(packages, "results");
    const totalPackages = get(packages, "meta.pagination.total");
    return (
      <>
        <PageTitle title="All Packages" />
        <div className="package-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "All Packages" }]} />
              <h4 className="title4"> List of all Packages</h4>
            </div>
            <div className="right-section">
              <ButtonSecondary btnText="Add new" href={ROUTES.NEWPACKAGE} />
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style mt--40">
              <div className="table-style table-responsive">
                <table className="table-custom-style">
                  <tr>
                    <th>Package Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>

                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-tags">
                          <td>
                            <p className="lead">{get(data, "name") || "---"}</p>
                          </td>
                          <td>
                            <div className="flex-tag">
                              <span
                                className={`circle ${ReturnStatusColor(
                                  data.status,
                                )} mr-2`}></span>
                              <p className="lead mb--0">
                                {get(data, "status") || "---"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <Space size="middle">
                              <ButtonOutline
                                btnText={<EditOutlined />}
                                btnStyle="btn edit square-edit"
                                clickHandler={() =>
                                  this.props.history.push(
                                    ROUTES.EDITPACKAGE.replace(":id", data.id),
                                  )
                                }
                              />
                              <ButtonPrimary
                                btnText={<EyeOutlined />}
                                btnPrimaryStyle="btn view square-view"
                                onClick={() =>
                                  this.props.history.push(
                                    ROUTES.VIEWPACKAGE.replace(
                                      ":id",
                                      get(data, "id"),
                                    ),
                                  )
                                }
                              />
                              <ButtonDanger
                                type="link"
                                btnText={<DeleteOutlined />}
                                btnDangerStyle="btn delete btn-link"
                                onClick={() => this.removeToggleModal(data.id)}>
                                Remove
                              </ButtonDanger>
                            </Space>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalPackages}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          )}

          {isLoader && <Loader />}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this package"
              description="Are you sure you want to remove this package?"
              // Action button event here
              removeHandler={this.removePackage}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}

          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

export default PackageDetails;
