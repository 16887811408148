import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { objectToFormdata } from "../../helpers";


export const getProfile = async () => {
    return await axiosInstance.get(API.GET_PROFILE);
};

export const patchUpdateProfile = async (payload) => {
    return await axiosInstance.patch(
        API.PATCH_UPDATE_PROFILE,
        objectToFormdata(payload),
    );
};

export const patchChangePassword = async (payload) => {
    return await axiosInstance.patch(API.PATCH_CHANGE_PASSWORD, payload);
};