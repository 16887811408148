export const acadruGalleryBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false" ><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Carousel
  blockManager.add("Carousel", {
    label: `
    <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 7.6c0-1-.5-1.6-1.3-1.6H3.4C2.5 6 2 6.7 2 7.6v9.8c0 1 .5 1.6 1.3 1.6h17.4c.8 0 1.3-.6 1.3-1.6V7.6zM21 18H3V7h18v11z" fill-rule="nonzero"/><path d="M4 12.5L6 14v-3zM20 12.5L18 14v-3z"/>
    </svg>
    <div class="gjs-block-label">Carousel</div>`,
    category: {
      id: "Acadru Gallery",
      label: "Acadru Gallery",
      open: false,
    },
    content: `
  ${rowCol6Start}
    <div data-gjs-type="lory-slider" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"></div>
  ${rowCol6End}
  `,
  });

  // 2 Image Grid
  blockManager.add("ImageGrid2", {
    label: "Image Grid 2",
    category: "Acadru Gallery",
    attributes: { class: "gjs-fonts gjs-f-b2" },
    content: `
  ${rowCol6Start}
    <div class="${classPreFix}-two-column-layout twoColumnGrid"  data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    </div>
  ${rowCol6End}
  `,
  });

  // 3 Image Grid
  blockManager.add("ImageGrid3", {
    label: "Image Grid 3",
    category: "Acadru Gallery",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `
  ${rowCol6Start}
    <div class="${classPreFix}-three-column-layout threeColumnGrid" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <div class="${classPreFix}-one-third-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-one-third-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" > <strong data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-one-third-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" > <strong data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    </div>
  ${rowCol6End}
  `,
  });

  // 4 Image Grid
  blockManager.add("ImageGrid4", {
    label: "Image Grid 4",
    category: "Acadru Gallery",
    attributes: { class: "fa fa-window-restore" },
    content: `
  ${rowCol6Start}
    <div class="${classPreFix}-four-column-layout fourColumnGrid" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <div class="${classPreFix}-one-fourth-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" > <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-one-fourth-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false" > <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-one-fourth-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false" > <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
      <div class="${classPreFix}-one-fourth-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    </div>
  ${rowCol6End}
  `,
  });
};
