import React, { Component } from "react";
// import { Button } from "antd";
// import ToggleMenu from "../ToggleMenu";
import ButtonDanger from '../buttons/ButtonDanger';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ButtonOutline from "../buttons/ButtonOutline";
import { ACTIONS } from "../../constants";

// Style
import "./styles.scss";

class CollectionCard extends Component {
  render() {
    const { permissions, isClassActive } = this.props;
    return (
      <div className="collection-section">
        <div className="left-box">
          <h5 className="title5">{this.props.name}</h5>
        </div>
        <div className={`right-box fx fx--ai-c ${isClassActive ? "fx--jc-fe-imp" : ""}`}>
          <div className="status">
            <span className={`circle ${this.props.dotStyle}`}></span>
            <p className="text-status"> {this.props.dotText}</p>
          </div>
          <div className="right-sec">
            {permissions.COLLECTIONS && permissions.COLLECTIONS.includes(ACTIONS.UPDATE) &&
              <ButtonOutline
                btnText={<EditOutlined />}
                // btnStyle="btn-sm"
                btnStyle="btn edit square-edit mr--10"
                clickHandler={this.props.clickHandler}
              />
            }
            {permissions.COLLECTIONS && permissions.COLLECTIONS.includes(ACTIONS.DELETE) &&
              <ButtonDanger
                type="link"
                btnText={<DeleteOutlined />}
                btnDangerStyle="btn delete btn-link"
                onClick={this.props.onClick}>
                Remove
              </ButtonDanger>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default CollectionCard;
