import { ROLES } from "../../constants";
import * as SERVICES from "./service";

export const setTokenToAPIInstanceService = (token) => {
  SERVICES.setTokenToAPIInstance(token);
};

export const getTokenToAPIInstanceService = async () => {
  return await SERVICES.getTokenToAPIInstance();
};

/**
 *
 * @param {String} email
 * @param {String} password
 */
export const loginService = async (email, password, recaptchaResponse) => {
  return await SERVICES.login({ email, password, recaptchaResponse })
    .then((res) => {
      const data = res.data.data;
      const userRoleType = data.roles[0].type;
      if (ROLES.ValidUserRoles.includes(userRoleType)) {
        return res.data;
      } else {
        throw new Error("Forbidden");
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const userNotificationSubscibeService = async (payload) => {
  return await SERVICES.usersNotificationSubscribe(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
