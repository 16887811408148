import React, { Component } from "react";
import { Modal, Avatar, Checkbox } from "antd";
import ButtonDanger from "../../buttons/ButtonDanger";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import defaultImg from '../../../assets/user-grey.svg';
import { get } from 'lodash';


import EmptyState from '../../EmptyState';

// Style
import "./styles.scss";

class RemoveMentorModal extends Component {
  constructor(props) {
    super();
    this.state = {
      top: "topLeft",
      bottom: "bottomRight",
      checked: [],
    };
  }

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  render() {
    const { isModalVisible, onClose, title, onClick } = this.props;
    const { checked } = this.state;
    const learners = get(this.props, "mentors", null);

    return (
      <div className="assign-student-modal">
        <Modal
          visible={isModalVisible}
          footer={null}
          className="assign-learner-modal-style">
          <div className="remove-modal">
            <h3 className="title3">{title}</h3>
            <div className="modal-body">
              {(!learners || learners.length === 0) ? <EmptyState title="Oops!" description="No mentors avaialable &#128533;" /> :
                <div className="checkbox-section">
                  <Checkbox.Group >
                    {learners && learners.map((learner, index) => {
                      return (
                        <Checkbox className="checkbox-style" onChange={this.onChange} value={learner.id} key={index} checked={checked.includes(learner.id)}>
                          <div className="fx fx--ai-fs mr--20 wrapper">
                            <Avatar
                              className="img-sec"
                              size={32}
                              src={get(learner, "media.path", null) || defaultImg}
                            />
                            <div className="learner-info-box fx fx--ai-fs fd-c">
                              <span className="learner-name wrap-modal-field">{learner.full_name}</span>
                              {learner.organization && <span className="id-style wrap-modal-field"><b>Organization: </b>{learner.organization}</span>}
                              <span className="id-style">{learner.email}</span>
                            </div>
                          </div>
                        </Checkbox>
                      )
                    })}
                  </Checkbox.Group>
                </div>
              }
              <div className="text-right mt--10 footer-section">
                <ButtonDanger
                  onClick={onClose}
                  btnText="Cancel"
                  btnDangerStyle="mr--20"
                />
                <ButtonPrimary onClick={() => { onClick(checked) }} btnText="Remove Mentors" disabled={checked.length === 0} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RemoveMentorModal;
