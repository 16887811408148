import { getAdminDashboard, getSchoolGradeForAdmin, updateAccessForDashboard } from "./services"

export const getAdminDashboardService = async (payload) => {
    return await getAdminDashboard(payload).then((result) => {
        return result.data;
    }).catch((err) => {
        throw err;
    })
}

export const getSchoolAndGrade = async () => {
    return await getSchoolGradeForAdmin().then((result) => {
        return result.data;
    }).catch((err) => {
        throw err
    });
}

export const updateAccess = async (payload) => {
    return await updateAccessForDashboard(payload).then((result) => {
        return result.data;
    }).catch((err) => {
        throw err;
    })
}