import React from "react";
import { DatePicker } from 'antd';

// Style
import "./styles.scss";

class PrimaryDatePicker extends React.Component {
  constructor(props) {
    super();
  }


  render() {
    const { onChange, value } = this.props;
    return (
      <div className="date-picker-style">
        <DatePicker inputReadOnly={true} onChange={onChange} value={value} />
      </div>
    );
  }
}

export default PrimaryDatePicker;