// By default Grapes injects base CSS into the canvas. For example, it sets body margin to 0
// and sets a default background color of white. This CSS is desired in most cases.
// use this property if you wish to overwrite the base CSS to your own CSS. This is most
// useful if for example your template is not based off a document with 0 as body margin.
export const GrapesJS_BASE_CSS = `
    * {
      box-sizing: border-box;
    }
    html, body, [data-gjs-type=wrapper] {
      min-height: 100%;
    }
    body {
      margin: 0;
      height: 100%;

      background-color: #fff
    }
    [data-gjs-type=wrapper] {
      overflow: auto;
      overflow-x: hidden;
    }

    * ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1)
    }

    * ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2)
    }

    * ::-webkit-scrollbar {
      width: 10px
    }
    .acadru-editor-html {
      padding-bottom: 150px;
    }
    .box.download .file-uploaded {
      display: block !important;
      position: relative;
      margin-top: 10px;
    }

    .box.download .file-uploaded::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 67%;
      height: 21px;
      background: #fef3e7;
      z-index: 1;
    }

    .acadru-editor-html .acadru-slider-container .acadru-slider .gjs-lory-slide {
      width: 65vw !important;
      background: #f5f5f5 !important;
      padding: 20px;
      max-width: 630px !important;
      min-width: 630px !important;
    }
    .acadru-editor-html .acadru-accordion-container .acadru-accordions .acadru-accordion::after {
      background: url("/plus-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .acadru-editor-html .acadru-accordion-container .acadru-accordions.accordion-active .acadru-accordion::after {
      background: url("/arrow-up-theme-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .acadru-customIframe .iframe {
      padding :20px;
    }
    .acadru-EmbedIFrame .embed {
      padding :20px;
    }
    .audio-player {
      padding: 10px !important;
    }

    .checkmark::after {
      left: 7px;
      top: 2px !important;
      width: 5px !important;
      height: 10px !important;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      min-width: 5px;
      min-height: 10px;
    }
    .swiper-slide {
      margin-right: 10px;
      background: #f5f5f5;
      border-radius: 20px;
    }

  .mb-image-with-caption {
    position: relative;
    display: inline-block;
  }

  .mb-image-with-caption img {
    display: block; 
  }

  .mb-centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mb-icon-class {
    display: inline-block; 
    width: 50px; 
    height: 50px;
    font-size: 24px; 
    color: #ffffff; 
    border-radius: 50%;  
    text-align: center; 
    line-height: 40px; 
  }
  `;

// CSS that could only be seen (for instance, inside the code viewer)
export const GrapesJS_PROTECTED_CSS = "* { box-sizing: border-box; } body {}";
