import React, { Component } from "react";
import "./styles.scss";
import { AutoComplete } from "antd";

class PrimaryAutoComplete extends Component {
  render() {
    const {
      autocompleteStyle,
      options,
      onSelect,
      onSearch,
      value,
      placeholder,
      onChange
    } = this.props;

    return (
      <>
        <div className="autocomplete-primary-section">
          <AutoComplete
            className={`primary-autocomplete ${autocompleteStyle}`}
            onChange={onChange}
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            value={value}
            placeholder={placeholder}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
          <span className="text-danger">{this.props.errorText}</span>
        </div>
      </>
    );
  }
}

export default PrimaryAutoComplete;
