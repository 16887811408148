import React, { Component } from "react";
import { Row, Col, Tooltip } from "antd";
import { get, set } from "lodash";
import { connect } from "react-redux";

// Custom Components
import InputPrimary from "../../../../components/InputPrimary";
import ImageUpload from "../../../../components/ImageUpload";
import ButtonPrimary from "../../../../components/buttons/ButtonPrimary";
import SelectInput from "../../../../components/SelectInput";
import PrimaryDatePicker from "../../../../components/PrimaryDatePicker";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import Loader from "../../../../components/PrimaryLoader";
import ErrorModal from "../../../../components/modal/ErrorModal";
import SuccessModal from "../../../../components/modal/SuccessModal";
import PhoneNoComponent from "../../../../components/PhoneNoComponent";

// images
import userImage from "../../../../assets/user-grey.svg";
import { InfoCircleOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";
import { PageTitle } from "../../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES } from "../../../../constants";

//Services
import * as DM from "../../dataManager";
import * as RDM from "../../../../common/dataManager";

//Helpers
import {
  breakTimestampForAPI,
  getMaxLengthForCountryCode,
  returnMomentObject,
  convertCase,
} from "../../../../helpers";
import { validate, parseError } from "../../parser";
import TextareaPrimary from "../../../../components/TextareaPrimary";

class SuperMentorNew extends Component {
  constructor(props) {
    super();
    this.editableID = null;
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      mentor: null,
      role_id: "",
      roles: []
    };
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    if (this.editableID) {
      this.getAllRoles();
      await this.getSpecificSuperMentorDetials();
    } else {
      this.setStateValue(0, "meta.total_invites");
    }
    this.setInitialMaxCount();
    this.getRoleId();
  }

  setInitialMaxCount = () => {
    this.setStateValue(0, "meta.max_invites")
  }

  setInitialMaxCount = () => {
    this.setStateValue(0, "meta.max_invites")
  }

  getRoleId = () => {
    this._startLoader();
    RDM.getAllRolesService({ type: "SUPER_MENTOR" })
      .then((res) => {
        this._stopLoader();
        this.setState({ role_id: get(res, "data.results[0].id", "") });
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getAllRoles = () => {
    this._startLoader();
    RDM.getAllRolesService()
      .then((res) => {
        this._stopLoader();
        const allowedTypes = ["MENTOR", "SUPER_MENTOR"];
        const roles = res?.data?.results?.filter((role) => allowedTypes.includes(role.type)) || [];
        this.setState({ roles });
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getSpecificSuperMentorDetials = () => {
    this._startLoader();
    DM.getUserService(this.editableID)
      .then((res) => {
        this.setState({ mentor: res.data }, () => {
          if (!res?.data?.meta?.total_invites) {
            this.setStateValue(0, "meta.total_invites");
          }
        });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    if (!state.mentor) state.mentor = {};
    if (field.includes('.')) {
      set(state.mentor, field, value);
    } else {
      state.mentor[`${field}`] = value;
    }
    this.setState(state);
  };

  handleSaveButton = async () => {
    const { mentor } = this.state;
    const checkValidate = validate(mentor || {}, this.editableID, false, "super_mentor");

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" },
      });
      return;
    }
    let { country_code } = mentor;
    if (country_code) {
      if (country_code.includes("(")) {
        country_code = country_code.split("(")[1];
        country_code = country_code.substring(0, country_code.length - 1);
      }
      if (!mentor.phone || !mentor.phone.includes("-"))
        mentor.phone = `${country_code}-${mentor.phone}`;
    }
    mentor.user_roles = [this.state.role_id];
    this._startLoader();
    if (this.editableID) {
      if (mentor.user_interest) delete (mentor?.user_interest)
      DM.editUserService(this.editableID, mentor)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Super Mentor updated successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    } else {
      DM.createUserService(mentor)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Super Mentor created successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    }
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal) this.props.history.push(ROUTES.SUPERMENTORS);
      },
    );
  };

  makeOptionChild = (entity) => {
    let entityChild = [];
    const state = this.state;
    if (state[`${entity}`] && Array.isArray(state[`${entity}`])) {
      this.state[`${entity}`].map((val) => {
        entityChild.push(
          <option value={val.id} key={val.id}>
            {val.name}
          </option>,
        );
        return null;
      });
    }
    return entityChild;
  };

  render() {
    const { mentor, isLoader, errorModal, errObj, succModal, role_id } = this.state;
    const defaultImageUrl = get(mentor, "media.path", null);

    if (mentor) {
      if (!mentor.country_code && mentor.phone && mentor.phone.includes("-")) {
        mentor.country_code = mentor.phone.split("-")[0];
      }
    }

    const roleChild = this.makeOptionChild("roles");

    return (
      <>
        <PageTitle title="Super Mentor" />
        <div className="mentor-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.SUPERMENTORS,
                  { text: this.editableID ? "Edit Super Mentor" : "Add New Super Mentor" },
                ]}
              />
              <h4 className="title4">
                {this.editableID ? "Edit Super Mentor" : "Add New Super Mentor"}
              </h4>
            </div>
          </div>

          <div className="form-section">
            <p className="title">Basic Details</p>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="input-item">
                  <p className="lable">Profile photo</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={
                      defaultImageUrl
                        ? "Change photo"
                        : "Pick a photo from your computer"
                    }
                    text={
                      defaultImageUrl
                        ? get(mentor, "media.file_name")
                        : "(Max file size: 10MB)"
                    }
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "avatar");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        First Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        value={get(mentor, "first_name")}
                        placeholder="John"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "first_name");
                        }}
                        errorText={get(errObj, "first_name")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Last Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        placeholder="Dohe"
                        value={get(mentor, "last_name")}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "last_name");
                        }}
                        errorText={get(errObj, "last_name")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Phone number</p>
                      <PhoneNoComponent
                        //  children={children}
                        codeVal={get(mentor, "country_code")}
                        phoneVal={get(mentor, "phone")}
                        onChangeCode={(val) => {
                          this.setStateValue(val, "country_code");
                        }}
                        onChangePhone={(e) => {
                          this.setStateValue(e.target.value, "phone");
                        }}
                        maxLength={getMaxLengthForCountryCode(
                          get(mentor, "country_code", null),
                        )}
                        errorText={get(errObj, "phone")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Email address <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        disabled={this.editableID ? true : false}
                        value={get(mentor, "email")}
                        type="email"
                        placeholder="johndohe@gmail.com"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "email");
                        }}
                        errorText={get(errObj, "email")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Gender</p>
                      <SelectInput
                        selectInputStyle="select-absolute shift-down-select"
                        placeholder="Select Gender"
                        value={convertCase(get(mentor, "gender")) || undefined}
                        options={["Male", "Female", "Other"]}
                        onChange={(val) => {
                          this.setStateValue(val.toUpperCase(), "gender");
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Date of birth</p>
                      <PrimaryDatePicker
                        value={
                          get(mentor, "dob")
                            ? returnMomentObject(get(mentor, "dob"))
                            : ""
                        }
                        onChange={(val) => {
                          this.setStateValue(
                            get(val, "_d", null)
                              ? breakTimestampForAPI(val._d).date
                              : "",
                            "dob",
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                {!this.editableID && (
                  <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">Password <span className="required-text">*</span></p>
                        <InputPrimary
                          type="password"
                          placeholder="password"
                          onChange={(e) => {
                            this.setStateValue(e.target.value, "password");
                          }}
                          errorText={get(errObj, "password")}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div className="input-item">
                        <p className="lable">Confirm Password <span className="required-text">*</span></p>
                        <InputPrimary
                          type="password"
                          placeholder="Confirm password"
                          onChange={(e) => {
                            this.setStateValue(
                              e.target.value,
                              "confirmPassword",
                            );
                          }}
                          errorText={get(errObj, "confirmPassword")}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <div style={{ display: 'flex' }}>
                    <p className="lable">
                      Remaining Invites<span className="required-text">*</span>
                    </p>
                    <Tooltip
                      title={`Use "-1" for unlimited invites, '0' for no invite & "1" for +1 invite`}>
                      <InfoCircleOutlined style={{ marginTop: '4px', marginLeft: '4px' }} />
                    </Tooltip>
                  </div>
                  <InputPrimary
                    defaultValue={get(mentor, "meta.max_invites", 0)}
                    value={get(mentor, "meta.max_invites", 0)}
                    type="number"
                    placeholder="Invites Allowed"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "meta.max_invites");
                    }}
                    errorText={get(errObj, "max_invites")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <div style={{ display: 'flex' }}>
                    <p className="lable">
                      Total Invites<span className="required-text">*</span>
                    </p>
                    <Tooltip
                      title={`Use "-1" for unlimited invites, '0' for no invite & "1" for +1 invite`}>
                      <InfoCircleOutlined style={{ marginTop: '4px', marginLeft: '4px' }} />
                    </Tooltip>
                  </div>
                  <InputPrimary
                    defaultValue={get(mentor, "meta.total_invites", 0)}
                    value={get(mentor, "meta.total_invites", 0)}
                    type="number"
                    placeholder="Total Invites"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "meta.total_invites");
                    }}
                    errorText={get(errObj, "total_invites")}
                  />
                </div>
              </Col>
            </Row>

            {this.editableID && <Row gutter={[20]} style={{ marginBottom: 16 }}>
              <Col span={24}>
                <div className="input-item">
                  <p className="lable">User Role</p>
                  <SelectInput
                    selectInputStyle="select-absolute shift-down-select"
                    value={role_id}
                    child={roleChild}
                    placeholder="Select Role"
                    onChange={(value) => {
                      this.setState({ role_id: value });
                    }}
                    errorText={get(errObj, "role_id")}
                  />
                </div>
              </Col>
            </Row>}

            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <p className="lable">Current School</p>
                  <InputPrimary
                    value={get(mentor, "current_school_uni")}
                    type="text"
                    placeholder="Current School"
                    maxLength={255}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "current_school_uni");
                    }}
                    errorText={get(errObj, "current_school_uni")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <p className="lable">Organization</p>
                  <InputPrimary
                    value={get(mentor, "organization")}
                    type="text"
                    placeholder="Organization"
                    maxLength={255}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "organization");
                    }}
                    errorText={get(errObj, "organization")}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <p className="lable">Profile Link</p>
                  <InputPrimary
                    value={get(mentor, "profile_link")}
                    type="url"
                    placeholder="Profile Link"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "profile_link");
                    }}
                    errorText={get(errObj, "profile_link")}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[20]}>
              <Col span={24}>
                <div className="input-item">
                  <p className="lable">About me</p>
                  <TextareaPrimary
                    value={get(mentor, "about")}
                    rows={4}
                    placeholder="About me"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "about");
                    }}
                    errorText={get(errObj, "about")}
                  />
                </div>
              </Col>
            </Row>

            <div className="mt--40 text-right">
              <ButtonPrimary
                btnText="Save"
                href="#"
                onClick={this.handleSaveButton}
              />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(SuperMentorNew);
