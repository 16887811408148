import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Styles
import "./styles.scss";

class ButtonDanger extends Component {
  render() {
    const { btnDangerStyle, onClick, href, btnText } = this.props;
    return (
      <Link
        className={`btn-danger ${btnDangerStyle ? btnDangerStyle : ''}`}
        onClick={onClick}
        to={href ? href : '#'}
      >
        {btnText}
      </Link>
    );
  }
}

export default ButtonDanger;