import React, { Component } from "react";
import { PageTitle } from "../../../components/PageTitle";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ErrorModal from "../../../components/modal/ErrorModal";
import Loader from "../../../components/PrimaryLoader";

import "./styles.scss";

import defaultImage from "../../../assets/blogs.svg";

import { BREADCRUMBS } from "../../../constants";

//Services
import * as DM from "../dataManager";
import { get } from "lodash";

class ViewModule extends Component {
  constructor(props) {
    super();
    this.state = {
      moduleDetails: null,
      errorShowModal: null,
      isLoader: false,
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    const editableID = this.props.match.params.id;

    if (editableID) {
      await this.getSpecificModuleDetials(editableID);
    }
  }

  //API Call Functions

  getSpecificModuleDetials = async (id) => {
    this._startLoader();
    await DM.getModuleDetailsService(id)
      .then((res) => {
        this.setState({ moduleDetails: res });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  //Helper Functions

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  render() {
    const { moduleDetails, isLoader, errorShowModal } = this.state;

    let careers = [];
    let tags = [];

    if (moduleDetails) {
      moduleDetails.careers &&
        Array.isArray(moduleDetails.careers) &&
        moduleDetails.careers.map((value) => {
          careers.push(value.name);
          return null;
        });

      moduleDetails.tags &&
        Array.isArray(moduleDetails.tags) &&
        moduleDetails.tags.map((value) => {
          tags.push(value.name);
          return null;
        });
    }

    const moduleImagePath = get(moduleDetails, "media[0].path", null);

    return (
      <>
        <PageTitle title="View Module" />
        <div className="view-module-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[BREADCRUMBS.MODULES, { text: "View Module" }]}
              />
              <h4 className="title4">View module</h4>
            </div>
          </div>
          <div className="view-modules-content mt--30">
            <div className="table-card-style">
              <div className="table-style module-list-style">
                <table className="table-custom-style">
                  <tbody>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Module Name</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "name", "---")}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Module category</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "categories[0].name", "---")}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Module Picture</p>
                      </td>
                      <td>
                        {moduleImagePath ?
                          <div
                            className="modulePicture"
                            style={{
                              backgroundImage: `url(${moduleImagePath})`
                            }}></div>
                          :
                          <img
                            src={defaultImage}
                            className="modulePicture"
                            alt="module"
                          />
                        }
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Likes</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "likes", "---")}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Views</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "views", "---")}
                        </div>
                      </td>

                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Status</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "status", "---")}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Featured</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "featured") ? "True" : "False"}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Banner</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "banner_text") ?
                            get(moduleDetails, "banner_text").replace("_", " ")
                            : "---"}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Quick Preview Description</p>
                      </td>
                      <td>
                        <div
                          className="value"
                          dangerouslySetInnerHTML={{
                            __html: get(moduleDetails, "description") || "---",
                          }}></div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Reflection Question</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "reflection_question") || "---"}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Text for Interest Cloud</p>
                      </td>
                      <td>
                        <div className="value">
                          {get(moduleDetails, "interest_cloud_text") || "---"}
                        </div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Related Careers</p>
                      </td>
                      <td>
                        <div className="value">{careers.length > 0 ?
                          (
                            careers.map((career, index) => {
                              if (index === careers.length - 1) return career;
                              else return career + ", ";
                            })
                          )
                          :
                          "---"}</div>
                      </td>
                    </tr>
                    <tr className="data-row">
                      <td>
                        <p className="lead">Related Tags</p>
                      </td>
                      <td>
                        <div className="value">{tags.length > 0 ?
                          (
                            tags.map((tag, index) => {
                              if (index === tags.length - 1) return tag;
                              else return tag + ", ";
                            })
                          )
                          : "---"}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoader && <Loader />}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

export default ViewModule;
