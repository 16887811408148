import * as SERVICES from "./services";

export const postCreateCategoryService = async (payload) => {
  return await SERVICES.createCategory(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};


export const getCategoryService = async (id) => {
  return await SERVICES.getCategory(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateCategoryService = async (id, payload) => {
  return await SERVICES.updateCategory(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteCategoryService = async (id) => {
  return await SERVICES.deleteCategory(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

