import { isString } from "lodash";

export const getAudioView = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  const defaultView = defaultType.view;

  return defaultView.extend({
    tagName: "audio",

    events: {
      dblclick: "onActive",
      click: "initResize",
      error: "onError",
      load: "onLoad",
      dragstart: "noDrag",
    },

    initialize(o) {
      const model = this.model;
      defaultView.prototype.initialize.apply(this, arguments);
      this.listenTo(model, "change:src", this.updateSrc);
      this.classEmpty = `${this.ppfx}-audio`;
      const config = this.config;
      config.modal && (this.modal = config.modal);
      config.am && (this.am = config.am);
      this.fetchFile();
    },

    /**
     * Fetch file if exists
     */
    fetchFile() {
      if (this.modelOpt.temporary) return;
      const model = this.model;
      const file = model.get("file");

      if (file) {
        const fu = this.em.get("AssetManager").FileUploader();
        fu.uploadFile(
          {
            dataTransfer: { files: [file] },
          },
          (res) => {
            const obj = res && res.data && res.data[0];
            const src = obj && (isString(obj) ? obj : obj.src);
            src && model.set({ src });
          },
        );
        model.set("file", "");
      }
    },

    /**
     * Update src attribute
     * @private
     * */
    updateSrc() {
      const { model, classEmpty, $el } = this;
      const src = model.getSrcResult();
      const srcExists = src && !model.isDefaultSrc();

      model.addAttributes({ src });
      $el[srcExists ? "removeClass" : "addClass"](classEmpty);
    },

    /**
     * Open dialog for image changing
     * @param  {Object}  e  Event
     * @private
     * */
    onActive(ev) {
      ev && ev.stopPropagation();
      var em = this.opts.config.em;
      var editor = em ? em.get("Editor") : "";

      if (editor && this.model.get("editable")) {
        editor.runCommand("open-assets", {
          target: this.model,
          types: ["audio"],
          accept: "audio/*",
          onSelect() {
            editor.Modal.close();
            editor.AssetManager.setTarget(null);
          },
        });
      }
    },

    onError() {
      const fallback = this.model.getSrcResult({ fallback: 1 });
      if (fallback) this.el.src = fallback;
    },

    onLoad() {
      // Used to update component tools box (eg. toolbar, resizer) once the image is loaded
      this.em.trigger("change:canvasOffset");
    },

    noDrag(ev) {
      ev.preventDefault();
      return false;
    },

    render(...args) {
      defaultView.prototype.render.apply(this, args);
      this.updateSrc();
      const { $el, model } = this;
      const cls = $el.attr("class") || "";
      !model.get("src") &&
        $el.attr("class", `${cls} ${this.classEmpty}`.trim());
      this.postRender();

      return this;
    },
  });
};
