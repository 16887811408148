import * as SERVICE from "./servies";

export const getSpecificCareerServies = async (id) => {
  return await SERVICE.getSpecificCareer(id)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postCreateCareerService = async (payload) => {
  return await SERVICE.postCreateCareer(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateCareerService = async (id, payload) => {
  return await SERVICE.patchUpdateCareer(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteCareerService = async (id) => {
  return await SERVICE.deleteCareer(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
