import { getImageForVideoModel } from "./model";
import { getImageForVideoView } from "./view";


export const addImageForVideoComponent = (editor) => {
    const comps = editor.DomComponents;
    comps.addType("image-for-video", {
        model: getImageForVideoModel(editor),
        view: getImageForVideoView(editor)
    })
}