import { Provider } from "react-redux";
import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// Store Configure With React
import perisistStore from "./store";

//Authentication
import { requiredAuthentication } from "./components/authentication";
import ErrorComponent from './components/ErrorComponent';

//Container
import Login from "../src/containers/Login";
import DashboardContainer from "./DashboardContainer";
import SuperMentorDashboardMail from "../src/containers/DashboardMailPage"

//Constans
import { ROUTES } from "./constants";

const { store, persistor } = perisistStore;
class AppRouter extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/super-mentor-dashboard-mail/:id/:key" component={SuperMentorDashboardMail} />
              <Route
                exact
                path={ROUTES.FORBIDDEN}
                render={(props) => <ErrorComponent
                  {...props}
                  pageTitle="Forbidden"
                  errorTitle="403"
                  errorMessage="You do not have permissions to access this page."
                />}
              />
              <Route
                exact
                path={ROUTES.PAGENOTFOUND}
                render={(props) => <ErrorComponent
                  {...props}
                  pageTitle="Page Not Found"
                  errorTitle="404"
                  errorMessage="Page not found"
                />}
              />
              <Route
                path="/"
                component={requiredAuthentication(DashboardContainer)}
              />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default AppRouter;
