import * as SERVICES from "./services";

export const getAllFeatureQuestionsService = async (params) => {
    return await SERVICES.getAllFeatureQuestions(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const postCreateFeatureQuestionService = async (payload) => {
    return await SERVICES.createFeatureQuestion(payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getFeatureQuestionService = async (id) => {
    return await SERVICES.getFeatureQuestion(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdateFeatureQuestionService = async (id, payload) => {
    return await SERVICES.updateFeatureQuestion(id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteFeatureQuestionService = async (id) => {
    return await SERVICES.deleteFeatureQuestion(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

