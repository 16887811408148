import { getEmbedModel } from "./model";
import { getEmbedView } from "./view";

export const addEmbedComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("embed", {
    model: getEmbedModel(editor),
    view: getEmbedView(editor),
  });
};
