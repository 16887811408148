import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import LearnersCard from "../../components/LearnersCard";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

//Actions
import { setAllCategories } from "./actions";

//Service
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

class Categories extends Component {
  state = {
    removeShowModal: false,
    isLoader: false,
    errorModal: null,
    succModal: null,
    meta: {
      page: 1,
      limit: 10,
    },
    selected_id: "",
  };

  componentDidMount() {
    this.getAllCategoriesData();
  }

  getAllCategoriesData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    CDM.getAllCategoriesService(queryObject)
      .then((res) => {
        this.props.setAllCategories(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllCategoriesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  removeCategory = () => {
    this._startLoader();
    let category = this.state.selected_id;
    DM.deleteCategoryService(category)
      .then((res) => {
        this._stopLoader();
        this.getAllCategoriesData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Category removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const dataColumns = get(this.props, "categories.categories.results", null);
    const permissions = get(this.props, "auth.permissions");
    const isClassActive =
      permissions.CATEGORIES &&
      !permissions.CATEGORIES.includes(ACTIONS.UPDATE) &&
      permissions.CATEGORIES.includes(ACTIONS.DELETE);
    const onlyEditClassActive =
      permissions.CATEGORIES.includes(ACTIONS.UPDATE) &&
      !permissions.CATEGORIES.includes(ACTIONS.DELETE);
    const totalCategories = get(
      this.props,
      "categories.categories.meta.pagination.total",
      null,
    );

    let leftCol = [];
    let rightCol = [];
    dataColumns &&
      dataColumns.map((data, index) => {
        if (index % 2 === 0) {
          leftCol.push(
            <LearnersCard>
              <div className="img-heading">
                <h5 className="title5">{get(data, "name")}</h5>
              </div>

              <div
                className={`right-sec fx fx--ai-c ${
                  isClassActive ? "fx--jc-fe-imp" : ""
                }`}>
                {permissions.CATEGORIES &&
                  permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                    <ButtonOutline
                      btnText={<EditOutlined />}
                      // btnStyle="btn-sm"
                      btnStyle="btn edit square-edit mr--10"
                      clickHandler={() =>
                        this.props.history.push(
                          ROUTES.EDITCATEGORY.replace(":id", data.id),
                        )
                      }
                    />
                  )}

                {permissions.CATEGORIES &&
                  permissions.CATEGORIES.includes(ACTIONS.DELETE) && (
                    <ButtonDanger
                      type="link"
                      btnText={<DeleteOutlined />}
                      btnDangerStyle="btn delete btn-link"
                      onClick={() => this.removeToggleModal(data.id)}>
                      Remove
                    </ButtonDanger>
                  )}
              </div>
            </LearnersCard>,
          );
        } else {
          rightCol.push(
            <LearnersCard>
              <div className="img-heading">
                <h5 className="title5">{get(data, "name")}</h5>
              </div>
              <div
                className={`right-sec fx fx--ai-c ${
                  isClassActive ? "fx--jc-fe-imp" : ""
                }`}>
                {permissions.CATEGORIES &&
                  permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                    <ButtonOutline
                      btnText={<EditOutlined />}
                      // btnStyle="btn-sm"
                      btnStyle="btn edit square-edit mr--10"
                      clickHandler={() =>
                        this.props.history.push(
                          ROUTES.EDITCATEGORY.replace(":id", data.id),
                        )
                      }
                    />
                  )}

                {permissions.CATEGORIES &&
                  permissions.CATEGORIES.includes(ACTIONS.DELETE) && (
                    <ButtonDanger
                      type="link"
                      btnText={<DeleteOutlined />}
                      btnDangerStyle="btn delete btn-link"
                      onClick={() => this.removeToggleModal(data.id)}>
                      Remove
                    </ButtonDanger>
                  )}
              </div>
            </LearnersCard>,
          );
        }
        return null;
      });

    return (
      <>
        <PageTitle title="Categories" />
        <div className="categories-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Category" }]} />
              <h4 className="title4"> List of All Categories</h4>
            </div>
            <div className="right-section">
              {permissions.CATEGORIES &&
                permissions.CATEGORIES.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new Category"
                    href={ROUTES.NEWCATEGORY}
                  />
                )}
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="list-style list-card-style mt--30 border-top-style">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <p className="lead">Name</p>
                    {permissions.CATEGORIES &&
                      permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                        <p
                          className={`lead right-sec-panel ${
                            onlyEditClassActive ? "only-edit" : ""
                          }`}>
                          Action
                        </p>
                      )}
                  </div>
                  {leftCol}
                </Col>

                {rightCol.length > 0 && (
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <p className="lead">Name</p>
                      {permissions.CATEGORIES &&
                        permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                          <p
                            className={`lead right-sec-panel ${
                              onlyEditClassActive ? "only-edit" : ""
                            }`}>
                            Action
                          </p>
                        )}
                    </div>
                    {rightCol}
                  </Col>
                )}
              </Row>
            </div>
          )}

          {isLoader && <Loader />}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this category"
              description="Are you sure you want to remove this category?"
              // Action button event here
              removeHandler={this.removeCategory}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}
          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}
          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}

          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                pageSize={meta.limit}
                current={meta.page}
                total={totalCategories}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllCategories,
})(Categories);
