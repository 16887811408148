import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import { Button, Col, Row, Spin, Upload } from "antd";
import ImageModuleCard from "../../../components/ImageModuleCard";
import PrimaryAutoComplete from "../../../components/PrimaryAutoComplete";
import ImageUpload from "../../../components/ImageUpload";
import Loader from "../../../components/PrimaryLoader";
import InputPrimary from "../../../components/InputPrimary";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { BREADCRUMBS, ROUTES, SEARCHDELAY } from "../../../constants";
import './styles.scss'
import CKEditor from "@ckeditor/ckeditor5-react";
import * as DM from "../dataManager";
import * as UDM from "../../Users/dataManager"
import * as CDM from "../../../common/dataManager";

import userImage from "../../../assets/white-paper.svg";
import defaultImage from "../../../assets/blogs.svg";
import SelectInput from "../../../components/SelectInput";
import { parseErrorSchoolProjectDetails, validateSchoolProjectDetails } from "../parser";
import { debounce, get } from "lodash";
import { editSchoolProject } from '../actions'
import { getAllLearners } from "../../Users/actions";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";
import { UploadOutlined } from '@ant-design/icons';
import SearchPrimary from "../../../components/SearchPrimary";
import schoolProject from "../../../store/reducers/schoolProject";
export class NewSchoolProject extends Component {
  constructor(props) {
    super();
    this.handleAutoComplete = debounce(
      this.handleAutoComplete,
      SEARCHDELAY.searchDelay,
    );
    this.handleStudentAutoComplete = debounce(
      this.handleStudentAutoComplete,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      isLoader: false,
      removeShowModal: false,
      editableModule: null,
      successShowModal: null,
      errorShowModal: null,
      errorObj: null,
      autoCompleteVal: "",
      autoCompleteOptions: [],
      autoCompleteStudentOptions: [],
      autoCompleteStudentVal: "",
      moduleImages: [],
      isRefreshPage: false,
      data: null,
      selectedValue: null,
      meta: {
        limit: 20,
        page: 1
      },
      schoolProject: {
        status: "PUBLISH",
        modules: [],
        students: [],
        studentId: '',
        type: "external",
        schoolProjectDocuments: [],
      },
      deletedFiles: [],
      learners: {
        searchQuery: null,
      },
      fetching: false,
      self: false,
      categoryOptions: [],
    }
    this.editableID = null;
  }

  async componentDidMount() {
    this.editableID = this.props.match.params.id;
    window.scrollTo(0, 0);
    this.getAllCategories();
    if (this.editableID) {
      await this.getSchoolProject(this.editableID);
    }
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.schoolProject[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  getAllCategories = () => {
    DM.getAllCategories().then((res) => {
      let allCategories = [];
      res.data.forEach((element) => {
        if (element !== 'All') {
          allCategories.push(element);
        }
      });
      this.setState({ categoryOptions: allCategories });
    }).catch((err) => {
      this._stopLoader();
    });
  }

  async getSchoolProject(id) {
    this._startLoader();
    const queryObject = { include: "projectmedia,studentmedia,documentmedia" }
    await DM.getSchoolProjectDetails(id, queryObject).then((res) => {
      const { student } = res.data;
      if (res.data.type === "external" && res.data.school_name === null) {
        this.setState({ self: true })
      }

      const obj = res.data.documentmedia.map((file) => ({
        uid: file.id,
        size: parseInt(file.size),
        name: file.file_name,
        url: file.path,
        type: file.mime_type,
      }));

      this.setState({
        schoolProject: { ...res.data, students: [] },
        autoCompleteStudentVal: res.data?.student?.full_name,
        schoolProjectDocuments: obj
      },
        () => this.setStateValue(student?.id, "studentId"));

      this.fillModuleImages();
      this._stopLoader();
    });
  }

  async uploadFiles(files) {
    const existingIds = this.state.schoolProjectDocuments?.map((document) => { return document.uid || [] });
    const newIds = files?.map((file) => { return file.uid || [] })
    const removedIds = existingIds?.filter((id) => !newIds.includes(id));
    const result = files?.map((file) => {
      if (file.originFileObj) {
        file.originFileObj.fieldName = "schoolProjectDocuments";
        return file.originFileObj;
      } else return file
    });
    this.setState({
      schoolProjectDocuments: result,
      deletedFiles: [...this.state.deletedFiles, removedIds]
    });
  }


  handleAutoComplete = async (value) => {
    await CDM.getAllModulesService({
      search_type: "CUSTOM",
      status: "PUBLISH",
      page: 1,
      limit: 10,
      keyword: value,
    })
      .then((res) => {
        let modules = get(res, "data.results");
        modules = modules.map((module) => {
          return {
            label: module.name,
            value: [
              module.name,
              get(module, "id", ""),
              get(module, "media[0].path", ""),
            ],
          };
        });
        let selectedModules = this.state.schoolProject.modules;
        modules = modules.filter((module) => {
          return !selectedModules.includes(module.value[1]);
        });
        this.setState({ autoCompleteOptions: modules });
      })
      .catch((err) => { });
  };


  handleStudentAutoComplete = async (value) => {
    const queryObject = {
      type: "LEARNER",
      status: "ACTIVE",
      page: 1,
      limit: 20,
      keyword: value,
    };
    UDM.getAllUsersService(queryObject).then((res) => {
      let students = get(res, "data.results");
      students = students.map((student) => {
        return {
          label: student.first_name + ' ' + student.last_name + '(' + student.email + ')',
          value: [
            student.first_name + ' ' + student.last_name,
            get(student, "id", ""),
          ],
        };
      });
      this.setState({ autoCompleteStudentOptions: students });
    })
      .catch((err) => {
        console.error(err);
      });
  };

  fillModuleImages = () => {
    let moduleImages = [];

    let modules = get(this.state.schoolProject, "modules");
    modules &&
      modules.map((module) => {
        moduleImages.push({
          imgUrl: get(module, "media.path", null),
          title: module.name,
          id: module.id,
        });
        return null;
      });

    this.setState({ moduleImages });
  };

  onStudentSelect = (value) => {
    let { schoolProject } = this.state;
    let studentArr = schoolProject.students;
    if (studentArr.includes(value[1])) {
      this.setState({ autoCompleteStudentVal: value[0] });
      return;
    }
    studentArr.push(value[1]);

    this.setState({
      autoCompleteStudentVal: value[0],
      schoolProject: { ...this.state.schoolProject, studentId: value[1] },
    });
  };

  onSelect = (value) => {
    let { moduleImages, schoolProject } = this.state;
    let modulesArr = schoolProject.modules;
    if (modulesArr.includes(value[1])) {
      this.setState({ autoCompleteVal: "" });
      return;
    }
    modulesArr.push(value[1]);
    moduleImages.push({
      imgUrl: value[2],
      title: value[0],
      id: value[1],
    });
    this.setState({
      autoCompleteVal: "",
      schoolProject: { ...this.state.schoolProject, modules: modulesArr },
      moduleImages,
    });
  };

  removeModule = (id) => {
    let selectedModules = this.state.schoolProject.modules;
    let selectedModuleImages = this.state.moduleImages;
    selectedModules = selectedModules.filter((module) => {
      if (typeof module === "object") return module.id !== id;
      return module !== id;
    });
    selectedModuleImages = selectedModuleImages.filter((module) => {
      return module.id !== id;
    });
    this.setState({
      moduleImages: selectedModuleImages,
      schoolProject: { ...this.state.schoolProject, modules: selectedModules },
    });
  };

  makeOptionChild = (entity) => {
    let entityChild = [];
    const state = this.state;
    if (state[`${entity}`] && Array.isArray(state[`${entity}`])) {
      this.state[`${entity}`].map((val) => {
        entityChild.push(
          <option value={val.id} key={val.id}>
            {val.full_name}
          </option>,
        );
        return null;
      });
    }
    return entityChild;
  };

  handleSearch = (entity, value) => {
    const { page, limit } = this.state.meta;
    const queryObject = {
      type: "LEARNER",
      status: "ACTIVE",
      limit: limit,
      page: page,
    };
    if (value && entity === "studentId") {
      this.setState({
        learners: [],
        fetching: true
      });
      queryObject.keyword = value;
      UDM.getAllUsersService(queryObject)
        .then((res) => {
          const learnersData = res.data.results.map((row) => ({
            full_name: row.full_name,
            id: row.id
          }));
          this.setState({ learners: learnersData });

          this.props.getAllLearners(learnersData);
          this._stopLoader();
        })
        .catch((err) => {
          this._stopLoader();
        });
    }
  }

  handleOnClick = async () => {
    const { schoolProject, originalName } = this.state;
    const checkValidate = validateSchoolProjectDetails(schoolProject, this.state.self);
    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorShowModal: { msg: "Validation error occurred" },
      });
      return;
    }

    this._startLoader();
    if (this.editableID) {
      schoolProject.modules =
        schoolProject.modules &&
        schoolProject.modules.map((module) => {
          if (typeof module === "object") return module.id;
          return module;
        });
      schoolProject.schoolProjectDocuments = this.state.schoolProjectDocuments;
      schoolProject.deletedFiles = this.state.deletedFiles;
      await DM.updateSchoolProject(this.editableID, schoolProject).then((res) => {
        this._stopLoader();
        this.setState({
          successShowModal: { msg: "School project is updated." },
        });
      }).catch((err) => {
        this._stopLoader();
        this.setState({
          errorObj: parseErrorSchoolProjectDetails(err),
          errorShowModal: { msg: "Validation error occurred" },
        });
      });
    } else {
      schoolProject.schoolProjectDocuments = this.state.schoolProjectDocuments;
      await DM.createSchoolProject(schoolProject).then((res) => {
        this._stopLoader();
        this.setState({
          successShowModal: { msg: "School Project added successfully!" }
        });
      }).catch((err) => {
        this._stopLoader();
        this.setState({
          errorObj: parseErrorSchoolProjectDetails(err),
          errorShowModal: { msg: "Validation error occurred" },
        });
      });
    }
  }

  render() {
    const { imgUrl, } = this.props;
    const { errorObj, schoolProject, autoCompleteVal, autoCompleteStudentVal, autoCompleteStudentOptions, autoCompleteOptions, isLoader, successShowModal, errorShowModal, categoryOptions } = this.state;
    const status = get(schoolProject, "status")
    const defaultImageUrl = get(schoolProject, "projectmedia.path") || imgUrl
    const defaultStudentImageUrl = get(schoolProject, "studentmedia.path") || imgUrl
    let dataColumns = this.state.moduleImages;
    return (
      <>
        <PageTitle title="Add New School Project" />
        <div className="new-school-project-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.SCHOOLPROJECTS,
                  { text: this.editableID ? "Edit School Project" : "Add School Project" },
                ]}
              />
              <h4 className="title4">
                {this.editableID ? "Edit School Project" : "Add School Project"}
              </h4>
            </div>
            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${status === "PUBLISH" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("PUBLISH", "status");
                  }}
                >
                  Published
                </Button>
                <Button
                  className={`btn-toggle ${status === "UNPUBLISH" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("UNPUBLISH", "status");
                  }}
                >
                  Unpublished
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Project Title<span className="required-text">*</span></p>

                  <InputPrimary
                    value={get(schoolProject, "title")}
                    placeholder="Project Title"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "title");
                    }}
                  />
                </div>
                {get(errorObj, "title") && (
                  <div className="warning">{get(errorObj, "title")}</div>
                )}
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="label">
                    Select Category
                    <span className="required-text">*</span>
                  </p>
                  <SelectInput
                    selectInputStyle="select-absolute shift-down-select margin-bottom-1"
                    allowClear={true}
                    placeholder="Search by category"
                    options={categoryOptions}
                    showSearch={true}
                    title=''
                    value={get(schoolProject, "category")}
                    onChange={(value) => {
                      this.setStateValue(value, "category");
                    }}
                  />
                  x
                </div>
                {get(errorObj, "category") && (
                  <div className="warning">{get(errorObj, "category")}</div>
                )}
              </Col>

            </Row>

            <Row gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Select the student type<span className="required-text">*</span></p>
                  <div className="radio-button">
                    {/* <div className="radio-item">
                      <input
                        type="radio"
                        name="student"
                        id="internal"
                        value={get(schoolProject, "type")}
                        checked={get(schoolProject, "type") === "internal"}
                        onChange={(value) => {
                          this.setStateValue('internal', "type");
                          this.setStateValue(null, "school_name");
                          this.setStateValue(null, "student_name");
                          this.setStateValue(null, "studentImage");
                          this.setStateValue(null, "studentmedia.path")
                        }}
                        errorText={get(errorObj, "type")}
                      />
                      <label for='internal' className="lable">Internal</label>
                    </div> */}
                    <div className="radio-item">
                      <input
                        type="radio"
                        name="student"
                        id="external"
                        value={get(schoolProject, "type")}
                        checked={get(schoolProject, "type") === "external"}
                        onChange={(value) => {
                          this.setStateValue('external', "type");
                          this.setStateValue(null, "studentId")
                          this.setState({ autoCompleteStudentVal: '' })
                        }}
                        errorText={get(errorObj, "type")}
                      />
                      <label for='external' className="lable">External</label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {schoolProject.type === 'external' ? null : (<Row gutter={20} style={{ marginBottom: '20px' }}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Select Student<span className="required-text">*</span></p>
                  <PrimaryAutoComplete
                    value={autoCompleteStudentVal}
                    onChange={(val) => {
                      this.setState({
                        autoCompleteStudentVal: val,
                      });
                    }}
                    placeholder="Search student name…"
                    options={autoCompleteStudentOptions}
                    onSearch={(value) => {
                      this.handleStudentAutoComplete(value);
                    }}
                    onSelect={this.onStudentSelect}
                  />
                </div>
                {get(errorObj, "studentId") && (
                  <div className="warning">{get(errorObj, "studentId")}</div>
                )}
              </Col>
            </Row>)}

            {schoolProject.type === 'internal' ? '' : (<Row gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Student Name<span className="required-text">*</span></p>

                  <InputPrimary
                    value={get(schoolProject, "student_name")}
                    placeholder="Enter Student Name"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "student_name");
                    }}
                  />
                  {get(errorObj, "student_name") && (
                    <div className="warning">{get(errorObj, "student_name")}</div>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <div className="drop-school">
                    <p className="lable">Enter School Name<span className="required-text">*</span></p>
                    <div className="checkbox-center">
                      <input type="checkbox" className="checkbox"
                        checked={this.state.self}
                        onChange={(e) => {
                          this.setState({ self: !this.state.self },
                            () => this.setStateValue(null, "school_name")
                          );
                        }} />
                      <label className="self-text">
                        Mark as Self
                      </label>
                    </div>
                  </div>

                  <InputPrimary
                    value={get(schoolProject, "school_name")}
                    placeholder="Enter School Name"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "school_name");
                    }}
                    disabled={this.state.self}
                  />
                  {get(errorObj, "school_name") && (
                    <div className="warning">{get(errorObj, "school_name")}</div>
                  )}
                </div>
              </Col>
            </Row>)}

            <Row gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--20 upload-image">
                  <p className="lable">Project Thumbnail</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={defaultImageUrl ? "Change Photo" : "Upload Photo"}
                    text={
                      defaultImageUrl
                        ? get(schoolProject, "projectmedia.file_name")
                        : "(Max file size: 10MB)"
                    }
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "mainImage");
                    }}
                  />
                  {get(errorObj, "mainImage") && (
                    <div className="warning">{get(errorObj, "mainImage")}</div>
                  )}
                </div>
              </Col>
              {schoolProject.type === 'internal' ? '' :
                (
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item mt--20 upload-image">
                      <p className="lable">Student Image</p>
                      <ImageUpload
                        defaultImage={true}
                        subText={defaultStudentImageUrl ? "Change Photo" : "Upload Photo"}
                        text={
                          defaultStudentImageUrl
                            ? get(schoolProject, "studentmedia.file_name")
                            : "(Max file size: 10MB)"
                        }
                        defaultImageUrl={defaultStudentImageUrl || userImage}
                        onChange={(file) => {
                          let originalFileStudentObject = file.originFileObj;
                          this.setStateValue(originalFileStudentObject, "studentImage");
                        }}
                      />
                      {get(errorObj, "studentImage") && (
                        <div className="warning">{get(errorObj, "studentImage")}</div>
                      )}
                    </div>
                  </Col>
                )}
            </Row>

            <Row>
              <Col span={24}>
                <div className="input-item mt--20">
                  <p className="lable">Short Description of the Project<span className="required-text">*</span></p>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'outdent',
                          'indent',
                          'bulletedList',
                          'numberedList',
                          'alignment',
                          'undo',
                          'redo'
                        ],
                      },
                    }}
                    data={get(schoolProject, "description", "") || ""}
                    onInit={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setStateValue(data, "description");
                    }}
                  />
                  {get(errorObj, "description") && (
                    <div className="warning">{get(errorObj, "description")}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="input-item mt--20">
                  <p className="lable">Content of the Project<span className="required-text">*</span></p>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'link',
                          'outdent',
                          'indent',
                          'bulletedList',
                          'numberedList',
                          'alignment',
                          'undo',
                          'redo',
                          'imageUpload'
                        ],
                      },
                      ckfinder: {
                        uploadUrl: `${process.env.REACT_APP_BASE_URL}/school-projects/upload-editor-image`,
                      },
                    }}
                    data={get(schoolProject, "content", "") || ""}
                    onInit={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setStateValue(data, "content");
                    }}
                  />
                  {get(errorObj, "content") && (
                    <div className="warning">{get(errorObj, "content")}</div>
                  )}
                </div>
              </Col>
            </Row>

            <div className="input-item">
              <p>Click to upload documents</p>
              <Col span={24}>
                <Upload
                  // accept=".pdf"
                  fileList={this.state.schoolProjectDocuments || []}
                  listType="text"
                  onChange={(list) => {
                    this.uploadFiles(list.fileList)
                  }}
                  // disabled={disabled}
                  beforeUpload={() => false}
                >
                  <Button className="upload-btn" icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Col>
            </div>

            <div className="input-item">
              <p className="lable">Add module</p>

              <PrimaryAutoComplete
                value={autoCompleteVal}
                onChange={(val) => {
                  this.setState({
                    autoCompleteVal: val,
                  });
                }}
                placeholder="Search module name…"
                options={autoCompleteOptions}
                onSearch={(value) => {
                  this.handleAutoComplete(value);
                }}
                onSelect={this.onSelect}
                errorText={get(errorObj, "modules")}
              />
            </div>
            <div className="module-sec">
              <Row gutter={16}>
                {dataColumns.map((data, index) => {
                  return (
                    <Col
                      className="gutter-row mt--50"
                      xs={12}
                      md={4}
                      key={index}>
                      <ImageModuleCard
                        removeModule={() => {
                          this.removeModule(data.id);
                        }}
                        imgUrl={get(data, "imgUrl") || defaultImage}
                        title={data.title}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="mt--40">
              <ButtonPrimary
                btnText="Save"
                onClick={() => {
                  this.handleOnClick();
                }}
              />
            </div>
          </div>

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
                this.props.history.push(ROUTES.SCHOOLPROJECTS);
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => { return store; }

export default connect(mapStateToProps, { editSchoolProject, getAllLearners })(NewSchoolProject);

