export const acadruMultimediaBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false"><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  //  Audio
  blockManager.add("Audio", {
    category: {
      id: "Acadru Multimedia",
      label: "Acadru Multimedia",
      open: false,
    },
    label: "Audio",
    type: "audio",
    attributes: { class: "fa fa-volume-up" },
    content: `
      ${rowCol6Start}
      <div class="${classPreFix}-multimedia audio" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="${classPreFix}-audio-text-box" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">
          <div class="${classPreFix}-audio-text" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">Audio title lorem Ipsum is simply dummy</div>
          <div class="${classPreFix}-audio-text sm" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false"><strong data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">Caption:</strong> lorem ipsum demo content</div>
        </div>
        <div class="${classPreFix}-audio-box" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">
          <audio controls class="audio-player" data-gjs-droppable="false" data-gjs-draggable="false">
          </audio>
        </div>
      </div>
      ${rowCol6End}
    `,
  });

  // Video
  blockManager.add("Video", {
    category: "Acadru Multimedia",
    label: "Video",
    type: "video",
    attributes: { class: "fa fa-video-camera" },
    content: `
      ${rowCol6Start}
      <div class="${classPreFix}-multimedia video" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="${classPreFix}-video-box" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false">
        <video controls class="${classPreFix}-video" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" style="width: 100%; height: 400px;">
          <source>
        </video>
        </div>
      </div>
      ${rowCol6End}
    `,
  });

  // Attachment
  blockManager.add("Attachment", {
    category: "Acadru Multimedia",
    label: "Attachment",
    type: "attachment",
    attributes: { class: "fa fa-download" },
    content: `
      ${rowCol6Start}
        <div class="${classPreFix}-multimedia download-attachment" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <div class="content-box" data-gjs-droppable="false" data-gjs-draggable="false"  data-gjs-copyable="false">

            <div class="content" data-gjs-droppable="false" data-gjs-draggable="false"  data-gjs-copyable="false" data-gjs-removable="false">
              <div class="box download" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false">
                <div class="download-content" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false">
                  <div class="icon-box" data-gjs-draggable="false">
                    <img data-gjs-draggable="false" class="icon" src="/pdf-theme-icon.svg" alt="icon" />
                  </div>
                  <div class="file-content" data-gjs-draggable="false" data-gjs-removable="false">
                    <div class="file-text" data-gjs-draggable="false" data-gjs-removable="false">Lorem Ipsum is simply.pdf</div>
                    <div class="download-link" data-gjs-draggable="false">
                      <a data-gjs-removable="false" data-gjs-copyable="false" data-gjs-draggable="false"id="file1a" href="#file1" class="no-href" target="_blank"> Download</a>
                    </div>
                    <file data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false" class='no-file-uploaded' id="file1" type="file" title="" />
                  </div>
                </div>
              </div>

            </div>

            <div class="content" data-gjs-droppable="false" data-gjs-draggable="false"  data-gjs-copyable="false" data-gjs-removable="true">

              <div class="box download" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false">
                <div class="download-content" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false">
                    <div class="icon-box" data-gjs-draggable="false">
                      <img data-gjs-draggable="false" class="icon" src="/pdf-theme-icon.svg" alt="icon" />
                    </div>
                  <div class="file-content" data-gjs-draggable="false" data-gjs-removable="false">
                    <div class="file-text" data-gjs-draggable="false" data-gjs-removable="false">Lorem Ipsum is simply.pdf</div>
                    <div class="download-link" data-gjs-draggable="false">
                      <a data-gjs-removable="false" data-gjs-copyable="false" data-gjs-draggable="false"id="file1a" href="#file2" class="no-href" target="_blank"> Download</a>
                    </div>
                    <file data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false" class='no-file-uploaded' id="file2" type="file" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      ${rowCol6End}
    `,
  });

  // Code
  blockManager.add("Code", {
    category: "Acadru Multimedia",
    label: "Code Snippet",
    type: "code",
    attributes: { class: "fa fa-code" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-multimedia code" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="title" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false">Code Snippet</div>
        <pre class="${classPreFix}-pre-tag" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <code class="${classPreFix}-code-tag" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            <div class="${classPreFix}-code-snippet" data-gjs-type="custom-code" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" data-gjs-copyable="false"></div>
          </code>
        </pre>
      </div>
    ${rowCol6End}
    `,
  });

  //Embed
  blockManager.add("Embed", {
    category: "Acadru Multimedia",
    label: "Embedd IFrame",
    type: "embed",
    attributes: { class: "fa fa-link" },
    content: `
    ${rowCol6Start}
    <div class="${classPreFix}-EmbedIFrame" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <embed data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" class='embed' width='560' height='315'> </embed>
    </div>
    ${rowCol6End}
  `,
  });

  blockManager.add("iframe", {
    category: "Acadru Multimedia",
    label: "Simple IFrame",
    type: "iframe",
    attributes: { class: "fa fa-window-maximize" },
    content: `
    ${rowCol6Start}
    <div class="${classPreFix}-customIframe">
      <iframe class='iframe' width='560' height='315'allowfullscreen='true' webkitallowfullscreen='true' mozallowfullscreen='true' frameborder='5' scrolling='yes' marginheight='10' marginwidth='10' > </iframe>
    </div>
    ${rowCol6End}
  `,
  });
};
