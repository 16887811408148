import * as SERVICES from './services';

export const getAllRolesService = async (params) => {
    return await SERVICES.getAllRoles(params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllModulesService = async (params) => {
    return SERVICES.getAllModules(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllCategoriesService = async (params) => {
    return await SERVICES.getAllCategories(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllSubCategoriesService = async (params) => {
    return await SERVICES.getAllSubCategories(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllCareerService = async (params) => {
    return await SERVICES.getAllCareers(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllTagCategoriesService = async (params) => {
    return await SERVICES.getALLTagCategories(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllTagsService = async (params) => {
    return await SERVICES.getALLTags(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllBlogCategoriesService = async (params) => {
    return await SERVICES.getALLBlogCategories(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getRoleService = async (id) => {
    return await SERVICES.getRole(id)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};