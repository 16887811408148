import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { objectToFormdata, makeQueryParam } from "../../helpers";

export const postCreateModuleTabs = async (id, payload) => {
  let url = API.POST_CREATE_MODULE_TABS.replace(":id", id);
  return await axiosInstance.post(url, payload);
};

export const patchUpdateModuleTabs = async (module_id, tabs_id, payload) => {
  let url = API.PATCH_UPDATE_MODULE_TABS.replace(":module_id", module_id);
  url = url.replace(":tab_id", tabs_id);
  return await axiosInstance.patch(url, payload);
};

export const getSpecificModule = async (id) => {
  let url = API.GET_SPECIFIC_MODULE.replace(":id", id);
  return await axiosInstance.get(url);
};

export const postTabFileUpload = async (id, payload) => {
  let url = API.POST_TAB_FILE_UPLOAD.replace(":id", id);
  return await axiosInstance.post(url, objectToFormdata({ File: payload }));
};

export const getAllModules = async (params) => {
  let url = `${API.GET_ALL_MODULES}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
};

export const getWebScrapingData = async (params) => {
  let url = `${API.GET_WEB_SCRAPPING_DATA}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
};

export const deleteModule = async (id) => {
  let url = API.DELETE_MODULE.replace(":id", id);
  return await axiosInstance.delete(url);
};

export const patchUpdateModule = async (id, payload) => {
  let url = API.PATCH_UPDATE_MODULE.replace(":id", id);
  return await axiosInstance.patch(url, objectToFormdata(payload));
};

export const postCreateModule = async (payload) => {
  return await axiosInstance.post(
    API.POST_CREATE_MODULE,
    objectToFormdata(payload),
  );
};

export const postCreateProject = async (id, payload) => {
  let url = API.POST_CRETE_PROJECT.replace(":id", id);
  return await axiosInstance.post(url, payload);
};

export const getAllProject = async (id, param) => {
  let url = API.GET_ALL_PROJECT.replace(":id", id);
  return await axiosInstance.get(url, makeQueryParam(param));
};

export const patchUpdateProject = async (id, project_id, payload) => {
  let url = API.PATCH_UPDATE_PROJECT.replace(":id", id).replace(
    ":project_id",
    project_id,
  );
  return await axiosInstance.patch(url, payload);
};

export const deleteProject = async (id, project_id) => {
  let url = API.PATCH_UPDATE_PROJECT.replace(":id", id).replace(
    ":project_id",
    project_id,
  );
  return await axiosInstance.delete(url);
};

export const exportModulesList = async () => {
  return await axiosInstance.get(API.EXPORT_MODULES_LIST);
};

export const postSimilarityIndex = async (body) => {
  return await axiosInstance.post(API.POST_SIMILARITY_INDEX, objectToFormdata(body));
}

export const postSeedKeyword = async (body) => {
  return await axiosInstance.post(API.POST_SEED_KEYWORD, objectToFormdata(body));
}

export const postSeedSIKeyword = async (body) => {
  return await axiosInstance.post(API.POST_SEED_SI_KEYWORD, objectToFormdata(body));
}

export const getMyViewedModules = async (params) => {
  let url = `${API.GET_MY_VIEWED_MODULES}${makeQueryParam(params)}`;
  return await axiosInstance.get(url);
};