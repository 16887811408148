import React, { Component } from "react";
import { PageTitle } from "../../../../components/PageTitle";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";

// Custom Components
import ProfileAdminCard from "../../../../components/ProfileAdminCard";
import Loader from '../../../../components/PrimaryLoader';

//Constants
import { BREADCRUMBS } from '../../../../constants';

//Service
import * as DM from '../../dataManager';

// Style
import "./styles.scss";

class AdminDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      admin: {}
    }
  }

  componentDidMount() {
    const admin_id = this.props.match.params.id;
    this.getAdminData(admin_id);
  }

  getAdminData = (id) => {
    this._startLoader();
    DM.getUserService(id)
      .then((res) => {
        this._stopLoader();

        this.setState({
          admin: res.data
        })
      })
      .catch((err) => {

        this._stopLoader();
      })
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  render() {
    const { admin, isLoader } = this.state;
    return (
      <>
        <PageTitle title="Admin" />
        <div className="admin-details-style">
          <div className="header-style mb--30 mt--10">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.ADMINS,
                  { text: "View Details" },
                ]}
              />
              <h4 className="title4"> View Details</h4>
            </div>
          </div>

          <ProfileAdminCard admin={admin} />
          {isLoader && <Loader />}
        </div>
      </>
    );
  }
}

export default AdminDetails;
