import React, { Component } from "react";
import { Card, Row, Col, Avatar } from "antd";
import { get } from 'lodash';

import { UserOutlined } from "@ant-design/icons";

import defaultImg from '../../assets/user-grey.svg';

// Style
import "./styles.scss";

//Helpers
import { calculateAge, breakTimestamp, convertCase } from '../../helpers';

class ProfileMentorCard extends Component {
  constructor(props) {
    super();
  }

  render() {
    let mentor = this.props.mentor;
    let profileImg = get(mentor, "media.path", null);

    return (
      <div className="profile-mentor-section">
        <Card>
          <h5 className="title5 mb--20">Profile Information:</h5>
          <Row gutter={40}>
            <Col span={5}>
              <div className="user-image">
                <img
                  src={profileImg || defaultImg}
                  className={profileImg ? "img-item" : "img-item default"}
                  alt="user"
                />
              </div>
            </Col>
            <Col span={19}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title"> Mentor Name</div>
                    <div className="avatar-section">
                      <Avatar size={38} icon={<UserOutlined />} />
                      <div>
                        <p className="desciption trim-name"> {mentor.full_name}</p>
                        <div className="title"> Age: {mentor.dob ? calculateAge(mentor.dob) : "--"}</div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Gender</div>
                    <p className="desciption">{convertCase(mentor.gender) || "---"}</p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title"> Date of birth</div>
                    <p className="desciption">{mentor.dob ? breakTimestamp(get(mentor, "dob")).date : "---"}</p>
                  </div>
                </Col>
              </Row>

              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                className="mt--20">
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Phone</div>
                    <p className="desciption">{mentor.phone || "---"}</p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Email</div>
                    <p className="desciption">{mentor.email || "---"}</p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Signup date</div>
                    <p className="desciption">{breakTimestamp(get(mentor, "created_at")).date || "---"}</p>
                  </div>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
                className="mt--20">
                <Col className="gutter-row" xs={24} md={24}>
                  <div className="info-box">
                    <div className="title">Organization</div>
                    <p className="desciption trim-org">{mentor.organization || "---"}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default ProfileMentorCard;
