import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { get } from "lodash";

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";
import Loader from "../../../components/PrimaryLoader";
import ErrorModal from "../../../components/modal/ErrorModal";
import SuccessModal from "../../../components/modal/SuccessModal";

//Constants
import { BREADCRUMBS, ROUTES } from "../../../constants";

//Services
import * as DM from "../dataManager";

//Helpers
import { validate, parseError } from "../parser";

class BlogCategoryNew extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      originalName: null,
      blogCategory: {
        name: "",
        status: "ACTIVE",
      },
    };
  }

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificBlogCategoryDetials(editableID);
    }
  }

  getSpecificBlogCategoryDetials = (id) => {
    this._startLoader();
    DM.getBlogCategoryService(id)
      .then((res) => {
        this.setState({
          blogCategory: { name: res.data.name, status: res.data.status },
          originalName: res.data.name
        });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.blogCategory[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal) this.props.history.push(ROUTES.BLOGCATEGORY);
      },
    );
  };

  handleSave = () => {
    const { blogCategory, originalName } = this.state;

    const checkValidate = validate(blogCategory);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" },
      });
      return;
    }

    const editableID = this.props.match.params.id;
    this._startLoader();
    if (editableID) {
      if (blogCategory.name === originalName)
        delete blogCategory.name;
      DM.patchUpdateBlogCategoryService(editableID, blogCategory)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Blog category updated successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    } else {
      DM.postCreateBlogCategoryService(blogCategory)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Blog category created successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    }
  };

  render() {
    const editableID = this.props.match.params.id;
    const {
      isLoader,
      errorModal,
      succModal,
      errObj,
      blogCategory,
    } = this.state;

    return (
      <>
        <PageTitle title="Blog Category" />
        <div className="blog-category-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.BLOGCATEGORIES,
                  {
                    text: editableID
                      ? "Edit blog category"
                      : "Add new blog category",
                  },
                ]}
              />
              <h4 className="title4">
                {" "}
                {editableID ? "Edit Blog category" : "Add new Blog Category"}
              </h4>
            </div>

            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${blogCategory.status === "ACTIVE" ? "btn-active" : ""
                    }`}
                  onClick={() => this.setStateValue("ACTIVE", "status")}>
                  Active
                </Button>
                <Button
                  className={`btn-toggle ${blogCategory.status === "ACTIVE" ? "" : "btn-active"
                    }`}
                  onClick={() => this.setStateValue("INACTIVE", "status")}>
                  Inactive
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">
                    Blog category name <span className="required-text">*</span>
                  </p>
                  <InputPrimary
                    placeholder="Enter blog category name"
                    value={blogCategory.name}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name");
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
              </Col>
            </Row>

            <div className="mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

export default BlogCategoryNew;
