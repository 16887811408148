import React, { Component } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

// Style
import "./styles.scss";

class PrimaryBreadcrumb extends Component {
  render() {
    return (
      <Breadcrumb separator=">" className="primary-breadcrumb">
        {this.props.breadcrumbs &&
          this.props.breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb.Item className="breadcrumb-item" key={index}>
              {breadcrumb.link ? (
                <Link className="breadcrumb-link" to={breadcrumb.link}>
                  {breadcrumb.text}
                </Link>
              ) : (
                  breadcrumb.text
                )}
            </Breadcrumb.Item>
          ))}
      </Breadcrumb>
    );
  }
}

export default PrimaryBreadcrumb;
