import React, { Component } from 'react';
import ButtonPrimary from '../buttons/ButtonPrimary';
import { PageTitle } from '../PageTitle';
import "./styles.scss";

import illustration from "../../assets/empty.svg";

import { ROUTES } from '../../constants';

class ErrorComponent extends Component {
  render() {
    return (
      <>
        <PageTitle title={this.props.pageTitle} />
        <div className="error-page-style">
          <div className="title3">Oops...</div>
          <div className="illustration-box">
            <div className="illustration" style={{ backgroundImage: `url(${illustration})` }}></div>
          </div>
          <div className="title1 xl">{this.props.errorTitle}</div>
          <div className="title2 mt--10 mb--40">{this.props.errorMessage}</div>
          <ButtonPrimary
            className="go-back-btn"
            href={ROUTES.DASHBOARD}
            btnText="Back to Home"
          />
        </div>
      </>
    );
  }
}

export default ErrorComponent;