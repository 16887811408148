import React, { Component } from "react";
import { Button, Col, Row, Spin, Tooltip } from "antd";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { debounce, get } from "lodash";
import { connect } from "react-redux";

// Custom Component
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import InputPrimary from "../../../components/InputPrimary";
import ImageUpload from "../../../components/ImageUpload";
import SelectInput from "../../../components/SelectInput";
// import TextareaPrimary from "../../../components/TextareaPrimary";
// import SearchPrimary from "../../../components/SearchPrimary";
import ImageModuleCard from "../../../components/ImageModuleCard";
import PrimaryAutoComplete from "../../../components/PrimaryAutoComplete";
// import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import Loader from "../../../components/PrimaryLoader";
import ErrorModal from "../../../components/modal/ErrorModal";
import SuccessModal from "../../../components/modal/SuccessModal";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../../components/PageTitle";
import { InfoCircleOutlined } from "@ant-design/icons";

// images
import blogImage from "../../../assets/blogs.svg";

// Style
import "./styles.scss";

//Constants
import { BREADCRUMBS, ROUTES, SEARCHDELAY, EDITOR } from "../../../constants";

//Services
import * as DM from "../dataManager";
import * as CDM from "../../../common/dataManager";

//Helpers
import { validate, parseError } from "../parser";

//Actions
import { saveNewBlog, clearNewBlog, editBlog, clearEditBlog } from "../actions";

ClassicEditor.defaultConfig = EDITOR.EDITORCONFIG;

class BlogNew extends Component {
  constructor(props) {
    super();
    this.searchTag = debounce(this.searchTag, SEARCHDELAY.searchDelay);
    this.handleAutoComplete = debounce(
      this.handleAutoComplete,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      blog: {
        status: "PUBLISH",
        modules: [],
        moduleImages: [],
        authorName: "",
        readTime: "",
        authorImage: null,
      },
      originalBlogName: null,
      blogCategories: [],
      autoCompleteVal: "",
      autoCompleteOptions: [],
      tags: [],
      fetching: false,
    };
    this.editableID = null;
  }

  async componentDidMount() {
    this.getBlogCategories();

    this.editableID = this.props.match.params.id;
    let autoSave = get(this.props, "autoSave");
    if (this.editableID) {
      if (!autoSave.editBlog) autoSave.editBlog = {};
      autoSave.editBlog[`${this.editableID}`] = {};
      await this.getSpecificBlogDetials(this.editableID);
    } else {
      if (!autoSave.newBlog) autoSave.newBlog = {};
      let newBlog = autoSave.newBlog;
      if (!newBlog.status) this.setStateValue("PUBLISH", "status");
      if (!newBlog.modules) this.setStateValue([], "modules");
    }
  }

  setStateValue = (value, field) => {
    let state = this.state;
    if (field === "tags") {
      state.fetching = false;
    }
    state.blog[`${field}`] = value;
    this.setState(state, () => {
      let autoSave = get(this.props, "autoSave");
      if (this.editableID) {
        let editBlog = get(autoSave, `editBlog.${this.editableID}`);
        editBlog[`${field}`] = value;
        this.props.editBlog(editBlog);
      } else {
        let newBlog = autoSave.newBlog;
        newBlog[`${field}`] = value;
        this.props.saveNewBlog(newBlog);
      }
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal) {
          this.props.history.push(ROUTES.BLOGS);
          if (this.editableID) this.props.clearEditBlog(this.editableID);
          else this.props.clearNewBlog();
        }
      },
    );
  };

  getBlogCategories = () => {
    this._startLoader();
    CDM.getAllBlogCategoriesService({ status: "ACTIVE" })
      .then((res) => {
        this._stopLoader();
        let blogCategories = get(res, "data.results");
        this.setState({ blogCategories });
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  fillModuleImages = () => {
    let moduleImages = [];

    let blog = null;
    if (this.editableID)
      blog = get(this.props, `autoSave.editBlog.${this.editableID}`);
    else blog = get(this.props, "autoSave.newBlog");
    let modules = get(blog, "modules");
    modules &&
      modules.map((module) => {
        moduleImages.push({
          imgUrl: get(module, "media.path", null),
          title: module.name,
          id: module.id,
        });
        return null;
      });

    this.setStateValue(moduleImages, "moduleImages");
  };

  getSpecificBlogDetials = (id) => {
    this._startLoader();
    DM.getBlogService(id)
      .then((res) => {
        this.setState({ blog: res.data, originalBlogName: res.data.title });
        this.props.editBlog(res.data);
        this.fillModuleImages();
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handleAutoComplete = (value) => {
    CDM.getAllModulesService({
      search_type: "CUSTOM",
      status: "PUBLISH",
      page: 1,
      limit: 10,
      keyword: value,
    })
      .then((res) => {
        let modules = get(res, "data.results");
        modules = modules.map((module) => {
          return {
            label: module.name,
            value: [
              module.name,
              get(module, "id", ""),
              get(module, "media[0].path", ""),
            ],
          };
        });
        let selectedModules = get(this.state, "blog.modules", null);
        if (selectedModules) {
          modules = modules.filter((module) => {
            return !selectedModules.includes(module.value[1]);
          });
        }
        this.setState({ autoCompleteOptions: modules });
      })
      .catch((err) => {});
  };

  onSelect = (value) => {
    let modulesArr = get(this.state, "blog.modules", []);
    let moduleImages = get(this.state, "blog.moduleImages", []);

    modulesArr =
      modulesArr &&
      modulesArr.map((module) => {
        if (typeof module === "object") return module.id;
        return module;
      });
    if (modulesArr && modulesArr.includes(value[1])) {
      this.setState({ autoCompleteVal: "" });
      return;
    }
    modulesArr.push(value[1]);
    moduleImages.push({
      imgUrl: value[2],
      title: value[0],
      id: value[1],
    });

    this.setState(
      {
        autoCompleteVal: "",
      },
      () => {
        this.setStateValue(modulesArr, "modules");
        this.setStateValue(moduleImages, "moduleImages");
      },
    );
  };

  removeModule = (id) => {
    let selectedModules = get(this.state, "blog.modules");
    let selectedModuleImages = get(this.state, "blog.moduleImages");
    selectedModules = selectedModules.filter((module) => {
      if (typeof module === "object") return module.id !== id;
      return module !== id;
    });
    selectedModuleImages = selectedModuleImages.filter((module) => {
      return module.id !== id;
    });

    this.setStateValue(selectedModuleImages, "moduleImages");
    this.setStateValue(selectedModules, "modules");
  };

  searchTag = (value) => {
    this.setState({
      tags: [],
      fetching: true,
    });
    CDM.getAllTagsService({ keyword: value, status: "ACTIVE" })
      .then((res) => {
        let tags = get(res, "data.results");
        this.setState({ tags, fetching: false });
      })
      .catch((err) => {
        this.setState({ tags: [], fetching: false });
      });
  };

  handleSave = () => {
    let { blog, originalBlogName } = this.state;
    let newBlog = this.editableID
      ? get(this.props, `autoSave.editBlog.${this.editableID}`)
      : get(this.props, "autoSave.newBlog");
    const checkValidate = validate(newBlog);

    // newBlog = { ...newBlog, ...blog };
    // console.log(newBlog);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" },
      });
      return;
    }

    this._startLoader();
    newBlog.tags =
      blog.tags &&
      blog.tags.map((tag) => {
        if (typeof tag === "object") return tag.id || tag.value;
        return tag;
      });
    // this.setStateValue(blog.tags, "tags");

    if (this.editableID) {
      if (typeof blog.category === "object")
        blog.category = get(blog, "category.uuid");
      blog.modules =
        blog.modules &&
        blog.modules.map((module) => {
          if (typeof module === "object") return module.id;
          return module;
        });

      if (blog.title === originalBlogName) delete blog.title;
      if (blog.mainImage) newBlog.mainImage = blog.mainImage;
      DM.patchUpdateBlogService(this.editableID, newBlog)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Blog updated successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    } else {
      // console.log(newBlog.tags);
      DM.postCreateBlogService(newBlog)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Blog created successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    }
  };

  render() {
    const {
      isLoader,
      errorModal,
      succModal,
      errObj,
      blog,
      blogCategories,
      autoCompleteOptions,
      autoCompleteVal,
    } = this.state;

    let { moduleImages } = blog;
    const defaultAuthorImgUrl = get(blog, "authorImg");
    const blogCategoriesDropdown = blogCategories.map((blogCategory) => {
      return (
        <option value={blogCategory.uuid} key={blogCategory.id}>
          {blogCategory.name}
        </option>
      );
    });

    let newBlog = get(this.props, "autoSave.newBlog");
    if (this.editableID)
      newBlog = get(this.props, `autoSave.editBlog.${this.editableID}`);
    let dataColumns =
      moduleImages && moduleImages.length === 0
        ? get(newBlog, "moduleImages")
        : moduleImages;
    const defaultImageUrl = get(blog, "media.path", null);

    let tags = get(blog, "tags") || get(newBlog, "tags");
    let defaultTags =
      tags &&
      tags.map((tag) => {
        if (typeof tag === "object") {
          return {
            label: tag.name || tag.label,
            value: tag.id || tag.value,
          };
        }
        return tag;
      });

    const tagChild =
      this.state.tags &&
      this.state.tags.map((tag) => {
        return (
          <option value={tag.id} key={tag.id}>
            {tag.name}
          </option>
        );
      });

    return (
      <>
        <PageTitle title="Blogs" />
        <div className="module-details-container">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.BLOGS,
                  { text: this.editableID ? "Edit Blog" : "Add New Blog" },
                ]}
              />
              <h4 className="title4">
                {" "}
                {this.editableID ? "Edit Blog" : "Add New Blog"}
              </h4>
            </div>
            <div className="right-section-style">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${
                    get(blog, "status", "PUBLISH") === "PUBLISH"
                      ? "btn-active"
                      : ""
                  }`}
                  onClick={() => this.setStateValue("PUBLISH", "status")}>
                  Published
                </Button>
                <Button
                  className={`btn-toggle ${
                    get(blog, "status", "PUBLISH") === "UNPUBLISH"
                      ? "btn-active"
                      : ""
                  }`}
                  onClick={() => this.setStateValue("UNPUBLISH", "status")}>
                  Unpublished
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section ">
            <Row gutter={[40]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">
                    Title of the blog <span className="required-text">*</span>
                  </p>
                  <InputPrimary
                    placeholder="Title of the blog"
                    value={get(blog, "title") || get(newBlog, "title")}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "title");
                    }}
                    errorText={get(errObj, "title")}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--20">
                  <p className="lable">
                    Select or Add category{" "}
                    <span className="required-text">*</span>
                  </p>
                  <SelectInput
                    placeholder="Select"
                    child={blogCategoriesDropdown}
                    value={
                      get(blog, "category.uuid") ||
                      get(blog, "category") ||
                      get(newBlog, "category.uuid") ||
                      get(newBlog, "category")
                    }
                    onChange={(val) => {
                      this.setStateValue(val, "category");
                    }}
                    errorText={get(errObj, "category")}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Author name</p>

                  <InputPrimary
                    value={
                      get(blog, "authorName") || get(newBlog, "authorName")
                    }
                    placeholder="Author Name"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "authorName");
                    }}
                    errorText={get(errObj, "authorName")}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Read time</p>

                  <InputPrimary
                    type="number"
                    value={get(blog, "readTime") || get(newBlog, "readTime")}
                    placeholder="Est. read time"
                    onChange={(e) => {
                      this.setStateValue(+e.target.value, "readTime");
                    }}
                    errorText={get(errObj, "readTime")}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--20 upload-image">
                  <p className="lable">Author Image</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={
                      defaultAuthorImgUrl
                        ? "Change Photo"
                        : "Pick a photo from your computer"
                    }
                    text={
                      defaultAuthorImgUrl
                        ? get(blog, "authorImage.file_name")
                        : "(Max file size: 10MB)"
                    }
                    defaultImageUrl={defaultAuthorImgUrl?.path || blogImage}
                    onChange={(file) => {
                      this.setStateValue(file.originFileObj, "authorImage");
                      this.setStateValue(file, "rawAuthorFile");
                    }}
                  />

                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--20 upload-image">
                  <p className="lable">Blog preview image</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={
                      defaultImageUrl
                        ? "Change photo"
                        : "Pick a photo from your computer"
                    }
                    text={
                      defaultImageUrl
                        ? get(blog, "media.file_name")
                        : "(Max file size: 10MB)"
                    }
                    defaultImageUrl={defaultImageUrl || blogImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "mainImage");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24}>
                <div className="input-item mt--20">
                  <p className="lable">Blog Summary</p>

                  <CKEditor
                    editor={ClassicEditor}
                    data={
                      get(newBlog, "summary") || get(newBlog, "summary") || ""
                    }
                    onInit={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setStateValue(data, "summary");
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24}>
                <div className="input-item">
                  <p className="lable">
                    Content of the blog <span className="required-text">*</span>{" "}
                    <Tooltip
                      title={`
                        1. Use "<@#--ACADRU-MODULE-REPLACER--#@>" for modules blocks replacement 
                        <br />
                        2. Use "<@#--TABLE-CONTENT-NAME--#@>" for sticky table module
                        `}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </p>
                  <span className="text-danger">{get(errObj, "content")}</span>
                  <CKEditor
                    editor={ClassicEditor}
                    data={get(blog, "content") || get(newBlog, "content") || ""}
                    onInit={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setStateValue(data, "content");
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24}>
                <div className="input-item">
                  <p className="lable">Blog footer</p>
                  <span className="text-danger">{get(errObj, "footer")}</span>
                  <CKEditor
                    editor={ClassicEditor}
                    data={get(blog, "footer") || get(newBlog, "footer") || ""}
                    onInit={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setStateValue(data, "footer");
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24}>
                <div className="input-item">
                  <p className="lable">Add modules</p>

                  <PrimaryAutoComplete
                    value={autoCompleteVal}
                    onChange={(val) => {
                      this.setState({
                        autoCompleteVal: val,
                      });
                    }}
                    placeholder="Search module name…"
                    options={autoCompleteOptions}
                    onSearch={(value) => {
                      this.handleAutoComplete(value);
                    }}
                    onSelect={this.onSelect}
                    errorText={get(errObj, "modules")}
                  />
                  {/* <SearchPrimary
                placeholder="Search module name…"
                enterButton="Search"
                onSearch={(value) => {
                }}
              /> */}
                </div>

                <div className="module-sec">
                  <Row gutter={16}>
                    {dataColumns &&
                      dataColumns.map((data, index) => {
                        return (
                          <Col
                            className="gutter-row mt--50"
                            xs={12}
                            md={4}
                            key={index}>
                            <ImageModuleCard
                              removeModule={() => {
                                this.removeModule(data.id);
                              }}
                              imgUrl={data.imgUrl || blogImage}
                              title={data.title}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24}>
                <div className="input-item mt--20">
                  <p className="lable">Add Related Tags</p>
                  <SelectInput
                    labelInValue={true}
                    selectInputStyle="tag-mode-style"
                    placeholder="Add tags"
                    mode="multiple"
                    value={defaultTags}
                    child={tagChild}
                    onChange={(value) => {
                      this.setStateValue(value, "tags");
                    }}
                    notFoundContent={
                      this.state.fetching ? <Spin size="small" /> : null
                    }
                    onSearch={this.searchTag}
                  />
                </div>
              </Col>
            </Row>

            {/* <div className="module-sec mt--50">
                <Row gutter={16}>
                  {dataColumns.map((data, index) => {
                    return (
                      <Col className="gutter-row" xs={12} md={4} key={index}>
                        <ImageModuleCard
                          removeModule={() => {
                          }}
                          imgUrl={data.imgUrl}
                          title={data.title}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div> */}

            <div className="mt--40">
              <ButtonPrimary
                btnText="Save"
                btnPrimaryStyle="mr--10"
                onClick={() => this.handleSave()}
              />
              {/* <ButtonSecondary btnText="Save as draft"
                onClick={() => this.handleSave("DRAFT")}
              /> */}
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  saveNewBlog,
  clearNewBlog,
  editBlog,
  clearEditBlog,
})(BlogNew);
