import { TYPES } from "../../containers/RoleManagement/Constants";;

export default (state = {}, action) => {
    switch (action.type) {
        case TYPES.GET_ALL_ROLES:
            return {
                ...state,
                allRoles: action.payload,
                error: {},
            };
        case TYPES.GET_ALL_PERMISSIONS:
            return {
                ...state,
                allPermissions: action.payload,
                error: {},
            };
        default:
            return state;
    }
};
