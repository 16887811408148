import { API } from "./Constants";
import { axiosInstance } from "../../tools";

export const createBlogCategory = async (payload) => {
    let url = API.POST_CREATE_BLOG_CATEGORY;
    return await axiosInstance.post(url, payload);
};

export const getBlogCategory = async (id) => {
    let url = API.GET_BLOG_CATEGORY.replace(":id", id);
    return await axiosInstance.get(url);
};

export const updateBlogCategory = async (id, payload) => {
    let url = API.PATCH_UPDATE_BLOG_CATEGORY.replace(":id", id);
    return await axiosInstance.patch(url, payload);
};

export const deleteBlogCategory = async (id) => {
    let url = API.DELETE_BLOG_CATEGORY.replace(":id", id);
    return await axiosInstance.delete(url);
};

