import * as SERVICES from './services';

export const getProfileService = async () => {
    return await SERVICES.getProfile()
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const updateProfileService = async (payload) => {
    return await SERVICES.patchUpdateProfile(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const changePasswordService = async (payload) => {
    return await SERVICES.patchChangePassword(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};
