import React, { Component } from "react";
import { Avatar, Card, Checkbox, Space } from "antd";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { debounce, get } from 'lodash';

// Custom Components
import ProfileMentorCard from "../../../../components/ProfileMentorCard";
import Loader from '../../../../components/PrimaryLoader';
import EmptyState from '../../../../components/EmptyState';
import PrimaryPagination from "../../../../components/PrimaryPagination";
import SearchPrimary from "../../../../components/SearchPrimary";

//Constants
import { ACTIONS, BREADCRUMBS, ROUTES, SEARCHDELAY } from '../../../../constants';

//Service
import * as DM from '../../dataManager';
import * as MDM from '../../../Modules/dataManager';
//Helpers
import { convertCase } from '../../../../helpers'

// Style
import "./styles.scss";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../../../components/PageTitle";
import ButtonDanger from "../../../../components/buttons/ButtonDanger";
import ButtonOutline from "../../../../components/buttons/ButtonOutline";
import RemoveModal from "../../../../components/modal/RemoveModal";
import ErrorModal from "../../../../components/modal/ErrorModal";
import SuccessModal from "../../../../components/modal/SuccessModal";

import { connect } from "react-redux";
import { USERSTATUSENUM } from "../../Constants";
import SelectInput from "../../../../components/SelectInput";
import ViewedModules from "../../../../components/ViewedModules";


class SuperMentorDetails extends Component {

  constructor(props) {
    super();
    this.getChildMentorData = debounce(
      this.getChildMentorData,
      SEARCHDELAY.searchDelay
    );

    this.state = {
      removeShowModal: false,
      removeSMShowModal: false,
      meta: {
        page: 1,
        limit: 10
      },
      meta2: {
        page: 1,
        limit: 10
      },
      meta3: {
        page: 1,
        limit: 10
      },
      searchQuery: '',
      mentor: {},
      mentors: [],
      totalMentors: 0,
      learners: [],
      modules: [],
      totalLearners: 0,
      isLoader: false,
      isLoaderSecondary: false,
      errorModal: null,
      succModal: null,
      selectedAssignId: "",
      selectedDeleteId: "",
      is_checked: false,
    }
  }

  componentDidMount() {
    const mentor_id = this.props.match.params.id;
    this.getMentorData(mentor_id);
    this.getMentorLearnersData();
    this.getChildMentorData();
    this.getMentorModulesData();
  }

  getMentorModulesData() {
    const id = this.props.match.params.id;
    this._startLoader();
    const { page, limit } = this.state.meta3;
    MDM.getMyViewedModulesService({ id, page, limit })
      .then((res) => {
        this._stopLoader();
        this.setState({
          modules: res.data
        })
      })
      .catch((err) => {
        this._stopLoader();
      })
  }

  handleModulePageChange = (page) => {
    let meta3 = this.state.meta3;
    meta3.page = page;
    this.setState({ meta3: meta3 }, () => {
      this.getMentorModulesData();
    })
  }

  getMentorData = (id) => {
    this._startLoader();
    this._startLoaderSecondary();
    DM.getUserService(id)
      .then((res) => {
        this._stopLoader();

        this.setState({
          mentor: res.data
        })
      })
      .catch((err) => {
        this._stopLoader();
      })
  }

  getChildMentorData = () => {
    const mentor_id = this.props.match.params.id;
    const { page, limit } = this.state.meta;
    const { searchQuery } = this.state;
    DM.getSuperMentorMentorsService(mentor_id, { page, limit, type: "MENTOR", keyword: searchQuery })
      .then((res) => {
        this._stopLoader();
        this.setState({
          mentors: get(res, "data.results"),
          totalMentors: get(res, "data.meta.pagination.total")
        })
      })
      .catch((err) => {
        this._stopLoader();
      })
  }

  getMentorLearnersData = () => {
    this._startLoaderSecondary();
    const mentor_id = this.props.match.params.id;
    const { page, limit } = this.state.meta2;
    DM.getMentorLearnersService(mentor_id, { page, limit, type: "LEARNER", is_checked: this.state.is_checked })
      .then((res) => {
        this._stopLoaderSecaondary();
        this.setState({
          learners: get(res, "data.results"),
          totalLearners: get(res, "data.meta.pagination.total")
        })
      })
      .catch((err) => {
        this._stopLoaderSecaondary();
      })
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta }, () => {
      this.getChildMentorData();
    })
  }

  handlePageChangeLearner = (page) => {
    let meta2 = this.state.meta2;
    meta2.page = page;
    this.setState({ meta2 }, () => {
      this.getMentorLearnersData();
    })
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  _startLoaderSecondary = () => {
    this.setState({ isLoaderSecondary: true });
  };

  _stopLoaderSecaondary = () => {
    this.setState({ isLoaderSecondary: false });
  };

  removeToggleModal = (id) => {
    this.setState({
      selectedDeleteId: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  }

  removeSMToggleModal = (id) => {
    this.setState({
      selectedAssignId: typeof id === "string" ? id : "",
      removeSMShowModal: !this.state.removeSMShowModal,
    });
  }

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  removeAdmin = (superMentorId) => {
    this._startLoader();
    const currentUser = this.props.match.params.id;
    const secondUser = superMentorId || this.state.selectedDeleteId;

    const mentor = superMentorId ? currentUser : secondUser;
    const superMentor = superMentorId ? secondUser : currentUser;

    DM.removeAssignedSuperMentorOneService(mentor, superMentor)
      .then((res) => {
        let description;
        this._stopLoader();
        if (superMentorId) {
          this.getMentorData(currentUser);
          description = "Supermentor removed successfully"
        } else {
          this.getChildMentorData();
          description = "Mentor removed successfully."
        }
        this.setState({
          succModal: {
            title: "Success",
            description
          },
        });
        superMentorId ? this.removeSMToggleModal() : this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        superMentorId ? this.removeSMToggleModal() : this.removeToggleModal();
      });
  };

  removeParentSM = () => {
    const id = this.state.selectedAssignId;
    this.removeAdmin(id);
  }

  handleMentorSearch = (e) => {
    this.setState({
      searchQuery: e.target.value,
      meta: { ...this.state.meta, page: 1 }
    }, () => this.getChildMentorData())
  }

  onCheckboxClick = async () => {
    this.setState({
      isLoader: true,
      isLoaderSecondary: true,
      is_checked: !this.state.is_checked,
      meta2: { ...this.state.meta2, page: 1 }
    }, () => {
      this.getMentorLearnersData();
    })
  }

  handlePerPageChangeForMeta2 = (limit) => {
    const { meta2 } = this.state
    this.setState({ meta2: { ...meta2, limit: limit, page: 1 }, isLoaderSecondary: true, isLoader: true }, () => { this.getMentorLearnersData() });
  }

  handlePerPageChangeForMeta = (limit) => {
    const { meta } = this.state
    this.setState({ meta: { ...meta, limit: limit, page: 1 }, isLoaderSecondary: true, isLoader: true }, () => { this.getChildMentorData(); });
  }

  render() {
    const permissions = get(this.props, "auth.permissions");
    const { removeShowModal, removeSMShowModal, mentor, isLoader, isLoaderSecondary, mentors, totalMentors, learners, totalLearners, modules, meta, meta2, meta3, errorModal, succModal, is_checked } = this.state;
    const dataColumns = mentors?.map(mentorItem => {
      return mentorItem.mentor;
    });

    const dataColumnsLearners = learners.map(mentorItem => {
      return mentorItem.student || mentorItem;
    });
    const moduleDataColumns = get(modules, "results")
    const totalModules = get(modules, "meta.pagination.total");

    return (
      <>
        <PageTitle title="Super Mentor" />
        <div className="mentor-details-style">
          <div className="header-style mb--30 mt--10">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.SUPERMENTORS,
                  { text: "View Details" },
                ]}
              />
              <h4 className="title4">View Details</h4>
            </div>
          </div>

          {mentor?.status === USERSTATUSENUM.UserStatus.INACTIVE ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <ProfileMentorCard mentor={mentor} />}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && get(mentor, "superMentor")) &&
            <Card className="mt--50">
              <div className="table-container">
                <div className="mb--20">
                  <h4 className="title4">Super mentors you are assigned to</h4>
                </div>

                <div className="table-card-style">
                  <div className="table-style">
                    <table className="table-custom-style">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Actions</th>
                      </tr>
                      {get(mentor, "superMentor").map((data, index) => {
                        return (
                          <tr className="data-row table-module" key={index}>
                            <td>
                              <p className="lead">{get(data, "rawid") || "---"}</p>
                            </td>
                            <td>
                              <div className="img-content-style-sm flex-col">
                                {get(data, "media.path", null) ?
                                  <Avatar size={40} src={get(data, "media.path", null)} /> :
                                  <Avatar size={40} icon={<UserOutlined />} />
                                }
                                <p className="lead trim-sm">
                                  <a className="lead link-text"
                                    href={
                                      ROUTES.VIEWSUPERMENTOR.replace(":id", get(data, "id"))
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {data.full_name}
                                  </a>
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="lead">{get(data, "email") || "---"}</p>
                            </td>
                            <td>
                              <p className="lead">{get(data, "phone") || "---"}</p>
                            </td>
                            <td>
                              {permissions.USERS && permissions.USERS.includes(ACTIONS.UPDATE) &&
                                <Space size="middle">
                                  <ButtonOutline
                                    btnText={<EditOutlined />}
                                    btnStyle="btn edit square-edit"
                                    clickHandler={() =>
                                      this.props.history.push(
                                        ROUTES.EDITSUPERMENTOR.replace(
                                          ":id",
                                          data.id,
                                        ),
                                      )
                                    }
                                  />
                                  <ButtonDanger
                                    type="link"
                                    btnText={<DeleteOutlined />}
                                    btnDangerStyle="btn delete btn-link"
                                    onClick={() => { this.removeSMToggleModal(data.id) }
                                    }>
                                    Remove
                                  </ButtonDanger>
                                </Space>
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          }

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && dataColumns.length > 0) && <Card className="mt--50">
            <div className="table-container">
              <div className="mb--20 div-space-between">
                <div>
                  <h4 className="title4">Assigned mentors</h4>
                  <h4 className="sub-heading  mt--10">Total:  {totalMentors ? totalMentors : 0}</h4>
                </div>
                <div className="filter-head">
                  <SelectInput
                    defaultValue={meta.limit}
                    value={meta.limit}
                    isTitleActive
                    title="Limit:"
                    placeholder="Select"
                    options={[10, 25, 50, 100]}
                    onChange={(value) => this.handlePerPageChangeForMeta(value)}
                  />
                  <SearchPrimary
                    placeholder="Search mentor"
                    onSearch={this.handleMentorSearch}
                  />
                </div>
              </div>

              {(!dataColumns || dataColumns.length === 0) ? <EmptyState title="No mentors" description="No mentors assigned for this super mentor." /> :
                <>
                  <div className="table-card-style">
                    <div className="table-style">
                      <table className="table-custom-style">
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Remove Mentor</th>
                        </tr>
                        {dataColumns && dataColumns.map((data, index) => {
                          const role = get(data, "roles[0].type", "---");
                          return (
                            <tr className="data-row table-module" key={index}>
                              <td>
                                <p className="lead">{get(data, "rawid") || "---"}</p>
                              </td>
                              <td>
                                <div className="img-content-style-sm">
                                  <p className="lead trim-sm">
                                    <a className="lead link-text"
                                      href={role === "SUPER_MENTOR" ?
                                        ROUTES.VIEWSUPERMENTOR.replace(":id", get(data, "id")) :
                                        ROUTES.VIEWMENTOR.replace(":id", get(data, "id"))}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {data.full_name}
                                    </a>
                                  </p>
                                  <span className="lead role">
                                    {role}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <p className="lead">{get(data, "email") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{get(data, "phone") || "---"}</p>
                              </td>
                              <td>
                                <ButtonDanger
                                  type="link"
                                  btnText={<DeleteOutlined />}
                                  btnDangerStyle="btn delete btn-link"
                                  onClick={() => { this.removeToggleModal(data.id) }
                                  }>
                                  Remove
                                </ButtonDanger>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="page-section">
                    <PrimaryPagination pageSize={meta.limit} current={meta.page} total={totalMentors} onChange={this.handlePageChange} />
                  </div>
                </>
              }
            </div>
          </Card>}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && dataColumnsLearners.length > 0) && <Card className="mt--50">
            <div className="table-container">
              <div className="assigned-learner-head">
                <div>
                  <h4 className="title4 mb--10">Assigned learners</h4>
                  <h4 className="sub-heading mb--20">Total:  {totalLearners ? totalLearners : 0}</h4>
                </div>
                <div className="filter-head">
                  <div>
                    <SelectInput
                      defaultValue={meta2.limit}
                      value={meta2.limit}
                      isTitleActive
                      title="Limit:"
                      placeholder="Select"
                      options={[10, 25, 50, 100]}
                      onChange={(value) => this.handlePerPageChangeForMeta2(value)}
                    />
                  </div>
                  <div className="check">
                    <Checkbox
                      checked={is_checked}
                      onChange={this.onCheckboxClick}>
                      Self
                    </Checkbox>
                  </div>
                </div>
              </div>
              {(!dataColumnsLearners || dataColumnsLearners.length === 0) ? <EmptyState title="No learners" description="No leaners assigned for this super mentor." /> :
                <>
                  <div className="table-card-style">
                    <div className="table-style">
                      <table className="table-custom-style">
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Organization</th>
                          <th>School Name</th>
                          <th>Package</th>
                        </tr>
                        {dataColumnsLearners && dataColumnsLearners.map((data, index) => {
                          return (
                            <tr className="data-row table-module" key={index}>
                              <td>
                                <p className="lead">{get(data, "rawid") || "---"}</p>
                              </td>
                              <td>
                                <div className="img-content-style">
                                  <p className="lead trim-sm">
                                    <a className="lead link-text" href={ROUTES.VIEWLEARNER.replace(":id", get(data, "id"))} target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {data.full_name}
                                    </a>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="lead">{get(data, "email") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{get(data, "organization") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{get(data, "current_school_uni") || "---"}</p>
                              </td>
                              <td>
                                <p className="lead">{convertCase(get(data, "userpackages.packages.name")) || "---"}</p>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="page-section">
                    <PrimaryPagination pageSize={meta2.limit} current={meta2.page} total={totalLearners} onChange={this.handlePageChangeLearner} />
                  </div>
                </>
              }
            </div>
          </Card>}

          {(mentor?.status !== USERSTATUSENUM.UserStatus.INACTIVE && moduleDataColumns?.length > 0) &&
            <ViewedModules
              dataColumns={moduleDataColumns}
              meta={meta3}
              totalModules={totalModules}
              isLearner={false}
              handlePageChange={this.handleModulePageChange}
            />
          }
        </div>
        {isLoader && isLoaderSecondary && <Loader />}

        {removeShowModal && (
          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this mentor"
            description="Are you sure you want to remove this mentor?"
            // Action button event here
            removeHandler={() => this.removeAdmin()}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
        )}

        {removeSMShowModal && (
          <RemoveModal
            isModalVisible={removeSMShowModal}
            onClose={this.removeSMToggleModal}
            title="Remove supermentor"
            description="Are you sure you want to remove assigned super mentor?"
            removeHandler={this.removeParentSM}
            dontRemoveHandler={this.removeSMToggleModal}
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
        )}

        {errorModal && (
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
        )}

        {succModal && (
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />
        )}

      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
}

export default connect(mapStateToProps, {})(SuperMentorDetails);
