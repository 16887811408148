import * as SERVICES from "./services";

export const postCreateTagService = async (payload) => {
    return await SERVICES.createTag(payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getTagService = async (id, params) => {
    return await SERVICES.getTag(id, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdateTagService = async (id, payload) => {
    return await SERVICES.updateTag(id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteTagService = async (id) => {
    return await SERVICES.deleteTag(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

