export default function ReturnStatusColor(status) {
    let colorClass;

    switch (status) {
        case "Assigned":
        case "ASSIGNED":
        case "PENDING":
            colorClass = "dot-orange";
            break;
        case "Viewed":
        case "VIEWED":
            colorClass = "dot-blue";
            break;
        case "Draft":
        case "DRAFT":
            colorClass = "dot-blue";
            break;
        case "Consulted":
        case "CONSULTED":
        case "SUCCESS":
            colorClass = "dot-green";
            break;
        case "Active":
        case "ACTIVE":
            colorClass = "dot-green";
            break;
        case "Publish":
        case "PUBLISH":
            colorClass = "dot-green";
            break;
        case "Unpublish":
        case "UNPUBLISH":
            colorClass = "dot-gray";
            break;
        case "Closed":
        case "CLOSED":
        case "Failed":
        case "FAILED":
            colorClass = "dot-red";
            break;
        default:
            colorClass = "dot-gray";
    }

    return colorClass;
}
