import React, { Component } from "react";
import { Row, Col, Checkbox } from "antd";
import { get } from 'lodash';
import { connect } from 'react-redux';

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import Loader from '../../../components/PrimaryLoader';
import ErrorModal from '../../../components/modal/ErrorModal';
import SuccessModal from '../../../components/modal/SuccessModal';
import EmptyState from '../../../components/EmptyState';
import PrimaryPagination from '../../../components/PrimaryPagination';

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";

//Actions
import { getAllPermissions } from '../actions';

//Constants
import { BREADCRUMBS, ROUTES } from '../../../constants';

//Helpers
import { validate, parseError } from '../parser';

//Service
import * as DM from '../dataManager';
import * as CDM from '../../../common/dataManager';

class RoleNew extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      role: {
        name: "",
        permissions: []
      },
      editableRole: null,
      meta: {
        page: 1,
        limit: 20
      },
    };
  }

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    this.getAllPermissionsData();
    if (editableID) {
      await this.getSpecificRoleDetials(editableID);
    }
  }

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.ROLE_MANAGEMENT);
    })
  }

  onChange = (e) => {
    let { permissions } = this.state.role;
    if (e.target.checked) {
      permissions = [...permissions, e.target.value]
      this.setState({
        role: { ...this.state.role, permissions }
      });
    } else {
      let idx = permissions.indexOf(e.target.value);
      if (idx !== -1) permissions.splice(idx, 1);
      this.setState({
        role: { ...this.state.role, permissions }
      });
    }
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.role[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllPermissionsData();
    })
  }

  getSpecificRoleDetials = (editableID) => {
    this._startLoader();
    CDM.getRoleService(editableID)
      .then((res) => {
        let editableRolePermissions = get(res, "data.permissions", []);
        editableRolePermissions = editableRolePermissions.map(permission => {
          return permission.id;
        })
        let role = { name: res.data.name, permissions: editableRolePermissions };
        this.setState({ role, editableRole: role.name });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  getAllPermissionsData = () => {
    this._startLoader();
    const { page, limit } = this.state.meta;
    DM.getAllPermissionsService({
      page, limit
    })
      .then((res) => {
        this.props.getAllPermissions(res.data);
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  handleSaveButton = async () => {
    const { role } = this.state;

    const checkValidate = validate(role);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: get(checkValidate, "permissions") || "Validation Error Occured" }
      });
      return;
    }

    const editableID = this.props.match.params.id;
    this._startLoader();
    if (editableID) {
      const prevName = this.state.editableRole;
      if (prevName === role.name)
        delete role.name;
      DM.updateRoleService(editableID, role)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Role updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.createRoleService(role)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Role created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }



  render() {
    const editableID = this.props.match.params.id;
    const { role, isLoader, errorModal, errObj, meta, succModal } = this.state;
    const dataColumns = get(this.props, "roles.allPermissions.results", null);
    const totalPermissions = get(this.props, "roles.allPermissions.meta.pagination.total", null);

    return (
      <>
        <PageTitle title="Role Management" />
        <div className="role-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.ROLE_MANAGEMENT,
                  { text: editableID ? "Edit role" : "Add new role" },
                ]}
              />
              <h4 className="title4">
                {editableID ? "Edit Role" : "Add new role"}
              </h4>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Role Title<span className="required-text">*</span></p>
                  <InputPrimary
                    placeholder="Enter role title"
                    value={role.name || undefined}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name")
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={24}>
                <div className="input-item">
                  <p className="lable mb--20">Permissions</p>

                  {(!dataColumns || dataColumns.length === 0) ? <EmptyState title="No permissions" /> :
                    <div
                      className="checkbox-column-style">
                      <Row gutter={[20]}>
                        {dataColumns && dataColumns.map((data, index) => {
                          return (
                            <Col xs={24} sm={24} md={6} key={index}>
                              <Checkbox className="checkbox-style" value={data.id} onChange={this.onChange} checked={role.permissions.includes(data.id)}>
                                {data.name}
                              </Checkbox>
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  }
                </div>
              </Col>
            </Row>

            <div className="page-section">
              <PrimaryPagination pageSize={meta.limit} current={meta.page} total={totalPermissions} onChange={this.handlePageChange} />
            </div>

            <div className="mt--10">
              <ButtonSecondary btnText={editableID ? "Save" : "Add"} href="#" onClick={this.handleSaveButton} />
            </div>
          </div>

          {isLoader && <Loader />}
          {errorModal &&
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          }
          {succModal &&
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = store => {
  return store;
}

export default connect(mapStateToProps, {
  getAllPermissions
})(RoleNew);
