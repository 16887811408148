import React, { Component } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { get, debounce } from "lodash";
import { connect } from "react-redux";
import defaultImg from "../../../assets/user-grey.svg";

// Custom Components
// import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import SelectInput from "../../../components/SelectInput";
import SearchPrimary from "../../../components/SearchPrimary";
import PrimaryPagination from "../../../components/PrimaryPagination";
import RemoveModal from "../../../components/modal/RemoveModal";
import LearnersCard from "../../../components/LearnersCard";
import ToggleMenu from "../../../components/ToggleMenu";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import AssignStudentModal from "../../../components/modal/AssignStudentModal";
import AssignChildMentorModal from "../../../components/modal/AssignChildMentorModal";
import RemoveChildMentorModal from "../../../components/modal/RemoveChildMentorModal";
import Loader from "../../../components/PrimaryLoader";
import EmptyState from "../../../components/EmptyState";
import ErrorModal from "../../../components/modal/ErrorModal";
import SuccessModal from "../../../components/modal/SuccessModal";

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../../constants";

//Services
import * as DM from "../dataManager";

//Actions
import { getAllSuperMentors } from "../actions";

class SuperMentorsScreen extends Component {
  constructor(props) {
    super();
    this.getAllSuperMentorsData = debounce(
      this.getAllSuperMentorsData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      assignLearnerShowModal: false,
      assignMentorShowModal: false,
      removeMentorShowModal: false,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
      },
      errorModal: null,
      succModal: null,
      mentors: {
        isMenteeAssigned: "All",
        searchQuery: null,
      },
      selected_id: "",
      selectedUser: null
    };
  }

  componentDidMount() {
    this.getAllSuperMentorsData();
  }

  getAllSuperMentorsData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) this._startLoader();
    let { isMenteeAssigned, searchQuery } = this.state.mentors;
    const { page, limit } = this.state.meta;
    const queryObject = {
      type: "SUPER_MENTOR",
      status: "ACTIVE",
      limit: limit,
      page: page,
    };
    if (isMenteeAssigned !== "All")
      queryObject.is_mentee_assign = isMenteeAssigned === "Yes";
    if (searchQuery) {
      queryObject.keyword = searchQuery;
    }
    DM.getAllUsersService(queryObject)
      .then((res) => {
        this.props.getAllSuperMentors(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.mentors[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "searchQuery") isLoaderBlocked = true;
      this.getAllSuperMentorsData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllSuperMentorsData();
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  assignLearnerToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      assignLearnerShowModal: !this.state.assignLearnerShowModal,
    });
  };

  assignMentorToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      assignMentorShowModal: !this.state.assignMentorShowModal,
    });
  };

  removeMentorToggleModal = (selectedSM) => {
    this.setState({
      selectedUser: selectedSM,
      removeMentorShowModal: !this.state.removeMentorShowModal,
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  assignLearners = (learners) => {
    this._startLoader();
    DM.assignLearnersService({
      mentors: [this.state.selected_id],
      learners: learners,
    })
      .then((res) => {
        this._stopLoader();
        this.getAllSuperMentorsData();
        this.assignLearnerToggleModal();
        this.setState({
          succModal: {
            title: "Success",
            description: "Learners assigned successfully.",
          },
        });
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.assignMentorToggleModal();
      });
  };

  assignMentors = (mentors) => {
    this._startLoader();
    DM.assignChildMentorsService({
      super_mentor_id: this.state.selected_id,
      mentors: mentors,
    })
      .then((res) => {
        this._stopLoader();
        this.getAllSuperMentorsData();
        this.assignMentorToggleModal();
        this.setState({
          succModal: {
            title: "Success",
            description: "Mentors assigned successfully.",
          },
        });
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.assignMentorToggleModal();
      });
  };

  removeMentees = (data) => {
    if (!data || data.length === 0) return;
    this._startLoader();
    const superMentor = this.state.selectedUser.id;
    const promises = data.map((mentor) => {
      return DM.removeAssignedSuperMentorOneService(mentor, superMentor);
    })
    Promise.all(promises).then((res) => {
      this.setState({
        selectedUser: null,
        succModal: {
          title: "Success",
          description: "Mentors removed successfully.",
        },
      });
      this.getAllSuperMentorsData();
    }).catch(err => {
      this.setState({
        selectedUser: null,
        errorModal: {
          title: "ERROR",
          description: get(err, "response.data.message", ""),
        },
      });
    }).then(() => {
      this._stopLoader();
      this.removeMentorToggleModal();
    })
  }

  removeMentor = () => {
    this._startLoader();
    let mentor = this.state.selected_id;
    DM.removeUserService(mentor, { status: "INACTIVE" })
      .then((res) => {
        this._stopLoader();
        this.getAllSuperMentorsData();
        this.removeToggleModal();
        this.setState({
          succModal: {
            title: "Success",
            description: "Mentor removed successfully.",
          },
        });
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      assignMentorShowModal,
      removeMentorShowModal,
      assignLearnerShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "users.allSuperMentors.results", null);
    const totalMentors = get(
      this.props,
      "users.allSuperMentors.meta.pagination.total",
      0,
    );

    let mentorCardCol1 = [];
    let mentorCardCol2 = [];
    if (dataColumns && Array.isArray(dataColumns)) {
      dataColumns.map((data, index) => {
        if (index % 2 === 0) {
          mentorCardCol1.push(
            <LearnersCard key={index}>
              <div className="div-card-main-sm">
                <div className="img-heading">
                  <div className="div-img-sm">
                    <Avatar
                      className="img-sec"
                      size={32}
                      src={get(data, "media.path", null) || defaultImg}
                    />
                  </div>
                  <div className="container-details-sm">
                    <h5 className="title5 org-name-container-sm">
                      <a
                        className="title5 trim-sm"
                        href={ROUTES.VIEWSUPERMENTOR.replace(":id", get(data, "id"))}
                        target="_blank"
                        rel="noopener noreferrer">
                        {data.full_name}
                      </a>
                      {data.organization && <span className="org-pill-sm trim-sm">{data.organization}</span>}
                    </h5>
                    <span>{data.email}</span>
                  </div>
                </div>
                <div className="right-sec">
                  <div className="status">
                    {data.userMentors ? (
                      <span className="circle dot-green"></span>
                    ) : (
                      <span className="circle dot-gray"></span>
                    )}
                    <p className="text-status">{data.userMentors ? "Yes" : "No"}</p>
                  </div>

                  <ToggleMenu>
                    <Button
                      type="link"
                      onClick={() => {
                        this.props.history.push(
                          ROUTES.VIEWSUPERMENTOR.replace(":id", data.id),
                        );
                      }}>
                      View Details
                    </Button>
                    <Button
                      type="link"
                      onClick={() => {
                        this.props.history.push(
                          ROUTES.VIEWSUPERMENTORDASHBOARD.replace(":id", data.id),
                        );
                      }}>
                      View Dashboard
                    </Button>
                    {permissions.USERS &&
                      permissions.USERS.includes(ACTIONS.UPDATE) && (
                        <>
                          <Button
                            type="link"
                            onClick={() =>
                              this.assignLearnerToggleModal(data.id)
                            }>
                            Assign Learner
                          </Button>
                          <Button
                            type="link"
                            onClick={() =>
                              this.assignMentorToggleModal(data.id)
                            }>
                            Assign Mentor
                          </Button>
                          {data.userMentors && <Button
                            type="link"
                            onClick={() =>
                              this.removeMentorToggleModal(data)
                            }>
                            Remove Mentor
                          </Button>}
                          <Button
                            type="link"
                            onClick={() => {
                              this.props.history.push(
                                ROUTES.EDITSUPERMENTOR.replace(":id", data.id),
                              );
                            }}>
                            Edit Details
                          </Button>
                        </>
                      )}
                    {permissions.USERS &&
                      permissions.USERS.includes(ACTIONS.DELETE) && (
                        <Button
                          className="text-danger"
                          type="link"
                          onClick={() => this.removeToggleModal(data.id)}>
                          Remove
                        </Button>
                      )}
                  </ToggleMenu>
                </div>
              </div>
            </LearnersCard>,
          );
        } else {
          mentorCardCol2.push(
            <LearnersCard key={index}>
              <div className="div-card-main-sm">
                <div className="img-heading">
                  <div className="div-img-sm">
                    <Avatar
                      className="img-sec"
                      size={32}
                      src={get(data, "media.path", null) || defaultImg}
                    />
                  </div>
                  <div className="container-details-sm">
                    <h5 className="title5 org-name-container-sm">
                      <a
                        className="title5 trim-sm"
                        href={ROUTES.VIEWSUPERMENTOR.replace(":id", get(data, "id"))}
                        target="_blank"
                        rel="noopener noreferrer">
                        {data.full_name}
                      </a>
                      {data.organization && <span className="org-pill-sm trim-sm">{data.organization}</span>}
                    </h5>
                    <span>{data.email}</span>
                  </div>
                </div>
                <div className="right-sec">
                  <div className="status">
                    {data.userMentors ? (
                      <span className="circle dot-green"></span>
                    ) : (
                      <span className="circle dot-gray"></span>
                    )}
                    <p className="text-status">{data.userMentors ? "Yes" : "No"}</p>
                  </div>

                  <ToggleMenu>
                    <Button
                      type="link"
                      onClick={() => {
                        this.props.history.push(
                          ROUTES.VIEWSUPERMENTOR.replace(":id", data.id),
                        );
                      }}>
                      View Details
                    </Button>
                    <Button
                      type="link"
                      onClick={() => {
                        this.props.history.push(
                          ROUTES.VIEWSUPERMENTORDASHBOARD.replace(":id", data.id),
                        );
                      }}>
                      View Dashboard
                    </Button>
                    {permissions.USERS &&
                      permissions.USERS.includes(ACTIONS.UPDATE) && (
                        <>
                          <Button
                            type="link"
                            onClick={() =>
                              this.assignLearnerToggleModal(data.id)
                            }>
                            Assign Learner
                          </Button>
                          <Button
                            type="link"
                            onClick={() =>
                              this.assignMentorToggleModal(data.id)
                            }>
                            Assign Mentor
                          </Button>
                          {data.userMentors && <Button
                            type="link"
                            onClick={() =>
                              this.removeMentorToggleModal(data)
                            }>
                            Remove Mentor
                          </Button>}
                          <Button
                            type="link"
                            onClick={() => {
                              this.props.history.push(
                                ROUTES.EDITSUPERMENTOR.replace(":id", data.id),
                              );
                            }}>
                            Edit Details
                          </Button>
                        </>
                      )}
                    {permissions.USERS &&
                      permissions.USERS.includes(ACTIONS.DELETE) && (
                        <Button
                          className="text-danger"
                          type="link"
                          onClick={() => this.removeToggleModal(data.id)}>
                          Remove
                        </Button>
                      )}
                  </ToggleMenu>
                </div>
              </div>
            </LearnersCard>,
          );
        }
        return null;
      });
    }

    return (
      <>
        <PageTitle title="Super Mentors" />
        <div className="mentors-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[{ text: "User" }, { text: "Super Mentor" }]}
              />
              <h4 className="title4"> List of all Super Mentors</h4>
            </div>
            <div className="right-section">
              {/* <ButtonPrimary btnText="Export List" /> */}
              {permissions.USERS && permissions.USERS.includes(ACTIONS.CREATE) && (
                <ButtonSecondary
                  btnText="Add new super mentor"
                  onClick={() => {
                    this.props.history.push(ROUTES.NEWSUPERMENTOR);
                  }}
                />
              )}
            </div>
          </div>

          <div className="find-section mb--20">
            <SelectInput
              defaultValue="All"
              isTitleActive={true}
              title="Mentor(s) Assigned:"
              placeholder="Select"
              options={["All", "Yes", "No"]}
              onChange={(val) => {
                this.setStateValue(val, "isMenteeAssigned");
              }}
            />

            <SearchPrimary
              placeholder="Search Super mentor"
              onSearch={(e) =>
                this.setStateValue(e.target.value, "searchQuery")
              }
            />
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="list-style list-card-style mt--30 border-top-style">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <p className="lead">Super Mentor Name</p>
                    <p className="lead right-panel">Mentor(s) Assigned</p>
                  </div>
                  {mentorCardCol1}
                </Col>
                {mentorCardCol2.length > 0 && (
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <p className="lead">Super Mentor Name</p>
                      <p className="lead right-panel">Mentor(s) Assigned</p>
                    </div>
                    {mentorCardCol2}
                  </Col>
                )}
              </Row>
            </div>
          )}
          {isLoader && <Loader />}
          {assignLearnerShowModal && (
            <AssignStudentModal
              modalTitle="Assign Learner(s):"
              isModalVisible={assignLearnerShowModal}
              onClose={this.assignLearnerToggleModal}
              onClick={this.assignLearners}
            />
          )}
          {assignMentorShowModal && (
            <AssignChildMentorModal
              currentUser={this.state.selected_id}
              modalTitle="Assign mentor(s):"
              isModalVisible={assignMentorShowModal}
              onClose={this.assignMentorToggleModal}
              onClick={this.assignMentors}
            />
          )}
          {removeMentorShowModal && (
            <RemoveChildMentorModal
              currentUser={this.state.selectedUser}
              modalTitle="Remove mentor(s):"
              isModalVisible={removeMentorShowModal}
              onClose={this.removeMentorToggleModal}
              onClick={this.removeMentees}
            />
          )}

          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this mentor"
              description="Are you sure you want to remove this mentor?"
              // Action button event here
              removeHandler={this.removeMentor}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}

          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}

          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                pageSize={meta.limit}
                current={meta.page}
                total={totalMentors}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  getAllSuperMentors,
})(SuperMentorsScreen);
