import React, { Component } from "react";
import { Avatar } from "antd";
import { connect } from "react-redux";
import { get, debounce } from "lodash";
import defaultImg from "../../assets/user-grey.svg";

// Custom Components
import SearchPrimary from "../../components/SearchPrimary";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import PrimaryPagination from "../../components/PrimaryPagination";
import Loader from "../../components/PrimaryLoader";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Helpers
import { breakTimestamp, returnStatusColor, convertCase } from "../../helpers";

//Services
import * as DM from "./dataManager";

//Actions
import { getAllPayments } from "./actions";
import SelectInput from "../../components/SelectInput";

//Constants
import { SEARCHDELAY } from "../../constants";

class Payments extends Component {
  constructor(props) {
    super();
    this.getAllPaymentsData = debounce(
      this.getAllPaymentsData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
      },
      errorModal: null,
      filter: {
        searchQuery: null,
        package_type: null,
      },
    };
  }

  componentDidMount() {
    this.getAllPaymentsData();
  }

  getAllPaymentsData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) this._startLoader();
    const { searchQuery, package_type } = this.state.filter;
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    if (searchQuery) {
      queryObject.username = searchQuery;
    }
    if (package_type && package_type !== "All")
      queryObject.package_type = package_type.toUpperCase();
    DM.getAllPaymentsService(queryObject)
      .then((res) => {
        this.props.getAllPayments(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.filter[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "searchQuery") isLoaderBlocked = true;
      this.getAllPaymentsData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllPaymentsData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  render() {
    const { isLoader, meta, errorModal, filter } = this.state;
    const dataColumns = get(this.props, "payments.allPayments.results", null);
    const totalPayments = get(
      this.props,
      "payments.allPayments.meta.pagination.total",
      0,
    );

    // const dataColumns = [
    //   {
    //     id: "#01",
    //     name: "Sandeep",
    //     type: "Learner",
    //     paymentDate: "06/06/2020",
    //     paymentTime: "11:00 AM",
    //     paymentMethod: "Razorpay",
    //     paymentID: "SRCP89302",
    //     payText: "Starter Package",
    //     amount: "₹00000",
    //   }
    // ];

    return (
      <>
        <PageTitle title="Payments" />
        <div className="payments-container-style">
          <div className="header-style mb--30">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Payments" }]} />
              <h4 className="title4"> List of all Payments</h4>
            </div>
          </div>

          <div className="find-section">
            <SelectInput
              value={get(filter, "package_type")}
              style={{ width: 130 }}
              isTitleActive={true}
              title="Package Type:"
              placeholder="Package Type"
              options={["All", "Starter", "Premium", "Elite"]}
              onChange={(val) => {
                this.setStateValue(val, "package_type");
              }}
            />

            <SearchPrimary
              placeholder="Search Payment"
              onSearch={(e) =>
                this.setStateValue(e.target.value, "searchQuery")
              }
            />
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style mt--30">
              <div className="table-style table-responsive">
                <table className="table-custom-style">
                  <tr>
                    <th>Cust. ID</th>
                    <th>Name</th>
                    <th>Package Type</th>
                    <th>Payment date</th>
                    <th>Payment method</th>
                    <th>Payment ID</th>
                    <th>Status</th>
                    <th>Currency</th>
                    <th>Coupon Code</th>
                    <th>Amount</th>
                  </tr>

                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-payment" key={index}>
                          <td>
                            <p className="lead">
                              {get(data, "orders.user.rawid") || "---"}
                            </p>
                          </td>
                          <td>
                            <div className="image-title-section">
                              <Avatar
                                className="mr--10"
                                size={32}
                                src={
                                  get(data, "orders.user.media.path", null) ||
                                  defaultImg
                                }
                              />
                              {get(data, "orders.user.full_name") || "---"}
                            </div>
                          </td>
                          <td>
                            {/* <p className="lead">{get(data, "orders.user.roles[0].name") || "---"}</p> */}
                            <p className="lead">
                              {convertCase(get(data, "orders.packages.name")) ||
                                "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {breakTimestamp(data.created_at).date || "---"}
                            </p>
                            <p className="lead">
                              {breakTimestamp(data.created_at).time || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {data.payment_method || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "gatewat_payment_reference_id") ||
                                "---"}
                            </p>
                          </td>
                          <td>
                            <div className="status-section">
                              <div className="status-style">
                                <span
                                  className={`circle ${returnStatusColor(
                                    data.status,
                                  )}`}></span>
                                <p className="text-status">
                                  {" "}
                                  {convertCase(get(data, "status"))}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "orders.currency.key") || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "orders.couponcode.code") || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              {get(data, "orders.amount") || "---"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalPayments}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          )}
          {isLoader && <Loader />}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  getAllPayments,
})(Payments);
