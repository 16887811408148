import moment from "moment";
import { getMonthNameFromNumber } from './handleDates'

export const getMonth = (month) => {
    const formattedMonth = moment(month).format('YYYY-MM');
    if (month && month !== '-') {
        return getMonthNameFromNumber(Number(formattedMonth.split('-')[1]), '-')
    }
    return '-';
}

export const setFilters = (month, key) => {
    if (typeof month !== 'string') {
        const formattedMonth = moment(month).format('YYYY-MM');
        month !== null ? localStorage.setItem(key, formattedMonth) : localStorage.removeItem(key);
    }
    else {
        localStorage.setItem(key, month);
    }
}

export const extractMonthAndYear = (date) => {
    const formattedDate = moment(date).format('YYYY-MM');
    const [year, month] = formattedDate.split('-');
    return {
        month: parseInt(month),
        year: parseInt(year)
    }
}

export const getHoursPerMonth = (date, data) => {
    if (!data || !date) {
        return '-'
    }
    const month = extractMonthAndYear(date).month;
    const year = extractMonthAndYear(date).year;
    let sum = 0;
    for (let i = 0; i < data.hourComparision.length; i++) {
        if (data.hourComparision[i].year === year && data.hourComparision[i].month === month) {
            sum += (data.hourComparision[i].mins) / 60;
        }
    }
    return (sum).toFixed(2);
}
