import React, { Component } from "react";
import { Select } from "antd";

import "./styles.scss";

class SelectInput extends Component {
  constructor(props) {
    super();
    this.state = { value: "Select an Option" };
  }

  onChange(value) {
  }

  onBlur() {
  }

  onFocus() {
  }

  onSearch(val) {
  }

  render() {
    const {
      options,
      placeholder,
      title,
      isTitleActive,
      selectContainerStyle,
      mode,
      onChange,
      onSearch,
      selectInputStyle,
      style,
      child,
      // defaultValue,
      value,
      disabled,
      notFoundContent,
      labelInValue,
      showSearch,
      allowClear
    } = this.props;
    return (
      <div
        className={`select-box-style ${selectContainerStyle ? selectContainerStyle : ""
          }`}>
        {isTitleActive && <p className="lead">{title}</p>}
        <Select
          allowClear={allowClear}
          showSearch={showSearch}
          placeholder={placeholder}
          value={value || undefined}
          className={`select-input-style ${selectInputStyle ? selectInputStyle : ""
            }`}
          style={style}
          optionFilterProp="children"
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={disabled}
          labelInValue={labelInValue}
          onSearch={onSearch}
          notFoundContent={notFoundContent}
          mode={mode}>
          {child
            ? child
            : options.map((option) => {
              return (
                <option value={option} key={option}>
                  {option}
                </option>
              );
            })}
        </Select>
        <span className="text-danger">{this.props.errorText}</span>
      </div>
    );
  }
}

export default SelectInput;
