import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  UserOutlined,
  WalletOutlined,
  PhoneOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  DesktopOutlined,
  ReadOutlined,
  ProjectOutlined,
  GatewayOutlined,
  TagOutlined,
  AliwangwangOutlined,
  QuestionCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";

// images
import logo from "../../assets/logo.png";
import companyIcon from "../../assets/favicon.png";
import userImage from "../../assets/user.svg";

// Style
import "./styles.scss";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

const { SubMenu } = Menu;
const { Sider } = Layout;

class PrimarySidebar extends Component {
  render() {


    let admin = get(this.props, "auth.user.data");
    const permissions = get(this.props, "auth.permissions");

    return (
      <Sider width={250} className="sider-section" {...this.props}>
        <div className="info-section">
          <div className="logo-sec">
            <a href={ROUTES.DASHBOARD}>
              <img className="logo-primary" src={logo} alt="logo" />
              <img className="logo-icon" src={companyIcon} alt="icon" />
            </a>
          </div>
          <div className="avatar-section">
            {get(admin, "media.path", null) ?
              <div
                className="img-box"
                style={{
                  backgroundImage: `url(${get(admin, "media.path")})`,
                }}></div>
              :
              <img
                className="img-box"
                src={userImage}
                alt="profile-pic">
              </img>
            }
            <h6 className="title6">{admin.full_name}</h6>
            <div className="text-email">{admin.email}</div>
          </div>
        </div>

        <Menu
          mode="inline"
          // defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          // selectedKeys={[
          //   window.location.pathname
          //     .split("/")
          //     .slice(1, 2)
          //     .join("/")
          //     .toLowerCase(),
          // ]}
          onClick={() => {
            this.props.menuToggle();
          }}
          className="sidebar-menu-style">
          <Menu.Item
            key={ROUTES.DASHBOARD}
            className={`menu-item`}>
            <NavLink to={ROUTES.DASHBOARD}>
              <DesktopOutlined /><span className="menu-name">Dashboard</span>
            </NavLink>
          </Menu.Item>

          {permissions.MODULES &&
            <Menu.Item
              key={ROUTES.MODULES}
              className={`menu-item`}>
              <NavLink to={ROUTES.MODULES}>
                <AppstoreOutlined /> <span className="menu-name">Modules</span>
              </NavLink>
            </Menu.Item>
          }


          {permissions.MODULES &&
            <Menu.Item className="menu-item" key={ROUTES.FEATUREQUESTIONS}>
              <NavLink to={ROUTES.FEATUREQUESTIONS}>
                <QuestionCircleOutlined /> <span className="menu-name">Featured Questions</span>
              </NavLink>
            </Menu.Item>
          }
          {permissions.USERS &&
            < SubMenu
              className="menu-item"
              key="sub2"
              icon={<UserOutlined />}
              title="Users">
              {(permissions.USERS.includes(ACTIONS.READ_LEARNERS) || permissions.USERS.includes(ACTIONS.CREATE) ||
                permissions.USERS.includes(ACTIONS.UPDATE) || permissions.USERS.includes(ACTIONS.DELETE)) &&
                <Menu.Item className="menu-sub-item" key={ROUTES.LEARNERS}>
                  <NavLink to={ROUTES.LEARNERS}>Learners</NavLink>
                </Menu.Item>
              }
              {(permissions.USERS.includes(ACTIONS.READ_MENTORS) || permissions.USERS.includes(ACTIONS.CREATE) ||
                permissions.USERS.includes(ACTIONS.UPDATE) || permissions.USERS.includes(ACTIONS.DELETE)) &&
                <Menu.Item className="menu-sub-item" key={ROUTES.MENTORS}>
                  <NavLink to={ROUTES.MENTORS}>Mentors</NavLink>
                </Menu.Item>
              }
              {(permissions.USERS.includes(ACTIONS.READ_MENTORS) || permissions.USERS.includes(ACTIONS.CREATE) ||
                permissions.USERS.includes(ACTIONS.UPDATE) || permissions.USERS.includes(ACTIONS.DELETE)) &&
                <Menu.Item className="menu-sub-item" key={ROUTES.SUPERMENTORS}>
                  <NavLink to={ROUTES.SUPERMENTORS}>Super Mentors</NavLink>
                </Menu.Item>
              }
              {(permissions.USERS.includes(ACTIONS.READ_ADMINS) || permissions.USERS.includes(ACTIONS.CREATE) ||
                permissions.USERS.includes(ACTIONS.UPDATE) || permissions.USERS.includes(ACTIONS.DELETE)) &&
                <Menu.Item className="menu-sub-item" key={ROUTES.ADMINS}>
                  <NavLink to={ROUTES.ADMINS}>Admins</NavLink>
                </Menu.Item>
              }
            </SubMenu>
          }

          {permissions.COLLECTIONS &&
            <Menu.Item className="menu-item" key={ROUTES.COLLECTIONS}>
              <NavLink to={ROUTES.COLLECTIONS}>
                <WalletOutlined /> <span className="menu-name">Collections</span>
              </NavLink>
            </Menu.Item>
          }

          {permissions.ENQUIRIES &&
            <Menu.Item className="menu-item" key={ROUTES.ENQUIRIES}>
              <NavLink to={ROUTES.ENQUIRIES}>
                <PhoneOutlined /> <span className="menu-name">Enquiries</span>
              </NavLink>
            </Menu.Item>
          }

          {permissions.PAYMENTS &&
            <Menu.Item className="menu-item" key={ROUTES.PAYMENTS}>
              <NavLink to={ROUTES.PAYMENTS}>
                <DollarCircleOutlined /> <span className="menu-name">Payments</span>
              </NavLink>
            </Menu.Item>
          }

          {permissions.ROLES &&
            <Menu.Item className="menu-item" key={ROUTES.ROLE_MANAGEMENT}>
              <NavLink to={ROUTES.ROLE_MANAGEMENT}>
                <UsergroupAddOutlined /> <span className="menu-name">Role Management</span>
              </NavLink>
            </Menu.Item>
          }

          {permissions.CAREERS &&
            <Menu.Item key={ROUTES.CAREERS} className="menu-item">
              <NavLink to={ROUTES.CAREERS}>
                <ReadOutlined /> <span className="menu-name">Careers</span>
              </NavLink>
            </Menu.Item>
          }

          {permissions.CATEGORIES &&
            <SubMenu
              className="menu-item"
              key="sub3"
              icon={<GatewayOutlined />}
              title="Categories">
              <Menu.Item className="menu-sub-item" key="/categories">
                <NavLink to={ROUTES.CATEGORIES}>Categories</NavLink>
              </Menu.Item>
              <Menu.Item className="menu-sub-item" key="/sub-categories">
                <NavLink to={ROUTES.SUBCATEGORIES}>Sub-categories</NavLink>
              </Menu.Item>
            </SubMenu>
          }

          {permissions.TAGS &&
            <SubMenu
              className="menu-item"
              key="sub4"
              icon={<TagOutlined />}
              title="Tags">
              <Menu.Item className="menu-sub-item" key={ROUTES.TAGCATEGORY}>
                <NavLink to={ROUTES.TAGCATEGORY}>Tag Category</NavLink>
              </Menu.Item>
              <Menu.Item className="menu-sub-item" key={ROUTES.TAGS}>
                <NavLink to={ROUTES.TAGS}>Tags</NavLink>
              </Menu.Item>
            </SubMenu>
          }

          {/* <Menu.Item className="menu-item" key="/blog">
            <NavLink to="/blog">
              <AliwangwangOutlined /> Blogs
            </NavLink>
          </Menu.Item> */}

          {permissions.BLOG &&
            <SubMenu
              className="menu-item"
              key="sub5"
              icon={<AliwangwangOutlined />}
              title="Blogs">
              <Menu.Item className="menu-sub-item" key={ROUTES.BLOGCATEGORY}>
                <NavLink to={ROUTES.BLOGCATEGORY}>Blog Category</NavLink>
              </Menu.Item>
              <Menu.Item className="menu-sub-item" key={ROUTES.BLOGS}>
                <NavLink to={ROUTES.BLOGS}>Blogs</NavLink>
              </Menu.Item>
            </SubMenu>
          }

          <Menu.Item
            key={ROUTES.SCHOOLPROJECTS}
            className={`menu-item`}>
            <NavLink to={ROUTES.SCHOOLPROJECTS}>
              <ProjectOutlined /> <span className="menu-name">School Projects</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key={ROUTES.PACKAGE} className="menu-item">
            <NavLink to={ROUTES.PACKAGE}>
              <DollarOutlined /> <span className="menu-name">Packages</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(PrimarySidebar);
