export const getCardFlipModel = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  const defaultModel = defaultType.model;

  return defaultModel.extend(
    {
      defaults: {
        ...defaultModel.prototype.defaults,
        type: "card-flip",
        tagName: "card-flip",
        editable: 1,

        // script() {
        //   var el = this;
        //   el.addEventListener("click", function (event) {
        //     var element = event.currentTarget;

        //     if (element.className === "card") {
        //       if (element.style.transform == "rotateY(180deg)") {
        //         element.style.transform = "rotateY(0deg)";
        //       } else {
        //         element.style.transform = "rotateY(180deg)";
        //       }
        //     }
        //   });
        // },
      },
    },
    {
      /**
       * Detect is passed element is a valid component.
       * In case the element is valid an object abstracted
       * form the element will re returened
       * @param {HTMLElement}
       * @return {object}
       * @private
       */
      isComponent: (el) => el.tagName === "CARD-FLIP",
    },
  );
};
