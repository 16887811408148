import { getCardFlipModel } from "./model";
import { getCardFlipView } from "./view";

export const addCardFlipComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("card-flip", {
    model: getCardFlipModel(editor),
    view: getCardFlipView(editor),
  });
};
