import { getAudioModel } from "./model";
import { getAudioView } from "./view";

export const addAudioComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("audio", {
    model: getAudioModel(editor),
    view: getAudioView(editor),
  });
};
