import { acadruTextBlock } from "./text";
import { acadruImageBlock } from "./image";
import { acadruGalleryBlock } from "./gallery";
import { acadruMultimediaBlock } from "./multimedia";
import { acadruNotesBlock } from "./notes";
import { acadruListBlock } from "./list";
import { acadruDividerBlock } from "./dividers";
import { acadruSpacerBlock } from "./spacer";
import { acadruFlipCardBlock } from "./flipCard";
import { acadruInteractiveBlock } from "./interactive";

export const addBlocks = async (editor) => {
  acadruTextBlock(editor);
  acadruImageBlock(editor);
  acadruGalleryBlock(editor);
  acadruMultimediaBlock(editor);
  acadruNotesBlock(editor);
  acadruListBlock(editor);
  acadruDividerBlock(editor);
  acadruSpacerBlock(editor);
  acadruFlipCardBlock(editor);
  acadruInteractiveBlock(editor);

};
