import { getFileModel } from "./model";
import { getFileView } from "./view";

export const addFileAssetType = (editor) => {
  const AM = editor.AssetManager;

  AM.addType("file", {
    model: getFileModel(editor),
    view: getFileView(editor),
    isType(value) {
      // The condition is intentionally simple
      if (typeof value === "string") {
        return {
          type: "file",
          src: value,
        };
      }
      return value;
    },
  });
};
