export const GrapesJS_CANVAS_Manager = {
  styles: [
    "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
    "https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap",
    "https://use.typekit.net/jsb3pqo.css",
    `${process.env.REACT_APP_DASHBOARD_URL}/editor.css`,
    // "http://localhost:3001/editor.css",

  ],
};
