export const getImageWithLinkView = (editor) => {
    //Get Component Object
    const comps = editor.DomComponents;

    //Get Default Component Object
    var defaultType = comps.getType("default");

    return defaultType.view.extend({
        events: {
            click: "navigate",
        },
        initialize: function (o) {
            defaultType.view.prototype.initialize.apply(this, arguments);
        }
    })
}