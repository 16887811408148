import React, { Component } from "react";
import { get } from 'lodash'
import { connect } from "react-redux";

// Custom Components
import EnquiryDetailsCard from "../../../components/EnquiryDetailsCard";
import PrimaryModal from "../../../components/modal/PrimaryModal";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import InputPrimary from "../../../components/InputPrimary";
import TextareaPrimary from "../../../components/TextareaPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import Loader from "../../../components/PrimaryLoader";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";
// import EmptyState from "../../../components/EmptyState";
import { PageTitle } from "../../../components/PageTitle";

// Style
import "./styles.scss";

//Constants
import { ACTIONS, BREADCRUMBS } from '../../../constants';

//Data Managers
import * as DM from "../dataManager";

//Parsers
import { validate, parseError } from '../parser';

class EnquiriesDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      primaryShowModal: null,
      successShowModal: null,
      errorShowModal: null,
      isLoader: false,
      errObj: null,
      editableEnquiry: null,
    };
  }
  //Life Cycle Methods

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificEnquiryData(editableID);
    }
  }

  //Helper Functions

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };
  primaryToggleModal = () => {
    this.setState({
      primaryShowModal: this.state.primaryShowModal ? null : {}
    });
  };

  //API Calling Function

  getSpecificEnquiryData = async (id) => {
    this._startLoader();
    await DM.getSpecificEnquiriesServies(id)
      .then((res) => {
        this.setState({ editableEnquiry: res });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  };

  reply = () => {
    const editableID = this.props.match.params.id;
    let { primaryShowModal } = this.state;
    const checkValidate = validate(primaryShowModal);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorShowModal: { title: "Validation Error Occured" }
      });
      return;
    }

    let payload = {
      status: "REPLIED",
      reply: get(primaryShowModal, "message")
    }
    this._startLoader();
    DM.patchUpdateEnquiriesService(editableID, payload)
      .then((res) => {
        this._stopLoader();
        this.setState({
          successShowModal: { title: "Success", msg: "Replied successfully." }
        }, () => {
          this.primaryToggleModal();
          this.getSpecificEnquiryData(editableID);
        })
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errObj: parseError(err),
          errorShowModal: { msg: get(err, "response.data.message", "") }
        })
      });
  }

  render() {
    const {
      primaryShowModal,
      isLoader,
      successShowModal,
      errorShowModal,
      editableEnquiry,
      errObj
    } = this.state;
    const permissions = get(this.props, "auth.permissions");

    return (
      <>
        <PageTitle title="Enquiry Details" />
        <div className="admin-details-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.ENQUIRIES,
                  { text: "Enquiry Details" },
                ]}
              />
              <h4 className="title4"> Enquiry Details</h4>
            </div>

            {get(editableEnquiry, "status") === "NOTREPLIED" && permissions.ENQUIRIES && permissions.ENQUIRIES.includes(ACTIONS.UPDATE) &&
              <div className="right-section">
                <ButtonPrimary
                  btnPrimaryStyle="mr--0-imp"
                  btnText="Reply"
                  onClick={this.primaryToggleModal}
                />
              </div>
            }
          </div>

          <div className="mt--30">
            <EnquiryDetailsCard data={editableEnquiry} />
          </div>

          <PrimaryModal isModalVisible={primaryShowModal}>
            <button className="close-modal" onClick={this.primaryToggleModal}>
              X
            </button>
            <div className="input-item">
              <p className="lable">Reply to</p>
              <InputPrimary disabled value={get(editableEnquiry, "email")} />
            </div>

            <div className="input-item mt--20">
              <TextareaPrimary rows={4} placeholder="Message here"
                value={get(primaryShowModal, "message")}
                onChange={(e) => {
                  let state = this.state;
                  state.primaryShowModal["message"] = e.target.value;
                  this.setState(state);
                }}
                errorText={get(errObj, "message")}
              />
            </div>

            <div className="text-right">
              <ButtonPrimary
                btnText="Reply"
                btnPrimaryStyle="mt--20"
                onClick={this.reply}
              />
            </div>
          </PrimaryModal>
          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(EnquiriesDetails);
