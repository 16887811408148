import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./styles.scss";

class ButtonPrimary extends Component {

  render() {
    const { onClick, href, btnText, btnPrimaryStyle, disabled } = this.props;
    return (
      <Link
        className={`btn-primary ${btnPrimaryStyle ? btnPrimaryStyle : ''}`}
        onClick={onClick}
        to={href ? href : '#'}
        disabled={disabled}
      >
        {btnText}
      </Link>
    );
  }
}

export default ButtonPrimary;