import React, { Component } from "react";
import grapesjs from "grapesjs";

import "grapesjs/dist/css/grapes.min.css";

import {
  GrapesJS_Storage_Manager,
  GrapesJS_ASSET_Manager,
  GrapesJS_STYLE_Manager,
  GrapesJS_CANVAS_Manager,
  GrapesJS_BASE_CSS,
  GrapesJS_PROTECTED_CSS,
} from "./config";
import { pluginOpt, plugins } from "./plugins";
import { addAssetTypes, addBlocks, addComponents, addFont } from "./custom";

// import { debounce } from "lodash";
export class GEditorComponent extends Component {
  constructor(props) {
    super();
    this.state = {};
    // this.autoSave = debounce(this.autoSave, 500);
  }

  async componentDidMount() {
    var editor = grapesjs.init({
      container: this.props.container,
      baseCss: GrapesJS_BASE_CSS,
      protectedCss: GrapesJS_PROTECTED_CSS,
      storageManager: GrapesJS_Storage_Manager,
      assetManager: this.configureAssetManager(),
      width: "auto",
      height: "100vh",
      allowScripts: 0,
      fromElement: 1,
      showOffsets: 1,
      components: [],
      plugins: plugins,
      pluginsOpts: pluginOpt,
      canvas: GrapesJS_CANVAS_Manager,
      styleManager: GrapesJS_STYLE_Manager,
    });

    editor.getWrapper().addClass("acadru-editor-html");
    editor.Modal.setTitle("Select File");

    await addBlocks(editor);
    await addFont(editor);
    await addComponents(editor);
    await addAssetTypes(editor);
    await this.addCustomStoragerManager(editor);
    await this.configurePostInitilizationOptions(editor);
  }

  autoSave = async (data) => {
    return await this.props.onSaveButtonPress(data);
  };

  addCustomStoragerManager = async (editor) => {
    const { data } = this.props;

    const autoSave = this.autoSave;
    // Custom Storage Configure
    editor.StorageManager.add("acadruStorage", {
      load(keys, clb, clbErr) {
        if (data) {
          clb(data);
        }
      },
      async store(_data, clb, clbErr) {
        const res = await autoSave(_data);
        if (res) {
          clb(res);
        }
        return _data;
      },
    });

    //Trigger Editor "Load" Event
    editor.on("load", () => {
      const rs = editor.StorageManager.get("acadruStorage");
      rs.load(
        {},
        (res) => {
          editor.setComponents(res["acadru-editor-html"]);
          editor.setStyle(res["acadru-editor-css"]);
        },
        (err) => {},
      );
    });
  };

  configureAssetManager = () => {
    let temp = GrapesJS_ASSET_Manager;
    temp.upload = "<Hello Acadru>";
    temp.uploadName = "File";

    temp.customFetch = async (url, options) => {
      let body = options.body;

      const data = await this.props.tabFileUpload(body.get(temp.uploadName));

      let finalObject = { data: [data.data.path] };
      return finalObject;
    };
    return temp;
  };

  configurePostInitilizationOptions = async (editor) => {
    // editor.Panels.addButton("options", [
    //   {
    //     id: "save-db",
    //     className: "fa fa-floppy-o icon-blank",
    //     command: "save-db",
    //     attributes: { title: "Save DB" },
    //   },
    // ]);

    // editor.Commands.add("save-db", {
    //   run: function (editor, sender) {
    //     sender && sender.set("active", 0); // turn off the button
    //     editor.store();
    //   },
    // });

    // editor.BlockManager.getCategories().each((ctg) => ctg.set("open", false));
    // console.log(editor.BlockManager.getCategories().models);

    // Make private already inserted selectors
    editor.SelectorManager.getAll().each((selector) =>
      selector.set("private", 1),
    );

    // All new selectors will be private
    editor.on("selector:add", (selector) => selector.set("private", 1));
    editor.on("rteToolbarPosUpdate", (pos) => {
      if (pos.top < 0) {
        pos.top = pos.top + -20;
      } else {
        pos.top = pos.top + 20;
      }
    });
  };

  render() {
    return <div id={this.props.container.replace("#", "")}></div>;
  }
}
