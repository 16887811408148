export const acadruImageBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false" ><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" >`;
  var rowCol6End = `</div></div>`;

  var rowCol12Start = `<div class="${classPreFix}-row" data-gjs-droppable="false"><div class="${classPreFix}-column column-12" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol12End = `</div></div>`;
  const imgWithPlayIcon = `<img src=${process.env.REACT_APP_PLAY_BUTTON_ICON_PATH} alt="Play Icon" />`;

  // Image
  blockManager.add("Image", {
    label: "Image",
    category: {
      id: "Acadru Image",
      label: "Acadru Image",
      open: false,
    },
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol6Start}
      <div class="image-with-caption" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" src="/empty-state.svg" alt="image" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false"/>
        <figcaption class='${classPreFix}-imgCaption'  data-gjs-draggable="false"  data-gjs-droppable="false" data-gjs-removable="false" data-gjs-copyable="false"> <strong  data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" >Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    ${rowCol6End}
    `,
  });

  //Image for video
  blockManager.add("ImageForVideo", {
    label: "Image for Video",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol6Start}
    <a class="mb-image-with-caption image-with-caption" data-gjs-type="image-for-video" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="true" data-gjs-selectable="true" title="Click to play!" style="padding: 10px;"> 
    <img class="${classPreFix}-img" data-gjs-selectable="true" data-gjs-droppable="false" src="/empty-state.svg" alt="image" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false"/> 
    <span class="mb-centered-icon">
      <i class="mb-icon-class">${imgWithPlayIcon}</i>
    </span>
  </a>
   ${rowCol6End}`
  })

  // ImageWithLink
  blockManager.add("ImageWithLink", {
    label: "Image with Link",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
      ${rowCol6Start}
       <a class="image-with-caption" data-gjs-type="image-with-link" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="true" title="Click to play!"> 
          <img class='${classPreFix}-img' data-gjs-droppable="false" src="/empty-state.svg" alt="image" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false"/> 
          <figcaption class='${classPreFix}-imgCaption'  data-gjs-draggable="false"  data-gjs-droppable="false" data-gjs-removable="false" data-gjs-copyable="false"> <strong  data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" >Caption :</strong> This is where the caption of the image will come.</figcaption>
        </a>
      ${rowCol6End}
      `,
  });

  // Image without caption
  blockManager.add("ImageWithoutCaption", {
    label: "Image without Caption",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol6Start}
      <div class="image-with-caption" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' data-gjs-droppable="false" src="/empty-state.svg" alt="image" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false"/>
      </div>
    ${rowCol6End}
    `,
  });

  // Vertical Image
  blockManager.add("VerticalImage", {
    label: "Vertical Image",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol6Start}
      <div class="vertical-image" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-img' style="height: 100%; min-height: 650px;" data-gjs-droppable="false" src="/empty-state.svg" alt="image" data-gjs-draggable="false" data-gjs-removable="false"  data-gjs-copyable="false"/>
        <figcaption class='${classPreFix}-imgCaption'  data-gjs-draggable="false"  data-gjs-droppable="false" data-gjs-removable="false" data-gjs-copyable="false"> <strong  data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" >Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    ${rowCol6End}
    `,
  });

  // Edge to Edge Image
  blockManager.add("EdgeToEdgeImage", {
    label: "Full Width Image",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol12Start}
      <div class="edge2edge-box" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <img class='${classPreFix}-e2e-img' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false" src="/empty-state.svg" alt="image"  />
        <figcaption class='${classPreFix}-imgCaption' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
      </div>
    ${rowCol12End}
    `,
  });

  // Image on left and text on right.
  blockManager.add("ImageWithText", {
    label: "Image with Text",
    category: "Acadru Image",
    attributes: { class: "fa fa-file-image-o" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-two-column-layout imageWithText" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" src="/empty-state.svg" alt="image" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" />
          <figcaption class='${classPreFix}-imgCaption' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false"> <strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
        </div>
        <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <h3 class="${classPreFix}-h3" data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Subheading 2</h3>
        <p class="${classPreFix}-description" data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
      </div>
    ${rowCol6End}
    `,
  });

  // textOverImage
  blockManager.add("textOverImage", {
    label: "Text over Image",
    category: "Acadru Image",
    attributes: { class: "fa fa-window-restore" },
    content: `
    ${rowCol6Start}
      <div class='${classPreFix}-textOverImage' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class='${classPreFix}-outer-box' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
          <div class='${classPreFix}-content-over-img' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            <div class='${classPreFix}-content-box' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
              <div class='${classPreFix}-horizontal-seperator' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"></div>
              <p class='${classPreFix}-description' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
          </div>

          <div class='${classPreFix}-img-box' data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
            <img class='${classPreFix}-img' data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"  src="https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt="image">
            </div>
            <figcaption class='${classPreFix}-imgCaption' data-gjs-copyable="false" data-gjs-droppable="false" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"><strong data-gjs-droppable="false" data-gjs-copyable="false" data-gjs-draggable="false" data-gjs-removable="false">Caption :</strong> This is where the caption of the image will come.</figcaption>
          </div>
        </div>
      </div>
    ${rowCol6End}
    `,
  });
};
