export const getImageForVideoView = (editor) => {
    //Get Component Object
    const comps = editor.DomComponents;

    //Get Default Component Object
    var defaultType = comps.getType("default");

    return defaultType.view.extend({
        events: {
            initialize: function (o) {
                defaultType.view.prototype.initialize.apply(this, arguments);
            },
            dblclick: function () {
                this.doStuff();
            }
        },
        doStuff() {
            this.opts.config.em.get("Editor").runCommand("open-assets", {
                target: this.model,
                types: ["image"],
                accept: "image/*",
            });
        },
    })
}