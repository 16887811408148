export const SET_ALL_MODULES = "SET_ALL_MODULES";
export const SAVE_NEW_MODULE = "SAVE_NEW_MODULE";
export const CLEAR_NEW_MODULE = "CLEAR_NEW_MODULE";
export const EDIT_MODULE = "EDIT_MODULE";
export const CLEAR_EDIT_MODULE = "CLEAR_EDIT_MODULE";
export const NEW_MINI_PROJECT = "NEW_MINI_PROJECT";
export const NEW_SKILL_BUILDING_PROJECT = "NEW_SKILL_BUILDING_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const CLEAR_NEW_MINI_PROJECT = "CLEAR_NEW_MINI_PROJECT";
export const CLEAR_NEW_SKILL_BUILDING_PROJECT = "CLEAR_NEW_SKILL_BUILDING_PROJECT";
export const CLEAR_EDIT_PROJECT = "CLEAR_EDIT_PROJECT";
