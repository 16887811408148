import React, { Component } from 'react';

// images
import emptyImg from '../../assets/empty.svg';

// Style
import "./styles.scss";

class EmptyState extends Component {
  render() {
    const { title, description } = this.props;

    return (
      <div className="empty-state-style">
        <img src={emptyImg} className="img-style" alt="empty_image" />
        <h3 className="title3">{title}</h3>
        <p className="lead">{description}</p>
      </div>
    );
  }
}

export default EmptyState;