import { TYPES } from "../../containers/SchoolProject/Constants"

export default (state = {}, action) => {
    switch (action.type) {
        case TYPES.SET_ALL_SCHOOL_PROJECTS:
            return {
                ...state,
                allSchoolProjects: action.payload,
                error: {},
            };

        default:
            return state;
    }
};