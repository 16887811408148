import React, { Component } from 'react';
import { Modal, Row, Col } from "antd";
import "./styles.scss";
import InputPrimary from '../../InputPrimary';
import ButtonPrimary from '../../buttons/ButtonPrimary';

// import loadingImg from "../../../assets/loading.svg";
// import thumbWaveImg from "../../../assets/thumb-with-waves.svg";
import { get } from 'lodash';

class ChangePasswordModal extends Component {

  constructor(props) {
    super();
    this.state = {
    };
  }

  setStateValue = (value, field) => {
    this.props.setModalValue(value, field);
  }

  render() {
    const { isModalVisible, onCancel, errObj, onSubmit } = this.props;
    return (
      <>
        <Modal
          visible={isModalVisible}
          centered
          footer={null}
          className="changePassword-modal-style"
          onCancel={onCancel}
        >
          < div className="changePassword-content">
            <div className="body-content">
              <form className="form form-section">
                <div className="heading-content text-center">
                  <h3 className="title3">Change Password</h3>
                </div>
                <Row className="row" gutter={30}>
                  <Col className="column gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="input-item">
                      <p className="lable">Old Password <span class="required-text">*</span></p>
                      <InputPrimary
                        type="password"
                        placeholder="Old password"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "old_password")
                        }}
                        errorText={get(errObj, "old_password")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="row" gutter={30}>
                  <Col className="column gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="input-item">
                      <p className="lable">New Password <span class="required-text">*</span></p>
                      <InputPrimary
                        type="password"
                        placeholder="New password"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "password")
                        }}
                        errorText={get(errObj, "password")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="row" gutter={30}>
                  <Col className="column gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="input-item">
                      <p className="lable">Confirm Password <span class="required-text">*</span></p>
                      <InputPrimary
                        type="password"
                        placeholder="Confirm password"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "password_confirmation")
                        }}
                        errorText={get(errObj, "password_confirmation")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="row" gutter={30}>
                  <Col className="column gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="input-item">
                      <div className="change-btn-box text-center">
                        <ButtonPrimary btnText="Change" onClick={onSubmit} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>

          {/* <div className="password-change-acknowledge align-center">
            <div className="img-box">
              <img className="thumb-img" src={thumbWaveImg} alt="icon" />
            </div>
            <div className="acknowledge-message">Your password has been changed!</div>
          </div> */}

        </Modal>
      </>
    );
  }
}

export default ChangePasswordModal;