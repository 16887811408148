import { get, debounce } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Row, Col } from "antd";

// Custom Components
import { PageTitle } from "../../components/PageTitle";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import LearnersCard from "../../components/LearnersCard";
import AssignMentorModal from "../../components/modal/AssignMentorModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import Loader from "../../components/PrimaryLoader";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import EmptyState from "../../components/EmptyState";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";

//Actions
import { setAllCareers } from "./actions";

//Data Managers
import * as CDM from "../../common/dataManager";
import * as DM from "./dataManager";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../constants";
import SearchPrimary from "../../components/SearchPrimary";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";
import { convertCase } from "../../helpers";

class CareerNew extends Component {
  constructor(props) {
    super();
    this.getALLCareersData = debounce(
      this.getALLCareersData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      assignMentorShowModal: false,
      successShowModal: null,
      errorModal: null,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
        order_by: "DESC",
        keyword: null,
      },
      selected_id: "",
      searchQuery: null,
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    await this.getALLCareersData();
  }

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    state.meta[`${field}`] = value;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "keyword") isLoaderBlocked = true;
      this.getALLCareersData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getALLCareersData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  assignMentorToggleModal = () => {
    this.setState({
      assignMentorShowModal: !this.state.assignMentorShowModal,
    });
  };

  //API Calling Fucntions

  getALLCareersData = async (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { page, limit, order_by, keyword } = this.state.meta;
    let param = {
      page,
      limit,
      order_by,
    };
    if (keyword) {
      param["keyword"] = keyword;
      param["page"] = 1;
    }
    await CDM.getAllCareerService(param)
      .then((res) => {
        this.props.setAllCareers(res.data);

        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  removeCareer = () => {
    this._startLoader();
    let id = this.state.selected_id;
    DM.deleteCareerService(id)
      .then((res) => {
        this._stopLoader();
        this.getALLCareersData();
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      assignMentorShowModal,
      isLoader,
      successShowModal,
      errorModal,
      meta,
    } = this.state;
    let careersCardCol1 = [];
    let careersCardCol2 = [];

    const permissions = get(this.props, "auth.permissions");
    const isClassActive =
      permissions.CAREERS &&
      !(
        permissions.CAREERS.includes(ACTIONS.UPDATE) ||
        permissions.CAREERS.includes(ACTIONS.DELETE)
      );
    const metaData = get(this.props, "careers.careers.meta", null);
    const dataColumns = get(this.props, "careers.careers.results", null);

    if (dataColumns && Array.isArray(dataColumns)) {
      dataColumns.map((val, index) => {
        if (index % 2 === 0) {
          careersCardCol1.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(val, "name")}</h5>
                </div>
              </div>
              <div
                className={`right-box fx fx--ai-c ${
                  isClassActive ? "fx--jc-fe-imp" : ""
                }`}>
                <div className="status">
                  <span
                    className={`circle ${ReturnStatusColor(
                      val.status,
                    )} mr-2`}></span>
                  <p className="text-status">
                    {" "}
                    {convertCase(get(val, "status"))}
                  </p>
                </div>
                <div className="right-sec">
                  {permissions.CAREERS &&
                    permissions.CAREERS.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() =>
                          this.props.history.push(
                            ROUTES.EDITCAREERS.replace(":id", get(val, "id")),
                          )
                        }
                      />
                    )}
                  {permissions.CAREERS &&
                    permissions.CAREERS.includes(ACTIONS.DELETE) && (
                      <ButtonDanger
                        type="link"
                        btnText={<DeleteOutlined />}
                        btnDangerStyle="btn delete btn-link"
                        onClick={() => this.removeToggleModal(get(val, "id"))}>
                        Remove
                      </ButtonDanger>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        } else {
          careersCardCol2.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(val, "name")}</h5>
                </div>
              </div>
              <div
                className={`right-box fx fx--ai-c ${
                  isClassActive ? "fx--jc-fe-imp" : ""
                }`}>
                <div className="status">
                  <span
                    className={`circle ${ReturnStatusColor(
                      val.status,
                    )} mr-2`}></span>
                  <p className="text-status">
                    {" "}
                    {convertCase(get(val, "status"))}
                  </p>
                </div>
                <div className="right-sec">
                  {permissions.CAREERS &&
                    permissions.CAREERS.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() =>
                          this.props.history.push(
                            ROUTES.EDITCAREERS.replace(":id", get(val, "id")),
                          )
                        }
                      />
                    )}
                  {permissions.CAREERS &&
                    permissions.CAREERS.includes(ACTIONS.DELETE) && (
                      <ButtonDanger
                        type="link"
                        btnText={<DeleteOutlined />}
                        btnDangerStyle="btn delete btn-link"
                        onClick={() => this.removeToggleModal(get(val, "id"))}>
                        Remove
                      </ButtonDanger>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        }
        return null;
      });
    }
    return (
      <>
        <PageTitle title="Careers" />

        <div className="careers-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Careers" }]} />
              <h4 className="title4"> List of all Careers</h4>
            </div>
            <div className="right-section">
              {permissions.CAREERS &&
                permissions.CAREERS.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new careers"
                    href={ROUTES.NEWCAREERES}
                  />
                )}
            </div>
          </div>

          <div className="find-section">
            <SearchPrimary
              placeholder="Search"
              onSearch={(e) => this.setStateValue(e.target.value, "keyword")}
            />
          </div>
          {dataColumns && dataColumns.length > 0 ? (
            <div className="list-style list-card-style mt--30">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <div className="left-box">
                      <p className="lead">Name</p>
                    </div>
                    <div
                      className={`right-box fx fx--ai-c ${
                        isClassActive ? "fx--jc-fe-imp mr--50" : ""
                      }`}>
                      <p className="lead">Status</p>
                      {isClassActive || (
                        <p className="lead right-panel-action">Action</p>
                      )}
                    </div>
                  </div>
                  {careersCardCol1}
                </Col>

                {careersCardCol2.length > 0 && (
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <div className="left-box">
                        <p className="lead">Name</p>
                      </div>
                      <div
                        className={`right-box fx fx--ai-c ${
                          isClassActive ? "fx--jc-fe-imp mr--50" : ""
                        }`}>
                        <p className="lead">Status</p>
                        {permissions.CAREERS &&
                          (permissions.CAREERS.includes(ACTIONS.UPDATE) ||
                            permissions.CAREERS.includes(ACTIONS.DELETE)) && (
                            <p className="lead right-panel-action">Action</p>
                          )}
                      </div>
                    </div>

                    {careersCardCol2}
                  </Col>
                )}
              </Row>
            </div>
          ) : (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          )}

          <AssignMentorModal
            modalTitle="Assign a mentor"
            isModalVisible={assignMentorShowModal}
            onClose={this.assignMentorToggleModal}
          />

          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this career"
            description="Are you sure you want to remove this career?"
            // Action button event here
            removeHandler={this.removeCareer}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                current={meta.page}
                total={get(metaData, "pagination.total")}
                pageSize={meta.limit}
                onChange={this.handlePageChange}
              />
            </div>
          )}

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};
export default connect(mapStateToProps, {
  setAllCareers,
})(CareerNew);
