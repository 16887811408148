import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { extractMonthAndYear } from "../../helpers/monthComparision";
import * as DM from "./dataManager";
import { get } from "lodash";
import "./styles.scss";
import PieChart from "../../components/PieChart";
import LineChart from "../../components/LineChart";
import { API } from "../Users/Constants";

class SuperMentorDashboardMail extends Component {
  constructor(props) {
    super();
    const firstMoment = moment().subtract(1, "M");
    const firstMonth = firstMoment.month() + 1;
    const firstYear = firstMoment.year();

    const secondMoment = moment().subtract(2, "M");
    const secondMonth = secondMoment.month() + 1;
    const secondYear = secondMoment.year();

    const thirdMoment = moment().subtract(3, "M");
    const thirdMonth = thirdMoment.month() + 1;
    const thirdYear = thirdMoment.year();

    this.state = {
      data: null,
      isLoader: false,
      firstMonth,
      secondMonth,
      thirdMonth,
      firstYear,
      secondYear,
      thirdYear,
    };
  }

  componentDidMount() {
    this.getSuperMentorDashboardData();
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  getSuperMentorDashboardData = (spinLoader = true) => {
    const superMentorId = this.props.match.params.id;
    spinLoader
      ? this._startLoader()
      : this.setState({ isComparisionSpinner: true });

    const requestData = {
      id: superMentorId,
      month: this.state.firstMonth,
      year: this.state.firstYear,
      comparisionByMonth: [
        { month: this.state.firstMonth, year: this.state.firstYear },
        { month: this.state.secondMonth, year: this.state.secondYear },
        { month: this.state.thirdMonth, year: this.state.thirdYear },
      ],
    };

    DM.getSuperMentorDashboardService(requestData).then((res) => {
      this.setState({ data: res.data });
      spinLoader
        ? this._stopLoader()
        : this.setState({ isComparisionSpinner: false });
    }).catch((error) => {
      console.log(error);
    })
  };

  totalModuleCount() {
    const { data } = this.state;
    if (!data || !data.categories) {
      return 0;
    }
    const count = data.categories.map((category) => category.count);
    let sum = 0;
    for (let i = 0; i < count.length; i++) {
      sum += count[i];
    }
    return sum;
  }

  renderMostTimeTableRows() {
    const { data } = this.state;
    if (!data || !data.timeBasedStudents) {
      return null;
    }
    return data.timeBasedStudents.map((timeBasedStudents, index) => (
      <div className="leaderboard" key={timeBasedStudents.user_uuid}>
        <div className="index">{index + 1}</div>
        <div className="name">
          <div
            className="table-name"
            title={
              timeBasedStudents.user_first_name +
              " " +
              timeBasedStudents.user_last_name
            }>
            <a
              href={API.REDIRECT_TO_LEARNER.replace(
                ":learnerId",
                timeBasedStudents.user_uuid,
              )}
              style={{ color: "black" }}>
              {timeBasedStudents.user_first_name +
                " " +
                timeBasedStudents.user_last_name}
            </a>
          </div>
        </div>
        <div className="score">{(timeBasedStudents.mins / 60).toFixed(2)}</div>
      </div>
    ));
  }

  renderMostAnswersTableRows() {
    const { data } = this.state;
    if (!data || !data.reflectionBasedStudents) {
      return null;
    }
    return data.reflectionBasedStudents.map(
      (reflectionBasedStudents, index) => (
        <div className="leaderboard" key={reflectionBasedStudents.user_uuid}>
          <div className="index">{index + 1}</div>
          <div className="name">
            <div
              className="table-name"
              title={
                reflectionBasedStudents.user_first_name +
                " " +
                reflectionBasedStudents.user_last_name
              }>
              <a
                href={API.REDIRECT_TO_LEARNER.replace(
                  ":learnerId",
                  reflectionBasedStudents.user_uuid,
                )}
                style={{ color: "black" }}>
                {reflectionBasedStudents.user_first_name +
                  " " +
                  reflectionBasedStudents.user_last_name}
              </a>
            </div>
          </div>
          <div className="score">
            {reflectionBasedStudents.questions_answered}
          </div>
        </div>
      ),
    );
  }

  render() {
    const {
      data,
      firstMonth,
      secondMonth,
      thirdMonth,
      firstYear,
      secondYear,
      thirdYear,
    } = this.state;
    return (
      <div className="super-mentor-dashboard-mail">
        <div className="count-components">
          <div className="inner-count">
            <div>
              <h3 className="text-color">Total number of learners active</h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeStudents[0].usersActive") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                Total number of hours spent by learners
              </h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeStudents[0].totalTimeSpent") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                Average number of hours spent by learners
              </h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeStudents[0].averageHoursSpent") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">Total number of mentors active</h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeMentors[0].usersActive") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                Total number of hours spent by mentors
              </h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeMentors[0].totalTimeSpent") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                Average number of hours spent by mentors
              </h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "activeMentors[0].averageHoursSpent") || 0}
              </h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                {" "}
                Total number of projects submitted by students
              </h3>
            </div>
            <div>
              <h3 className="number">{get(data, "projectCount") || 0}</h3>
            </div>
          </div>

          <div className="inner-count">
            <div>
              <h3 className="text-color">
                {" "}
                Total number of reflection questions answered by students
              </h3>
            </div>
            <div>
              <h3 className="number">
                {get(data, "reflectionAnswerCount") || 0}
              </h3>
            </div>
          </div>
        </div>

        {data && data?.categories?.length > 0 ? (
          <div className="module-data">
            <div className="chart-container">
              <PieChart data={get(data, "categories")} />
            </div>
            <div className="module-text">
              Total number of modules viewed by the learners:{" "}
              {this.totalModuleCount()}
            </div>
          </div>
        ) : (
          ""
        )}

        {data && data?.hourComparision?.length > 0 ? (
          <div className="module-data">
            <div>
              <LineChart
                data={get(data, "hourComparision")}
                month1={{ month: firstMonth, year: firstYear }}
                month2={{ month: secondMonth, year: secondYear }}
                month3={{ month: thirdMonth, year: thirdYear }}
              />
            </div>
            <div className="module-text">
              Total hours spent each day by the learners in a month
            </div>
          </div>
        ) : (
          ""
        )}

        {data && data?.timeBasedStudents?.length > 0 ? (
          <div className="max-time">
            <div className="top-performers">
              <div className="leaderboard-text">
                Top learners who spent the most time on the portal (in hrs)
              </div>
              <div className="time-based-top-performers">
                <div>{this.renderMostTimeTableRows().splice(0, 5)}</div>
                <div>{this.renderMostTimeTableRows().splice(5, 10)}</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {data && data?.reflectionBasedStudents?.length > 0 ? (
          <div className="max-time">
            <div className="top-performers">
              <div className="leaderboard-text">
                Top learners who answered maximum reflection questions
              </div>
              <div className="time-based-top-performers">
                <div>{this.renderMostAnswersTableRows().splice(0, 5)}</div>
                <div>{this.renderMostAnswersTableRows().splice(5, 10)}</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {})(SuperMentorDashboardMail);
