import React, { Component } from 'react';
import "./styles.scss";
import { Modal, Col, Row } from "antd";
import { get } from 'lodash';

import InputPrimary from "../../InputPrimary";
import { GEditorComponent } from "../../../utils";
import ButtonPrimary from '../../buttons/ButtonPrimary';
import ButtonDanger from '../../buttons/ButtonDanger';

class ModuleProjectModal extends Component {
  constructor(props) {
    super();
    this.editor = GEditorComponent;
  }

  componentDidMount() {
  }

  handleSave = async () => {
    // await this.props.onClose();
    await this.props.handleSave();
  }

  render() {
    const { isModalVisible, onClose, containerId, errorObj, tabFileUpload, onSaveTabsContent, title, content } = this.props;
    return (
      <>
        <div className="project-modal">
          <Modal
            visible={isModalVisible}
            onClose={onClose}
            footer={null}
            className="add-project-modal-style"
            onCancel={onClose}
          >
            <div className="project-modal-style">
              <Row gutter={[40]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="input-item">
                    <p className="lable">Project Title <span class="required-text">*</span></p>
                    <InputPrimary
                      value={title}
                      placeholder="Enter project title"
                      onChange={(e) => {
                        onSaveTabsContent({ name: e.target.value })
                      }}
                      errorText={get(errorObj, "name")}
                    />
                  </div>
                </Col>
              </Row>

              {/* <GEditorComponent /> */}

              <div className="mt--40 editor-section">
                <this.editor
                  tabFileUpload={(file) => tabFileUpload(file)}
                  onSaveButtonPress={(data) => onSaveTabsContent({ content: data })}
                  data={content}
                  container={containerId}
                />
              </div>

              <div className="mt--40 footer-btn-section">
                <ButtonDanger onClick={onClose} btnText="Close" btnDangerStyle="mr--20" />
                <ButtonPrimary btnText="Save" onClick={this.handleSave} />
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default ModuleProjectModal;