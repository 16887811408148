import * as SERVICES from "./services";

export const postCreateSubCategoryService = async (category_id, payload) => {
    return await SERVICES.createSubCategory(category_id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};


export const getSubCategoryService = async (category_id, id) => {
    return await SERVICES.getSubCategory(category_id, id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdateSubCategoryService = async (category_id, id, payload) => {
    return await SERVICES.updateSubCategory(category_id, id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteSubCategoryService = async (category_id, id) => {
    return await SERVICES.deleteSubCategory(category_id, id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

