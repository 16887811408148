import React, { Component } from "react";
import { connect } from "react-redux";
import { get, debounce } from "lodash";

// Custom Components
// import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import ButtonSecondary from "../../../components/buttons/ButtonSecondary";
import ButtonOutline from "../../../components/buttons/ButtonOutline";
import SearchPrimary from "../../../components/SearchPrimary";
import RemoveModal from "../../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import PrimaryPagination from "../../../components/PrimaryPagination";
import Loader from "../../../components/PrimaryLoader";
import EmptyState from "../../../components/EmptyState";
import ErrorModal from "../../../components/modal/ErrorModal";
import SuccessModal from "../../../components/modal/SuccessModal";
import ButtonDanger from "../../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";

// Style
import "./styles.scss";
import { Space } from "antd";
import { PageTitle } from "../../../components/PageTitle";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../../constants";

//Services
import * as DM from "../dataManager";

//Actions
import { getAllAdmins } from "../actions";

class AdminScreen extends Component {
  constructor(props) {
    super();
    this.getAllAdminsData = debounce(
      this.getAllAdminsData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
      },
      errorModal: null,
      succModal: null,
      searchQuery: null,
      selected_id: "",
    };
  }

  componentDidMount() {
    this.getAllAdminsData();
  }

  getAllAdminsData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { searchQuery } = this.state;
    const { page, limit } = this.state.meta;
    const queryObject = {
      type: "ADMIN",
      status: "ACTIVE",
      limit: limit,
      page: page,
    };
    if (searchQuery) {
      queryObject.keyword = searchQuery;
    }
    DM.getAllUsersService(queryObject)
      .then((res) => {
        this.props.getAllAdmins(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handleSearch = (value) => {
    let state = this.state;
    state.searchQuery = value;
    state.meta.page = 1;
    this.setState(state, () => {
      this.getAllAdminsData(true);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllAdminsData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  removeAdmin = () => {
    this._startLoader();
    let admin = this.state.selected_id;
    DM.removeUserService(admin, { status: "INACTIVE" })
      .then((res) => {
        this._stopLoader();
        this.getAllAdminsData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Admin removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "users.allAdmins.results", null);
    const totalAdmins = get(
      this.props,
      "users.allAdmins.meta.pagination.total",
      0,
    );

    return (
      <>
        <PageTitle title="Admin" />
        <div className="admin-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[{ text: "User" }, { text: "Admins" }]}
              />
              <h4 className="title4"> List of all admins</h4>
            </div>
            <div className="right-section">
              {/* <ButtonPrimary btnText="Export List" /> */}
              {permissions.USERS && permissions.USERS.includes(ACTIONS.CREATE) && (
                <ButtonSecondary
                  btnText="Add new Admin"
                  onClick={() => {
                    this.props.history.push(ROUTES.NEWADMIN);
                  }}
                />
              )}
            </div>
          </div>

          <div className="find-section mb--20">
            <SearchPrimary
              placeholder="Search by admin name"
              onSearch={(e) => this.handleSearch(e.target.value)}
            />
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style">
              <div className="table-style">
                <table className="table-custom-style">
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Address </th>
                    <th>Phone Number</th>
                    <th>Actions</th>
                  </tr>

                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-admin" key={index}>
                          <td>
                            <p className="lead">
                              <a
                                className="lead link-text"
                                href={ROUTES.VIEWADMIN.replace(
                                  ":id",
                                  get(data, "id"),
                                )}
                                target="_blank"
                                rel="noopener noreferrer">
                                {data.first_name || "---"}
                              </a>
                            </p>
                          </td>
                          <td>
                            <p className="lead">
                              <a
                                className="lead link-text"
                                href={ROUTES.VIEWADMIN.replace(
                                  ":id",
                                  get(data, "id"),
                                )}
                                target="_blank"
                                rel="noopener noreferrer">
                                {data.last_name || "---"}
                              </a>
                            </p>
                          </td>
                          <td>
                            <p className="lead">{data.email || "---"}</p>
                          </td>
                          <td>
                            <p className="lead">{data.phone || "---"}</p>
                          </td>
                          <td>
                            <Space size="middle">
                              <ButtonPrimary
                                btnText={<EyeOutlined />}
                                btnPrimaryStyle="btn view square-view"
                                onClick={() =>
                                  this.props.history.push(
                                    ROUTES.VIEWADMIN.replace(":id", data.id),
                                  )
                                }
                              />
                              {permissions.USERS &&
                                permissions.USERS.includes(ACTIONS.UPDATE) && (
                                  <ButtonOutline
                                    btnText={<EditOutlined />}
                                    // btnStyle="btn-sm"
                                    btnStyle="btn edit square-edit"
                                    clickHandler={() =>
                                      this.props.history.push(
                                        ROUTES.EDITADMIN.replace(
                                          ":id",
                                          data.id,
                                        ),
                                      )
                                    }
                                  />
                                )}
                              {permissions.USERS &&
                                permissions.USERS.includes(ACTIONS.DELETE) && (
                                  <ButtonDanger
                                    type="link"
                                    btnText={<DeleteOutlined />}
                                    btnDangerStyle="btn delete btn-link"
                                    onClick={() =>
                                      this.removeToggleModal(data.id)
                                    }>
                                    Remove
                                  </ButtonDanger>
                                )}
                            </Space>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalAdmins}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          )}

          {isLoader && <Loader />}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this admin"
              description="Are you sure you want to remove this admin?"
              // Action button event here
              removeHandler={this.removeAdmin}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}

          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  getAllAdmins,
})(AdminScreen);
