import { get, debounce } from "lodash";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import React, { Component } from "react";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import SelectInput from "../../components/SelectInput";
import SearchPrimary from "../../components/SearchPrimary";
import CollectionCard from "../../components/CollectionCard";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../components/PageTitle";
import Loader from "../../components/PrimaryLoader";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import EmptyState from "../../components/EmptyState";

// Style
import "./styles.scss";

//Data Managers
import * as DM from "./dataManager";

//Actions
import { setAllCollections } from "./action";

//Constants
import { ROUTES, SEARCHDELAY, ACTIONS } from "../../constants";

class CollectionsScreen extends Component {
  constructor(props) {
    super();
    this.getALLCollectionData = debounce(
      this.getALLCollectionData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
      },
      keyword: null,
      order_by: "DESC",
      collectionIdForRemove: null,
      successShowModal: null,
      errorShowModal: null,
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    await this.getALLCollectionData();
  }

  //API Calling Fucntions

  getALLCollectionData = (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { meta, keyword, order_by } = this.state;
    let params = {
      include: "modules",
      page: meta.page,
      limit: meta.limit,
      order_by,
    };
    if (keyword) {
      params["keyword"] = keyword;
      params["page"] = 1;
    }

    DM.getAllCollectionServices(params)
      .then((res) => {
        this.props.setAllCollections(res.data);

        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handleRemoveCollection = async () => {
    const { collectionIdForRemove } = this.state;
    this._startLoader();

    await DM.deleteCollectionServies(collectionIdForRemove)
      .then((res) => {
        this.setState({
          successShowModal: {
            msg: "Collection deleted",
          },
        });
        this.removeToggleModal();
        this._stopLoader();
        this.getALLCollectionData();
      })
      .catch((err) => {
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.error.id[0]"),
          },
        });
        this.removeToggleModal();
        this._stopLoader();
      });
  };

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    if (field === "order_by") value = value === "Newest" ? "DESC" : "ASC";
    state[`${field}`] = value;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "keyword") isLoaderBlocked = true;
      this.getALLCollectionData(isLoaderBlocked);
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = () => {
    this.setState({
      removeShowModal: false,
      collectionIdForRemove: null,
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllCategoriesData();
    });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      successShowModal,
      errorShowModal,
      meta,
      order_by,
      // keyword
    } = this.state;
    let collectionCardCol1 = [];
    let collectionCardCol2 = [];

    const dataColumns = get(
      this.props,
      "collections.collections.results",
      null,
    );
    const totalCollections = get(
      this.props,
      "collections.collections.meta.pagination.total",
      null,
    );
    const permissions = get(this.props, "auth.permissions");
    const isClassActive =
      permissions.COLLECTIONS &&
      !(
        permissions.COLLECTIONS.includes(ACTIONS.UPDATE) ||
        permissions.COLLECTIONS.includes(ACTIONS.DELETE)
      );

    if (dataColumns && Array.isArray(dataColumns)) {
      dataColumns.map((val, index) => {
        if (index % 2 === 0) {
          collectionCardCol1.push(
            <CollectionCard
              isClassActive={isClassActive}
              permissions={permissions}
              key={index}
              name={val.name}
              dotText={val.status === "INACTIVE" ? "Inactive" : "Active"}
              dotStyle={val.status === "INACTIVE" ? "dot-gray" : "dot-green"}
              onClick={() => {
                this.setState({
                  removeShowModal: true,
                  collectionIdForRemove: get(val, "id"),
                });
              }}
              clickHandler={() =>
                this.props.history.push(
                  ROUTES.EDITCOLLECTION.replace(":id", val.id),
                )
              }
            />,
          );
        } else {
          collectionCardCol2.push(
            <CollectionCard
              isClassActive={isClassActive}
              permissions={permissions}
              key={index}
              name={val.name}
              dotText={val.status === "INACTIVE" ? "Inactive" : "Active"}
              dotStyle={val.status === "INACTIVE" ? "dot-gray" : "dot-green"}
              onClick={() => {
                this.setState({
                  removeShowModal: true,
                  collectionIdForRemove: get(val, "id"),
                });
              }}
              clickHandler={() =>
                this.props.history.push(
                  ROUTES.EDITCOLLECTION.replace(":id", val.id),
                )
              }
            />,
          );
        }
        return null;
      });
    }

    return (
      <>
        <PageTitle title="Collections" />

        <div className="collection-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Collection" }]} />
              <h4 className="title4"> List of all Collections</h4>
            </div>
            <div className="right-section">
              {permissions.COLLECTIONS &&
                permissions.COLLECTIONS.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new collection"
                    href={ROUTES.NEWCOLLECTION}
                  />
                )}
            </div>
          </div>

          <div className="find-section mb--20">
            <SelectInput
              style={{ width: 100 }}
              isTitleActive={true}
              defaultValue={order_by === "DESC" ? "Newest" : "Oldest"}
              title="Order by:"
              placeholder="Select"
              options={["Newest", "Oldest"]}
              onChange={(val) => {
                this.setStateValue(val, "order_by");
              }}
            />
            <SearchPrimary
              placeholder="Search for collections"
              onSearch={(e) => this.setStateValue(e.target.value, "keyword")}
            />
          </div>
          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="list-style list-card-style mt--30">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <div className="left-box">
                      <p className="lead">Collection Name</p>
                    </div>
                    <div
                      className={`right-box fx fx--ai-c ${
                        isClassActive ? "fx--jc-fe-imp mr--50" : ""
                      }`}>
                      <p className="lead">Status</p>
                      <p className="lead right-sec-panel">Action</p>
                    </div>
                  </div>

                  {collectionCardCol1}
                </Col>
                {collectionCardCol2.length > 0 && (
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <div className="left-box">
                        <p className="lead">Collection Name</p>
                      </div>
                      <div
                        className={`right-box fx fx--ai-c ${
                          isClassActive ? "fx--jc-fe-imp mr--50" : ""
                        }`}>
                        <p className="lead">Status</p>
                        <p className="lead right-sec-panel">Action</p>
                      </div>
                    </div>
                    {collectionCardCol2}
                  </Col>
                )}
              </Row>
            </div>
          )}

          {isLoader && <Loader />}

          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this collections"
            description="Are you sure you want to remove this collection?"
            // Action button event here
            removeHandler={this.handleRemoveCollection}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
                this.props.history.push(ROUTES.COLLECTIONS);
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}

          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                pageSize={meta.limit}
                current={meta.page}
                total={totalCollections}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};
export default connect(mapStateToProps, {
  setAllCollections,
})(CollectionsScreen);
