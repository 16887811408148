import { get } from "lodash";

export const validate = (data) => {
  let errorObj = {};
  if (!data.name) {
    errorObj["name"] = "Name is Required";
  }
  if (!data.description) {
    errorObj["description"] = "Description is Required";
  }
  if (!data.modules || data.modules.length === 0) {
    errorObj["modules"] = "Module is Required";
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const parseError = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.name) {
    errorObj["name"] = get(err, "name[0]");
  }
  if (err && err.description) {
    errorObj["description"] = get(err, "description[0]");
  }
  if (err && err.modules) {
    errorObj["modules"] = get(err, "modules[0]");
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};
