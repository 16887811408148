export const EDITORCONFIG = {
    toolbar: {
        items: [
            'heading', '|',
            'bold', 'italic', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', '|',
            'insertTable', '|',
            'mediaEmbed', 'blockQuote', '|',
            'undo', 'redo'
        ],
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: 'en'
};

// export const EDITORCONFIG = {
//     toolbar: [
//         { name: 'document', groups: ['mode', 'document', 'doctools'] },
//         { name: 'clipboard', groups: ['clipboard', 'undo'] },
//         { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
//         { name: 'forms', groups: ['forms'] },
//         '/',
//         { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
//         { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
//         { name: 'links', groups: ['links'] },
//         { name: 'insert', groups: ['insert'] },
//         '/',
//         { name: 'styles', groups: ['styles'] },
//         { name: 'colors', groups: ['colors'] },
//         { name: 'tools', groups: ['tools'] },
//         { name: 'others', groups: ['others'] },
//         { name: 'about', groups: ['about'] }
//     ],

//     removeButtons: 'ExportPdf,Preview,Print,Templates,Save,NewPage,Source,PasteFromWord,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Image,Flash,Iframe,About'
// };
