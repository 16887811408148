import { getAllPayments } from './services';

export const getAllPaymentsService = async (params) => {
    return await getAllPayments(params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};