import { createRole, getAllPermissions, deleteRole, updateRole } from './services';

export const createRoleService = async (payload) => {
    return await createRole(payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllPermissionsService = async (params) => {
    return await getAllPermissions(params)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteRoleService = async (id) => {
    return await deleteRole(id)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const updateRoleService = async (id, payload) => {
    return await updateRole(id, payload)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
};
