import { TYPES } from "../../containers/Categories/Constants";
import { SET_ALL_SUB_CATEGORIES } from '../../containers/SubCategories/Constants';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ALL_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
        error: {},
      };
    case TYPES.SET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        error: {}
      };
    default:
      return state;
  }
};
