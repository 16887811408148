import React, { Component } from "react";
import { Modal, Row, Col } from "antd";
import "./styles.scss";
import SelectInput from "../../SelectInput";
import FileUpload from "../../FileUpload";

class BulkImportModal extends Component {
  constructor(props) {
    super();
    this.state = {
      fileList: [],
      importFor: null,
      disabled: true,
    };
  }

  uploadFile = (file) => {
    const { importFor } = this.state;
    const { uploadKeyword, uploadSIForKeyword, uploadSIForModule } = this.props;
    var importSelect = importFor;
    this.setState({ importFor: null }, () => {
      if (importSelect === "Keyword") {
        uploadKeyword(file.file);
      }
      if (importSelect === "SI of Keyword") {
        uploadSIForKeyword(file.file);
      }
      if (importSelect === "SI of Module") {
        uploadSIForModule(file.file);
      }
    });
  };

  render() {
    const { isModalVisible, onCancel } = this.props;
    const { fileList, importFor } = this.state;
    return (
      <>
        <Modal
          visible={isModalVisible}
          centered
          footer={null}
          className="buildImport-modal-style"
          onCancel={onCancel}>
          <div className="buildImport-content">
            <div className="body-content">
              <form className="form form-section">
                <div className="heading-content text-center">
                  <h3 className="title3">Bulk Import</h3>
                </div>
                <Row className="row" gutter={30}>
                  <Col
                    className="column gutter-row"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}>
                    <div className="input-item">
                      <p className="lable">
                        Import for <span class="required-text">*</span>
                      </p>
                      <SelectInput
                        isTitleActive={false}
                        placeholder="Import for"
                        options={["Keyword", "SI of Keyword", "SI of Module"]}
                        onChange={(val) => {
                          this.setState({ importFor: val });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="row" gutter={30}>
                  <Col
                    className="column gutter-row"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}>
                    <div className="input-item">
                      <p className="lable">
                        Upload <span class="required-text">*</span>
                      </p>
                      <FileUpload
                        fileList={fileList}
                        disabled={!importFor}
                        onChange={this.uploadFile}
                      />
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default BulkImportModal;
