import { TYPES } from "./Constants";

export const setAllModules = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SET_ALL_MODULES,
    payload: data,
  });
};

export const saveNewModule = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_NEW_MODULE,
    payload: data,
  });
}

export const clearNewModule = () => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_NEW_MODULE
  });
}

export const editModule = (data) => (dispatch) => {
  dispatch({
    type: TYPES.EDIT_MODULE,
    payload: data
  });
}

export const clearEditModule = (id) => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_EDIT_MODULE,
    payload: { id }
  });
}

export const newMiniProject = (data) => (dispatch) => {
  dispatch({
    type: TYPES.NEW_MINI_PROJECT,
    payload: data,
  });
}

export const newSkillBuildingProject = (data) => (dispatch) => {
  dispatch({
    type: TYPES.NEW_SKILL_BUILDING_PROJECT,
    payload: data,
  });
}

export const clearNewMiniProject = (id) => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_NEW_MINI_PROJECT,
    payload: { id }
  });
}

export const clearNewSkillBuildingProject = (id) => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_NEW_SKILL_BUILDING_PROJECT,
    payload: { id }
  });
}

export const editProject = (data) => (dispatch) => {
  dispatch({
    type: TYPES.EDIT_PROJECT,
    payload: data
  });
}

export const clearEditProject = (id) => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_EDIT_PROJECT,
    payload: { id }
  });
}