import * as SERVICE from "./service";

export const getAllCollectionServices = async (params) => {
  return await SERVICE.getAllCollections(params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSpecificCollectionServies = async (id) => {
  return await SERVICE.getSpecificCollection(id)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postCreateCollectionService = async (payload) => {
  return await SERVICE.postCreateCollection(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateCollectionService = async (id, payload) => {
  return await SERVICE.patchUpdateColletion(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteCollectionServies = async (id) => {
  return await SERVICE.deleteCollection(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
