import { addComponents } from "../custom";
import grapesjsLorySlider from "grapesjs-lory-slider";
import grapesjsTabs from "grapesjs-tabs";
import grapesjsCustomCode from "grapesjs-custom-code";
import grapesjsTouch from "grapesjs-touch";
import grapesjsParserPostcss from "grapesjs-parser-postcss";
import grapesjsTooltip from "grapesjs-tooltip";
import grapesjsTuiImageEditor from "grapesjs-tui-image-editor";
import grapesjsTyped from "grapesjs-typed";
import grapesjsStyleBg from "grapesjs-style-bg";
import webpage from "grapesjs-preset-webpage";
import accordion from "grapesjs-accordion";
import grapesjsTable from "grapesjs-table";
import swiperSlider from "grapesjs-swiper-slider";
import grapesjsRTEExtention from "grapesjs-rte-extensions";
import grapesjsCarousel from 'grapesjs-carousel'

export const plugins = [
  webpage,
  grapesjsLorySlider,
  grapesjsTabs,
  grapesjsCustomCode,
  grapesjsTouch,
  grapesjsParserPostcss,
  grapesjsTooltip,
  grapesjsTuiImageEditor,
  grapesjsTyped,
  grapesjsStyleBg,
  accordion,
  grapesjsTable,
  swiperSlider,
  addComponents,
  grapesjsRTEExtention,
  grapesjsCarousel
];
