import * as SERVICES from "./services";

export const postCreateBlogService = async (payload) => {
  return await SERVICES.createBlog(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getALLBlogsService = async (param) => {
  return await SERVICES.getAllBlogs(param)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getBlogService = async (id) => {
  return await SERVICES.getSpecificBlogs(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateBlogService = async (id, payload) => {
  return await SERVICES.updateBlog(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteBlogService = async (id) => {
  return await SERVICES.deleteBlog(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
