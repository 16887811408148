import React, { Component } from "react";
import { Space } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";
import SelectInput from "../../components/SelectInput";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";
import { convertCase } from "../../helpers";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

//Actions
import { setAllTags } from "./actions";

//Service
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";
import SearchPrimary from "../../components/SearchPrimary";

class Tags extends Component {
  state = {
    removeShowModal: false,
    isLoader: false,
    errorModal: null,
    succModal: null,
    meta: {
      page: 1,
      limit: 10,
    },
    filter: {
      purpose: null,
      category_id: null,
      keyword: null,
    },
    selected_id: "",
    categories: [],
  };

  componentDidMount() {
    this.getAllTagCategoriesData();
    this.getAllTagsData();
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllTagsData();
    });
  };

  setStateValue = (value, field) => {
    let state = this.state;
    state.filter[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "keyword") isLoaderBlocked = true;
      this.getAllTagsData(isLoaderBlocked);
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  getAllTagsData = async (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { page, limit } = this.state.meta;
    const { purpose, category_id, keyword } = this.state.filter;
    const queryObject = {
      limit: limit,
      page: page,
      include: "category",
    };
    if (purpose && purpose !== "--No Filter--")
      queryObject.purpose = purpose.toUpperCase();
    if (category_id) queryObject.category_id = category_id;

    if (keyword) {
      queryObject["keyword"] = keyword;
      queryObject["page"] = 1;
    }

    await CDM.getAllTagsService(queryObject)
      .then((res) => {
        this.props.setAllTags(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  getAllTagCategoriesData = () => {
    this._startLoader();
    const queryObject = {
      // limit: 20,
      // page: 1
      // status: "ACTIVE"
    };
    CDM.getAllTagCategoriesService(queryObject)
      .then((res) => {
        this.setState({ categories: get(res, "data.results") });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  removeTag = () => {
    this._startLoader();
    const id = this.state.selected_id;
    DM.deleteTagService(id)
      .then((res) => {
        this._stopLoader();
        this.getAllTagsData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Tag removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
      categories,
      filter,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "tags.tags.results", null);
    const totalTags = get(this.props, "tags.tags.meta.pagination.total", null);

    const tagCategoriesDropdown = [];
    tagCategoriesDropdown.push(
      <option value="" key="0">
        All
      </option>,
    );
    categories.map((category) => {
      tagCategoriesDropdown.push(
        <option value={category.id} key={category.id}>
          {category.name}
        </option>,
      );
      return null;
    });

    return (
      <>
        <PageTitle title="All Tags" />
        <div className="tags-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "All Tags" }]} />
              <h4 className="title4"> List of all Tags</h4>
            </div>
            <div className="right-section">
              {/* <ButtonPrimary btnText="Export List" /> */}
              {permissions.TAGS &&
                permissions.TAGS.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary btnText="Add new" href={ROUTES.NEWTAG} />
                )}
            </div>
          </div>

          <div className="find-section">
            <SelectInput
              value={get(filter, "purpose")}
              style={{ width: 150 }}
              isTitleActive={true}
              title="Filter by purpose:"
              placeholder="Select"
              options={["--No Filter--", "All", "Social", "SEO", "Marketing"]}
              onChange={(val) => {
                this.setStateValue(val, "purpose");
              }}
            />
            <SelectInput
              // selectInputStyle="mr--0-imp"
              value={get(filter, "category_id")}
              style={{ width: 180 }}
              child={tagCategoriesDropdown}
              isTitleActive={true}
              title="Filter by category:"
              placeholder="Select tag category"
              onChange={(val) => {
                this.setStateValue(val, "category_id");
              }}
            />
            <SearchPrimary
              placeholder="Search by name"
              onSearch={(e) => this.setStateValue(e.target.value, "keyword")}
            />
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
              <div className="table-card-style mt--40">
                <div className="table-style">
                  <table className="table-custom-style">
                    <tr>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Purpose</th>
                      <th>Status</th>
                      {permissions.TAGS &&
                        (permissions.TAGS.includes(ACTIONS.UPDATE) ||
                          permissions.TAGS.includes(ACTIONS.DELETE)) && (
                          <th>Actions</th>
                        )}
                    </tr>

                    {dataColumns &&
                      dataColumns.map((data, index) => {
                        return (
                          <tr className="data-row table-tags" key={index}>
                            <td>
                              <p className="lead">{get(data, "name")}</p>
                            </td>
                            <td>
                              <p className="lead">{get(data, "category.name")}</p>
                            </td>
                            <td>
                              <p className="lead">
                                {data.tag_purpose === "SEO"
                                  ? data.tag_purpose
                                  : convertCase(get(data, "tag_purpose"))}
                              </p>
                            </td>
                            <td>
                              <div className="flex-tag">
                                <span
                                  className={`circle ${ReturnStatusColor(
                                    data.status,
                                  )} mr-2`}></span>
                                <p className="lead mb--0">
                                  {convertCase(get(data, "status"))}
                                </p>
                              </div>
                            </td>
                            {permissions.TAGS &&
                              (permissions.TAGS.includes(ACTIONS.UPDATE) ||
                                permissions.TAGS.includes(ACTIONS.DELETE)) && (
                                <td>
                                  <Space size="middle">
                                    {permissions.TAGS &&
                                      permissions.TAGS.includes(
                                        ACTIONS.UPDATE,
                                      ) && (
                                        <ButtonOutline
                                          btnText={<EditOutlined />}
                                          // btnStyle="btn-sm"
                                          btnStyle="btn edit square-edit"
                                          clickHandler={() =>
                                            this.props.history.push(
                                              ROUTES.EDITTAG.replace(
                                                ":id",
                                                data.id,
                                              ),
                                            )
                                          }
                                        />
                                      )}
                                    {permissions.TAGS &&
                                      permissions.TAGS.includes(
                                        ACTIONS.DELETE,
                                      ) && (
                                        <ButtonDanger
                                          type="link"
                                          btnText={<DeleteOutlined />}
                                          btnDangerStyle="btn delete btn-link"
                                          onClick={() =>
                                            this.removeToggleModal(data.id)
                                          }>
                                          Remove
                                        </ButtonDanger>
                                      )}
                                  </Space>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </table>
                </div>
                <div className="page-section">
                  <PrimaryPagination
                    pageSize={meta.limit}
                    current={meta.page}
                    total={totalTags}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            )}

          {isLoader && <Loader />}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this tag"
              description="Are you sure you want to remove this tag?"
              // Action button event here
              removeHandler={this.removeTag}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}

          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllTags,
})(Tags);
