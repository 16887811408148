import React, { Component } from "react";
import { Row, Col } from "antd";
import { get } from 'lodash';

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ImageUpload from "../../../components/ImageUpload";
import Loader from '../../../components/PrimaryLoader';
import ErrorModal from '../../../components/modal/ErrorModal';
import SuccessModal from '../../../components/modal/SuccessModal';

// images
import userImage from "../../../assets/user.svg";

// Style
import "./styles.scss";
import SelectInput from "../../../components/SelectInput";
import { PageTitle } from "../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES } from '../../../constants';

//Services
import * as DM from '../dataManager';
import * as CDM from '../../../common/dataManager';

//Helpers
import { validate, parseError } from '../parser';

class SubCategoriesNew extends Component {
  state = {
    isLoader: false,
    errorModal: null,
    succModal: null,
    errObj: null,
    editableSubCategory: null,
    subCategory: {
      name: "",
      category: "",
      mainImage: ""
    },
    categories: []
  };

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    const editableCategoryId = this.props.match.params.category_id;
    this.getCategories();
    if (editableID) {
      await this.getSpecificSubCategoryDetails(editableCategoryId, editableID);
    }
  }

  getCategories = () => {
    this._startLoader();
    CDM.getAllCategoriesService()
      .then(res => {
        this._stopLoader();
        let categories = get(res, "data.results");
        this.setState({ categories })
      })
      .catch(err => {

        this._stopLoader();
      })
  }

  getSpecificSubCategoryDetails = (category_id, id) => {
    this._startLoader();
    DM.getSubCategoryService(category_id, id)
      .then((res) => {
        this.setState({
          subCategory: {
            name: get(res, "data.name"),
            mainImage: get(res, "data.media.path")
          },
          editableSubCategory: res.data
        });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.subCategory[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.SUBCATEGORIES);
    })
  }

  handleSave = () => {
    const { subCategory } = this.state;

    const editableID = this.props.match.params.id;
    const editableCategoryId = this.props.match.params.category_id;
    if (editableID) {
      subCategory.category = editableCategoryId;
      subCategory.name = subCategory.name || get(this.state.editableSubCategory, "name");
    }
    const checkValidate = validate(subCategory);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    this._startLoader();
    if (editableID) {
      DM.patchUpdateSubCategoryService(editableCategoryId, editableID, subCategory)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Sub-category updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.postCreateSubCategoryService(subCategory.category, subCategory)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Sub-category created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }

  render() {

    const editableID = this.props.match.params.id;
    const { isLoader, errorModal, succModal, errObj, subCategory, categories, editableSubCategory } = this.state;
    const defaultImageUrl = get(editableSubCategory, "media.path", null);

    const categoriesDropdown = categories.map(category => {
      return (<option value={category.id} key={category.id}>
        {category.name}
      </option>
      )
    })

    return (
      <>
        <PageTitle title="Sub Categories" />
        <div className="sub-categories-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.SUBCATEGORIES,
                  { text: editableID ? "Edit Sub-category" : "Add new Sub-category" },
                ]}
              />
              <h4 className="title4"> {editableID ? "Edit Sub-category" : "Add new Sub-category"}</h4>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item mt--20">
                  <p className="lable">Select Category <span className="required-text">*</span></p>
                  <SelectInput
                    disabled={editableID}
                    value={subCategory.category || get(editableSubCategory, "categories.name")}
                    placeholder="Select Category"
                    child={categoriesDropdown}
                    onChange={(val) => {
                      this.setStateValue(val, "category")
                    }}
                    errorText={get(errObj, "category")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Title <span className="required-text">*</span></p>
                  <InputPrimary
                    placeholder="Enter sub-category title"
                    value={subCategory.name}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name")
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={8}>
                <div className="input-item">
                  <p className="lable">Cover Image</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={defaultImageUrl ? "Change photo" : "Pick a photo from your computer"}
                    text={defaultImageUrl ? get(editableSubCategory, "media.file_name") : "(Max file size: 10MB)"}
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "mainImage");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className="mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

            {isLoader && <Loader />}
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SubCategoriesNew;
