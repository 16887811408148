import React, { Component } from "react";
import { Button, Col, Row, Spin } from "antd";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import InputPrimary from "../../InputPrimary";
import ImageUpload from "../../ImageUpload";
import SelectInput from "../../SelectInput";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";

// images
import userImage from "../../../assets/user.svg";

// Style
import "./styles.scss";
import TextareaPrimary from "../../TextareaPrimary";
import { get } from "lodash";

//Constants
import { EDITOR } from "../../../constants";

//Helpers
import { convertCase } from "../../../helpers";

//Data Managers
import * as CDM from "../../../common/dataManager";

ClassicEditor.defaultConfig = EDITOR.EDITORCONFIG;
class ModuleDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      shown: true,
      subCategories: [],
      careers: [],
      tags: [],
      fetching: false,
    };
  }

  setStateValue = (value, field) => {
    this.props.setStateValue(value, field);
  };

  makeOptionChild = (entity) => {
    let entityChild =
      this.state[entity] &&
      this.state[entity].map((val) => {
        return (
          <option value={val.id} key={val.id}>
            {val.name}
          </option>
        );
      });
    return entityChild;
  };

  getDefaultValuesFromArray = (arr) => {
    let defaultEntity =
      arr &&
      arr.map((val) => {
        if (typeof val === "object") {
          return {
            label: get(val, "name") || get(val, "label"),
            value: get(val, "id") || get(val, "value"),
          };
        }
        return val;
      });
    return defaultEntity;
  };

  handleSearch = (entity, value) => {
    if (value && entity === "sub-category") {
      this.setState({
        subCategories: [],
        fetching: true,
      });
      CDM.getAllSubCategoriesService({ keyword: value })
        .then((res) => {
          let subCategories = get(res, "data.results");
          this.setState({ subCategories, fetching: false });
        })
        .catch((err) => {
          this.setState({ subCategories: [], fetching: false });
        });
    }

    if (value && entity === "careers") {
      this.setState({
        careers: [],
        fetching: true,
      });
      CDM.getAllCareerService({ keyword: value, status: "ACTIVE" })
        .then((res) => {
          let careers = get(res, "data.results");
          this.setState({ careers, fetching: false });
        })
        .catch((err) => {
          this.setState({ careers: [], fetching: false });
        });
    }

    if (value && entity === "tags") {
      this.setState({
        tags: [],
        fetching: true,
      });
      CDM.getAllTagsService({ keyword: value, status: "ACTIVE" })
        .then((res) => {
          let tags = get(res, "data.results");
          this.setState({ tags, fetching: false });
        })
        .catch((err) => {
          this.setState({ tags: [], fetching: false });
        });
    }
  };

  render() {
    const { data, module, errorObj, imgUrl } = this.props;
    const defaultImageUrl = get(data, "media.path") || imgUrl;

    let status = get(data, "status") || module.status;
    let featured = get(data, "featured") || module.featured;

    let subCategories =
      module.categories && module.categories.length > 0
        ? module.categories
        : get(data, "categories");
    let defaultSubCategory =
      subCategories && subCategories.length > 0
        ? {
          label:
            get(subCategories[0], "name") || get(subCategories[0], "label"),
          value:
            get(subCategories[0], "id") || get(subCategories[0], "value"),
        }
        : {
          label: null,
          value: null,
        };

    let tags =
      get(module, "tags", []).length > 0 ? module.tags : get(data, "tags");
    let careers =
      get(module, "careers", []).length > 0
        ? module.careers
        : get(data, "careers");

    let defaultTags = this.getDefaultValuesFromArray(tags);
    let defaultCareers = this.getDefaultValuesFromArray(careers);
    let defaultBannerText =
      get(module, "banner_text") || get(data, "banner_text");
    if (defaultBannerText)
      defaultBannerText = convertCase(defaultBannerText).replace("_", " ");

    const categoryChild = this.makeOptionChild("subCategories");
    const careerChild = this.makeOptionChild("careers");
    const tagChild = this.makeOptionChild("tags");

    return (
      <div className="module-details-container">
        <div className="input-item">
          <p className="lable">
            Add Module Name <span className="required-text">*</span>
          </p>
          <InputPrimary
            value={get(data, "name") || module.name}
            placeholder="Add module name"
            onChange={(e) => {
              this.setStateValue(e.target.value, "name");
            }}
            errorText={get(errorObj, "name")}
          />
        </div>

        <Row gutter={[40]} className="mt--30">
          <Col xs={24} sm={24} md={12}>
            <div className="input-item">
              <p className="lable">
                Add Module Sub-Category <span className="required-text">*</span>
              </p>
              {/* <InputPrimary
                value={module.category || get(data, "categories[0].name")}
                placeholder="Select Category"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "category");
                }}
              /> */}

              <SelectInput
                labelInValue={true}
                placeholder="Select sub-category"
                showSearch={true}
                value={defaultSubCategory}
                child={categoryChild}
                onChange={(value) => {
                  this.setStateValue([value], "categories");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("sub-category", value)}
                errorText={get(errorObj, "categories")}
              />
            </div>

            <div className="input-item mt--20 upload-image">
              <p className="lable">Module Picture</p>
              <ImageUpload
                defaultImage={true}
                subText={defaultImageUrl ? "Change Photo" : "Upload Photo"}
                text={
                  defaultImageUrl
                    ? get(data, "media.file_name")
                    : "(Max file size: 10MB)"
                }
                defaultImageUrl={defaultImageUrl || userImage}
                onChange={(file) => {
                  let originalFileObject = file.originFileObj;
                  this.setStateValue(originalFileObject, "mainImage");
                  this.setStateValue(file, "rawFile");
                }}
              />
            </div>

            <div className="input-item mt--10">
              <p className="lable">Add Level</p>
              <Row>
                <Col span={24}>
                  <SelectInput
                    placeholder="Select level"
                    options={["Beginner", "Intermediate", "Advanced"]}
                    value={
                      convertCase(
                        get(module, "module_level") ||
                        get(data, "module_level"),
                      ) || undefined
                    }
                    onChange={(value) => {
                      this.setStateValue(value, "module_level");
                    }}
                    errorText={get(errorObj, "module_level")}
                  />
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Likes</p>
                  <InputPrimary
                    type="number"
                    value={module.likes || get(data, "likes")}
                    placeholder="Enter Likes"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "likes");
                    }}
                    errorText={get(errorObj, "likes")}
                  />
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} className="margin-mobile">
                <div className="input-item">
                  <p className="lable">Views</p>
                  <InputPrimary
                    type="number"
                    value={module.views || get(data, "views")}
                    placeholder="Enter Views"
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "views");
                    }}
                    errorText={get(errorObj, "views")}
                  />
                </div>
              </Col>
            </Row>

            <div className="input-item mt--20">
              <p className="lable">Status</p>
              <Row>
                <Col span={24}>
                  <div className="toggle-button-box full">
                    <Button
                      className={`btn-toggle ${status === "UNPUBLISH" ? "btn-active" : ""
                        }`}
                      onClick={() => {
                        this.setStateValue("UNPUBLISH", "status");
                      }}>
                      UNPUBLISHED
                    </Button>
                    <Button
                      className={`btn-toggle ${status === "PUBLISH" ? "btn-active" : ""
                        }`}
                      onClick={() => {
                        this.setStateValue("PUBLISH", "status");
                      }}>
                      PUBLISHED
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="input-item mt--20">
              <p className="lable">Featured</p>
              <Row>
                <Col span={24}>
                  <div className="toggle-button-box full">
                    <Button
                      className={`btn-toggle ${!featured ? "btn-active" : ""}`}
                      onClick={() => {
                        this.setStateValue(false, "featured");
                      }}>
                      UNFEATURED
                    </Button>
                    <Button
                      className={`btn-toggle ${featured ? "btn-active" : ""}`}
                      onClick={() => {
                        this.setStateValue(true, "featured");
                      }}>
                      FEATURED
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="input-item mt--20">
              <p className="lable">Add Banner</p>
              <Row>
                <Col span={24}>
                  <SelectInput
                    placeholder="Select Banner"
                    options={[
                      "No Banner",
                      "Mentor Recommended",
                      "Popular",
                      "Trending",
                      "Weekly Addition",
                    ]}
                    value={defaultBannerText || undefined}
                    onChange={(value) => {
                      this.setStateValue(value, "banner_text");
                    }}
                    errorText={get(errorObj, "banner_text")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Quick Preview Description</p>
              <CKEditor
                editor={ClassicEditor}
                data={
                  get(module, "description", get(data, "description", "")) || ""
                }
                onInit={(editor) => {
                  // console.log(editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setStateValue(data, "description");
                }}
              />
              {/* <TextareaPrimary
                onChange={(e) => {
                  this.setStateValue(e.target.value, "description");
                }}
                value={module.description || get(data, "description")}
                rows={4}
                placeholder="Description"
                errorText={get(errorObj, "description[0]")}
              /> */}
            </div>
          </Col>

          <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Reflection Question</p>
              <CKEditor
                editor={ClassicEditor}
                data={
                  get(
                    module,
                    "reflection_question",
                    get(data, "reflection_question", ""),
                  ) || ""
                }
                onInit={(editor) => {
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  this.setStateValue(data, "reflection_question");
                }}
              />
            </div>
          </Col>

          <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Text for Interest Cloud </p>
              <TextareaPrimary
                rows={4}
                value={
                  module.interest_cloud_text || get(data, "interest_cloud_text")
                }
                onChange={(e) => {
                  this.setStateValue(e.target.value, "interest_cloud_text");
                }}
                placeholder="Enter Text for Interest Cloud Process"
                errorText={get(errorObj, "interest_cloud_text")}
              />
            </div>
          </Col>

          <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Add Related careers</p>
              <SelectInput
                labelInValue={true}
                selectInputStyle="tag-mode-style"
                placeholder="Type career name.."
                mode="multiple"
                value={defaultCareers}
                child={careerChild}
                onChange={(value) => {
                  this.setStateValue(value, "careers");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("careers", value)}
              />
              <span className="text-danger">{get(errorObj, "careers[2]")}</span>
            </div>
          </Col>

          {/* <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Add Related modules</p>
              <SelectInput
                selectInputStyle="tag-mode-style"
                placeholder="Search module name… "
                mode="tags"
                child={moduleChild}
                onChange={(value) => {
                  this.setStateValue(value, "related_modules");
                }}
              />
            </div>
          </Col> */}

          <Col span={24}>
            <div className="input-item mt--20">
              <p className="lable">Add Related Tags</p>
              <SelectInput
                labelInValue={true}
                selectInputStyle="tag-mode-style"
                placeholder="Add tags"
                mode="multiple"
                value={defaultTags}
                child={tagChild}
                onChange={(value) => {
                  // console.log(value);
                  this.setStateValue(value, "tags");
                }}
                notFoundContent={
                  this.state.fetching ? <Spin size="small" /> : null
                }
                onSearch={(value) => this.handleSearch("tags", value)}
              />
            </div>
          </Col>
        </Row>

        <div className="mt--40">
          <ButtonPrimary
            btnText="Save"
            onClick={() => {
              this.props.onClick(module);
            }}
          />
        </div>
      </div>
    );
  }
}

export default ModuleDetails;
