export const acadruNotesBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false"><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Quote
  blockManager.add("Quote", {
    label: "Quote",
    category: {
      id: "Acadru Notes",
      label: "Acadru Notes",
      open: false,
    },
    attributes: { class: "fa fa-quote-left" },
    content: `
  ${rowCol6Start}
    <div class="${classPreFix}-notes-content quote" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <div class="quote-note" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-copyable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <span class="highlight" data-gjs-draggable="false">Lorem Ipsum</span> has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and <span class="highlight" data-gjs-draggable="false">scrambled</span> it to make a type specimen book.</div>
      <div class="quote-by"> - Quote By</div>
    </div>
  ${rowCol6End}
  `,
  });

  // Note
  blockManager.add("Note", {
    label: "Note",
    category: "Acadru Notes",
    attributes: { class: "fa fa-file-text-o" },
    content: `
  ${rowCol6Start}
    <div class="${classPreFix}-notes-content note" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
      <div class="note-title" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" data-gjs-copyable="false"><i class="fa fa-exclamation-circle" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" data-gjs-copyable="false"></i></div>
      <div class="note-message" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-copyable="false" data-gjs-removable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
    </div>
  ${rowCol6End}
  `,
  });
};
