import { TYPES } from "./Constants"

export const getAllLearners = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_LEARNERS,
        payload: data,
    });
};

export const getAllMentors = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_MENTORS,
        payload: data,
    });
};

export const getAllSuperMentors = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_SUPER_MENTORS,
        payload: data,
    });
};

export const getAllChildMentorsList = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_CHILD_MENTORS_LIST,
        payload: data,
    });
};

export const getAllAdmins = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_ADMINS,
        payload: data,
    });
};
