import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SelectInput from "../../../../components/SelectInput";
import empty from "../../../../assets/empty.svg"
import PieChart from "../../../../components/PieChart";
import { DatePicker, Spin, Tooltip } from "antd";
import * as DM from '../../dataManager'
import { extractMonthAndYear, setFilters } from "../../../../helpers/monthComparision";
import clearFilterIcon from "../../../../assets/clear-filter.png";
import Loader from "../../../../components/PrimaryLoader"
import { returnMomentObject } from "../../../../helpers";
import { get } from "lodash";
import LineChart from "../../../../components/LineChart";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import { BREADCRUMBS } from "../../../../constants";
import "./styles.scss";
import EmptyState from "../../../../components/EmptyState";
import { API } from "../../Constants";
import moment from "moment";
import ErrorModal from "../../../../components/modal/ErrorModal";

const title = "Dashboard";
class SuperMentorDashboard extends Component {
    constructor(props) {
        super();
        this.state = {
            isLoader: false,
            data: null,
            filterData: null,
            selectedSchool: localStorage.getItem('selectedSchool2') || '',
            selectedGrade: localStorage.getItem('selectedGrade2') || '',
            selectedMonth: localStorage.getItem('selectedMonth2'),
            selectedOrganization: localStorage.getItem('selectedOrganization2') || '',
            month1: localStorage.getItem('month4'),
            month2: localStorage.getItem('month5'),
            month3: localStorage.getItem('month6'),
            isComparisionSpinner: false,
            errorModal: false
        }
    }

    componentDidMount() {
        this.getSchoolGradeForSuperMentor();
        this.setDefaultHourComparisonFilters();
    }

    _startLoader = () => {
        this.setState({ isLoader: true });
    };

    _stopLoader = () => {
        this.setState({ isLoader: false });
    };

    getSuperMentorDashboardData = (spinLoader = true) => {
        const superMentorId = this.props.match.params.id
        const { selectedMonth } = this.state;
        spinLoader ? this._startLoader() : this.setState({ isComparisionSpinner: true });
        const school = localStorage.getItem('selectedSchool2');
        const grade = localStorage.getItem('selectedGrade2');
        const organization = localStorage.getItem('selectedOrganization2');
        const { month, year } = extractMonthAndYear(selectedMonth);

        const requestData = {
            id: superMentorId,
            "month": month,
            "year": year,
            "grade": grade,
            "school": school,
            "organization": organization,
            "comparisionByMonth": [extractMonthAndYear(this.state.month1), extractMonthAndYear(this.state.month2), extractMonthAndYear(this.state.month3)]
        }
        DM.getSuperMentorDashboardService(requestData).then((res) => {
            this.setState({ data: res.data })
            spinLoader ? this._stopLoader() : this.setState({ isComparisionSpinner: false });
        })
    }

    getSchoolGradeForSuperMentor = () => {
        this._startLoader();
        DM.getSchoolAndGrade().then((res) => {
            const stateUpdate = {
                filterData: res.data
            };
            if (!res.data.gradeList.includes(this.state.selectedGrade)) {
                localStorage.removeItem('selectedGrade2')
                stateUpdate.selectedGrade = '';
            }
            if (!res.data.schoolList.includes(this.state.selectedSchool)) {
                localStorage.removeItem('selectedSchool2')
                stateUpdate.selectedSchool = '';
            }
            if (!res.data.organizationList.includes(this.state.selectedOrganization)) {
                localStorage.removeItem('selectedOrganization2')
                stateUpdate.selectedOrganization = '';
            }
            this.setState(stateUpdate, () => { this.getSuperMentorDashboardData(true); });
        });
    };


    disabledDate = (current) => {
        return current && current > moment();
    }

    gradeOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.gradeList) {
            const gradeList = filterData.gradeList;
            return gradeList;
        } else {
            console.error("filterData or gradeList is undefined.");
            return [];
        }
    }

    schoolOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.schoolList) {
            const schoolList = filterData.schoolList;
            return schoolList;
        } else {
            console.error("filterData or schoolList is undefined.");
            return [];
        }
    }

    organizationOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.organizationList) {
            const organizationList = filterData.organizationList;
            return organizationList;
        } else {
            console.error("filterData or OrganizationList is undefined.");
            return [];
        }
    }

    handleSchoolChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedSchool2')
            this.setState({ selectedSchool: '' })
            this.getSuperMentorDashboardData();
        } else {
            this.setState({ selectedSchool: value }, () => {
                setFilters(this.state.selectedSchool, "selectedSchool2")
                this.getSuperMentorDashboardData();
            });
        }
    }

    handleOrganizationChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedOrganization2')
            this.setState({ selectedOrganization: '' })
            this.getSuperMentorDashboardData();
        } else {
            this.setState({ selectedOrganization: value }, () => {
                setFilters(this.state.selectedOrganization, "selectedOrganization2")
                this.getSuperMentorDashboardData();
            })
        }
    }

    handleGradeChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedGrade2')
            this.setState({ selectedGrade: '' })
            this.getSuperMentorDashboardData();
        } else {
            this.setState({ selectedGrade: value }, () => {
                setFilters(this.state.selectedGrade, "selectedGrade2")
                this.getSuperMentorDashboardData();
            });
        }
    }

    handleMonthChange = (value) => {
        this.setState({ selectedMonth: value }, () => {
            setFilters(this.state.selectedMonth, "selectedMonth2")
            this.getSuperMentorDashboardData();
        });
    }

    isGlobalFilter() {
        const { selectedOrganization, selectedSchool, selectedGrade, selectedMonth } = this.state;
        if ((selectedSchool && selectedSchool !== '') || (selectedGrade && selectedGrade !== '') || (selectedMonth && selectedMonth !== '') || (selectedOrganization && selectedOrganization !== '')) {
            return <Tooltip placement="topLeft" title={"Clear all filters"}> <img className="clear-filter" src={clearFilterIcon} alt="icon" /> </Tooltip>;
        }
        return null;
    }

    clearGlobalFilters = () => {
        this.setState({
            selectedGrade: '',
            selectedMonth: '',
            selectedSchool: '',
            selectedOrganization: '',
            isLoader: false,
        }, () => {
            localStorage.removeItem('selectedSchool2');
            localStorage.removeItem('selectedGrade2');
            localStorage.removeItem('selectedMonth2');
            localStorage.removeItem('selectedOrganization2');
            this.getSuperMentorDashboardData();
        });

    };

    totalModuleCount() {
        const { data } = this.state;
        if (!data || !data.categories) {
            return 0;
        }
        const count = data.categories.map((category) => category.count)
        let sum = 0;
        for (let i = 0; i < count.length; i++) {
            sum += count[i];
        }
        return sum;
    }

    validateMonthChange = (current, m1, m2) => {
        current = extractMonthAndYear(current)
        m1 = extractMonthAndYear(m1);
        m2 = extractMonthAndYear(m2);

        if ((current.month === m1?.month && current.year === m1?.year) || (current.month === m2?.month && current.year === m2?.year)) {
            this.setState({
                errorModal: { title: "Validation Error!", description: "Can't select same month twice!" },
            });
            return true
        }
        else {
            return false
        }
    }

    errorToggleModal = () => {
        this.setState({
            errorModal: this.state.errorModal ? null : {},
        });
    };

    handleMonth1Change = (value) => {
        const { month2, month3 } = this.state;
        const check = this.validateMonthChange(value, month2, month3);
        if (check) return
        this.setState({ month1: value }, () => {
            setFilters(this.state.month1, 'month4')
            this.getSuperMentorDashboardData(false);
        })
    };

    handleMonth2Change = (value) => {
        const { month1, month3 } = this.state;
        const check = this.validateMonthChange(value, month1, month3);
        if (check) return
        this.setState({ month2: value }, () => {
            setFilters(this.state.month2, 'month5')
            this.getSuperMentorDashboardData(false);
        })
    };

    handleMonth3Change = (value) => {
        const { month1, month2 } = this.state;
        const check = this.validateMonthChange(value, month1, month2);
        if (check) return
        this.setState({ month3: value }, () => {
            setFilters(this.state.month3, 'month6')
            this.getSuperMentorDashboardData(false);
        })
    };

    isMonthComparisionFilter() {
        const { month1, month2, month3 } = this.state;
        if ((month1 && month1 !== '-') || (month2 && month2 !== '-') || (month3 && month3 !== '-')) {
            return <Tooltip placement="topLeft" title={"Clear all Months"}> <img className="clear-filter" src={clearFilterIcon} alt="icon" /> </Tooltip>;
        }
        return null;
    }

    clearHourComparisionFilters = () => {
        this.setState({
            month1: '',
            month2: '',
            month3: '',
            isLoader: false,
        }, () => {
            localStorage.removeItem('month4');
            localStorage.removeItem('month5');
            localStorage.removeItem('month6');
            this.getSuperMentorDashboardData(false);
        });
    }

    setDefaultHourComparisonFilters = () => {
        const currentMonth = moment();
        const monthsArray = Array.from({ length: 3 }, (_, i) => currentMonth.clone().subtract(i + 1, 'months').format('YYYY-MM'));

        if (!((localStorage.getItem('month4')) || (localStorage.getItem('month5')) || (localStorage.getItem('month6')))) {
            return this.setState({ month1: monthsArray[0], month2: monthsArray[1], month3: monthsArray[2] }, () => {
                localStorage.setItem('month4', monthsArray[0]);
                localStorage.setItem('month5', monthsArray[1]);
                localStorage.setItem('month6', monthsArray[2]);
                this.getSuperMentorDashboardData(true);
            })
        }
    }

    renderMostTimeTableRows() {
        const { data } = this.state;
        if (!data || !data.timeBasedStudents) {
            return null;
        }
        return data.timeBasedStudents.map((timeBasedStudents, index) => (
            <div className="leaderboard" key={timeBasedStudents.user_uuid}>
                <div className="index">{index + 1}</div>
                <div className="name"><div className='table-name' title={timeBasedStudents.user_first_name + ' ' + timeBasedStudents.user_last_name}><a href={API.REDIRECT_TO_LEARNER.replace(":learnerId", timeBasedStudents.user_uuid)} style={{ color: 'black' }}>{timeBasedStudents.user_first_name + ' ' + timeBasedStudents.user_last_name}</a></div></div>
                <div className="score">{(timeBasedStudents.mins / 60).toFixed(2)}</div>
            </div>
        ));
    }

    renderMostAnswersTableRows() {
        const { data } = this.state;
        if (!data || !data.reflectionBasedStudents) {
            return null;
        }
        return data.reflectionBasedStudents.map((reflectionBasedStudents, index) => (
            <div className="leaderboard" key={reflectionBasedStudents.user_uuid}>
                <div className="index">{index + 1}</div>
                <div className="name"><div className='table-name' title={reflectionBasedStudents.user_first_name + ' ' + reflectionBasedStudents.user_last_name}><a href={API.REDIRECT_TO_LEARNER.replace(":learnerId", reflectionBasedStudents.user_uuid)} style={{ color: 'black' }}>{reflectionBasedStudents.user_first_name + ' ' + reflectionBasedStudents.user_last_name}</a></div></div>
                <div className="score">{reflectionBasedStudents.questions_answered}</div>
            </div>
        ));
    }

    render() {
        const { data, errorModal, selectedMonth, selectedOrganization, selectedGrade, selectedSchool, isLoader, month1, month2, month3, isComparisionSpinner } = this.state
        return (
            isLoader || !data ?
                <Loader /> :
                (<div className='super-mentor-dashboard' >
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                    <div className="header-style mb--30 mt--10">
                        <div className="title-header">
                            <PrimaryBreadcrumb
                                breadcrumbs={[
                                    BREADCRUMBS.USERS,
                                    BREADCRUMBS.SUPERMENTORS,
                                    { text: "View Dashboard" },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='filter-head'>
                        <div className='heading'>
                            <h3 className="font-weight"><span className="heading-color">Super Mentor</span> Dashboard</h3>
                        </div>
                        {((data.access.PROJECT_COUNT === "HIDE") && (data.access.REFLECTION_ANSWER_COUNT === "HIDE") && (data.access.CATEGORIES === "HIDE") && (data.access.HOUR_COMPARISION === "HIDE") && (data.access.TIME_BASED_STUDENTS === "HIDE") && (data.access.REFLECTION_BASED_STUDENTS === "HIDE") && (data.access.STUDENTS_ACTIVE === "HIDE") && (data.access.STUDENTS_ACTIVITY_TIME === "HIDE") && (data.access.AVERAGE_STUDENTS_ACTIVITY_TIME === "HIDE") && (data.access.MENTORS_ACTIVE === "HIDE") && (data.access.MENTORS_ACTIVITY_TIME === "HIDE") && (data.access.AVERAGE_MENTORS_ACTIVITY_TIME === "HIDE")) ? '' :
                            (<div className='filters'>
                                <div className='individual-filter clear-filter-alignment' onClick={this.clearGlobalFilters}>
                                    {this.isGlobalFilter()}
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select Organization"
                                        options={this.organizationOptions()}
                                        onChange={this.handleOrganizationChange}
                                        value={selectedOrganization}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select School"
                                        options={this.schoolOptions()}
                                        onChange={this.handleSchoolChange}
                                        value={selectedSchool}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select Grade"
                                        options={['8th', '9th', '10th', '11th', '12th', 'others']}
                                        onChange={this.handleGradeChange}
                                        value={selectedGrade}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <DatePicker
                                        className='date-picker'
                                        picker="month"
                                        placeholder="Select Month"
                                        onChange={this.handleMonthChange}
                                        value={selectedMonth && returnMomentObject(selectedMonth)}
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                            </div>)}

                    </div>

                    {((data.access.PROJECT_COUNT === "HIDE") && (data.access.REFLECTION_ANSWER_COUNT === "HIDE") && (data.access.CATEGORIES === "HIDE") && (data.access.HOUR_COMPARISION === "HIDE") && (data.access.TIME_BASED_STUDENTS === "HIDE") && (data.access.REFLECTION_BASED_STUDENTS === "HIDE") && (data.access.STUDENTS_ACTIVE === "HIDE") && (data.access.STUDENTS_ACTIVITY_TIME === "HIDE") && (data.access.AVERAGE_STUDENTS_ACTIVITY_TIME === "HIDE") && (data.access.MENTORS_ACTIVE === "HIDE") && (data.access.MENTORS_ACTIVITY_TIME === "HIDE") && (data.access.AVERAGE_MENTORS_ACTIVITY_TIME === "HIDE")) ?
                        (<div className="empty">
                            <img style={{ width: '200px', height: '200px' }} src={empty} alt='' />
                            <h1 className="title3">Oops!</h1>
                            <h1 className="lead">No Records Found &#128533;</h1>
                        </div>) : ''}
                    <div className='count-components'>
                        {data.access.STUDENTS_ACTIVE === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Total number of learners active</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeStudents[0].usersActive") || 0}</h3>
                                </div>
                            </div>)}

                        {data.access.STUDENTS_ACTIVITY_TIME === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Total number of hours spent by learners</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeStudents[0].totalTimeSpent") || 0}</h3>
                                </div>
                            </div>)}

                        {data.access.AVERAGE_STUDENTS_ACTIVITY_TIME === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Average number of hours spent by learners</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeStudents[0].averageHoursSpent") || 0}</h3>
                                </div>
                            </div>)}

                        {data.access.MENTORS_ACTIVE === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Total number of mentors active</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeMentors[0].usersActive") || 0}</h3>
                                </div>
                            </div>)}
                        {data.access.MENTORS_ACTIVITY_TIME === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Total number of hours spent by mentors</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeMentors[0].totalTimeSpent") || 0}</h3>
                                </div>
                            </div>)}

                        {data.access.AVERAGE_MENTORS_ACTIVITY_TIME === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'>Average number of hours spent by mentors</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "activeMentors[0].averageHoursSpent") || 0}</h3>
                                </div>
                            </div>)}

                        {data.access.PROJECT_COUNT === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'> Total number of projects submitted by learners</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "projectCount")}</h3>
                                </div>
                            </div>)}
                        {data.access.REFLECTION_ANSWER_COUNT === "SHOW" && (
                            <div className='inner-count'>
                                <div>
                                    <h3 className='text-color'> Total number of reflection questions answered by learners</h3>
                                </div>
                                <div>
                                    <h3 className='number'>{get(data, "reflectionAnswerCount")}</h3>
                                </div>
                            </div>)}
                    </div>
                    {data.access.CATEGORIES === "SHOW" && (<div className="module-data">
                        {data?.categories?.length > 0 ? <div>
                            <div className="chart-container">
                                <PieChart data={get(data, "categories")} />
                            </div>
                            <div className='module-text'>
                                Total number of modules viewed by the learners: {this.totalModuleCount()}
                            </div>
                        </div> : <EmptyState title="Oops!" description="No Record Found &#128533;" />}
                    </div>)}
                    {data.access.HOUR_COMPARISION === "SHOW" && (
                        <div className="module-data">
                            <div className='hour-comparison-filter'>
                                <div className='clear-filter-alignment' onClick={this.clearHourComparisionFilters}>
                                    {this.isMonthComparisionFilter()}
                                </div>
                                <div className='permission-toggle-module'>
                                    <DatePicker
                                        className='date-picker'
                                        picker="month"
                                        placeholder="Month 1"
                                        style={{ width: 200 }}
                                        onChange={this.handleMonth1Change}
                                        value={month1 && returnMomentObject(month1)}
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                                <div className='permission-toggle-module'>
                                    <DatePicker
                                        className='date-picker'
                                        picker="month"
                                        placeholder="Month 2"
                                        style={{ width: 200 }}
                                        onChange={this.handleMonth2Change}
                                        value={month2 && returnMomentObject(month2)}
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                                <div className='permission-toggle-module'>
                                    <DatePicker
                                        className='date-picker'
                                        picker="month"
                                        placeholder="Month 3"
                                        style={{ width: 200 }}
                                        onChange={this.handleMonth3Change}
                                        value={month3 && returnMomentObject(month3)}
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                            </div>
                            {isComparisionSpinner ? <Spin style={{ margin: 120 }} /> : <>
                                <div>
                                    <LineChart data={get(data, "hourComparision")} month1={extractMonthAndYear(month1)} month2={extractMonthAndYear(month2)} month3={extractMonthAndYear(month3)} />
                                </div>
                                <div className="module-text">
                                    Total hours spent each day by the learners in a month
                                </div></>}
                        </div>)}
                    <div>
                        {data.access.TIME_BASED_STUDENTS === "SHOW" && (<div className="max-time">
                            {data?.timeBasedStudents?.length > 0 ?
                                (<div className="top-performers">
                                    <div className="leaderboard-text">
                                        Top learners who spent the most time on the portal (in hrs)
                                    </div>
                                    <div className="time-based-top-performers">
                                        <div>
                                            {this.renderMostTimeTableRows().splice(0, 5)}
                                        </div>
                                        <div>
                                            {this.renderMostTimeTableRows().splice(5, 10)}
                                        </div>
                                    </div>
                                </div>) : <EmptyState
                                    title="Oops!"
                                    description="No Record Found &#128533;"
                                />}
                        </div>)}
                    </div>
                    <div>
                        {data.access.REFLECTION_BASED_STUDENTS === "SHOW" && (<div className="max-time">
                            {data?.reflectionBasedStudents?.length > 0 ? (<div className="top-performers">
                                <div className="leaderboard-text">
                                    Top learners who answered maximum reflection questions
                                </div>
                                <div className="time-based-top-performers">
                                    <div>
                                        {this.renderMostAnswersTableRows().splice(0, 5)}
                                    </div>
                                    <div>
                                        {this.renderMostAnswersTableRows().splice(5, 10)}
                                    </div>
                                </div>
                            </div>) : <EmptyState
                                title="Oops!"
                                description="No Record Found &#128533;"
                            />}
                        </div>)}
                        <ErrorModal
                            isModalVisible={errorModal}
                            onClose={this.errorToggleModal}
                            title={get(errorModal, "title")}
                            description={get(errorModal, "description")}
                        />
                    </div>
                </div>)
        )
    }
}

const mapStateToProps = (store) => {
    return store;
};

export default connect(mapStateToProps, {})(SuperMentorDashboard);