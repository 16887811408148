import React, { Component } from "react";
import { Pagination } from "antd";
import { get } from 'lodash';

// Style
import "./styles.scss";

class PrimaryPagination extends Component {
  render() {
    return (
      <div className="pagination-section">
        <Pagination
          current={this.props.current}
          defaultCurrent={this.props.defaultCurrent}
          total={this.props.total}
          onChange={this.props.onChange}
          pageSize={get(this.props, "pageSize", null)}
          showSizeChanger={false}
        />
      </div>
    );
  }
}

export default PrimaryPagination;
