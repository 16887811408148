import React, { Component } from "react";
import { Modal, Avatar, Checkbox, Spin } from "antd";
import SearchPrimary from "../../SearchPrimary";
import ButtonDanger from "../../buttons/ButtonDanger";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import SelectInput from "../../SelectInput";
import defaultImg from '../../../assets/user-grey.svg';
import { connect } from 'react-redux'
import { get, debounce } from 'lodash';

import EmptyState from '../../EmptyState';

// Style
import "./styles.scss";

//Actions
import { getAllLearners } from '../../../containers/Users/actions';

//Services
import * as DM from '../../../containers/Users/dataManager';

class AssignStudentModal extends Component {
  constructor(props) {
    super();
    this.getAllStudentsData = debounce(this.getAllStudentsData, 800);
    this.state = {
      isLoaded: false,
      top: "topLeft",
      bottom: "bottomRight",
      checked: [],
      searchQuery: null,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAllStudentsData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isModalVisible && this.props.isModalVisible)
      this.getAllStudentsData();
  }

  getAllStudentsData = () => {
    let { searchQuery, limit } = this.state;
    let queryObj = { type: "LEARNER", status: "ACTIVE", limit };
    if (searchQuery) queryObj.keyword = searchQuery;
    DM.getAllUsersService(queryObj)
      .then(res => {
        this.props.getAllLearners(res.data);
        this.setState({ isLoaded: true })
      })
      .catch(err => {
      })
  }

  handleSearch = (e) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.getAllStudentsData();
    })
  }

  clearCheckboxes = () => {
    this.setState({
      checked: []
    })
  }

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  handlePerPageChange = (limit) => {
    this.setState({ limit }, () => { this.getAllStudentsData() });
  }

  render() {
    const { isModalVisible, onClose, modalTitle, onClick } = this.props;
    const { checked, limit, isLoaded } = this.state;
    const learners = get(this.props, "users.allLearners.results", null);

    return (
      <div className="assign-student-modal">
        <Modal
          visible={isModalVisible}
          // centered
          footer={null}
          // width="350"
          className="assign-learner-modal-style">
          <div className="remove-modal">
            <div className="assign-learner-modal-header">
              <h3 className="title3">{modalTitle}</h3>
              <SelectInput
                defaultValue={limit}
                value={limit}
                isTitleActive
                title="Limit:"
                placeholder="Select"
                options={[10, 25, 50, 100]}
                onChange={(value) => this.handlePerPageChange(value)}
              />
            </div>
            <div className="modal-body">

              <div className="search-box">
                <SearchPrimary placeholder="Search learner" onSearch={this.handleSearch} />
              </div>
              {(!learners || learners.length === 0) ?
                (isLoaded ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <div className="spinner-container-as-modal"><Spin /></div>) :
                <div className="checkbox-section">
                  <Checkbox.Group >
                    {learners && learners.map((learner, index) => {
                      return (
                        <Checkbox className="checkbox-style" onChange={this.onChange} value={learner.id} key={index} checked={checked.includes(learner.id)}>
                          <div className="fx fx--ai-fs mr--20 wrapper">
                            <Avatar
                              className="img-sec"
                              size={32}
                              src={get(learner, "media.path", null) || defaultImg}
                            />
                            <div className="learner-info-box fx fx--ai-fs fd-c">
                              <span className="learner-name wrap-modal-field">{learner.full_name}</span>
                              {learner.organization && <span className="id-style wrap-modal-field"><b>Organization: </b>{learner.organization}</span>}
                              <span className="id-style">{learner.email}</span>
                            </div>
                          </div>
                        </Checkbox>
                      )
                    })}
                  </Checkbox.Group>
                  <div className='text-center-s'>And More...</div>
                </div>
              }
              <div className="text-right mt--10 footer-section">
                <ButtonDanger
                  onClick={onClose}
                  btnText="Cancel"
                  btnDangerStyle="mr--20"
                />
                <ButtonPrimary onClick={() => { onClick(checked) }} btnText="Assign" disabled={checked.length === 0} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, { getAllLearners })(AssignStudentModal);
