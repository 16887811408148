import React, { Component } from 'react';
import { PageTitle } from '../../components/PageTitle';
import { connect } from "react-redux";
import { debounce, get } from "lodash";
import "./styles.scss";
import * as DM from './dataManager';
import SelectInput from '../../components/SelectInput';
import clearFilterIcon from "../../assets/clear-filter.png";
import image from "../../assets/cancel.svg";
import { DatePicker, Spin, Switch, Tooltip } from 'antd';
import Loader from '../../components/PrimaryLoader';
import { getMonth, setFilters, extractMonthAndYear, getHoursPerMonth } from '../../helpers/monthComparision'
import { returnMomentObject } from '../../helpers';
import { PACKAGES, SEARCHDELAY } from '../../constants';
import { API } from "./Constants"
import EmptyState from '../../components/EmptyState';
import moment from 'moment';
import ErrorModal from '../../components/modal/ErrorModal';

class Welcome extends Component {
    constructor(props) {
        super();
        this.handleProjectCountToggle = debounce(
            this.handleProjectCountToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleReflectionAnswersToggle = debounce(
            this.handleReflectionAnswersToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleActiveStudentsToggle = debounce(
            this.handleActiveStudentsToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleStudentTotalTimeToggle = debounce(
            this.handleStudentTotalTimeToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleStudentAverageTimeToggle = debounce(
            this.handleStudentAverageTimeToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleActiveMentorToggle = debounce(
            this.handleActiveMentorToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleMentorTotalTimeToggle = debounce(
            this.handleMentorTotalTimeToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleMentorAverageTimeToggle = debounce(
            this.handleMentorAverageTimeToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleCategoryToggle = debounce(
            this.handleCategoryToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleHourComparisionToggle = debounce(
            this.handleHourComparisionToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleTimeBasedToggle = debounce(
            this.handleTimeBasedToggle,
            SEARCHDELAY.toggleDelay
        )
        this.handleReflectionBasedToggle = debounce(
            this.handleReflectionBasedToggle,
            SEARCHDELAY.toggleDelay
        )
        this.state = {
            isLoader: true,
            isComparisionSpinner: false,
            data: null,
            filterData: null,
            errorModal: null,
            selectedOrganization: localStorage.getItem('selectedOrganization') || '',
            selectedSchool: localStorage.getItem('selectedSchool') || '',
            selectedGrade: localStorage.getItem('selectedGrade') || '',
            selectedMonth: localStorage.getItem('selectedMonth'),
            month1: localStorage.getItem('month1'),
            month2: localStorage.getItem('month2'),
            month3: localStorage.getItem('month3'),
            setProjectCountToggle: false,
            setReflectionCountToggle: false,
            setStudentsActiveToggle: false,
            setStudentTotalTimeToggle: false,
            setStudentAverageTimeToggle: false,
            setMentorActiveToggle: false,
            setMentorTotalTimeToggle: false,
            setMentorAverageTimeToggle: false,
            setCategoryToggle: false,
            setHourComparisionToggle: false,
            setTimeBasedToggle: false,
            setReflectionBasedToggle: false,
            isMentorAssigned: localStorage.getItem('isMentorAssigned'),
            packageType: localStorage.getItem('packageType')
        }
    }

    async componentDidMount() {
        this.getSchoolGradeForAdmin();
        this.setDefaultHourComparisonFilters();
    }

    _startLoader = () => {
        this.setState({ isLoader: true });
    };

    _stopLoader = () => {
        this.setState({ isLoader: false });
    };

    getAdminDashboardData = (spinLoader = true) => {
        const { selectedMonth } = this.state;
        spinLoader ? this._startLoader() : this.setState({ isComparisionSpinner: true });
        const school = localStorage.getItem('selectedSchool');
        const grade = localStorage.getItem('selectedGrade');
        const organization = localStorage.getItem('selectedOrganization');
        const { month, year } = extractMonthAndYear(selectedMonth);
        const isMentorAssigned = localStorage.getItem('isMentorAssigned');
        const packageType = localStorage.getItem('packageType');

        const requestData = {
            "month": month,
            "year": year,
            "grade": grade,
            "school": school,
            "organization": organization,
            "comparisionByMonth": [extractMonthAndYear(this.state.month1), extractMonthAndYear(this.state.month2), extractMonthAndYear(this.state.month3)],
            "isMentorAssigned": isMentorAssigned,
            "packageType": packageType
        }
        DM.getAdminDashboardService(requestData).then((res) => {
            this.setState({ data: res.data })
            this.setToggleOnReload(res.data);
            spinLoader ? this._stopLoader() : this.setState({ isComparisionSpinner: false });
        })
    }

    disabledDate = (current) => {
        return current && current > moment();
    }

    getSchoolGradeForAdmin = () => {
        this._startLoader();
        DM.getSchoolAndGrade().then((res) => {
            const stateUpdate = {
                filterData: res.data
            };
            if (!res.data.gradeList.includes(this.state.selectedGrade)) {
                localStorage.removeItem('selectedGrade')
                stateUpdate.selectedGrade = '';
            }
            if (!res.data.schoolList.includes(this.state.selectedSchool)) {
                localStorage.removeItem('selectedSchool')
                stateUpdate.selectedSchool = '';
            }
            if (!res.data.organizationList.includes(this.state.selectedOrganization)) {
                localStorage.removeItem('selectedOrganization')
                stateUpdate.selectedOrganization = '';
            }
            this.setState(stateUpdate, () => { this.getAdminDashboardData(true); });
        });
    }

    updateDashboardAccess(key, status) {
        const organization = localStorage.getItem('selectedOrganization');
        const { setProjectCountToggle, setReflectionCountToggle, setStudentsActiveToggle, setStudentTotalTimeToggle, setStudentAverageTimeToggle, setMentorActiveToggle, setMentorTotalTimeToggle, setMentorAverageTimeToggle, setCategoryToggle, setHourComparisionToggle, setTimeBasedToggle, setReflectionBasedToggle } = this.state;
        let requestData;
        requestData = {
            PROJECT_COUNT: setProjectCountToggle ? "SHOW" : "HIDE",
            REFLECTION_ANSWER_COUNT: setReflectionCountToggle ? "SHOW" : "HIDE",
            STUDENTS_ACTIVE: setStudentsActiveToggle ? "SHOW" : "HIDE",
            STUDENTS_ACTIVITY_TIME: setStudentTotalTimeToggle ? "SHOW" : "HIDE",
            AVERAGE_STUDENTS_ACTIVITY_TIME: setStudentAverageTimeToggle ? "SHOW" : "HIDE",
            MENTORS_ACTIVE: setMentorActiveToggle ? "SHOW" : "HIDE",
            MENTORS_ACTIVITY_TIME: setMentorTotalTimeToggle ? "SHOW" : "HIDE",
            AVERAGE_MENTORS_ACTIVITY_TIME: setMentorAverageTimeToggle ? "SHOW" : "HIDE",
            CATEGORIES: setCategoryToggle ? "SHOW" : "HIDE",
            HOUR_COMPARISION: setHourComparisionToggle ? "SHOW" : "HIDE",
            TIME_BASED_STUDENTS: setTimeBasedToggle ? "SHOW" : "HIDE",
            REFLECTION_BASED_STUDENTS: setReflectionBasedToggle ? "SHOW" : "HIDE",
        }
        if (organization) {
            requestData.organization = organization;
        }
        DM.updateAccess(requestData).then(() => { })
    }

    setToggleOnReload(data) {
        this.setState({
            setProjectCountToggle: data.access.PROJECT_COUNT !== 'HIDE',
            setReflectionCountToggle: data.access.REFLECTION_ANSWER_COUNT !== 'HIDE',
            setCategoryToggle: data.access.CATEGORIES !== 'HIDE',
            setHourComparisionToggle: data.access.HOUR_COMPARISION !== 'HIDE',
            setTimeBasedToggle: data.access.TIME_BASED_STUDENTS !== 'HIDE',
            setReflectionBasedToggle: data.access.REFLECTION_BASED_STUDENTS !== 'HIDE',
            setStudentsActiveToggle: data.access.STUDENTS_ACTIVE !== 'HIDE',
            setStudentTotalTimeToggle: data.access.STUDENTS_ACTIVITY_TIME !== 'HIDE',
            setStudentAverageTimeToggle: data.access.AVERAGE_STUDENTS_ACTIVITY_TIME !== 'HIDE',
            setMentorActiveToggle: data.access.MENTORS_ACTIVE !== 'HIDE',
            setMentorTotalTimeToggle: data.access.MENTORS_ACTIVITY_TIME !== 'HIDE',
            setMentorAverageTimeToggle: data.access.AVERAGE_MENTORS_ACTIVITY_TIME !== 'HIDE'
        })
    }

    handleProjectCountToggle = () => {
        this.updateDashboardAccess("PROJECT_COUNT", this.state.setProjectCountToggle ? "SHOW" : "HIDE")
    };

    handleReflectionAnswersToggle = () => {
        this.updateDashboardAccess("REFLECTION_ANSWER_COUNT", this.state.setReflectionCountToggle ? "SHOW" : "HIDE")
    };

    handleActiveStudentsToggle = () => {
        this.updateDashboardAccess("STUDENTS_ACTIVE", this.state.setStudentsActiveToggle ? "SHOW" : "HIDE")
    }

    handleStudentTotalTimeToggle = () => {
        this.updateDashboardAccess("STUDENTS_ACTIVITY_TIME", this.state.setStudentTotalTimeToggle ? "SHOW" : "HIDE")
    }

    handleStudentAverageTimeToggle = () => {
        this.updateDashboardAccess("AVERAGE_STUDENTS_ACTIVITY_TIME", this.state.setStudentAverageTimeToggle ? "SHOW" : "HIDE")
    }

    handleActiveMentorToggle = () => {
        this.updateDashboardAccess("MENTORS_ACTIVE", this.state.setMentorActiveToggle ? "SHOW" : "HIDE")
    }

    handleMentorTotalTimeToggle = () => {
        this.updateDashboardAccess("MENTORS_ACTIVITY_TIME", this.state.setMentorTotalTimeToggle ? "SHOW" : "HIDE")
    }

    handleMentorAverageTimeToggle = () => {
        this.updateDashboardAccess("AVERAGE_MENTORS_ACTIVITY_TIME", this.state.setMentorAverageTimeToggle ? "SHOW" : "HIDE")
    }

    handleCategoryToggle = () => {
        this.updateDashboardAccess("CATEGORIES", this.state.setCategoryToggle ? "SHOW" : "HIDE")
    };

    handleHourComparisionToggle = () => {
        this.updateDashboardAccess("HOUR_COMPARISION", this.state.setHourComparisionToggle ? "SHOW" : "HIDE")
    };

    handleTimeBasedToggle = () => {
        this.updateDashboardAccess("TIME_BASED_STUDENTS", this.state.setTimeBasedToggle ? "SHOW" : "HIDE")
    }

    handleReflectionBasedToggle = () => {
        this.updateDashboardAccess("REFLECTION_BASED_STUDENTS", this.state.setReflectionBasedToggle ? "SHOW" : "HIDE")
    }

    totalModuleCount() {
        const { data } = this.state;
        if (!data || !data.categories) {
            return 0;
        }
        const count = data.categories.map((category) => category.count)
        let sum = 0;
        for (let i = 0; i < count.length; i++) {
            sum += count[i];
        }
        return sum;
    }

    OrganizationOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.organizationList) {
            const organizationList = filterData.organizationList;
            return organizationList;
        } else {
            console.error("filterData or OrganizationList is undefined.");
            return [];
        }
    }

    schoolOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.schoolList) {
            const schoolList = filterData.schoolList;
            return schoolList;
        } else {
            console.error("filterData or schoolList is undefined.");
            return [];
        }
    }

    gradeOptions() {
        const { filterData } = this.state;
        if (filterData && filterData.gradeList) {
            const gradeList = filterData.gradeList;
            return gradeList;
        } else {
            console.error("filterData or gradeList is undefined.");
            return [];
        }
    }

    renderCategoryTableRows() {
        const { data } = this.state;
        if (!data || !data.categories) {
            return null;
        }
        return data.categories.map((category, index) => (
            <tr key={category.category_id}>
                <td>{index + 1}</td>
                <td>{category.category_name}</td>
                <td>{category.count}</td>
            </tr>
        ));
    }

    renderMostTimeTableRows() {
        const { data } = this.state;
        if (!data || !data.timeBasedStudents) {
            return null;
        }
        return data.timeBasedStudents.map((timeBasedStudents, index) => (
            <tr key={timeBasedStudents.user_uuid}>
                <td>{index + 1}</td>
                <td><div className='table-name' title={timeBasedStudents.user_first_name + ' ' + timeBasedStudents.user_last_name}><a href={API.REDIRECT_TO_LEARNER_PROFILE.replace(":id", timeBasedStudents.user_uuid)}>{timeBasedStudents.user_first_name + ' ' + timeBasedStudents.user_last_name}</a></div></td>
                <td>{(timeBasedStudents.mins / 60).toFixed(2)}</td>
            </tr>
        ));
    }


    renderMostAnswersTableRows() {
        const { data } = this.state;
        if (!data || !data.reflectionBasedStudents) {
            return null;
        }
        return data.reflectionBasedStudents.map((reflectionBasedStudents, index) => (
            <tr key={reflectionBasedStudents.user_uuid}>
                <td>{index + 1}</td>
                <td><div className='table-name' title={reflectionBasedStudents.user_first_name + ' ' + reflectionBasedStudents.user_last_name}><a href={API.REDIRECT_TO_LEARNER_PROFILE.replace(":id", reflectionBasedStudents.user_uuid)}>{reflectionBasedStudents.user_first_name + ' ' + reflectionBasedStudents.user_last_name}</a></div></td>
                <td>{reflectionBasedStudents.questions_answered}</td>
            </tr>
        ));
    }

    isGlobalFilter() {
        const { selectedSchool, selectedGrade, selectedMonth, selectedOrganization, isMentorAssigned, packageType } = this.state;
        if ((selectedSchool && selectedSchool !== '') || (selectedGrade && selectedGrade !== '') || (selectedMonth && selectedMonth !== '') || (selectedOrganization && selectedOrganization !== '') || (isMentorAssigned && isMentorAssigned !== '') || (packageType && packageType !== '')) {
            return <Tooltip placement="topLeft" title={"Clear all filters"}> <img className="clear-filter" src={clearFilterIcon} alt="icon" /> </Tooltip>;
        }
        return null;
    }

    isMonthComparisionFilter() {
        const { month1, month2, month3 } = this.state;
        if ((month1 && month1 !== '-') || (month2 && month2 !== '-') || (month3 && month3 !== '-')) {
            return <Tooltip placement="topLeft" title={"Clear all Months"}> <img className="clear-filter" src={clearFilterIcon} alt="icon" /> </Tooltip>;
        }
        return null;
    }

    deleteHourComparisionMonth = (key) => {
        if (key === '1') {
            localStorage.removeItem('month1');
            this.setState({ month1: '' })
        }
        else if (key === '2') {
            localStorage.removeItem('month2')
            this.setState({ month2: '' })
        }
        else {
            localStorage.removeItem('month3')
            this.setState({ month3: '' })
        }
        this.getAdminDashboardData(false);
    }

    deleteMonth1(key) {
        if (this.state.month1 && this.state.month1 !== '-') {
            return <img className='cancel' src={image} alt='cancel' onClick={() => this.deleteHourComparisionMonth(key)} />
        }

    }

    deleteMonth2(key) {
        if (this.state.month2 && this.state.month2 !== '-') {
            return <img className='cancel' src={image} alt='cancel' onClick={() => this.deleteHourComparisionMonth(key)} />
        }
    }

    deleteMonth3(key) {
        if (this.state.month3 && this.state.month3 !== '-') {
            return <img className='cancel' src={image} alt='cancel' onClick={() => this.deleteHourComparisionMonth(key)} />
        }
    }

    handleSchoolChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedSchool')
            this.setState({ selectedSchool: '' })
            this.getAdminDashboardData();
        } else {
            this.setState({ selectedSchool: value }, () => {
                setFilters(this.state.selectedSchool, "selectedSchool")
                this.getAdminDashboardData();
            });
        }
    }

    handleOrganizationChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedOrganization')
            this.setState({ selectedOrganization: '' })
            this.getAdminDashboardData();
        } else {
            this.setState({ selectedOrganization: value }, () => {
                setFilters(this.state.selectedOrganization, "selectedOrganization")
                this.getAdminDashboardData();
            })
        }
    }

    handleChange = (value, key) => {
        if (!value) {
            localStorage.removeItem(key)
            this.setState({ [key]: '' })
            this.getAdminDashboardData();
        } else {
            this.setState({ [key]: value }, () => {
                if (key === "isMentorAssigned") setFilters(this.state.isMentorAssigned, key)
                if (key === "packageType") setFilters(this.state.packageType, key)
                this.getAdminDashboardData();
            })
        }
    }

    handleGradeChange = (value) => {
        if (!value) {
            localStorage.removeItem('selectedGrade')
            this.setState({ selectedGrade: '' })
            this.getAdminDashboardData();
        } else {
            this.setState({ selectedGrade: value }, () => {
                setFilters(this.state.selectedGrade, "selectedGrade")
                this.getAdminDashboardData();
            });
        }
    }

    handleMonthChange = (value) => {
        this.setState({ selectedMonth: value }, () => {
            setFilters(this.state.selectedMonth, "selectedMonth")
            this.getAdminDashboardData();
        });
    }

    validateMonthChange = (current, m1, m2) => {
        current = extractMonthAndYear(current)
        m1 = extractMonthAndYear(m1);
        m2 = extractMonthAndYear(m2);

        if ((current.month === m1?.month && current.year === m1?.year) || (current.month === m2?.month && current.year === m2?.year)) {
            this.setState({
                errorModal: { title: "Validation Error!", description: "Can't select same month twice!" },
            });
            return true
        }
        else {
            return false
        }
    }

    errorToggleModal = () => {
        this.setState({
            errorModal: this.state.errorModal ? null : {},
        });
    };

    handleMonth1Change = (value) => {
        const { month2, month3 } = this.state;
        const check = this.validateMonthChange(value, month2, month3);
        if (check) return
        this.setState({ month1: value }, () => {
            setFilters(this.state.month1, 'month1')
            this.getAdminDashboardData(false);
        })
    };

    handleMonth2Change = (value) => {
        const { month1, month3 } = this.state;
        const check = this.validateMonthChange(value, month1, month3);
        if (check) return
        this.setState({ month2: value }, () => {
            setFilters(this.state.month2, 'month2')
            this.getAdminDashboardData(false);
        })
    };

    handleMonth3Change = (value) => {
        const { month1, month2 } = this.state;
        const check = this.validateMonthChange(value, month1, month2);
        if (check) return
        this.setState({ month3: value }, () => {
            setFilters(this.state.month3, 'month3')
            this.getAdminDashboardData(false);
        })
    };

    clearGlobalFilters = () => {
        this.setState({
            selectedGrade: '',
            selectedMonth: '',
            selectedSchool: '',
            selectedOrganization: '',
            isMentorAssigned: '',
            packageType: '',
            isLoader: false,
        }, () => {
            localStorage.removeItem('selectedSchool');
            localStorage.removeItem('selectedGrade');
            localStorage.removeItem('selectedMonth');
            localStorage.removeItem('selectedOrganization');
            localStorage.removeItem('isMentorAssigned');
            localStorage.removeItem('packageType');
            this.getAdminDashboardData();
        });

    };

    clearHourComparisionFilters = () => {
        this.setState({
            month1: '',
            month2: '',
            month3: '',
            isLoader: false,
        }, () => {
            localStorage.removeItem('month1');
            localStorage.removeItem('month2');
            localStorage.removeItem('month3');
            this.getAdminDashboardData(false);
        });
    }

    setDefaultHourComparisonFilters = () => {
        const currentMonth = moment();
        const monthsArray = Array.from({ length: 3 }, (_, i) => currentMonth.clone().subtract(i + 1, 'months').format('YYYY-MM'));

        if (!((localStorage.getItem('month1')) || (localStorage.getItem('month2')) || (localStorage.getItem('month3')))) {
            return this.setState({ month1: monthsArray[0], month2: monthsArray[1], month3: monthsArray[2] }, () => {
                localStorage.setItem('month1', monthsArray[0]);
                localStorage.setItem('month2', monthsArray[1]);
                localStorage.setItem('month3', monthsArray[2]);
                this.getAdminDashboardData(true);
            })
        }
    }

    render() {
        const { data, isMentorAssigned, packageType, errorModal, isLoader, isComparisionSpinner, month1, month2, month3, selectedMonth, selectedOrganization, selectedSchool, selectedGrade, setProjectCountToggle, setReflectionCountToggle, setMentorAverageTimeToggle, setMentorActiveToggle, setMentorTotalTimeToggle, setStudentAverageTimeToggle, setStudentsActiveToggle, setStudentTotalTimeToggle, setCategoryToggle, setHourComparisionToggle, setTimeBasedToggle, setReflectionBasedToggle } = this.state;
        const packagesOptions = ["All", PACKAGES.packages.FREE_PACKAGE, PACKAGES.packages.STARTER_PACKAGE, PACKAGES.packages.PREMIUM_PACKAGE, PACKAGES.packages.ELITE_PACKAGE]
        return (
            isLoader || !data ?
                <Loader /> :
                (<div className='dashboard' >
                    <PageTitle title="Dashboard" />


                    <div className='filter-head'>
                        <div className='heading'>
                            <h3 className="font-weight"><span className="heading-color">Admin</span> Dashboard</h3>
                        </div>
                        <div className='filter-parent'>
                            <div className='filters'>
                                <div className='individual-filter clear-filter-alignment' onClick={this.clearGlobalFilters}>
                                    {this.isGlobalFilter()}
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select School"
                                        options={this.schoolOptions()}
                                        onChange={this.handleSchoolChange}
                                        value={selectedSchool}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select Grade"
                                        options={['8th', '9th', '10th', '11th', '12th', 'others']}
                                        onChange={this.handleGradeChange}
                                        value={selectedGrade}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <DatePicker
                                        className='date-picker'
                                        picker="month"
                                        placeholder="Select Month"
                                        onChange={this.handleMonthChange}
                                        value={selectedMonth && returnMomentObject(selectedMonth)}
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                            </div>
                            <div className='filters'>
                                <div className='individual-filter clear-filter-alignment'></div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Select Organization"
                                        options={this.OrganizationOptions()}
                                        onChange={this.handleOrganizationChange}
                                        value={selectedOrganization}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Mentor Assigned"
                                        options={["All", "Yes", "No"]}
                                        onChange={(value) => this.handleChange(value, "isMentorAssigned")}
                                        value={isMentorAssigned}
                                        allowClear={true}
                                    />
                                </div>
                                <div className='individual-filter'>
                                    <SelectInput
                                        style={{ width: 200 }}
                                        isTitleActive={true}
                                        placeholder="Package Type"
                                        options={packagesOptions}
                                        onChange={(value) => this.handleChange(value, "packageType")}
                                        value={packageType}
                                        allowClear={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='count-components'>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setStudentsActiveToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setStudentsActiveToggle: checked });
                                                this.handleActiveStudentsToggle();
                                            }
                                        }
                                        className={setStudentsActiveToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'> Total number of learners active</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeStudents[0].usersActive") || 0}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setStudentTotalTimeToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setStudentTotalTimeToggle: checked });
                                                this.handleStudentTotalTimeToggle();
                                            }
                                        }
                                        className={setStudentTotalTimeToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'> Total number of hours spent by learners</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeStudents[0].totalTimeSpent") || 0}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setStudentAverageTimeToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setStudentAverageTimeToggle: checked });
                                                this.handleStudentAverageTimeToggle();
                                            }
                                        }
                                        className={setStudentAverageTimeToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'>Average number of hours spent by learners</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeStudents[0].averageHoursSpent") || 0}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setMentorActiveToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setMentorActiveToggle: checked });
                                                this.handleActiveMentorToggle();
                                            }
                                        }
                                        className={setMentorActiveToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'> Total number of mentors active</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeMentors[0].usersActive") || 0}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setMentorTotalTimeToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setMentorTotalTimeToggle: checked });
                                                this.handleMentorTotalTimeToggle();
                                            }
                                        }
                                        className={setMentorTotalTimeToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'>Total number of hours spent by mentors</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeMentors[0].totalTimeSpent") || 0}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setMentorAverageTimeToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setMentorAverageTimeToggle: checked });
                                                this.handleMentorAverageTimeToggle();
                                            }
                                        }
                                        className={setMentorAverageTimeToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'>Average number of hours spent by mentors</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "activeMentors[0].averageHoursSpent") || 0}</h3>
                            </div>
                        </div>

                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setProjectCountToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setProjectCountToggle: checked });
                                                this.handleProjectCountToggle();
                                            }
                                        }
                                        className={setProjectCountToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'>Total number of projects submitted by learners</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "projectCount")}</h3>
                            </div>
                        </div>
                        <div className='inner-count'>
                            <div className='permission-toggle'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setReflectionCountToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setReflectionCountToggle: checked });
                                                this.handleReflectionAnswersToggle();
                                            }
                                        }
                                        className={setReflectionCountToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className='text-color'> Total number of reflection questions answered by learners</h3>
                            </div>
                            <div>
                                <h3 className='number'>{get(data, "reflectionAnswerCount")}</h3>
                            </div>
                        </div>
                    </div>

                    <div className='module-data total-count'>
                        <div className='text-color individual-total-count'>
                            <div className='inner-text size'>{selectedMonth ? 'Total no of learners added this month:' : 'Total no of learners:'}</div>
                            <span className='number'> {get(data, "totalStudents")}</span>
                        </div>
                        <div className='text-color individual-total-count'>
                            <div className='inner-text size'>{selectedMonth ? 'Total no of mentors added this month:' : 'Total no of mentors:'}</div>
                            <span className='number'> {get(data, "totalMentors")}</span>
                        </div>
                    </div>

                    <div className='module-data'>
                        <div className='module-inner'>
                            <div className='permission-toggle-module'>
                                <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                <div className='permission-data'>
                                    <Switch
                                        checked={setCategoryToggle}
                                        onChange={
                                            (checked) => {
                                                this.setState({ setCategoryToggle: checked });
                                                this.handleCategoryToggle();
                                            }
                                        }
                                        className={setCategoryToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                    />
                                </div>
                            </div>
                            {data?.categories?.length ? <>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Category</th>
                                                <th>No. of Modules Viewed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderCategoryTableRows()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='module-text'>
                                    Total number of modules viewed by the learners: {this.totalModuleCount()}
                                </div></> : <EmptyState title="Oops!" description="No Record For Categories Found &#128533;" />}
                        </div>
                    </div>
                    <div className='module-data'>
                        <div className='permission-toggle-module'>
                            <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                            <div className='permission-data'>
                                <Switch
                                    checked={setHourComparisionToggle}
                                    onChange={
                                        (checked) => {
                                            this.setState({ setHourComparisionToggle: checked });
                                            this.handleHourComparisionToggle();
                                        }
                                    }
                                    className={setHourComparisionToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                />
                            </div>
                        </div>

                        <div className='hour-comparison-filter'>
                            <div className='clear-filter-alignment' onClick={this.clearHourComparisionFilters}>
                                {this.isMonthComparisionFilter()}
                            </div>
                            <div className='permission-toggle-module'>
                                <DatePicker
                                    className='date-picker'
                                    picker="month"
                                    placeholder="Month 1"
                                    style={{ width: 200 }}
                                    onChange={this.handleMonth1Change}
                                    value={month1 && returnMomentObject(month1)}
                                    disabledDate={this.disabledDate}
                                />
                            </div>
                            <div className='permission-toggle-module'>
                                <DatePicker
                                    className='date-picker'
                                    picker="month"
                                    placeholder="Month 2"
                                    style={{ width: 200 }}
                                    onChange={this.handleMonth2Change}
                                    value={month2 && returnMomentObject(month2)}
                                    disabledDate={this.disabledDate}
                                />
                            </div>
                            <div className='permission-toggle-module'>
                                <DatePicker
                                    className='date-picker'
                                    picker="month"
                                    placeholder="Month 3"
                                    style={{ width: 200 }}
                                    onChange={this.handleMonth3Change}
                                    value={month3 && returnMomentObject(month3)}
                                    disabledDate={this.disabledDate}
                                />
                            </div>
                        </div>
                        {isComparisionSpinner ? <Spin style={{ margin: 80 }} /> : <>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Month</th>
                                            <th>No. of hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td className='hour-comparison-month'>{getMonth(month1)} {this.deleteMonth1('1')}</td>
                                            <td>{getHoursPerMonth(month1, data)}</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td className='hour-comparison-month'>{getMonth(month2)} {this.deleteMonth2('2')}</td>
                                            <td> {getHoursPerMonth(month2, data)}</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td className='hour-comparison-month'>{getMonth(month3)}  {this.deleteMonth3('3')}</td>
                                            <td> {getHoursPerMonth(month3, data)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='module-text'>
                                Total hours spent in a month by the learners
                            </div>
                        </>}
                    </div>

                    <div className='top-performers'>
                        <div className='max-time'>
                            <div className='top'>
                                <div className='permission-toggle-module'>
                                    <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                    <div className='permission-data'>
                                        <Switch
                                            checked={setTimeBasedToggle}
                                            onChange={
                                                (checked) => {
                                                    this.setState({ setTimeBasedToggle: checked });
                                                    this.handleTimeBasedToggle();
                                                }
                                            }
                                            className={setTimeBasedToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                        />
                                    </div>
                                </div>
                                {data.timeBasedStudents.length ? <>
                                    <div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>learner Name</th>
                                                    <th>Number of hours</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderMostTimeTableRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='module-text'>
                                        Top learners who spent the most time on the portal
                                    </div>
                                </> : <EmptyState title="Oops!" description="No Record For Top Learners With Max Time Spent Found &#128533;" />}
                            </div>
                        </div>
                        <div className='max-time'>
                            <div className='top'>
                                <div className='permission-toggle-module'>
                                    <div className='permission-data'><h3 className='font-weight'>Show to super mentor</h3></div>
                                    <div className='permission-data'>
                                        <Switch
                                            checked={setReflectionBasedToggle}
                                            onChange={
                                                (checked) => {
                                                    this.setState({ setReflectionBasedToggle: checked });
                                                    this.handleReflectionBasedToggle();
                                                }
                                            }
                                            className={setReflectionBasedToggle ? 'toggle-switch toggle-btn-alignment ' : 'toggle-btn-alignment '}
                                        />
                                    </div>
                                </div>
                                {data.reflectionBasedStudents.length ? <>
                                    <div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>learner Name</th>
                                                    <th>Number of reflection questions answered</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderMostAnswersTableRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='module-text'>
                                        Top learners who answered maximum reflection questions
                                    </div>
                                </> : <EmptyState title="Oops!" description="No Record Found For Learners With Reflection Question Answers &#128533;" />}
                            </div>
                        </div>
                        <ErrorModal
                            isModalVisible={errorModal}
                            onClose={this.errorToggleModal}
                            title={get(errorModal, "title")}
                            description={get(errorModal, "description")}
                        />
                    </div>
                </div>)
        );
    }
}

const mapStateToProps = (store) => {
    return store;
};

export default connect(mapStateToProps, {})(Welcome);