import { get, debounce } from "lodash";
import React, { Component } from "react";
import { Row, Col, Button } from "antd";

import defaultImage from "../../../assets/blogs.svg";

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import TextareaPrimary from "../../../components/TextareaPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ImageModuleCard from "../../../components/ImageModuleCard";
import Loader from "../../../components/PrimaryLoader";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";
import PrimaryAutoComplete from '../../../components/PrimaryAutoComplete';

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES, SEARCHDELAY } from "../../../constants";

//Data Managers
import * as DM from "../dataManager";
import * as CDM from '../../../common/dataManager';

//Parser
import { validate, parseError } from "../parser";

class CareerNew extends Component {
  constructor(props) {
    super();
    this.handleAutoComplete = debounce(this.handleAutoComplete, SEARCHDELAY.searchDelay);
    this.state = {
      shown: true,
      isLoader: false,
      successShowModal: null,
      errorShowModal: null,
      originalName: null,
      career: {
        name: null,
        status: "ACTIVE",
        modules: [],
        description: null
      },
      errorObj: null,
      autoCompleteVal: "",
      autoCompleteOptions: [],
      moduleImages: []
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificCareerData(editableID);
    }
  }

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    state.career[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handleSizeChange = () => {
    this.setState({ shown: !this.state.shown });
  };

  fillModuleImages = () => {
    let moduleImages = [];

    let modules = get(this.state.career, "modules");
    modules && modules.map(module => {
      moduleImages.push({
        imgUrl: get(module, "media.path", null),
        title: module.name,
        id: module.id
      });
      return null;
    })

    this.setState({ moduleImages });
  }

  //API Calling Function
  getSpecificCareerData = async (id) => {
    this._startLoader();
    await DM.getSpecificCareerServies(id)
      .then((res) => {
        this.setState({ career: res, originalName: get(res, "name") });
        this.fillModuleImages();
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  };

  handleAutoComplete = (value) => {
    CDM.getAllModulesService({
      search_type: "CUSTOM",
      status: "PUBLISH",
      page: 1,
      limit: 10,
      keyword: value
    })
      .then((res) => {
        let modules = get(res, "data.results");
        modules = modules.map(module => {
          return {
            label: module.name,
            value: [module.name, get(module, "id", ""), get(module, "media[0].path", "")]
          }
        })
        let selectedModules = this.state.career.modules;
        modules = modules.filter(module => {
          return !selectedModules.includes(module.value[1]);
        })
        this.setState({ autoCompleteOptions: modules })
      })
      .catch((err) => {

      });
  }

  onSelect = (value) => {
    let { moduleImages, career } = this.state;
    let modulesArr = career.modules;
    if (modulesArr.includes(value[1])) {
      this.setState({ autoCompleteVal: "" })
      return;
    }
    modulesArr.push(value[1]);
    moduleImages.push({
      imgUrl: value[2],
      title: value[0],
      id: value[1]
    })
    this.setState({
      autoCompleteVal: "",
      career: { ...this.state.career, modules: modulesArr },
      moduleImages
    })
  }

  removeModule = (id) => {
    let selectedModules = this.state.career.modules;
    let selectedModuleImages = this.state.moduleImages;
    selectedModules = selectedModules.filter(module => {
      if (typeof module === "object")
        return module.id !== id;
      return module !== id;
    })
    selectedModuleImages = selectedModuleImages.filter(module => {
      return module.id !== id;
    })
    this.setState({
      moduleImages: selectedModuleImages,
      career: { ...this.state.career, modules: selectedModules }
    })
  }

  handleSave = () => {
    const { career, originalName } = this.state;

    const checkValidate = validate(career);

    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorShowModal: { msg: "Validation error occurred" },
      });
      return;
    }

    this._startLoader();
    const editableID = this.props.match.params.id;
    if (editableID) {
      if (career.name === originalName)
        delete career.name;
      delete career.id;
      career.modules = career.modules && career.modules.map(module => {
        if (typeof module === "object")
          return module.id;
        return module;
      })
      // console.log(career);
      DM.patchUpdateCareerService(editableID, career)
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: { msg: "Career is updated." },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: { msg: "Validation error occurred" },
          });
        });
    } else {
      DM.postCreateCareerService(career)
        .then((res) => {
          this._stopLoader();
          this.setState({
            successShowModal: { msg: "Career is created." },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errorObj: parseError(err),
            errorShowModal: { msg: "Validation error occurred" },
          });
        });
    }
  }

  render() {
    // const dataColumns = [
    //   {
    //     imgUrl:
    //       "https://qnlo.dk/wp-content/uploads/2020/05/pfSNx3Z12K8-1280x720.jpeg",
    //     title: "title",
    //   }
    // ];

    const {
      career,
      isLoader,
      errorShowModal,
      successShowModal,
      autoCompleteOptions,
      autoCompleteVal,
      errorObj
    } = this.state;

    const status = get(career, "status");
    const editableID = this.props.match.params.id;
    let dataColumns = this.state.moduleImages;

    return (
      <>
        <PageTitle title="Career" />
        <div className="new-career-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.CAREERS,
                  editableID
                    ? { text: "Edit career" }
                    : { text: "Add new career" },
                ]}
              />
              <h4 className="title4">
                {editableID ? "Edit career" : " Add new career"}
              </h4>
            </div>
            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${status === "ACTIVE" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("ACTIVE", "status");
                  }}>
                  Active
                </Button>
                <Button
                  className={`btn-toggle ${status === "INACTIVE" ? "btn-active" : ""
                    }`}
                  onClick={() => {
                    this.setStateValue("INACTIVE", "status");
                  }}>
                  Inactive
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="input-item width--half">
              <p className="lable">Career Title <span className="required-text">*</span></p>
              <InputPrimary
                value={get(career, "name")}
                placeholder="Career Name"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "name");
                }}
                errorText={get(errorObj, "name")}
              />
            </div>

            <div className="input-item width--half">
              <p className="lable">Description <span className="required-text">*</span></p>
              <TextareaPrimary
                value={
                  get(career, "description")
                }
                rows={4}
                placeholder="Description"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "description");
                }}
                errorText={get(errorObj, "description")}
              />
            </div>

            <div className="input-item width--half">
              <p className="lable">Add related modules</p>
              <PrimaryAutoComplete
                value={autoCompleteVal}
                onChange={(val) => {
                  this.setState({
                    autoCompleteVal: val
                  })
                }}
                placeholder="Search module name…"
                options={autoCompleteOptions}
                onSearch={(value) => {
                  this.handleAutoComplete(value);
                }}
                onSelect={this.onSelect}
              />
            </div>

            <div className="module-sec">
              <Row gutter={16}>
                {dataColumns.map((data, index) => {
                  return (
                    <Col className="gutter-row mt--50" xs={12} md={4} key={index}>
                      <ImageModuleCard
                        removeModule={() => this.removeModule(data.id)}
                        imgUrl={get(data, "imgUrl") || defaultImage}
                        title={data.title}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>

            <div className="mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>
          </div>

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
                this.props.history.push(ROUTES.CAREERS);
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

export default CareerNew;
