import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam } from '../../helpers';

export const getAllFeatureQuestions = async (params) => {
    let url = `${API.GET_ALL_FEATURE_QUESTIONS}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const createFeatureQuestion = async (payload) => {
    let url = API.POST_CREATE_FEATURE_QUESTION;
    return await axiosInstance.post(url, payload);
};

export const getFeatureQuestion = async (id) => {
    let url = API.GET_FEATURE_QUESTION.replace(":id", id);
    return await axiosInstance.get(url);
};

export const updateFeatureQuestion = async (id, payload) => {
    let url = API.PATCH_UPDATE_FEATURE_QUESTION.replace(":id", id);
    return await axiosInstance.patch(url, payload);
};

export const deleteFeatureQuestion = async (id) => {
    let url = API.DELETE_FEATURE_QUESTION.replace(":id", id);
    return await axiosInstance.delete(url);
};

