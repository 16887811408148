import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { get } from "lodash";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import RemoveModal from "../../components/modal/RemoveModal";
import LearnersCard from "../../components/LearnersCard";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Services
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

//Actions
import { setAllSubCategories } from "./actions";

class SubCategories extends Component {
  state = {
    removeShowModal: false,
    isLoader: false,
    errorModal: null,
    succModal: null,
    meta: {
      page: 1,
      limit: 10,
    },
    selected: {
      id: "",
      category_id: "",
    },
  };

  componentDidMount() {
    this.getAllSubCategoriesData();
  }

  getAllSubCategoriesData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    CDM.getAllSubCategoriesService(queryObject)
      .then((res) => {
        this.props.setAllSubCategories(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllSubCategoriesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = (id, category_id) => {
    this.setState({
      selected: {
        id: typeof id === "string" ? id : "",
        category_id: typeof category_id === "string" ? category_id : "",
      },
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  removeSubCategory = () => {
    this._startLoader();
    let { id, category_id } = this.state.selected;
    DM.deleteSubCategoryService(category_id, id)
      .then((res) => {
        this._stopLoader();
        this.getAllSubCategoriesData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Sub-category deleted successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const dataColumns = get(
      this.props,
      "categories.subCategories.results",
      null,
    );
    const permissions = get(this.props, "auth.permissions");
    const totalSubCategories = get(
      this.props,
      "categories.subCategories.meta.pagination.total",
      null,
    );

    let leftCol = [];
    let rightCol = [];
    dataColumns &&
      dataColumns.map((data, index) => {
        if (index % 2 === 0) {
          leftCol.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(data, "name")}</h5>
                </div>
              </div>
              <div className="right-box fx fx--ai-c">
                <div className="img-heading">
                  <p className="title6">{get(data, "categories.name")}</p>
                </div>
                <div className="right-sec">
                  {permissions.CATEGORIES &&
                    permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() => {
                          let url = ROUTES.EDITSUBCATEGORY.replace(
                            ":category_id",
                            get(data, "categories.id"),
                          );
                          url = url.replace(":id", data.id);
                          this.props.history.push(url);
                        }}
                      />
                    )}

                  {permissions.CATEGORIES &&
                    permissions.CATEGORIES.includes(ACTIONS.DELETE) && (
                      <ButtonDanger
                        type="link"
                        btnText={<DeleteOutlined />}
                        btnDangerStyle="btn delete btn-link"
                        onClick={() =>
                          this.removeToggleModal(
                            data.id,
                            get(data, "categories.id"),
                          )
                        }>
                        Remove
                      </ButtonDanger>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        } else {
          rightCol.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(data, "name")}</h5>
                </div>
              </div>
              <div className="right-box fx fx--ai-c">
                <div className="img-heading">
                  <p className="title6">{get(data, "categories.name")}</p>
                </div>
                <div className="right-sec">
                  {permissions.CATEGORIES &&
                    permissions.CATEGORIES.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() => {
                          let url = ROUTES.EDITSUBCATEGORY.replace(
                            ":category_id",
                            get(data, "categories.id"),
                          );
                          url = url.replace(":id", data.id);
                          this.props.history.push(url);
                        }}
                      />
                    )}

                  {permissions.CATEGORIES &&
                    permissions.CATEGORIES.includes(ACTIONS.DELETE) && (
                      <ButtonDanger
                        type="link"
                        btnText={<DeleteOutlined />}
                        btnDangerStyle="btn delete btn-link"
                        onClick={() =>
                          this.removeToggleModal(
                            data.id,
                            get(data, "categories.id"),
                          )
                        }>
                        Remove
                      </ButtonDanger>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        }
        return null;
      });

    return (
      <>
        <PageTitle title="Sub Categories" />
        <div className="sub-categories-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Sub Categories" }]} />
              <h4 className="title4"> List of All Sub Categories</h4>
            </div>
            <div className="right-section">
              {permissions.CATEGORIES &&
                permissions.CATEGORIES.includes(ACTIONS.CREATE) && (
                  <ButtonSecondary
                    btnText="Add new Sub-category"
                    href={ROUTES.NEWSUBCATEGORY}
                  />
                )}
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="list-style list-card-style mt--30 border-top-style">
              <Row gutter={[20]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="title-section">
                    <div className="left-box">
                      <p className="lead">Name</p>
                    </div>
                    <div className="right-box fx fx--ai-c">
                      <p className="lead">Category</p>
                      <p className="lead right-sec-panel">Action</p>
                    </div>
                  </div>
                  {leftCol}
                </Col>

                {rightCol.length > 0 && (
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <div className="left-box">
                        <p className="lead">Name</p>
                      </div>
                      <div className="right-box fx fx--ai-c">
                        <p className="lead">Category</p>
                        <p className="lead right-sec-panel">Action</p>
                      </div>
                    </div>
                    {rightCol}
                  </Col>
                )}
              </Row>
            </div>
          )}

          {isLoader && <Loader />}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this category"
              description="Are you sure you want to remove this category?"
              // Action button event here
              removeHandler={this.removeSubCategory}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}
          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}
          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}

          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                pageSize={meta.limit}
                current={meta.page}
                total={totalSubCategories}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllSubCategories,
})(SubCategories);
