import { get } from "lodash";

export const getIdsFromArray = (prevValue, value, key) => {
  let data = get(value, `${key}`);
  if (!data) data = get(prevValue, `${key}`);
  if (data && Array.isArray(data)) {
    let ids = [];

    data &&
      Array.isArray(data) &&
      data.map((val) => {
        ids.push(get(val, "id") || get(val, "value"));
        return null;
      });

    data = ids;
  }

  return data;
};

export const getData = (prevValue, value, key) => {
  let data = get(value, `${key}`);

  if (data === undefined) {
    data = get(prevValue, `${key}`);
  }
  return data;
};
