import * as SERVICE from "./services"

export const getAllSchoolProjects = (params) => {
    return SERVICE.getAllSchoolProjects(params).then((res) => {
        return res.data
    }).catch((err) => {
        throw err;
    })
}

export const getAllCategories = () => {
    return SERVICE.getAllCategories().then((res) => {
        return res.data
    }).catch((err) => {
        throw err;
    })
}

export const getSchoolProjectDetails = (id, params) => {
    return SERVICE.getSchoolProject(id, params).then((res) => {
        return res.data;
    }).catch((err) => {
        throw err;
    })
}

export const deleteSchoolProject = (id) => {
    return SERVICE.deleteSchoolProject(id).then((res) => {
        return res.data;
    }).catch((err) => {
        throw err;
    });
}

export const updateSchoolProject = (id, payload) => {
    return SERVICE.editSchoolProject(id, payload).then((res) => {
        return res.data;
    }).catch((err) => {
        throw err;
    });
}

export const createSchoolProject = (payload) => {
    return SERVICE.addSchoolProject(payload).then((res) => {
        return res.data;
    }).catch((err) => {
        throw err;
    })
}