export const acadruInteractiveBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  // var classPreFix = "acadru";
  // var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false" ><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false" >`;
  // var rowCol6End = `</div></div>`;

  // Swiper Slider
  blockManager.add("SwiperSlider", {
    label: `
    <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 7.6c0-1-.5-1.6-1.3-1.6H3.4C2.5 6 2 6.7 2 7.6v9.8c0 1 .5 1.6 1.3 1.6h17.4c.8 0 1.3-.6 1.3-1.6V7.6zM21 18H3V7h18v11z" fill-rule="nonzero"/><path d="M4 12.5L6 14v-3zM20 12.5L18 14v-3z"/>
    </svg>
    <div class="gjs-block-label">Step Slider</div>`,
    category: {
      id: "Acadru Interactive",
      label: "Acadru Interactive",
      open: false,
    },
    content: `
      <div data-gjs-type="swiper-container" data-gjs-removable="true" data-gjs-copyable="false"></div>
    `,
    grabCursor: true,
  });
};
