import * as SERVICES from "./services";

export const postCreateTagCategoryService = async (payload) => {
    return await SERVICES.createTagCategory(payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getTagCategoryService = async (id) => {
    return await SERVICES.getTagCategory(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdateTagCategoryService = async (id, payload) => {
    return await SERVICES.updateTagCategory(id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteTagCategoryService = async (id) => {
    return await SERVICES.deleteTagCategory(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

