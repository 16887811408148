import React, { Component } from "react";
import { Input } from "antd";

// Style
import "./styles.scss";

const { TextArea } = Input;

class TextareaPrimary extends Component {
  render() {
    return (
      <div className="textarea-primary-section">
        <TextArea
          rows={this.props.rows}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          defaultValue={this.props.defaultValue}
          className="textarea-primary"
        />
        <span className="text-danger">{this.props.errorText}</span>
      </div>
    );
  }
}

export default TextareaPrimary;
