export const getVideoView = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("video");
  const defaultView = defaultType.view;

  return defaultView.extend({
    init() {
      // The 'active' is triggered by the 'activeOnRender' you've used inside your block
      this.listenTo(this.model, "active", this.doStuff);
    },
    events: {
      dblclick: function () {
        this.doStuff();
      },
    },
    doStuff() {
      this.opts.config.em.get("Editor").runCommand("open-assets", {
        target: this.model,
        types: ["video"],
        accept: "video/*",
      });
    },
  });
};
