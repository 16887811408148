import { TYPES } from "../../containers/BlogCategory/Constants";
import { TYPES as BLOG_TYPE } from "../../containers/BlogManagement/Constants";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.SET_ALL_BLOG_CATEGORIES:
      return {
        ...state,
        blogCategories: action.payload,
        error: {},
      };
    case BLOG_TYPE.SET_ALL_BLOGS:
      return {
        ...state,
        allBlogs: action.payload,
        error: {},
      };
    default:
      return state;
  }
};
