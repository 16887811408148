import React, { Component } from "react";
import { Row, Col } from "antd";
import { get } from 'lodash';
import { connect } from "react-redux";

// Custom Components
import InputPrimary from "../../components/InputPrimary";
import ImageUpload from "../../components/ImageUpload";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import Loader from '../../components/PrimaryLoader';
import ErrorModal from '../../components/modal/ErrorModal';
import SuccessModal from '../../components/modal/SuccessModal';
import PhoneNoComponent from "../../components/PhoneNoComponent";
import ChangePasswordModal from "../../components/modal/ChangePasswordModal";

// images
import userImage from "../../assets/user-grey.svg";

//Actions
import { updateProfile } from './actions';

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Services
import * as DM from './dataManager';

//Helpers
import { validate, parseError, validatePassword } from './parser';
import { getMaxLengthForCountryCode } from '../../helpers'
class MyProfile extends Component {
  state = {
    errObj: null,
    isLoader: false,
    errorModal: null,
    succModal: null,
    admin: null,
    changePasswordModal: null
  };

  componentDidMount() {
    this.getProfileDetails();
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.admin[`${field}`] = value;
    this.setState(state);
  }

  setModalValue = (value, field) => {
    let state = this.state;
    state.changePasswordModal[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    })
  }

  changePasswordToggleModal = () => {
    this.setState({
      changePasswordModal: this.state.changePasswordModal ? null : {}
    })
  }

  getProfileDetails = () => {
    this._startLoader();
    DM.getProfileService()
      .then((res) => {
        this.setState({ admin: res.data });
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  handleSave = () => {
    const { admin } = this.state;
    const checkValidate = validate(admin);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    let { country_code } = admin;
    if (country_code) {
      if (country_code.includes("(")) {
        country_code = country_code.split("(")[1];
        country_code = country_code.substring(0, country_code.length - 1);
      }
      if (!admin.phone || !admin.phone.includes("-"))
        admin.phone = `${country_code}-${admin.phone}`;
    }
    this._startLoader();
    DM.updateProfileService(admin)
      .then((res) => {
        this._stopLoader();
        this.setState({
          errObj: null,
          succModal: { title: "Success", description: "Profile updated successfully." }
        })
        this.props.updateProfile({ data: res.data });
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errObj: parseError(err),
          errorModal: { description: get(err, "response.data.message", "") }
        })
      })
  }

  handleChangePassword = () => {
    const { changePasswordModal } = this.state;
    const checkValidate = validatePassword(changePasswordModal);

    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    this._startLoader();
    DM.changePasswordService(changePasswordModal)
      .then((res) => {
        this._stopLoader();
        this.setState({
          errObj: null,
          succModal: { title: "Success", description: "Password changed successfully." },
          changePasswordModal: null
        })
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errObj: parseError(err),
          errorModal: { description: get(err, "response.data.message", "") }
        })
      })
  }

  render() {
    const { admin, isLoader, errorModal, succModal, changePasswordModal, errObj } = this.state;
    const defaultImageUrl = get(admin, "media.path", null);
    if (admin) {
      if (!admin.country_code && admin.phone && admin.phone.includes("-")) {
        admin.country_code = admin.phone.split("-")[0];
        admin.phone = admin.phone.split("-")[1];
      }
    }

    return (
      <>
        <PageTitle title="My Profile" />
        <div className="my-profile-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "My Profile" }]} />
              <h4 className="title4"> My Profile</h4>
            </div>
          </div>

          <div className={`form-section`}>
            <p className="title">Basic Details</p>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="input-item">
                  <p className="lable">Profile photo</p>
                  <ImageUpload
                    defaultImage={true}
                    subText={defaultImageUrl ? "Change photo" : "Pick a photo from your computer"}
                    text={defaultImageUrl ? get(admin, "media.file_name") : "(Max file size: 10MB)"}
                    defaultImageUrl={defaultImageUrl || userImage}
                    onChange={(file) => {
                      let originalFileObject = file.originFileObj;
                      this.setStateValue(originalFileObject, "avatar");
                      this.setStateValue(file, "rawFile");
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        First Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        value={get(admin, "first_name", "")}
                        placeholder="John"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "first_name")
                        }}
                        errorText={get(errObj, "first_name")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Last Name <span className="required-text">*</span>
                      </p>
                      <InputPrimary placeholder="Dohe" value={get(admin, "last_name")}
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "last_name")
                        }}
                        errorText={get(errObj, "last_name")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Phone Number</p>
                      <PhoneNoComponent
                        // children={children}
                        codeVal={get(admin, "country_code")}
                        phoneVal={get(admin, "phone")}
                        onChangeCode={(val) => {
                          this.setStateValue(val, "country_code")
                        }}
                        onChangePhone={(e) => {
                          this.setStateValue(e.target.value, "phone")
                        }}
                        maxLength={getMaxLengthForCountryCode(get(admin, "country_code", null))}
                        errorText={get(errObj, "phone")}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">
                        Email Address <span className="required-text">*</span>
                      </p>
                      <InputPrimary
                        disabled={true}
                        value={get(admin, "email")}
                        type="email"
                        placeholder="johndohe@gmail.com"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "email")
                        }}
                        errorText={get(errObj, "email")}
                      />
                    </div>
                  </Col>
                </Row>

                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <div className="link change-password-link text-primary title6"
                        onClick={this.changePasswordToggleModal}
                      >
                        Change Password
                      </div>
                    </div>

                    {/* <div className="input-item">
                      <p className="lable">Password</p>
                      <InputPrimary
                        type="password" placeholder="password" onChange={(e) => {
                          this.setStateValue(e.target.value, "password")
                        }}
                      />
                    </div> */}
                  </Col>
                  {/* <Col xs={24} sm={24} md={12}>
                    <div className="input-item">
                      <p className="lable">Confirm Password</p>
                      <InputPrimary
                        // value={admin.confirmPassword}
                        type="password"
                        placeholder="Confirm password"
                        onChange={(e) => {
                          this.setStateValue(e.target.value, "confirmPassword")
                        }}
                      />
                    </div>
                  </Col> */}
                </Row>

              </Col>
            </Row>

            <div className="mt--40 text-right">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

            {isLoader && <Loader />}
            {errorModal &&
              <ErrorModal
                isModalVisible={errorModal}
                onClose={this.errorToggleModal}
                title={get(errorModal, "title")}
                description={get(errorModal, "description")}
              />
            }

            {succModal &&
              <SuccessModal
                isModalVisible={succModal}
                onClose={this.successToggleModal}
                title={get(succModal, "title")}
                description={get(succModal, "description")}
              />
            }

            {changePasswordModal &&
              <ChangePasswordModal
                isModalVisible={changePasswordModal}
                onCancel={() => {
                  this.setState({ changePasswordModal: null })
                }}
                setModalValue={this.setModalValue}
                errObj={errObj}
                onSubmit={this.handleChangePassword}
              />
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, { updateProfile })(MyProfile);
