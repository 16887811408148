import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { get } from 'lodash';

// Custom Components
import InputPrimary from "../../../components/InputPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import SelectInput from "../../../components/SelectInput";
import Loader from '../../../components/PrimaryLoader';
import ErrorModal from '../../../components/modal/ErrorModal';
import SuccessModal from '../../../components/modal/SuccessModal';

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES } from '../../../constants';

//Services
import * as DM from '../dataManager';
import * as CDM from '../../../common/dataManager';

//Parser
import { validate, parseError } from '../parser';
import { convertCase } from "../../../helpers";

class TagNew extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoader: false,
      errorModal: null,
      succModal: null,
      errObj: null,
      originalTagName: null,
      tag: {
        name: "",
        status: "ACTIVE",
        tag_category: "",
        tag_purpose: ""
      },
      tagCategories: []
    };
  }

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    this.getTagCategories();
    if (editableID) {
      await this.getSpecificTagDetials(editableID);
    }
  }

  getSpecificTagDetials = (id) => {
    this._startLoader();
    DM.getTagService(id, { include: "category" })
      .then((res) => {
        this.setState({
          tag: res.data,
          originalTagName: get(res, "data.name")
        })
        this._stopLoader();
      })
      .catch((err) => {

        this._stopLoader();
      });
  }

  getTagCategories = () => {
    this._startLoader();
    CDM.getAllTagCategoriesService({ status: "ACTIVE" })
      .then(res => {
        this._stopLoader();
        let tagCategories = get(res, "data.results");
        this.setState({ tagCategories })
      })
      .catch(err => {

        this._stopLoader();
      })
  }

  setStateValue = (value, field) => {
    let state = this.state;
    state.tag[`${field}`] = value;
    this.setState(state);
  }

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    }, () => {
      if (!this.state.succModal) this.props.history.push(ROUTES.TAGS);
    })
  }

  handleSave = () => {
    const { tag, originalTagName } = this.state;

    const editableID = this.props.match.params.id;
    if (!tag.tag_category)
      tag.tag_category = get(tag, "category.id", null);
    const checkValidate = validate(tag);
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
        errorModal: { title: "Validation Error Occured" }
      });
      return;
    }

    this._startLoader();
    if (editableID) {
      if (originalTagName === tag.name)
        delete tag.name;
      DM.patchUpdateTagService(editableID, tag)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Tag updated successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    } else {
      DM.postCreateTagService(tag)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: { title: "Success", description: "Tag created successfully." }
          })
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") }
          })
        })
    }
  }

  render() {

    const editableID = this.props.match.params.id;
    const { isLoader, errorModal, succModal, errObj, tag, tagCategories } = this.state;

    const tagCategoriesDropdown = tagCategories.map(tagCategory => {
      return (<option value={tagCategory.id} key={tagCategory.id}>
        {tagCategory.name}
      </option>
      )
    })

    return (
      <>
        <PageTitle title="Tags" />
        <div className="tags-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.TAGS,
                  { text: editableID ? "Edit Tag" : "Add new Tag" },
                ]}
              />
              <h4 className="title4"> {editableID ? "Edit Tag" : "Add new Tag"}</h4>
            </div>

            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${tag.status === "ACTIVE" ? "btn-active" : ""
                    }`}
                  onClick={() => this.setStateValue("ACTIVE", "status")}>
                  Active
                </Button>
                <Button
                  className={`btn-toggle ${tag.status === "ACTIVE" ? "" : "btn-active"
                    }`}
                  onClick={() => this.setStateValue("INACTIVE", "status")}>
                  Inactive
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Tag Name <span className="required-text">*</span></p>
                  <InputPrimary
                    placeholder="Enter tag name"
                    value={tag.name}
                    onChange={(e) => {
                      this.setStateValue(e.target.value, "name")
                    }}
                    errorText={get(errObj, "name")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Select a Tag category <span className="required-text">*</span></p>
                  <SelectInput
                    value={get(tag, "tag_category") || get(tag, "category.name")}
                    placeholder="Select tag category"
                    child={tagCategoriesDropdown}
                    onChange={(val) => {
                      this.setStateValue(val, "tag_category")
                    }}
                    errorText={get(errObj, "tag_category")}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col xs={24} sm={24} md={12}>
                <div className="input-item">
                  <p className="lable">Tag Purpose<span className="required-text">*</span></p>
                  <SelectInput
                    placeholder="Select tag purpose"
                    value={convertCase(get(tag, "tag_purpose")) || undefined}
                    options={[
                      "Social",
                      "SEO",
                      "Marketing",
                      "All",
                    ]}
                    onChange={(val) => {
                      this.setStateValue(val.toUpperCase(), "tag_purpose")
                    }}
                    errorText={get(errObj, "tag_purpose")}
                  />
                </div>
              </Col>
            </Row>

            <div className="mt--40">
              <ButtonPrimary btnText="Save" onClick={this.handleSave} />
            </div>

            {isLoader && <Loader />}
            {errorModal &&
              <ErrorModal
                isModalVisible={errorModal}
                onClose={this.errorToggleModal}
                title={get(errorModal, "title")}
                description={get(errorModal, "description")}
              />
            }
            {succModal &&
              <SuccessModal
                isModalVisible={succModal}
                onClose={this.successToggleModal}
                title={get(succModal, "title")}
                description={get(succModal, "description")}
              />
            }
          </div>
        </div>
      </>
    );
  }
}

export default TagNew;
