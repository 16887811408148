import React, { Component } from "react";
import {
  // Row,
  // Col,
  // Button,
  Space,
} from "antd";
import { connect } from "react-redux";
import { get, debounce } from "lodash";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import RemoveModal from "../../components/modal/RemoveModal";
import EmptyState from "../../components/EmptyState";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import Loader from "../../components/PrimaryLoader";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Helpers
import ReturnStatusColor from "../../helpers/returnStatusColor";
import { convertCase, truncate } from "../../helpers";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

//Actions
import { setAllFeatureQuestions } from "./actions";

//Service
import * as DM from "./dataManager";
import SearchPrimary from "../../components/SearchPrimary";

class FeatureQuestions extends Component {
  constructor(props) {
    super();
    this.getAllFeatureQuestionsData = debounce(
      this.getAllFeatureQuestionsData,
      800,
    );
    this.state = {
      removeShowModal: false,
      isLoader: false,
      errorModal: null,
      succModal: null,
      meta: {
        page: 1,
        limit: 10,
      },
      selected_id: "",
      keyword: null,
    };
  }

  componentDidMount() {
    this.getAllFeatureQuestionsData();
  }

  getAllFeatureQuestionsData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { meta, keyword } = this.state;
    const { page, limit } = meta;
    const queryObject = {
      limit: limit,
      page: page,
      search_type: "CUSTOM",
    };

    if (keyword) {
      queryObject["keyword"] = keyword;
    }

    DM.getAllFeatureQuestionsService(queryObject)
      .then((res) => {
        this.props.setAllFeatureQuestions(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllFeatureQuestionsData();
    });
  };

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {},
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  removeFeatureQuestion = () => {
    this._startLoader();
    const id = this.state.selected_id;
    DM.deleteFeatureQuestionService(id)
      .then((res) => {
        this._stopLoader();
        this.getAllFeatureQuestionsData();
        this.setState({
          succModal: {
            title: "Success",
            description: "Featured Question removed successfully.",
          },
        });
        this.removeToggleModal();
      })
      .catch((err) => {
        this.setState({
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", ""),
          },
        });
        this._stopLoader();
        this.removeToggleModal();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      meta,
      errorModal,
      succModal,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(
      this.props,
      "featureQuestions.allFeatureQuestions.results",
      null,
    );
    const totalFeatureQuestions = get(
      this.props,
      "featureQuestions.allFeatureQuestions.meta.pagination.total",
      null,
    );

    // const dataColumns = [
    //   {
    //     id: "#001",
    //     question: "question goes here…",
    //     moduleName: "Module Name",
    //     status: "Publish",
    //   }
    // ];

    return (
      <>
        <PageTitle title="Feature questions" />
        <div className="tags-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[{ text: "Featured Questions" }]}
              />
              <h4 className="title4"> All Featured Questions</h4>
            </div>
            <div className="right-section">
              <ButtonSecondary
                btnText="Add new"
                href={ROUTES.NEWFEATUREQUESTION}
              />
            </div>
          </div>

          <div className="find-section">
            <SearchPrimary
              placeholder="Search by question"
              onSearch={(e) => {
                let state = this.state;
                const value = e.target.value;
                state.meta.page = 1;
                state.meta.limit = 10;
                state.keyword = value;
                this.setState(state, () => {
                  this.getAllFeatureQuestionsData();
                });
              }}
            />
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <div className="table-card-style mt--40">
              <div className="table-style">
                <table className="table-custom-style">
                  <tr>
                    <th>ID</th>
                    <th>Question</th>
                    <th>Module Name</th>
                    <th>Status</th>
                    {permissions.MODULES &&
                      (permissions.MODULES.includes(ACTIONS.UPDATE) ||
                        permissions.MODULES.includes(ACTIONS.DELETE)) && (
                        <th>Action</th>
                      )}
                  </tr>
                  {dataColumns &&
                    dataColumns.map((data, index) => {
                      return (
                        <tr className="data-row table-tags" key={index}>
                          <td>
                            <p className="lead">
                              {get(data, "rawid") || "---"}
                            </p>
                          </td>
                          <td>
                            <div className="question title">
                              {truncate(get(data, "question"), 0, 100)}
                            </div>
                          </td>
                          <td>
                            <div className="question title">
                              {get(data, "module.name")}
                            </div>
                          </td>
                          <td>
                            <div className="flex-tag">
                              <span
                                className={`circle ${ReturnStatusColor(
                                  data.status,
                                )} mr-2`}></span>
                              <p className="lead mb--0">
                                {convertCase(get(data, "status")) + "ed"}
                              </p>
                            </div>
                          </td>
                          {permissions.MODULES &&
                            (permissions.MODULES.includes(ACTIONS.UPDATE) ||
                              permissions.MODULES.includes(ACTIONS.DELETE)) && (
                              <td>
                                <Space size="middle">
                                  {permissions.MODULES &&
                                    permissions.MODULES.includes(
                                      ACTIONS.UPDATE,
                                    ) && (
                                      <ButtonOutline
                                        btnText={<EditOutlined />}
                                        // btnStyle="btn-sm"
                                        btnStyle="btn edit square-edit"
                                        clickHandler={() => {
                                          this.props.history.push(
                                            ROUTES.EDITFEATUREQUESTION.replace(
                                              ":id",
                                              data.id,
                                            ),
                                          );
                                        }}
                                      />
                                    )}
                                  {permissions.MODULES &&
                                    permissions.MODULES.includes(
                                      ACTIONS.DELETE,
                                    ) && (
                                      <ButtonDanger
                                        type="link"
                                        btnText={<DeleteOutlined />}
                                        btnDangerStyle="btn delete btn-link"
                                        onClick={() =>
                                          this.removeToggleModal(data.id)
                                        }>
                                        Remove
                                      </ButtonDanger>
                                    )}
                                </Space>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          )}

          {/* <div className="list-style list-card-style mt--30 border-top-style">
            <div className="heading-container">
              <Row gutter={[20]}>
                <Col span={18}>
                  <p className="lead">Question</p>
                </Col>
                <Col span={3}>
                  <p className="lead">Status</p>
                </Col>
                <Col span={3}>
                  <p className="lead">Actions</p>
                </Col>
              </Row>
            </div>
            <LearnersCard>
              <Row gutter={[20]}>
                <Col span={18}>
                  <p className="lead description-style">
                    In consequat, quam id sodales hendrerit, eros mi molestie
                    leo, nec lacinia risus neque tristique augue. Proin tempus
                    urna vel congue elementum. Vestibulum euismod accumsan dui.
                  </p>
                </Col>
                <Col span={3}>
                  <div className="status">
                    <span className="circle dot-green"></span>
                    <p className="text-status"> Publish</p>
                  </div>
                </Col>
                <Col span={3}>
                  <div className="right-sec">
                    <ButtonOutline
                      btnText="Edit"
                      btnStyle="btn-sm"
                      className="button-outline"
                    />
                    <ToggleMenu>
                      <Button
                        type="link"
                        className="text-danger"
                        onClick={this.removeToggleModal}>
                        Delete
                      </Button>
                    </ToggleMenu>
                  </div>
                </Col>
              </Row>
            </LearnersCard>

            <LearnersCard>
              <Row gutter={[20]}>
                <Col span={18}>
                  <p className="lead description-style">
                    In consequat, quam id sodales hendrerit, eros mi molestie
                    leo, nec lacinia risus neque tristique augue. Proin tempus
                    urna vel congue elementum. Vestibulum euismod accumsan dui.
                  </p>
                </Col>
                <Col span={3}>
                  <div className="status">
                    <span className="circle dot-gray"></span>
                    <p className="text-status"> Unpublish</p>
                  </div>
                </Col>
                <Col span={3}>
                  <div className="right-sec">
                    <ButtonOutline
                      btnText="Edit"
                      btnStyle="btn-sm"
                      className="button-outline"
                    />
                    <ToggleMenu>
                      <Button
                        type="link"
                        className="text-danger"
                        onClick={this.removeToggleModal}>
                        Delete
                      </Button>
                    </ToggleMenu>
                  </div>
                </Col>
              </Row>
            </LearnersCard>
          </div> */}

          {isLoader && <Loader />}
          <RemoveModal
            isModalVisible={removeShowModal}
            onClose={this.removeToggleModal}
            title="Remove this featured question?"
            description="Are you sure you want to remove this featured question?"
            // Action button event here
            removeHandler={this.removeFeatureQuestion}
            dontRemoveHandler={this.removeToggleModal}
            // Action button text here
            removeBtnText="Yes, Remove"
            dontRemoveBtnText="Don't Remove"
          />
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />

          {dataColumns && dataColumns.length > 0 && (
            <div className="page-section">
              <PrimaryPagination
                pageSize={meta.limit}
                current={meta.page}
                total={totalFeatureQuestions}
                onChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  setAllFeatureQuestions,
})(FeatureQuestions);
