/* eslint-disable react/react-in-jsx-scope */
import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonDanger from "../../buttons/ButtonDanger";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import { Avatar, Checkbox, Modal, Spin } from "antd";
import SelectInput from "../../SelectInput";
import SearchPrimary from "../../SearchPrimary";
import EmptyState from "../../EmptyState";
import { get } from "lodash";
import * as PDM from "../../../containers/Package/dataManager"

import "./styles.scss"
import { PACKAGES } from "../../../constants";

class AssignPackageModal extends Component {
    constructor(props) {
        super();
        this.state = {
            isLoaded: false,
            top: 'topLeft',
            bottom: 'bottomRight',
            checked: [],
            packages: null,
        }
    }

    componentDidMount() {
        this.getAllPackagesData()
    }

    onChange = (e) => {
        let { checked } = this.state;
        if (e.target.checked && checked.length === 0) {
            this.setState({
                checked: [e.target.value],
                isChecked: true
            });
        } else {
            let idx = checked.indexOf(e.target.value);
            if (idx !== -1) checked.splice(idx, 1);
            this.setState({
                checked: checked,
                isChecked: true
            });
        }
    };

    getAllPackagesData = () => {
        // this._startLoader();
        const queryObject = {
            limit: 100,
            page: 1,
        };
        PDM.getAllPackagesService(queryObject)
            .then(async (res) => {
                const packages = [];
                await res?.data?.results.map((pkg) => {
                    if (pkg.name === PACKAGES.packages.STARTER_PACKAGE || pkg.name === PACKAGES.packages.PREMIUM_PACKAGE || pkg.name === PACKAGES.packages.ELITE_PACKAGE) {
                        packages.push(pkg);
                    }
                })
                this.setState({ packages: packages });
                // this._stopLoader();
            })
            .catch((err) => {
                // this._stopLoader();
            });
    };

    render() {
        const { isModalVisible, onClose, modalTitle, onClick } = this.props;
        const { checked, isLoaded, packages } = this.state;

        return (
            <div className="assign-package-modal">
                <Modal visible={isModalVisible} footer={null} width="350" className="assign-package-modal-style">
                    <div className="remove-modal">
                        <div className="assign-package-modal-header">
                            <h3 className="title3">{modalTitle}</h3>
                            {/* <SelectInput
                                defaultValue={limit}
                                value={limit}
                                isTitleActive
                                title="Limit:"
                                placeholder="Select"
                                options={[10, 25, 50, 100]}
                                onChange={(value) => this.handlePerPageChange(value)}
                            /> */}
                        </div>
                        <div className="modal-body mt--20">
                            {(!packages || packages.length === 0) ?
                                (isLoaded ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : <div className='spinner-container-am-modal'><Spin /></div>) :
                                <div className="checkbox-section">
                                    <Checkbox.Group>
                                        {packages && packages.map((packages, idx) => {
                                            return (
                                                <Checkbox className="checkbox-style" key={idx} value={packages.id} checked={checked.includes(packages.id)} onChange={this.onChange} disabled={!checked.includes(packages.id) && checked?.length > 0}>
                                                    <div className="fx fx--ai-fs mr--20">
                                                        <div className="learner-info-box fx fx--ai-fs fd-c card-box ml--20">
                                                            <span className="learner-name wrap-modal-field">{packages.name}</span>
                                                        </div>
                                                    </div>
                                                </Checkbox>
                                            )
                                        })}
                                    </Checkbox.Group>
                                </div>
                            }

                            <div className="text-right mt--10 footer-section">
                                <ButtonDanger onClick={onClose} btnText="Cancel" btnDangerStyle="mr--20" />
                                <ButtonPrimary onClick={() => onClick(checked)} btnText="Assign" disabled={checked.length === 0} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return store;
};

export default connect(mapStateToProps, {})(AssignPackageModal);