import { TYPES } from "../../containers/TagCategory/Constants";
import { SET_ALL_TAGS } from '../../containers/Tags/Constants';

export default (state = {}, action) => {
    switch (action.type) {
        case TYPES.SET_ALL_TAG_CATEGORIES:
            return {
                ...state,
                tagCategories: action.payload,
                error: {},
            };
        case SET_ALL_TAGS:
            return {
                ...state,
                tags: action.payload,
                error: {},
            };
        default:
            return state;
    }
};
