import { API } from "./Constants";
import { makeQueryParam, objectToFormdata } from "../../helpers";
import { axiosInstance } from "../../tools";
import { objectToFormdataFileUpload } from "../../helpers/objectToFormDataFileUpload";

export const getAllSchoolProjects = async (params) => {
    let url = `${API.GET_ALL_SCHOOL_PROJECTS}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
}

export const getAllCategories = async () => {
    let url = `${API.GET_ALL_CATEGORIES}`
    return await axiosInstance.get(url);
}

export const getSchoolProject = async (id, params) => {
    let url = `${API.GET_SCHOOL_PROJECT.replace(":id", id)}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
}

export const deleteSchoolProject = async (id) => {
    let url = API.DELETE_SCHOOL_PROJECT.replace(":id", id)
    return await axiosInstance.delete(url);
}

export const editSchoolProject = async (id, payload) => {
    let url = API.EDIT_SCHOOL_PROJECT.replace(":id", id)
    return await axiosInstance.patch(url, objectToFormdataFileUpload(payload))
}

export const addSchoolProject = async (payload) => {
    let url = API.ADD_SCHOOL_PROJECT
    return await axiosInstance.post(url, objectToFormdataFileUpload(payload))
}

