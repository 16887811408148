import { CODES } from "../constants";

const map = CODES.countryCodes;
export const getMaxLengthForCountryCode = (country_code) => {
    if (!country_code) return 10;
    for (let i = 0; i < map.length; i++) {
        if (map[i].value === country_code) {
            let lengthArr = map[i].length;
            return lengthArr.reduce(function (a, b) {
                return Math.max(a, b);
            })
        }

    }
    return 10;
}