export const POST_CREATE_USER = "/users";
export const GET_ALL_USERS = "/users";
export const GET_USER = "/users/:id";
export const GET_USER_MODULES = "/users/:id/modules";
export const POST_ASSIGN_MENTOR = "/users/assign-mentors";
export const POST_ASSIGN_LEARNERS = "/users/assign-learners";
export const POST_ASSIGN_CHILD_MENTORS = "/users/assign-child-mentor";
export const DELETE_ASSIGNED_MENTOR = "/users/:id/remove-assigned-mentor";
export const DELETE_ASSIGNED_MENTOR_ONE = "/users/:id/remove-assigned-mentor/:mentorId";
export const DELETE_ASSIGNED_SUPER_MENTOR = "/users/:id/remove-assigned-supermentor";
export const DELETE_ASSIGNED_SUPER_MENTOR_ONE = "/users/:id/remove-assigned-supermentor/:superMentorId";
export const PATCH_REMOVE_USER = "/users/:id";
export const PATCH_EDIT_USER = "/users/profile/:id";
export const GET_MENTOR_LEARNERS = "/users/:id/my-learners";
export const GET_SUPERMENTOR_MENTORS = "/users/:id/my-child-mentors";
export const POST_SUPERMENTOR_DASHBOARD = "/users/supermentor-dashboard"
export const GET_GRADE_SCHOOLS_FOR_SUPERMENTOR = "/users/school-grade"
export const REDIRECT_TO_LEARNER = "/user/learner/details/:learnerId"
export const UPDATE_LEARNER_PACKAGES = "/users/update-packages";