import { Card } from "antd";
import React, { Component } from "react";
import SelectInput from "../SelectInput";
import EmptyState from "../EmptyState";
import { breakTimestamp, convertCase, returnStatusColor } from "../../helpers";
import { ROUTES } from "../../constants";
import { get } from "lodash";
import PrimaryPagination from "../PrimaryPagination";

export class ViewedModules extends Component {
    render() {
        const { moduleFilter, dataColumns, meta, totalModules, isLearner, handleModuleChange, handlePageChange } = this.props;
        return (
            <>
                <Card className="mt--50">
                    <div className="table-container">
                        <div className="table-info-section">
                            <h4 className="title4">Modules</h4>

                            {isLearner ? (<div className="filter-select-section">
                                <SelectInput
                                    value={moduleFilter === "ASSIGNED" ? "Assigned" : "Viewed"}
                                    defaultValue="Viewed"
                                    isTitleActive={true}
                                    title="Filter by:"
                                    placeholder="Select here"
                                    options={["Assigned", "Viewed"]}
                                    onChange={(val) => {
                                        handleModuleChange(val.toUpperCase());
                                    }}
                                />
                            </div>) : null}
                        </div>

                        {(!dataColumns || dataColumns.length === 0) ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> :
                            <>
                                <div className="table-card-style">
                                    <div className="table-style">
                                        <table className="table-custom-style">
                                            <tr>
                                                <th>Module Name</th>
                                                <th>Last Viewed Date</th>
                                                <th> {isLearner ? 'Assigned Date' : 'First Viewed Date'}</th>
                                                <th> Assigned By </th>
                                                <th>Status</th>
                                            </tr>
                                            {dataColumns && dataColumns.map((data, index) => {
                                                return (
                                                    <tr className="data-row table-module" key={index}>
                                                        <td>
                                                            <div className="img-content-style">
                                                                <p className="lead">{data.module.name}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="lead">{isLearner ?
                                                                data.lastViewd ? breakTimestamp(data.lastViewd).date : "---" :
                                                                data.updated_at ? breakTimestamp(data.updated_at).date : "---"}</p>
                                                        </td>
                                                        <td>
                                                            <p className="lead">{breakTimestamp(data.created_at).date}</p>
                                                        </td>
                                                        <td>
                                                            <p className="lead link-text">
                                                                {get(data, "status") === "ASSIGNED" && data.assignedby ?
                                                                    <a className="lead link-text" href={ROUTES.VIEWMENTOR.replace(":id", get(data, "assignedby.id"))} target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {get(data, "assignedby.full_name")}
                                                                    </a>
                                                                    :
                                                                    get(data, "status") === "ASSIGNED" ? "---" : "Self"
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="flex-box">
                                                                <span
                                                                    className={`circle ${returnStatusColor(
                                                                        data.status,
                                                                    )} mr-2`}></span>
                                                                <p className="lead mb--0">{isLearner ? convertCase(get(data, "status")) : 'Viewed'}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </div>
                                </div>
                                <div className="page-section">
                                    <PrimaryPagination
                                        pageSize={meta.limit}
                                        current={meta.page}
                                        total={totalModules}
                                        onChange={handlePageChange} />
                                </div>
                            </>
                        }
                    </div>
                </Card>
            </>
        )
    }
}

export default ViewedModules;