import React, { Component } from 'react';
import "./styles.scss";
import { Alert } from 'antd';

// internet checker library
import { Offline } from "react-detect-offline";

class OfflineComponent extends Component {
  render() {
    return (
      <>
        <Offline
          polling={{
            url: 'https://api.acadru.com/health',
            interval: 20000,
            timeout: 10000
          }}
        >
          <div className="offline-box">
            <Alert
              showIcon={true}
              message="There is no internet connection! Please check your network setting and try again"
              type="warning" />
          </div>
        </Offline>
      </>
    );
  }
}

export default OfflineComponent;