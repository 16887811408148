import React, { Component } from 'react';

// Style
import "./styles.scss";

class LearnersCard extends Component {
  render() {
    return (
      <div className="learners-card-style">
        {this.props.children}
      </div>
    );
  }
}

export default LearnersCard;