import { addAudioComponent } from "./audio";
import { addDocumentComponent } from "./documents";
import { addVideoComponent } from "./video";
import { addIFrameComponent } from "./iframe";
import { addEmbedComponent } from "./embed";
import { addCardFlipComponent } from "./cardFlip";
import { addBlockersComponents } from "./blocker";
import { addImageWithLinkComponent } from "./imageWithLink";
import { addImageForVideoComponent } from "./imageForVideo";

export const addComponents = async (editor) => {
  addAudioComponent(editor);
  addDocumentComponent(editor);
  addVideoComponent(editor);
  addIFrameComponent(editor);
  addEmbedComponent(editor);
  addCardFlipComponent(editor);
  addBlockersComponents(editor);
  addImageWithLinkComponent(editor);
  addImageForVideoComponent(editor);
};
