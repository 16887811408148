import React, { Component } from "react";
import { Card, Row, Col } from "antd";
import { get } from 'lodash'

// Style
import "./styles.scss";

class ProfileAdminCard extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { admin } = this.props;
    return (
      <div className="profile-admin-section">
        <Card>
          <h5 className="title5 mb--20">Admin Profile Information:</h5>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xs={24} md={12} lg={12} xl={12}>
              <div className="info-box">
                <div className="title"> Full Name</div>
                <div className="avatar-section">
                  <p className="desciption">{get(admin, "full_name") || "---"}</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} md={12} lg={12} xl={12}>
              <div className="info-box">
                <div className="title">Email</div>
                <p className="desciption">{admin.email || "---"}</p>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} md={12} lg={12} xl={12}>
              <div className="info-box mt--20">
                <div className="title">Phone</div>
                <p className="desciption">{admin.phone || "---"}</p>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} md={12} lg={12} xl={12}>
              <div className="info-box mt--20">
                <div className="title">Role</div>
                <p className="desciption">{get(admin, "roles[0].name") || "---"}</p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default ProfileAdminCard;
