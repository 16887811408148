import { get } from 'lodash';
import { CODES } from '../../constants';

export const validate = (data) => {
    let errorObj = {};
    if (!data.first_name) {
        errorObj["first_name"] = "First Name is Required";
    }
    if (!data.last_name) {
        errorObj["last_name"] = "Last Name is Required";
    }
    if (!data.email) {
        errorObj["email"] = "Email is Required";
    }

    if (data.phone && !data.country_code) {
        errorObj["phone"] = "Country code is required with phone number";
    }

    if (data.phone) {
        if (data.phone.includes("-"))
            data.phone = data.phone.split("-")[1];
        const len = data.phone.length;
        const codes = CODES.countryCodes;
        for (let i = 0; i < codes.length; i++) {
            if (codes[i].value === data.country_code || codes[i].code === data.country_code) {
                if (!codes[i].length.includes(len)) {
                    errorObj["phone"] = "Phone number length is invalid";
                    break;
                }
            }
        }
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};

export const validatePassword = (data) => {
    let errorObj = {};
    if (!data.old_password) {
        errorObj["old_password"] = "Old Password is Required";
    }
    if (!data.password) {
        errorObj["password"] = "New Password is Required";
    }
    if (!data.password_confirmation) {
        errorObj["password_confirmation"] = "Confirm Password is Required";
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};

export const parseError = (Error) => {
    const err = get(Error, "response.data.error");
    let errorObj = {};

    if (err && err.first_name) {
        errorObj["first_name"] = get(err, "first_name[0]");
    }
    if (err && err.last_name) {
        errorObj["last_name"] = get(err, "last_name[0]");
    }
    if (err && err.phone) {
        errorObj["phone"] = get(err, "phone[0]");
    }
    if (err && err.email) {
        errorObj["email"] = get(err, "email[0]");
    }
    if (err && err.old_password) {
        errorObj["old_password"] = get(err, "old_password[0]");
    }
    if (err && err.password) {
        errorObj["password"] = get(err, "password[0]");
    }
    if (err && err.password_confirmation) {
        errorObj["password_confirmation"] = get(err, "password_confirmation[0]");
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};