export const acadruTextBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false"><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Main Heaidng
  blockManager.add("mainHeading", {
    label: "Main Heading",
    category: {
      id: "Acadru Text",
      label: "Acadru Text",
      open: false,
    },
    attributes: { class: "fa fa-font" },
    content: `
  ${rowCol6Start}
    <div class='${classPreFix}-main-heading' data-gjs-droppable="false" data-gjs-draggable="false"  data-gjs-removable="false" data-gjs-selectable="false">Main Page Heading</div>
  ${rowCol6End}
  `,
  });

  // Heaidng
  blockManager.add("Heading", {
    label: "Heading",
    category: "Acadru Text",
    attributes: { class: "fa fa-header" },
    content: `
    ${rowCol6Start}
      <h1 class='${classPreFix}-h1' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Heading</h1>
    ${rowCol6End}
    `,
  });
  // Subheaidng
  blockManager.add("Subheading", {
    label: "Subheading",
    category: "Acadru Text",
    attributes: { class: "fa fa-subscript" },
    content: `
    ${rowCol6Start}
      <h3 class='${classPreFix}-h3' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Subheading</h3>
    ${rowCol6End}
    `,
  });

  // text
  blockManager.add("Text", {
    label: "Acadru Text",
    category: "Acadru Text",
    attributes: { class: "gjs-fonts gjs-f-text" },
    content: `
      <p class='${classPreFix}-p' data-gjs-droppable="true" data-gjs-draggable="true" data-gjs-removable="true" data-gjs-selectable="true">Text</p>
    `,
  });

  // Paragrapgh
  blockManager.add("paragraph", {
    label: "Paragraph",
    category: "Acadru Text",
    attributes: { class: "fa fa-paragraph" },
    content: `
    ${rowCol6Start}
      <p class='${classPreFix}-p' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
    ${rowCol6End}
    `,
  });

  // Heading with Paragraph
  blockManager.add("headingWithPara", {
    label: "Heading with Paragraph",
    category: "Acadru Text",
    attributes: { class: "fa fa-file-text" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-heading-with-paragraph" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <h1 class="${classPreFix}-h1" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Heading</h1>
        <p class='${classPreFix}-p' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
      </div>
    ${rowCol6End}
    `,
  });

  // Subheading with Paragraph
  blockManager.add("subHeadingWithPara", {
    label: "Subheading with Paragraph",
    category: "Acadru Text",
    attributes: { class: "fa fa-file-text" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-heading-with-paragraph" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <h3 class="${classPreFix}-h3" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Subheading</h3>
        <p class='${classPreFix}-p' data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
      </div>
    ${rowCol6End}
    `,
  });

  // 2 Column Paragrapgh
  blockManager.add("twoColumnParagraph", {
    label: "2 Column Paragraph",
    category: "Acadru Text",
    attributes: { class: "fa fa-columns" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-two-column-layout paragraph" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</div>
        <div class="${classPreFix}-half-column" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</div>
      </div>
    ${rowCol6End}
    `,
  });

  // table
  blockManager.add("acadruTable", {
    label: "Table",
    category: "Acadru Text",
    attributes: { class: "fa fa-table" },
    activate: 1,
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-table" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <table data-gjs-type="table" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false"></table>
      </div>
    ${rowCol6End}
    `,

    // content: {
    //   type: "table",
    //   activeOnRender: true,
    //   content: `
    //     ${rowCol6Start}
    //       <table data-gjs-type="table" data-gjs-droppable="false">

    //       </table>
    //     ${rowCol6End}
    //   `,
    // },
  });

  // blockManager.add("heading1", {
  //   label: "Heading1",
  //   category: "custom",
  //   attributes: { class: "fa fa-header" },

  //   // content:
  //   content: `<h1 class='${classPreFix}-h1 mtb-30'>Heading1</h1>`,
  // });

  // blockManager.add("Text", {
  //   label: "Text",
  //   category: "custom",
  //   attributes: { class: "gjs-fonts gjs-f-text" },
  //   content: `<div class="mtb-30 ${classPreFix}-customTextBox"><p class='${classPreFix}-description'>Insert your text here</P></div>`,
  // });

  // blockManager.add("contentWithImage", {
  //   label: "Content with Image",
  //   category: "custom",
  //   attributes: { class: "fa fa-address-card-o" },

  //   // content:
  //   content: `<div class='${classPreFix}-contentWithImage-mainDiv mtb-30'><h3 class='${classPreFix}-h3 '>Subheading1</h3> <p class='${classPreFix}-p'>This is a paragraph.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //   <div class='${classPreFix}-imgDiv' style="border-radius: 15px; height: 350px; position: relative; margin-top: 94px; overflow: hidden; margin-left: 50px; margin-right: 50px;">
  //   <img class='${classPreFix}-img' style="width: 100%; height: 100%; position: absolute; object-fit: cover;" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image"></div>
  //   <p class='${classPreFix}-imgCaption' style="font-style: italic;  margin-left: 50px; opacity: 0.7;"> <strong>Caption :</strong> This is where the caption of the image will come.</p>
  //   </div>`,
  // });

  // blockManager.add("informationImageBox", {
  //   label: "information Image Box",
  //   category: "custom",
  //   attributes: { class: "fa fa-newspaper-o" },

  //   // content:
  //   content: `<div class='${classPreFix}-informationImageBox mtb-30'>
  //       <div class='${classPreFix}-content-box' style="display: inline-block;">
  //         <div class='${classPreFix}-half-box' style="width: 49%; float: left">
  //           <div class='${classPreFix}-img-box' style="border-radius: 15px; height: 325px; width: 100%; position: relative; overflow: hidden; margin-right: 70px;">
  //             <img class='${classPreFix}-img' style="width: 100%; height: 100%; position: absolute; object-fit: cover;" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image">
  //           </div>
  //           <p class='${classPreFix}-img-caption' style="font-style: italic; opacity: 0.7; margin-left: 10px;"> <strong>Caption :</strong> This is where the caption of the image will come.</p>
  //         </div>

  //         <div class='${classPreFix}-half-box' style="float: left; width: 49%; padding-left: 50px;">
  //           <h3 class='${classPreFix}-subheading'> Subheading 2</h3>
  //           <p class='${classPreFix}-description'>This is a paragraph.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //         </div>
  //       </div>

  //       <div class='${classPreFix}-content-box' style="display: inline-block;">
  //         <div class='${classPreFix}-half-box' style="width: 49%; float: left; border-right: 4px solid #F99028; padding-right: 72px;">
  //         <p class='${classPreFix}-description'>This is a paragraph.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //       </div>

  //       <div class='${classPreFix}-half-box' style="width: 49%; float: left; padding-left: 72px;">
  //         <p class='${classPreFix}-description'>This is a paragraph.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //         </div>
  //       </div>
  //     </div>`,
  // });

  // blockManager.add("imageFullContentBox", {
  //   label: "Full Image with Content",
  //   category: "custom",
  //   attributes: { class: "fa fa-braille" },

  //   // content:
  //   content: `<div class='${classPreFix}-imageFullContentBox mtb-30'>
  //       <div class='${classPreFix}-img-box'>
  //         <div class='${classPreFix}-img-div' style="height: 350px; position: relative; margin-top: 94px; overflow: hidden;">
  //           <img class='${classPreFix}-img' style="width: 100%; height: 100%; position: absolute; object-fit: cover;" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image">
  //         </div>
  //         <p class='${classPreFix}-img-caption' style="font-style: italic; opacity: 0.7;"><strong>Caption :</strong> This is where the caption of the image will come.</p>
  //       </div>
  //       <div class='${classPreFix}-content-box' style="margin-top: 50px;">
  //         <h3 class='${classPreFix}-subheading'>Subheading1</h3>
  //         <p class='${classPreFix}-description'>This is a paragraph.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //         <div class='${classPreFix}-horizontal-seperator' style="height: 4px; background: #F99028; width: 80%; margin: 70px auto 0;"></div>
  //       </div>
  //     </div>`,
  // });

  // blockManager.add("imageContentOverlay", {
  //   label: "image Content Overlay",
  //   category: "custom",
  //   attributes: { class: "fa fa-window-restore" },

  //   // content:
  //   content: `<div class='${classPreFix}-imageContentOverlay mtb-30'>
  //       <div class='${classPreFix}-content-box'>
  //         <h3 class='${classPreFix}-subheading'>Subheading 1</h3>
  //         <p class='${classPreFix}-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
  //         <p class='${classPreFix}-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  //       </div>

  //       <div class='${classPreFix}-outer-box' style="margin-top: 50px; position: relative; height: 350px; overflow: hidden; overflow: hidden; border-radius: 15px;">

  //         <div class='${classPreFix}-content-over-img' style="position: absolute; background: rgba(0,0,0,0.5); z-index: 9; top: 0; left: 0; width: 100%; height: 100%;">
  //           <div class='${classPreFix}-content-box' style="padding-left: 50px;">
  //             <div class='${classPreFix}-horizontal-seperator' style="height: 4px; background: white; width: 20%; margin: 30px 0 20px;"></div>
  //             <p class='${classPreFix}-description' style="max-width: 50%; color: white; line-height: 30px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
  //           </div>
  //         </div>

  //         <div class='${classPreFix}-img-box' style="height: 350px; position: relative; overflow: hidden;">
  //           <img class='${classPreFix}-img' style="width: 100%; height: 100%; position: absolute; object-fit: cover;" src="https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt="image">
  //           </div>
  //         <p class='${classPreFix}-img-caption' style="font-style: italic; opacity: 0.7;"><strong>Caption :</strong> This is where the caption of the image will come.</p>
  //       </div>
  //     </div>`,
  // });

  // blockManager.add("iframeVideoBox", {
  //   label: "Iframe Video Box",
  //   category: "custom",
  //   attributes: { class: "fa fa-file-video-o" },

  //   // content:
  //   content: `<div class='${classPreFix}-iframeVideoBox mtb-30' style="padding: 30px; border: 1px solid #ddd;">
  //       <div class='${classPreFix}-iframe-content'>
  //         <iframe class='${classPreFix}-iframe' style="padding: 20px" allowfullscreen="true" webkitallowfullscreen="true"
  //         mozallowfullscreen="true" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" width="100%" height="361">
  //         </iframe>
  //       </div>
  //       <div class='${classPreFix}-iframe-content-box'>
  //         <h3 class='${classPreFix}-h3'>In 1994 we still pushed a __heavy bucket and rag mop__ around the house, watching the soapy water turn dark with dirt well before the job was done.</h3>
  //         <p class='${classPreFix}-description'>The year was 1994. New technologies and means of communication had begun to transform every aspect of our lives. Yet we still cleaned our floors the same way our mothers had-pushing a heavy, sloshing bucket and rag mop around the house, watching the soapy water turn dark with dirt well before the job was done.</p>
  //         <a class='${classPreFix}-link' href="#" style="color: #313537; font-weight: bold;  text-decoration: none;">READ MORE CONTINUUMINNOVATION <span class='${classPreFix}-right-chevron' style="position: relative; top: 2px;"><img src="https://www.flaticon.com/svg/static/icons/svg/32/32213.svg" width="15"></span></a>
  //       </div>
  //     </div>`,
  // });

  // blockManager.add("ImageContentBox", {
  //   label: "Image Content Box ",
  //   category: "custom",
  //   attributes: { class: "fa fa-image" },

  //   // content:
  //   content: `<div class='${classPreFix}-ImageContentBox mtb-30' style="padding: 30px; border: 1px solid #ddd;">
  //       <div class='${classPreFix}-img-box' style="height: 361px; position: relative; overflow: hidden;">
  //         <img style="width: 100%; height: 100%; position: absolute; object-fit: cover;" src=" https://images.ctfassets.net/85176i98xmcy/5MoI1UwfcsUOGA62i0UM0c/526b2fa8a53fb2f672d3ae4b78ae4e35/swiffer_insights_02.jpg" alt="image">
  //       </div>
  //       <div class='${classPreFix}-img-content'>
  //         <h3 class='${classPreFix}-h3'>In 1994 we still pushed a __heavy bucket and rag mop__ around the house, watching the soapy water turn dark with dirt well before the job was done.</h3>
  //         <p class='${classPreFix}-description'>The year was 1994. New technologies and means of communication had begun to transform every aspect of our lives. Yet we still cleaned our floors the same way our mothers had-pushing a heavy, sloshing bucket and rag mop around the house, watching the soapy water turn dark with dirt well before the job was done.</p>
  //         <a class='${classPreFix}-link' href="#" style="color: #313537; font-weight: bold; position: relative; top: 2px; text-decoration: none;">READ MORE CONTINUUMINNOVATION <span style="position: relative; top: 2px;"><img src="https://www.flaticon.com/svg/static/icons/svg/32/32213.svg" width="15"></span></a>
  //       </div>
  //     <div>`,
  // });

  // blockManager.add("Image", {
  //   label: "Image",
  //   category: "custom",
  //   attributes: { class: "fa fa-picture-o" },

  //   // content:
  //   content: `<div class='${classPreFix}-image mtb-30' style="display:flex; justify-content: flex-end; margin: 1.5rem 0;">
  //       <img class='${classPreFix}-img' style="border-radius: 0.5rem" src="https://www.gstatic.com/webp/gallery/1.jpg" alt="image" width="100%" height="100%">
  //     </div>`,
  // });

  // blockManager.add("Caption", {
  //   label: "Caption",
  //   category: "custom",
  //   attributes: { class: "fa fa-sticky-note-o" },

  //   // content:
  //   content: `<div class='${classPreFix}-caption mtb-30' style="margin-top: 1rem">Cation: <span style="padding-left: 0.5rem;">Write Caption of image</span></div>`,
  // });
};
