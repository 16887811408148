import { getVideoModel } from "./model";
import { getVideoView } from "./view";

export const addVideoComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("video", {
    model: getVideoModel(editor),
    view: getVideoView(editor),
  });
};
