import React, { Component } from 'react';
import { Modal } from 'antd';

// Style
import "./styles.scss"
import ButtonPrimary from '../../buttons/ButtonPrimary';
// import ButtonDanger from '../../buttons/ButtonDanger';
import successImg from "../../../assets/checked.svg";

class SuccessModal extends Component {
  state = {
    top: 'topLeft',
    bottom: 'bottomRight',
  };

  render() {
    const { isModalVisible, onClose } = this.props;

    return (
      <Modal visible={isModalVisible} centered footer={null} className="success-modal-style">
        <div className="success-modal">
          <img className="icon" src={successImg} alt="icon" />
          <h3 className="title3">{this.props.title}</h3>
          <h6 className="title6">{this.props.description}</h6>

          <div className="footer-section">
            {/* <ButtonDanger onClick={onClose} btnText="No" btnDangerStyle="mr--20" /> */}
            <ButtonPrimary onClick={onClose} btnText="Okay" />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SuccessModal;
