export const POST_CREATE_MODULE_TABS = "/modules/:id/tabs";
export const PATCH_UPDATE_MODULE_TABS =
  "/modules/:module_id/tabs/:tab_id?include=tabs";
export const DELETE_MODULE = "/modules/:id";
export const PATCH_UPDATE_MODULE = "/modules/:id";
export const GET_SPECIFIC_MODULE =
  "/modules/auth/:id?include=tabs,projects,careers,tags";
export const GET_MY_VIEWED_MODULES = "/modules/my-viewed-modules";
export const POST_TAB_FILE_UPLOAD = "/modules/:id/tabs/file-upload";
export const GET_WEB_SCRAPPING_DATA = "/modules/embed-card-details";
export const POST_CREATE_MODULE = "/modules?include=tags,categories,careers";

export const POST_CRETE_PROJECT = "/modules/:id/projects?include=projects";
export const GET_ALL_PROJECT = "/modules/:id/projects";
export const PATCH_UPDATE_PROJECT =
  "/modules/:id/projects/:project_id?include=projects";
export const DELETE_PROJECT =
  "/modules/:id/projects/:project_id?include=projects";

export const EXPORT_MODULES_LIST = "/modules/export";
export const POST_SIMILARITY_INDEX = "/modules/similarity-index";
export const POST_SEED_KEYWORD = "/interest-cloud/seed-keyword";
export const POST_SEED_SI_KEYWORD = "/interest-cloud/seed-keyword-si";
