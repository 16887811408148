import { TYPES } from './Constants';

export const getAllRoles = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_ROLES,
        payload: data,
    });
}

export const getAllPermissions = (data) => (dispatch) => {
    dispatch({
        type: TYPES.GET_ALL_PERMISSIONS,
        payload: data,
    });
};

