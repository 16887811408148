import React, { Component } from 'react';

import "./styles.scss";

class ButtonOutline extends Component {
  render() {
    const { clickHandler, btnStyle } = this.props;
    return (
      <button
        className={`btn-outline ${btnStyle ? btnStyle : ''}`}
        onClick={() => {
          if (clickHandler) clickHandler();
        }}
      >
        {this.props.btnText}
      </button>
    );
  }
}

export default ButtonOutline;