import { getIFrameModel } from "./model";
import { getIFrameView } from "./view";

export const addIFrameComponent = (editor) => {
  const comps = editor.DomComponents;
  comps.addType("iframe", {
    model: getIFrameModel(editor),
    view: getIFrameView(editor),
  });
};
