export const acadruDividerBlock = async (editor) => {
  var blockManager = editor.BlockManager;
  var classPreFix = "acadru";
  var rowCol6Start = `<div class="${classPreFix}-row" data-gjs-droppable="false" ><div class="${classPreFix}-column column-6" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">`;
  var rowCol6End = `</div></div>`;

  // Divider line
  blockManager.add("LineDivider", {
    label: "Line Divider",
    category: {
      id: "Acadru Dividers",
      label: "Acadru Dividers",
      open: false,
    },
    attributes: { class: "fa fa-minus" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-divider line" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"></div>
      </div>
    ${rowCol6End}
    `,
  });

  // Numbered Divider
  blockManager.add("NumberedDivider", {
    label: "Numbered Divider",
    category: "Acadru Dividers",
    attributes: { class: "fa fa-sort-numeric-asc" },
    content: `
    ${rowCol6Start}
      <div class="${classPreFix}-divider numberedDivider" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false">
        <div class="divider-dots left" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"></div>
        <div class="number-count" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false">1</div>
        <div class="divider-dots right" data-gjs-droppable="false" data-gjs-draggable="false" data-gjs-removable="false" data-gjs-selectable="false"></div>
      </div>
    ${rowCol6End}
    `,
  });
};
