import React, { Component } from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// images
import userImage from "../../assets/user.svg";

import {
  CaretDownOutlined,
  LoginOutlined,
  UserOutlined,
} from "@ant-design/icons";

// Style
import "./styles.scss";

//Actions
import { logout } from "../../containers/Login/action";

//Routes
import { ROUTES } from "../../constants";
import { get } from "lodash";

const { Header } = Layout;

// const menu = (props) => {
//   return (

//   );
// };

class PrimaryHeader extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  removeFilters() {
    localStorage.removeItem('selectedSchool')
    localStorage.removeItem('selectedGrade')
    localStorage.removeItem('selectedOrganization')
    localStorage.removeItem('selectedOrganization2')
    localStorage.removeItem('selectedSchool2')
    localStorage.removeItem('selectedGrade2')
    localStorage.removeItem('selectedMonth')
    localStorage.removeItem('selectedMonth2')
    localStorage.removeItem('month1')
    localStorage.removeItem('month2')
    localStorage.removeItem('month3')
    localStorage.removeItem('month4')
    localStorage.removeItem('month5')
    localStorage.removeItem('month6')
  }

  onMenuClick = (key) => {
    if (key === "logout") {
      this.removeFilters();
      this.props.logout();
    }
  };
  render() {
    let admin = get(this.props, "auth.user.data");
    const menu = (
      <Menu
        className="mene-toggle-style"
        onClick={({ key }) => {
          this.onMenuClick(key);
        }}>
        <Menu.Item className="first" key="email" {...this.props}>
          <span className="info">Signed in as</span>
          <br />
          <span className="login-id">{admin.email}</span>
        </Menu.Item>
        <Menu.Item className="menu-item" key="profile">
          <Link className="link" rel="noopener noreferrer" to={ROUTES.MYPROFILE}>
            <UserOutlined className="icon" /> Profile
          </Link>
        </Menu.Item>
        {/* <Menu.Item>
        <Link className="link" rel="noopener noreferrer" to="/#">
          <SettingOutlined className="icon" />Setting
      </Link>
      </Menu.Item> */}
        <Menu.Item className="menu-item" key="logout">
          <Link className="link" rel="noopener noreferrer" to={ROUTES.LOGIN}>
            <LoginOutlined className="icon" /> Sign Out
          </Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="header">
        <div>{this.props.toggleButton}</div>

        <div className="header-right-style">
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link"
              href="/#"
              onClick={(e) => e.preventDefault()}>
              <Avatar
                size={28}
                src={get(admin, "media.path", null) || userImage}
              />{" "}
              <CaretDownOutlined className="icon-toggle" />
            </a>
          </Dropdown>
        </div>
      </Header>
    );
  }
}
const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, { logout })(PrimaryHeader);
