
import { get } from "lodash";
import { getData, getIdsFromArray } from "../Modules/helpers";

export const _updateSchoolProjectParser = (prevValue, value) => {
    let payload = {
        title: getData(prevValue, value, "title"),
        description: getData(prevValue, value, "description"),
        content: getData(prevValue, value, "content"),
        likes_count: getData(prevValue, value, "likes_count"),
        views_count: getData(prevValue, value, "views_count"),
        status: getData(prevValue, value, "status"),
        category: getData(prevValue, value, "category"),
        modules: getIdsFromArray(prevValue, value, "modules"),
        mainImage: getData(prevValue, value, "mainImage"),
        studentImage: getData(prevValue, value, "studentImage"),
        studentName: getData(prevValue, value, "studentName"),
        schoolName: getData(prevValue, value, "schoolName"),
        studentId: getData(prevValue, value, "studentId"),
        type: getData(prevValue, value, "type")
    }
    return payload;
}

export const _parseSchoolProjectForGlobalStorage = (prevValue, value) => {
    let payload = {
        title: getData(prevValue, value, "title"),
        description: getData(prevValue, value, "description"),
        content: getData(prevValue, value, "content"),
        likes_count: getData(prevValue, value, "likes_count"),
        views_count: getData(prevValue, value, "views_count"),
        status: getData(prevValue, value, "status"),
        category: getData(prevValue, value, "category"),
        modules: getIdsFromArray(prevValue, value, "modules"),
        studentName: getData(prevValue, value, "studentName"),
        schoolName: getData(prevValue, value, "schoolName"),
        studentId: getData(prevValue, value, "studentId"),
        type: getData(prevValue, value, "type")
    }
    return payload;
}

export const validateSchoolProjectDetails = (data, self) => {
    let errorObj = {};

    if (data && !data.title) {
        errorObj["title"] = "School Project title is Required";
    }

    if (data && data.title && data.title.length > 255) {
        errorObj["title"] = "School Project title must be less than 255 characters";
    }

    if (data && data.mainImage && data?.mainImage?.size > 10000000) {
        errorObj["mainImage"] = "Project Image size must be less than 10MB";
    }

    if (data && data.studentImage && data?.studentImage?.size > 10000000) {
        errorObj["studentImage"] = "Student Image size must be less than 10MB";
    }

    if (data && (!data.category || data.category.length === 0)) {
        errorObj["category"] = "Category is Required";
    }

    if (data && data.type === "external") {
        if (data && (!data.student_name || data.student_name.length === 0)) {
            errorObj["student_name"] = "Student name is Required";
        }

        if (data && data.student_name && data.student_name.length > 255) {
            errorObj["student_name"] = "Student name must be less than 255 characters"
        }

        if (data && !self && (!data.school_name || data.school_name.length === 0)) {
            errorObj["school_name"] = "School name is Required";
        }

        if (data && data.school_name && data.school_name.length > 255) {
            errorObj["school_name"] = "School name must be less than 255 characters"
        }
    }

    if (data && data.type === "internal") {
        if (!data.studentId || data.studentId === 0) {
            errorObj["studentId"] = "Student is Required";
        }
    }

    if (data && (!data.description || data.description.length === 0)) {
        errorObj["description"] = "Description is Required";
    }

    if (data && (!data.content || data.content.length === 0)) {
        errorObj["content"] = "Content is Required";
    }

    if (data && (!data.status || data.status.length === 0)) {
        errorObj["status"] = "Status is Required";
    }

    if (data && (!data.type || data.type.length === 0)) {
        errorObj["type"] = "Type is Required";
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
}

export const parseErrorSchoolProjectDetails = (Error) => {
    const err = get(Error, "response.data.error");
    let errorObj = {};

    if (err && err.title) {
        errorObj["title"] = get(err, "title[0]");
    }

    if (err && err.title) {
        errorObj["title"] = get(err, "title[0]");
    }

    if (err && err.projectImage) {
        errorObj["projectImage"] = get(err, "projectImage[0]");
    }

    if (err && err.studentImage) {
        errorObj["studentImage"] = get(err, "studentImage[0]");
    }

    if (err && err.category) {
        errorObj["category"] = get(err, "category[0]");
    }

    if (err && err.studentName) {
        errorObj["student_name"] = get(err, "studentName[0]");
    }

    if (err && err.schoolName) {
        errorObj["school_name"] = get(err, "schoolName[0]");
    }

    if (err && err.studentId) {
        errorObj["studentId"] = get(err, "studentId[0]");
    }

    if (err && err.description) {
        errorObj["description"] = get(err, "description[0]");
    }

    if (err && err.content) {
        errorObj["content"] = get(err, "content[0]");
    }

    if (err && err.status) {
        errorObj["status"] = get(err, "status[0]");
    }

    if (err && err.type) {
        errorObj["type"] = get(err, "type[0]");
    }
    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};