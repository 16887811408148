import { debounce, get } from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Space } from "antd";

// Custom Components
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import SearchPrimary from "../../components/SearchPrimary";
import RemoveModal from "../../components/modal/RemoveModal";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import SelectInput from "../../components/SelectInput";
import PrimaryPagination from "../../components/PrimaryPagination";
import { PageTitle } from "../../components/PageTitle";
// import ToggleCheckbox from "../../components/ToggleCheckbox";
import Loader from "../../components/PrimaryLoader";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import EmptyState from "../../components/EmptyState";
import { EyeOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";

//Data Managers
import * as DM from "./dataManager";

//Actions
import { setAllEnquiries } from "./action";

//Constants
import { ACTIONS, ROUTES, SEARCHDELAY } from "../../constants";
import { breakTimestamp } from "../../helpers";

class Enquiries extends Component {
  constructor(props) {
    super();
    this.getALLEnquiriesData = debounce(
      this.getALLEnquiriesData,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      removeShowModal: false,
      successShowModal: null,
      errorShowModal: null,
      isLoader: false,
      meta: {
        page: 1,
        limit: 10,
        order_by: "DESC",
        keyword: null,
        status: null,
      },
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    await this.getALLEnquiriesData();
  }

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    state.meta[`${field}`] = value;
    state.meta.page = 1;
    this.setState(state, () => {
      let isLoaderBlocked = false;
      if (field === "keyword") isLoaderBlocked = true;
      this.getALLEnquiriesData(isLoaderBlocked);
    });
  };

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getALLEnquiriesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  removeToggleModal = () => {
    this.setState({
      removeShowModal: !this.state.removeShowModal,
    });
  };

  //API Calling Fucntions

  getALLEnquiriesData = async (isLoaderBlocked) => {
    window.scrollTo(0, 0);
    if (!isLoaderBlocked) {
      this._startLoader();
    }
    const { page, limit, order_by, keyword, status } = this.state.meta;
    let param = {
      page,
      limit,
      order_by,
    };
    if (keyword) {
      param["keyword"] = keyword;
    }
    if (status && status !== "ALL")
      param["status"] = status === "REPLIED" ? status : "NOTREPLIED";
    await DM.getAllEnquiriesServices(param)
      .then((res) => {
        this.props.setAllEnquiries(res.data);

        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  render() {
    const {
      removeShowModal,
      isLoader,
      successShowModal,
      errorShowModal,
      meta,
    } = this.state;

    const metaData = get(this.props, "enquiries.allEnquiries.meta", null);
    const permissions = get(this.props, "auth.permissions");
    const dataColumns = get(this.props, "enquiries.allEnquiries.results", null);
    return (
      <>
        <PageTitle title="Enquiries" />
        <div className="admin-container-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Enquiries" }]} />
              <h4 className="title4"> List of all Enquiries</h4>
            </div>
            <div className="right-section">
              {/* <ButtonPrimary
                btnPrimaryStyle="mr--0-imp"
                btnText="Export List"
              /> */}
            </div>
          </div>

          <div className="find-section mb--20">
            <SelectInput
              style={{ width: 130 }}
              isTitleActive={true}
              title="Filter by:"
              placeholder="Select"
              options={["All", "Replied", "Not Replied"]}
              defaultValue="All"
              onChange={(val) => {
                this.setStateValue(val.toUpperCase(), "status");
              }}
            />
            <SearchPrimary
              placeholder="Search"
              onSearch={(e) => this.setStateValue(e.target.value, "keyword")}
            />
          </div>

          {dataColumns &&
          dataColumns.length > 0 &&
          permissions.ENQUIRIES &&
          permissions.ENQUIRIES.includes(ACTIONS.READ) ? (
            <div className="table-card-style">
              <div className="table-style">
                <table className="table-custom-style">
                  <tr>
                    <th>Contact Details</th>
                    <th>Date</th>
                    <th>Replied / Not Replied</th>
                    <th>Actions</th>
                  </tr>

                  {dataColumns.map((data, index) => {
                    let dateTime = breakTimestamp(get(data, "created_at"));
                    return (
                      <tr className="data-row table-enquiries" key={index}>
                        <td>
                          <div className="list-style">
                            <div className="list-item-style">
                              <span className="text-heading">Name : </span>
                              {data.name || "---"}
                            </div>
                            <div className="list-item-style">
                              <span className="text-heading">Email : </span>
                              {data.email || "---"}
                            </div>
                            <div className="list-item-style">
                              <span className="text-heading">Phone : </span>
                              {data.phone || "---"}
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="lead">{dateTime.date}</p>
                          <p className="lead">{dateTime.time}</p>
                        </td>
                        <td>
                          <p className="lead">
                            {data.status === "REPLIED"
                              ? "Replied"
                              : "Not Replied"}
                          </p>
                        </td>
                        <td>
                          <Space size="middle">
                            <ButtonPrimary
                              btnText={<EyeOutlined />}
                              btnPrimaryStyle="btn view square-view"
                              onClick={() =>
                                this.props.history.push(
                                  ROUTES.ENQUIRIESDETAILS.replace(
                                    ":id",
                                    data.id,
                                  ),
                                )
                              }
                            />
                          </Space>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>

              <div className="page-section">
                <PrimaryPagination
                  current={meta.page}
                  total={get(metaData, "pagination.total")}
                  pageSize={meta.limit}
                  onChange={this.handlePageChange}
                />
              </div>
            </div>
          ) : (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          )}

          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this module"
              description="Are you sure you want to remove this module?"
              // Action button event here
              removeHandler={this.removeToggleModal}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          )}

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};
export default connect(mapStateToProps, {
  setAllEnquiries,
})(Enquiries);
