import { TYPES } from "../../containers/Careers/Constants";

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.SET_ALL_CAREER:
      return {
        ...state,
        careers: action.payload,
        error: {},
      };

    default:
      return state;
  }
};
