import { axiosInstance } from "../../tools"
import { API } from "./Constants"

export const getAdminDashboard = async (payload) => {
    return await axiosInstance.post(
        API.POST_ADMIN_DASHBOARD,
        payload
    )
}

export const getSchoolGradeForAdmin = async () => {
    return await axiosInstance.get(
        API.GET_GRADE_SCHOOLS_FOR_ADMIN
    )
}

export const updateAccessForDashboard = async (payload) => {
    return await axiosInstance.patch(
        API.PATCH_DASHBOARD_ACCESS,
        payload
    )
}