import { getAudioModel } from "./model";
import { getAudioView } from "./view";

export const addAudioAssetType = (editor) => {
  const AM = editor.AssetManager;

  AM.addType("audio", {
    model: getAudioModel(editor),
    view: getAudioView(editor),
    isType(value) {
      // The condition is intentionally simple
      if (typeof value === "string") {
        return {
          type: "audio",
          src: value,
        };
      }
      return value;
    },
  });
};
