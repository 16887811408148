import React, { Component } from "react";
import { Upload, Modal } from "antd";

// Style
import "./styles.scss";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class ImageUpload extends Component {
  constructor(props) {
    super();
    this.state = {
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      defaultImage: false,
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
    if (fileList && fileList.length) this.props.onChange(fileList[0]);
  };

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const { defaultImageUrl, text, subText } = this.props;
    const uploadButton = (
      <div>
        <img src={defaultImageUrl} className="default-image" alt="default" />
        {text && <div className="ant-upload-text"> {text}</div>}
        {subText && <p className="sub-text"> {subText}</p>}
      </div>
    );
    return (
      <div className="clearfix image-upload-section">
        <Upload
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={() => false}>
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          width={"60vw"}
          height={"60vh"}
          onCancel={this.handleCancel}>
          <img alt="example" style={{ maxHeight: '60vh', width: '100%', objectFit: 'contain' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default ImageUpload;
