import { configurePersistReducer } from "./persistReducers";

import authReducers from "./auth";
import moduleReducers from "./modules";
import categoryReducers from "./categories";
import careersReducers from "./careers";
import userReducers from "./users";
import collectionReducers from "./collections";
import enquiriesReducers from "./enquiries";
import roleReducers from './roles';
import paymentReducers from './payments';
import tagReducers from './tags';
import blogReducers from './blogs';
import featureQuestionReducers from './featureQuestions';
import autoSaveReducers from './autoSave';
import SchoolProjectReducers from "./schoolProject"


import { TYPES } from "../../containers/Login/Constants";

import { combineReducers } from "redux";

const appReducer = combineReducers({
  auth: authReducers,
  modules: moduleReducers,
  categories: categoryReducers,
  careers: careersReducers,
  collections: collectionReducers,
  users: userReducers,
  enquiries: enquiriesReducers,
  roles: roleReducers,
  payments: paymentReducers,
  tags: tagReducers,
  blogs: blogReducers,
  featureQuestions: featureQuestionReducers,
  autoSave: autoSaveReducers,
  schoolProjects: SchoolProjectReducers
});

const rootReducer = (state, action) => {
  if (action.type === TYPES.USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default configurePersistReducer(rootReducer);
