export const addBlockersComponents = (editor) => {
  const comps = editor.DomComponents;

  /**
Copy;
Drag;
Drop;
Edit;
Remo;
Selec; 

   */

  // For Plugin Options

  comps.addType("CDraDroR", {
    isComponent: (el) => el.tagName === "CDRADROR",
    model: {
      defaults: {
        tagName: "cdradror",
        copyable: false,
        draggable: false,
        droppable: false,
        removable: false,
      },
    },
  });

  comps.addType("CR", {
    isComponent: (el) => el.tagName === "CR",
    model: {
      defaults: {
        tagName: "cr",
        copyable: false,
        removable: false,
      },
    },
  });

  comps.addType("DraDroR", {
    isComponent: (el) => el.tagName === "DRADROR",
    model: {
      defaults: {
        tagName: "dradror",
        draggable: false,
        droppable: false,
        removable: false,
      },
    },
  });

  comps.addType("DraDroRS", {
    isComponent: (el) => el.tagName === "DRADRORS",
    model: {
      defaults: {
        tagName: "dradrors",
        draggable: false,
        droppable: false,
        removable: false,
        selectable: false,
      },
    },
  });

  comps.addType("Dro", {
    isComponent: (el) => el.tagName === "DRO",
    model: {
      defaults: {
        tagName: "dro",
        droppable: false,
      },
    },
  });

  comps.addType("DroRS", {
    isComponent: (el) => el.tagName === "DRORS",
    model: {
      defaults: {
        tagName: "drors",
        droppable: false,
        removable: false,
        selectable: false,
      },
    },
  });

  //For All Blocks

  comps.addType("acadruCommonRow", {
    isComponent: (el) => el.tagName === "ACADRUCOMMONROW",
    model: {
      defaults: {
        tagName: "acadrucommonrow",
        removable: false,
        draggable: false,
        droppable: false,
        selectable: false,
      },
    },
  });

  comps.addType("acadruCommonCol", {
    isComponent: (el) => el.tagName === "ACADRUCOL",
    model: {
      defaults: {
        tagName: "acadrucol",
        removable: false,
        draggable: false,
        droppable: false,
        selectable: false,
      },
    },
  });
};
