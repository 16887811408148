import React, { Component } from "react";
import { Modal } from "antd";

// Style
import "./styles.scss";

class PrimaryModal extends Component {
  state = {
    top: "topLeft",
    bottom: "bottomRight",
  };

  render() {
    const { isModalVisible, children } = this.props;

    return (
      <>
        <Modal
          visible={isModalVisible}
          centered
          footer={null}
          width="350"
          className="primary-modal-style">
          <div className="primary-modal">{children}</div>
        </Modal>
      </>
    );
  }
}

export default PrimaryModal;
