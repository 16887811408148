import { API } from "./Constants";
import { axiosInstance } from "../tools";
import { makeQueryParam } from "../helpers";

export const getAllRoles = async (params) => {
    let url = `${API.GET_ALL_ROLES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getAllModules = async (params) => {
    let url = `${API.GET_ALL_MODULES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getAllCategories = async (params) => {
    let url = `${API.GET_ALL_CATEGORIES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getAllSubCategories = async (params) => {
    let url = `${API.GET_ALL_SUB_CATEGORIES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getAllCareers = async (params) => {
    return await axiosInstance.get(
        `${API.GET_ALL_CAREERS}${makeQueryParam(params)}`,
    );
};

export const getALLTagCategories = async (params) => {
    let url = `${API.GET_ALL_TAG_CATEGORIES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getALLTags = async (params) => {
    let url = `${API.GET_ALL_TAGS}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getALLBlogCategories = async (params) => {
    let url = `${API.GET_ALL_BLOG_CATEGORIES}${makeQueryParam(params)}`;
    return await axiosInstance.get(url);
};

export const getRole = async (id) => {
    let url = API.GET_ROLE.replace(":id", id);
    return await axiosInstance.get(url);
};