import { isString } from "lodash";
import { getVideoModel } from "./model";
import { getVideoView } from "./view";

export const addVideoAssetType = (editor) => {
  const AM = editor.AssetManager;

  AM.addType("video", {
    model: getVideoModel(editor),
    view: getVideoView(editor),

    // `value` is for example the argument passed in `am.add(VALUE);`
    isType(value) {
      // The condition is intentionally simple
      if (isString(value) && value.substring(0, 6) === "<video ") {
        return {
          type: "video",
          src: value,
        };
      }
      // Maybe you pass the `video` object already
      else if (typeof value === "object" && value.type === "video") {
        return value;
      }
    },
  });
};
