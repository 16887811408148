import moment from 'moment';

export const breakTimestamp = (dateTime) => {
    return {
        date: moment(dateTime).format('D MMMM YYYY'),
        time: moment(dateTime).format('hh:mm A'),
    };
}

export const returnMomentObject = (dateString) => {
    return moment(dateString);
}

export const breakTimestampForAPI = (dateTime) => {
    return {
        date: moment(dateTime).format('YYYY-MM-DD'),
        time: moment(dateTime).format('hh:mm A')
    }
}

export const calculateAge = (dob) => {
    return moment().diff(dob, 'years');
}

export const getMonthNameFromNumber = (monthNumber, key) => {
    if (!monthNumber) return key;
    const newDate = new Date(new Date().getFullYear(), monthNumber - 1);
    const monthName = newDate.toLocaleString('en-US', { month: 'short' })
    return monthName;
}
