import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { get, debounce } from "lodash";

import defaultImage from "../../../assets/blogs.svg";
// Custom Components
import TextareaPrimary from "../../../components/TextareaPrimary";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ImageModuleCard from "../../../components/ImageModuleCard";
import Loader from "../../../components/PrimaryLoader";
import PrimaryAutoComplete from "../../../components/PrimaryAutoComplete";
import SuccessModal from "../../../components/modal/SuccessModal";
import ErrorModal from "../../../components/modal/ErrorModal";

// Style
import "./styles.scss";
import { PageTitle } from "../../../components/PageTitle";

//Data Managers
import * as DM from "../dataManager";
import * as CDM from "../../../common/dataManager";

//Parser
import { validate, parseError } from "../parser";

//Constants
import { BREADCRUMBS, ROUTES, SEARCHDELAY } from "../../../constants";

class FeatureQuestionsNew extends Component {
  constructor(props) {
    super();
    this.handleAutoComplete = debounce(
      this.handleAutoComplete,
      SEARCHDELAY.searchDelay,
    );
    this.state = {
      shown: true,
      isLoader: false,
      errorModal: null,
      succModal: null,
      featureQuestion: {
        status: "PUBLISH",
        module: null,
      },
      errorObj: null,
      autoCompleteVal: "",
      autoCompleteOptions: [],
      moduleImage: null,
    };
  }

  async componentDidMount() {
    const editableID = this.props.match.params.id;
    if (editableID) {
      await this.getSpecificFeatureQuestion(editableID);
    }
  }

  //Helper Functions

  setStateValue = (value, field) => {
    let state = this.state;
    state.featureQuestion[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal)
          this.props.history.push(ROUTES.FEATUREQUESTIONS);
      },
    );
  };

  setModuleImage = () => {
    let moduleImage = {};
    let module = get(this.state.featureQuestion, "module");
    moduleImage = {
      imgUrl: get(module, "media.path", null),
      title: module.name,
    };
    this.setState({ moduleImage });
  };

  getSpecificFeatureQuestion = async (id) => {
    this._startLoader();
    await DM.getFeatureQuestionService(id)
      .then((res) => {
        this.setState({ featureQuestion: res.data });
        this.setModuleImage();
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  handleAutoComplete = (value) => {
    CDM.getAllModulesService({
      search_type: "CUSTOM",
      status: "PUBLISH",
      page: 1,
      limit: 10,
      keyword: value,
    })
      .then((res) => {
        let modules = get(res, "data.results");
        modules = modules.map((module) => {
          return {
            label: module.name,
            value: [
              module.name,
              get(module, "id", ""),
              get(module, "media[0].path", ""),
            ],
          };
        });
        let selectedModule = this.state.featureQuestion.module;
        modules = modules.filter((module) => {
          return selectedModule !== module.value[1];
        });
        this.setState({ autoCompleteOptions: modules });
      })
      .catch((err) => {});
  };

  onSelect = (value) => {
    let { moduleImage, featureQuestion } = this.state;
    let selectedModule = featureQuestion.module;
    if (selectedModule === value[1]) {
      this.setState({ autoCompleteVal: "" });
      return;
    }

    selectedModule = value[1];
    moduleImage = {
      imgUrl: value[2],
      title: value[0],
    };
    this.setState({
      autoCompleteVal: "",
      featureQuestion: {
        ...this.state.featureQuestion,
        module: selectedModule,
      },
      moduleImage,
    });
  };

  handleOnClick = async () => {
    const { featureQuestion } = this.state;

    const checkValidate = validate(featureQuestion);

    if (checkValidate) {
      this.setState({
        errorObj: checkValidate,
        errorModal: { title: "Validation Error Occured" },
      });
      return;
    }

    this._startLoader();
    const editableID = this.props.match.params.id;
    if (editableID) {
      delete featureQuestion.id;
      if (typeof featureQuestion.module === "object")
        featureQuestion.module = get(featureQuestion, "module.id");
      DM.patchUpdateFeatureQuestionService(editableID, featureQuestion)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Featured Question updated successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    } else {
      await DM.postCreateFeatureQuestionService(featureQuestion)
        .then((res) => {
          this._stopLoader();
          this.setState({
            succModal: {
              title: "Success",
              description: "Featured Question created successfully.",
            },
          });
        })
        .catch((err) => {
          this._stopLoader();
          this.setState({
            errObj: parseError(err),
            errorModal: { description: get(err, "response.data.message", "") },
          });
        });
    }
  };

  removeModule = () => {
    this.setState({
      moduleImage: null,
      featureQuestion: { ...this.state.featureQuestion, module: null },
    });
  };

  render() {
    const editableID = this.props.match.params.id;
    const {
      isLoader,
      featureQuestion,
      errorObj,
      succModal,
      errorModal,
      autoCompleteOptions,
      autoCompleteVal,
      moduleImage,
    } = this.state;

    const status = featureQuestion.status;
    return (
      <>
        <PageTitle title="Add new featured question" />
        <div className="tags-new-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.FEATUREQUESTIONS,
                  editableID
                    ? { text: "Edit Featured Question" }
                    : { text: "Add New Featured Question" },
                ]}
              />
              <h4 className="title4">
                {editableID
                  ? "Edit Featured Question"
                  : "Add new Featured Question"}
              </h4>
            </div>
            <div className="right-section">
              <div className="toggle-button-box">
                <Button
                  className={`btn-toggle ${
                    status === "PUBLISH" ? "btn-active" : ""
                  }`}
                  onClick={() => {
                    this.setStateValue("PUBLISH", "status");
                  }}>
                  Publish
                </Button>
                <Button
                  className={`btn-toggle ${
                    status === "PUBLISH" ? "" : "btn-active"
                  }`}
                  onClick={() => {
                    this.setStateValue("UNPUBLISH", "status");
                  }}>
                  Unpublish
                </Button>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="input-item">
              <p className="lable">
                Featured question <span className="required-text">*</span>
              </p>
              <TextareaPrimary
                value={get(featureQuestion, "question")}
                rows={4}
                placeholder="Question"
                onChange={(e) => {
                  this.setStateValue(e.target.value, "question");
                }}
                errorText={get(errorObj, "question")}
              />
            </div>

            <div className="input-item">
              <p className="lable">
                Add module <span className="required-text">*</span>
              </p>
              <PrimaryAutoComplete
                value={autoCompleteVal}
                onChange={(val) => {
                  this.setState({
                    autoCompleteVal: val,
                  });
                }}
                placeholder="Search module name…"
                options={autoCompleteOptions}
                onSearch={(value) => {
                  this.handleAutoComplete(value);
                }}
                onSelect={this.onSelect}
                errorText={get(errorObj, "module")}
              />
            </div>

            {moduleImage && (
              <div className="module-sec">
                <Row gutter={16}>
                  <Col className="gutter-row mt--50" xs={12} md={4}>
                    <ImageModuleCard
                      removeModule={() => {
                        this.removeModule();
                      }}
                      imgUrl={get(moduleImage, "imgUrl") || defaultImage}
                      title={get(moduleImage, "title")}
                    />
                  </Col>
                </Row>
              </div>
            )}

            <div className="mt--40">
              <ButtonPrimary
                btnText="Save"
                onClick={() => {
                  this.handleOnClick();
                }}
              />
            </div>
          </div>

          {isLoader && <Loader />}
          <ErrorModal
            isModalVisible={errorModal}
            onClose={this.errorToggleModal}
            title={get(errorModal, "title")}
            description={get(errorModal, "description")}
          />
          <SuccessModal
            isModalVisible={succModal}
            onClose={this.successToggleModal}
            title={get(succModal, "title")}
            description={get(succModal, "description")}
          />
        </div>
      </>
    );
  }
}

export default FeatureQuestionsNew;
