import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { isMobileWindow } from "./constants/layout";

import PrimaryHeader from "./components/PrimaryHeader";
import PrimarySidebar from "./components/PrimarySidebar";
import Modules from "./containers/Modules";
import NewModules from "./containers/Modules/AddNew";
import CollectionsScreen from "./containers/Collections";
import NewCallectionScreen from "./containers/Collections/AddNew";

import LearnersScreen from "./containers/Users/Learners";
import LearnerNew from "./containers/Users/Learners/LearnerNew";
import LearnerDetails from "./containers/Users/Learners/LearnerDetails";

import MentorsScreen from "./containers/Users/Mentors";
import MentorNew from "./containers/Users/Mentors/MentorNew";
import MentorDetails from "./containers/Users/Mentors/MentorDetails";

import SuperMentorsScreen from "./containers/Users/SuperMentors";
import SuperMentorNew from "./containers/Users/SuperMentors/SuperMentorNew";
import SuperMentorDetails from "./containers/Users/SuperMentors/SuperMentorDetails";
import SuperMentorDashboard from "./containers/Users/SuperMentors/SuperMentorDashboard";

import AdminScreen from "./containers/Users/Admins";
import AdminDetails from "./containers/Users/Admins/AdminDetails";
import AdminNew from "./containers/Users/Admins/AdminNew";

import Enquiries from "./containers/Enquiries";
import EnquiriesDetails from "./containers/Enquiries/EnquiriesDetails";

import MyProfile from "./containers/MyProfile";

import Payments from "./containers/Payments";

import RoleManagement from "./containers/RoleManagement";
import RoleNew from "./containers/RoleManagement/RoleNew";

import Welcome from './containers/Welcome';
import CareersScreen from "./containers/Careers";
import CareerNew from "./containers/Careers/CareerNew";
import Categories from "./containers/Categories";
import CategoryNew from "./containers/Categories/CategoryNew";
import SubCategories from "./containers/SubCategories";
import SubCategoriesNew from "./containers/SubCategories/SubCategoriesNew";
import TagCategory from "./containers/TagCategory";
import TagCategoryNew from "./containers/TagCategory/TagCategoryNew";
import Tags from "./containers/Tags";
import TagNew from "./containers/Tags/TagNew";
import FeatureQuestionsNew from "./containers/FeatureQuestions/FeatureQuestionsNew";
import FeatureQuestions from "./containers/FeatureQuestions";
import { ROUTES } from "./constants";
import BlogManagement from "./containers/BlogManagement";
import BlogNew from "./containers/BlogManagement/BlogNew";
import BlogCategory from "./containers/BlogCategory";
import BlogCategoryNew from "./containers/BlogCategory/BlogCategoryNew";
import ViewModule from "./containers/Modules/View";
import Package from "./containers/Package";
import PackageNew from "./containers/Package/PackageNew";
import ViewPackage from "./containers/Package/View";
import SchoolProjectPage from "./containers/SchoolProject";
import NewSchoolProject from './containers/SchoolProject/AddNewSchoolProject'

const { Content } = Layout;

class DashboardContainer extends Component {
  state = {
    collapsed: isMobileWindow.matches,
    visible: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout
        className={`main-container ${this.state.collapsed === false ? "active-main" : ""
          }`}>
        <PrimarySidebar
          menuToggle={() => {
            if (isMobileWindow.matches) {
              this.toggle();
            }
          }}
          breakpoint={"md"}
          trigger={null}
          collapsedWidth={isMobileWindow.matches ? 0 : 80}
          collapsed={this.state.collapsed}
        />
        <Layout className="site-layout">
          <PrimaryHeader
            toggleButton={
              <div>
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                  },
                )}
              </div>
            }
          />
          <Content className="mainContent-sec">
            <Switch>
              <Route exact path={ROUTES.DASHBOARD} component={Welcome} />
              <Route exact path={ROUTES.MODULES} component={Modules} />
              <Route exact path={ROUTES.NEWMODULE} component={NewModules} />
              <Route exact path={ROUTES.EDITMODULE} component={NewModules} />
              <Route exact path={ROUTES.VIEWMODULE} component={ViewModule} />

              {/* Collections Flow */}
              <Route
                exact
                path={ROUTES.COLLECTIONS}
                component={CollectionsScreen}
              />
              <Route
                exact
                path={ROUTES.NEWCOLLECTION}
                component={NewCallectionScreen}
              />
              <Route
                exact
                path={ROUTES.EDITCOLLECTION}
                component={NewCallectionScreen}
              />

              {/* User Learners Flow */}
              <Route exact path={ROUTES.LEARNERS} component={LearnersScreen} />
              <Route exact path={ROUTES.NEWLEARNER} component={LearnerNew} />
              <Route exact path={ROUTES.EDITLEARNER} component={LearnerNew} />
              <Route
                exact
                path={ROUTES.VIEWLEARNER}
                component={LearnerDetails}
              />

              {/* User Mentors Flow */}
              <Route exact path={ROUTES.MENTORS} component={MentorsScreen} />
              <Route exact path={ROUTES.VIEWMENTOR} component={MentorDetails} />
              <Route exact path={ROUTES.NEWMENTOR} component={MentorNew} />
              <Route exact path={ROUTES.EDITMENTOR} component={MentorNew} />

              {/* User SuperMentor Flow */}
              <Route exact path={ROUTES.SUPERMENTORS} component={SuperMentorsScreen} />
              <Route exact path={ROUTES.VIEWSUPERMENTOR} component={SuperMentorDetails} />
              <Route exact path={ROUTES.VIEWSUPERMENTORDASHBOARD} component={SuperMentorDashboard} />
              <Route exact path={ROUTES.NEWSUPERMENTOR} component={SuperMentorNew} />
              <Route exact path={ROUTES.EDITSUPERMENTOR} component={SuperMentorNew} />

              {/* User Admin Flow */}
              <Route exact path={ROUTES.ADMINS} component={AdminScreen} />
              <Route exact path={ROUTES.VIEWADMIN} component={AdminDetails} />
              <Route exact path={ROUTES.NEWADMIN} component={AdminNew} />
              <Route exact path={ROUTES.EDITADMIN} component={AdminNew} />

              {/* Enquiries */}
              <Route exact path={ROUTES.ENQUIRIES} component={Enquiries} />
              <Route
                exact
                path={ROUTES.ENQUIRIESDETAILS}
                component={EnquiriesDetails}
              />

              {/* My Profile */}
              <Route exact path={ROUTES.MYPROFILE} component={MyProfile} />

              {/* Payment */}
              <Route exact path={ROUTES.PAYMENTS} component={Payments} />

              {/* Role Management */}
              <Route exact path={ROUTES.ROLE_MANAGEMENT} component={RoleManagement} />
              <Route exact path={ROUTES.NEWROLE} component={RoleNew} />
              <Route exact path={ROUTES.EDITROLE} component={RoleNew} />

              {/* Careers */}
              <Route exact path={ROUTES.CAREERS} component={CareersScreen} />
              <Route exact path={ROUTES.NEWCAREERES} component={CareerNew} />
              <Route exact path={ROUTES.EDITCAREERS} component={CareerNew} />

              {/* Categories */}
              <Route exact path={ROUTES.CATEGORIES} component={Categories} />
              <Route exact path={ROUTES.NEWCATEGORY} component={CategoryNew} />
              <Route exact path={ROUTES.EDITCATEGORY} component={CategoryNew} />

              <Route exact path={ROUTES.SUBCATEGORIES} component={SubCategories} />
              <Route
                exact
                path={ROUTES.NEWSUBCATEGORY}
                component={SubCategoriesNew}
              />
              <Route
                exact
                path={ROUTES.EDITSUBCATEGORY}
                component={SubCategoriesNew}
              />

              {/* Product page */}
              <Route exact path={ROUTES.SCHOOLPROJECTS} component={SchoolProjectPage} />
              <Route exact path={ROUTES.NEWSCHOOLPROJECTS} component={NewSchoolProject} />
              <Route exact path={ROUTES.EDITSCHOOLPROJECT} component={NewSchoolProject} />

              {/* Tag */}
              <Route exact path={ROUTES.TAGCATEGORY} component={TagCategory} />
              <Route
                exact
                path={ROUTES.NEWTAGCATEGORY}
                component={TagCategoryNew}
              />
              <Route
                exact
                path={ROUTES.EDITTAGCATEGORY}
                component={TagCategoryNew}
              />

              <Route exact path={ROUTES.TAGS} component={Tags} />
              <Route exact path={ROUTES.NEWTAG} component={TagNew} />
              <Route exact path={ROUTES.EDITTAG} component={TagNew} />

              {/* Featured question */}
              <Route
                exact
                path={ROUTES.NEWFEATUREQUESTION}
                component={FeatureQuestionsNew}
              />
              <Route
                exact
                path={ROUTES.EDITFEATUREQUESTION}
                component={FeatureQuestionsNew}
              />
              <Route
                exact
                path={ROUTES.FEATUREQUESTIONS}
                component={FeatureQuestions}
              />

              {/* Blog */}
              <Route exact path={ROUTES.BLOGS} component={BlogManagement} />
              <Route exact path={ROUTES.NEWBLOG} component={BlogNew} />
              <Route exact path={ROUTES.EDITBLOG} component={BlogNew} />
              <Route exact path={ROUTES.BLOGCATEGORY} component={BlogCategory} />
              <Route
                exact
                path={ROUTES.NEWBLOGCATEGORY}
                component={BlogCategoryNew}
              />
              <Route
                exact
                path={ROUTES.EDITBLOGCATEGORY}
                component={BlogCategoryNew}
              />

              {/* Package */}
              <Route exact path={ROUTES.PACKAGE} component={Package} />
              <Route exact path={ROUTES.NEWPACKAGE} component={PackageNew} />
              <Route exact path={ROUTES.EDITPACKAGE} component={PackageNew} />
              <Route exact path={ROUTES.VIEWPACKAGE} component={ViewPackage} />

              <Redirect to={ROUTES.PAGENOTFOUND} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default DashboardContainer;
