import React from 'react';
import { Chart } from 'react-google-charts';
import './styles.scss';

const PieChart = ({ data }) => {
    let arr = [];
    let totalCount = 0;
    let total = 0;

    data.forEach(categories => {
        totalCount += categories.count;
    })

    const chartData = data.map((category) =>
        [category.category_name, category.count, Number(((category.count / totalCount) * 100).toFixed(1))],
    );

    chartData.forEach(category => {
        total += category[2]
        arr.push(category[2])
    })

    let max = Math.max(...arr)
    const maxArray = arr.filter(function check(val) { return val === max })
    const maxAppears = maxArray.length;
    let numberToAddSubtract = Number((total - 100).toFixed(1));
    total = Number(total.toFixed(1));

    if (maxAppears > 1) {
        numberToAddSubtract = Number((numberToAddSubtract / maxAppears).toFixed(1))
    }

    if (total > 100) {
        chartData.forEach(category => {
            if (category[2] === max) {
                category[2] = max - numberToAddSubtract;
            }
        })
    }
    if (total < 100) {
        chartData.forEach(category => {
            if (category[2] === max) {
                category[2] = max + numberToAddSubtract;
            }
        })
    }

    const dataToShow = chartData.map(category => [category[0], category[2], category[1]]);

    const legendItems = data.map((category, index) => ({
        label: `${category.category_name}`,
        count: `${category.count}`,
        color: ['#F48e4d', '#F6A571', '#F7B082', '#F8BB94', '#F9C7A6', '#FBD2B8', '#FCDDCA', '#FDEADB', '#'][index % 9]
    }));

    const sliceColors = legendItems.map(item => item.color);

    return (
        <div className="pie-chart-container">
            <div className='chart'>
                <Chart
                    width={'800px'}
                    height={'500px'}
                    chartType="PieChart"
                    data={[
                        ['Category', 'Percentage', { role: 'tooltip', type: 'string', p: { html: true } }],
                        ...dataToShow.map(category => [category[0], category[1], `${category[0]}: ${Number((category[1]).toFixed(1))}% (${category[2]})`]),
                    ]}
                    options={{
                        pieHole: 0.4,
                        legend: 'none',
                        slices: sliceColors.map((color, index) => ({
                            color: color,
                        })),
                    }}
                />
            </div>
            <div className='legend'>
                {legendItems.map((item, index) => (
                    <div key={index} className="legend-item">
                        <div className="legend-circle" style={{ backgroundColor: item.color }} />
                        <span className="legend-label">{item.label}:</span>
                        <span className="legend-count">{`${item.count}`}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PieChart;
