import React, { Component } from "react";
import { Avatar, Card, Space } from "antd";
import { UserOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ButtonDanger from "../../../../components/buttons/ButtonDanger";
import ButtonOutline from "../../../../components/buttons/ButtonOutline";
import { get } from 'lodash';
import { connect } from "react-redux";
// import defaultImg from '../../../../assets/user-grey.svg';

// Custom Components
import ProfileInfoCard from "../../../../components/ProfileInfoCard";
import SelectInput from "../../../../components/SelectInput";
import RemoveModal from "../../../../components/modal/RemoveModal";
import Loader from '../../../../components/PrimaryLoader';
import EmptyState from '../../../../components/EmptyState';
import ErrorModal from "../../../../components/modal/ErrorModal";
import SuccessModal from "../../../../components/modal/SuccessModal";
import PrimaryPagination from '../../../../components/PrimaryPagination'

//Helpers
import { breakTimestamp, returnStatusColor, convertCase } from "../../../../helpers";

// Style
import "./styles.scss";
import PrimaryBreadcrumb from "../../../../components/PrimaryBreadcrumb";
import { PageTitle } from "../../../../components/PageTitle";

//Constants
import { BREADCRUMBS, ROUTES, ACTIONS } from '../../../../constants';

//Services
import * as DM from "../../dataManager";
import { USERSTATUSENUM } from "../../Constants";
import ViewedModules from "../../../../components/ViewedModules";

class LearnerDetails extends Component {
  constructor(props) {
    super();
    this.learner_id = null;
    this.state = {
      errorModal: null,
      succModal: null,
      meta: {
        page: 1,
        limit: 10
      },
      removeShowModal: false,
      isLoader: false,
      learner: {},
      modules: [],
      moduleFilter: "VIEWED",
      selected_mentor: null
    };
  }

  componentDidMount() {
    this.learner_id = this.props.match.params.id;
    this.getLearnerData();
    this.getLearnerModulesData();
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getLearnerModulesData();
    })
  }

  handleModuleChange = (val) => {
    this.setState({
      moduleFilter: val
    }, () => {
      this.getLearnerModulesData();
    })
  }

  removeToggleModal = (id) => {
    this.setState({
      selected_mentor: id,
      removeShowModal: !this.state.removeShowModal,
    });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {}
    })
  }

  successToggleModal = () => {
    this.setState({
      succModal: this.state.succModal ? null : {}
    })
  }


  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  getLearnerData = () => {
    this._startLoader();
    DM.getUserService(this.learner_id)
      .then((res) => {
        this._stopLoader();

        this.setState({
          learner: res.data
        })
      })
      .catch((err) => {

        this._stopLoader();
      })
  }

  getLearnerModulesData = () => {
    this._startLoader();
    const { page, limit } = this.state.meta;
    DM.getUserModulesService(this.learner_id, { status: this.state.moduleFilter, page, limit })
      .then((res) => {
        this._stopLoader();
        this.setState({
          modules: res.data
        })
      })
      .catch((err) => {
        this._stopLoader();
      })
  }

  removeMentor = () => {
    this._startLoader();
    DM.removeAssignedMentorOneService(this.learner_id, this.state.selected_mentor)
      .then((res) => {
        this._stopLoader();
        this.removeToggleModal();
        this.setState({
          selected_mentor: null,
          succModal: { title: "Success", description: "Mentor removed successfully." }
        })
        this.getLearnerData();
      })
      .catch((err) => {
        this.setState({
          selected_mentor: null,
          errorModal: {
            title: "ERROR",
            description: get(err, "response.data.message", "")
          }
        })
        this._stopLoader();
        this.removeToggleModal();
      })
  }

  render() {
    const permissions = get(this.props, "auth.permissions");
    const { removeShowModal, isLoader, learner, modules, errorModal, succModal, meta, moduleFilter } = this.state;
    const dataColumns = get(modules, "results");
    const totalModules = get(modules, "meta.pagination.total");

    return (
      <>
        <PageTitle title="Learners" />
        <div className="learner-details-style">
          <div className="header-style mb--30 mt--10">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.USERS,
                  BREADCRUMBS.LEARNERS,
                  { text: "View Details" },
                ]}
              />
              <h4 className="title4"> View Details</h4>
            </div>
          </div>

          {(learner?.status === USERSTATUSENUM.UserStatus.INACTIVE) ? <EmptyState title="Oops!" description="No Record Found &#128533;" /> : < ProfileInfoCard learner={learner} />}

          {
            (learner?.mentor?.length > 0 && learner?.status !== USERSTATUSENUM.UserStatus.INACTIVE) &&
            <Card className="mt--50">
              <div className="table-container">
                <div className="table-info-section">
                  <h4 className="title4">Assigned mentors</h4>
                </div>

                <div className="table-card-style">
                  <div className="table-style">
                    <table className="table-custom-style">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Organization</th>
                        <th>Action</th>
                      </tr>
                      {learner?.mentor.map((data, index) => {
                        return (
                          <tr className="data-row table-module" key={index}>
                            <td>
                              <div className="img-content-style">
                                {get(data, "media.path", null) ?
                                  <Avatar size={40} src={get(data, "media.path", null)} /> :
                                  <Avatar size={40} icon={<UserOutlined />} />
                                }
                                <p className="lead ml-some">
                                  <a className="lead link-text" href={ROUTES.VIEWMENTOR.replace(":id", get(data, "id"))} target="_blank" rel="noopener noreferrer">
                                    {data.full_name || "---"}
                                  </a>
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="lead">{data.email || "---"}</p>
                            </td>
                            <td>
                              <p className="lead">{data.phone || "---"}</p>
                            </td>
                            <td>
                              <p className="lead">{data.organization || "---"}</p>
                            </td>
                            <td>
                              {permissions.USERS && permissions.USERS.includes(ACTIONS.UPDATE) &&
                                <Space size="middle">
                                  <ButtonOutline
                                    btnText={<EditOutlined />}
                                    btnStyle="btn edit square-edit"
                                    clickHandler={() =>
                                      this.props.history.push(
                                        ROUTES.EDITMENTOR.replace(
                                          ":id",
                                          data.id,
                                        ),
                                      )
                                    }
                                  />
                                  <ButtonDanger
                                    type="link"
                                    btnText={<DeleteOutlined />}
                                    btnDangerStyle="btn delete btn-link"
                                    onClick={() =>
                                      this.removeToggleModal(data.id)
                                    }>
                                    Remove
                                  </ButtonDanger>
                                </Space>}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          }

          {((learner?.status !== USERSTATUSENUM.UserStatus.INACTIVE && dataColumns && dataColumns.length > 0) || moduleFilter === 'ASSIGNED') &&
            <ViewedModules
              moduleFilter={moduleFilter}
              dataColumns={dataColumns}
              meta={meta}
              totalModules={totalModules}
              isLearner={true}
              handleModuleChange={(val) => this.handleModuleChange(val)}
              handlePageChange={this.handlePageChange}
            />}

          {isLoader && <Loader />}
          {removeShowModal &&
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Remove this Mentor"
              description="Are you sure to remove this mentor?"
              // Action button event here
              removeHandler={this.removeMentor}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, Remove"
              dontRemoveBtnText="Don't Remove"
            />
          }

          {errorModal &&
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          }

          {succModal &&
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
})(LearnerDetails);
