import { TYPES } from "./Constants";

export const setAllBlogs = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SET_ALL_BLOGS,
    payload: data,
  });
};

export const saveNewBlog = (data) => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_NEW_BLOG,
    payload: data,
  });
}

export const clearNewBlog = () => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_NEW_BLOG
  });
}

export const editBlog = (data) => (dispatch) => {
  dispatch({
    type: TYPES.EDIT_BLOG,
    payload: data
  });
}

export const clearEditBlog = (id) => (dispatch) => {
  dispatch({
    type: TYPES.CLEAR_EDIT_BLOG,
    payload: { id }
  });
}
