import React, { Component } from "react";
import { Modal, Avatar, Checkbox } from "antd";
import ButtonDanger from "../../buttons/ButtonDanger";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import defaultImg from '../../../assets/user-grey.svg';

import { get } from 'lodash';

import EmptyState from '../../EmptyState';

// Style
import "./styles.scss";

class RemoveChildMentorModal extends Component {
  constructor(props) {
    super();
    this.state = {
      top: "topLeft",
      bottom: "bottomRight",
      checked: [],
      allChecked: false,
      searchQuery: null,
      mentors: props.currentUser.userMentors || [],
      isDataLoaded: false
    };
  }

  clearCheckboxes = () => {
    this.setState({
      allChecked: false,
      checked: []
    })
  }

  checkAll = () => {
    if (this.state.allChecked) {
      return this.clearCheckboxes();
    }
    const { mentors } = this.state;
    if (!mentors || mentors.length === 0) return;
    const checked = mentors.map(item => item.id);
    this.setState({ checked, allChecked: true });
  }

  onChange = (e) => {
    let { checked } = this.state;
    if (e.target.checked) {
      this.setState({
        checked: [...checked, e.target.value],
      });
    } else {
      let idx = checked.indexOf(e.target.value);
      if (idx !== -1) checked.splice(idx, 1);
      this.setState({
        checked: checked,
      });
    }
  };

  render() {
    const { isModalVisible, onClose, modalTitle, onClick } = this.props;
    const { checked, allChecked, mentors } = this.state;

    return (
      <div className="assign-student-modal">
        <Modal
          visible={isModalVisible}
          // centered
          footer={null}
          // width="350"
          className="assign-learner-modal-style">
          <div className="remove-modal">
            <h3 className="title3-rm mb--20">{modalTitle}</h3>
            <div className="modal-body">
              {(!mentors || mentors.length === 0) ? (<EmptyState title="Oops!" description="No mentors assigned &#128533;" />) :
                <div className="checkbox-section">
                  <div className="select-all-box-sm">
                    <p className="select-all-text-sm">Select All</p>
                    <Checkbox onChange={this.checkAll} checked={allChecked} />
                  </div>
                  <div className="ant-checkbox-group">
                    {mentors && mentors.map((mentor, index) => {
                      return (
                        <Checkbox className="checkbox-style" onChange={this.onChange} value={mentor.id} key={index} checked={checked.includes(mentor.id)}>
                          <div className="fx fx--ai-fs mr--20 wrapper">
                            <Avatar
                              className="img-sec"
                              size={32}
                              src={get(mentor, "media.path", null) || defaultImg}
                            />
                            <div className="learner-info-box fx fx--ai-fs fd-c">
                              <span className="learner-name wrap-modal-field">{mentor.full_name}</span>
                              {mentor.organization && <span className="id-style wrap-modal-field"><b>Organization: </b>{mentor.organization}</span>}
                              <div>
                                {false && <span className="id-style role">{mentor?.roles[0]?.name || "--"}</span>}
                                <span className="id-style">{mentor.email}</span>
                              </div>
                            </div>
                          </div>
                        </Checkbox>
                      )
                    })}
                  </div>
                </div>
              }
              <div className="text-right mt--10 footer-section">
                <ButtonDanger
                  onClick={onClose}
                  btnText="Cancel"
                  btnDangerStyle="mr--20"
                />
                <ButtonPrimary onClick={() => { onClick(checked) }} btnText="Remove" disabled={checked.length === 0} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RemoveChildMentorModal;
