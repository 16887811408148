import { get } from 'lodash';

export const validate = (data) => {
    let errorObj = {};
    if (!data.title) {
        errorObj["title"] = "Blog title is Required";
    }
    if (!data.content) {
        errorObj["content"] = "Blog content is Required";
    }
    if (!data.category) {
        errorObj["category"] = "Blog category is Required";
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};

export const parseError = (Error) => {
    const err = get(Error, "response.data.error");
    let errorObj = {};

    if (err && err.title) {
        errorObj["title"] = get(err, "title[0]");
    }
    if (err && err.summary) {
        errorObj["summary"] = get(err, "summary[0]");
    }
    if (err && err.content) {
        errorObj["content"] = get(err, "content[0]");
    }
    if (err && err.category) {
        errorObj["category"] = get(err, "category[0]");
    }

    if (Object.keys(errorObj).length) return errorObj;
    else return null;
};