import { connect } from "react-redux";
import React, { Component } from "react";
import { Form, Row, Col } from "antd";
import { get } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

// images
import logo from "../../assets/logo.png";

//Components
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import InputPrimary from "../../components/InputPrimary";
import SuccessModal from "../../components/modal/SuccessModal";
import ErrorModal from "../../components/modal/ErrorModal";
import Loader from "../../components/PrimaryLoader";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Services
import * as DM from "./dataManager";
import * as CDM from "../../common/dataManager";

//Actions
import { login, setPermissions } from "./action";

//Constants
import { ROUTES } from "../../constants";

//Parsers
import { validate, parseError } from "./parser";

//Helpers
import { urlBase64ToUint8Array } from "../../helpers";

const recaptchaRef = React.createRef();

class LoginScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      email: null,
      password: null,
      recaptchaResponse: null,
      successShowModal: null,
      errorShowModal: null,
      isLoader: false,
      errObj: null,
    };
  }

  // componentDidMount() {
  //   recaptchaRef.current.execute();
  // }

  setStateValue = (value, field) => {
    let state = this.state;
    state[`${field}`] = value;
    this.setState(state);
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  handleLogin = async () => {
    const { email, password, recaptchaResponse } = this.state;
    const checkValidate = validate({ email, password });
    if (checkValidate) {
      this.setState({
        errObj: checkValidate,
      });
      return;
    }

    this._startLoader();
    await DM.loginService(email, password, recaptchaResponse)
      .then((res) => {
        this.handleAfterLogin(res);
      })
      .catch((error) => {
        this._stopLoader();
        this.setState({
          errObj: parseError(error),
          errorShowModal: { msg: get(error, "response.data.message", "") },
        });
      });
  };

  handleAfterLogin = async (res) => {
    await DM.setTokenToAPIInstanceService(res.meta.token);
    let payload = {
      data: res.data,
      token: res.meta.token,
    };

    this.props.login(payload);
    let permissions = await this.getPermissions(res.data.roles);
    await this.createPermissionMap(permissions);
    await this.handleUsersNotifications();
    this._stopLoader();
    if (permissions.length === 0) {
      this.setState({
        errorShowModal: {
          msg:
            "You do not have any permission. Kindly ask Super Admin to give you permissions.",
        },
      });
    }
    this.props.history.push(ROUTES.DASHBOARD);
  };

  getPermissions = async (roles) => {
    let permissions = [];
    for (let i = 0; i < roles.length; i++) {
      let res = await CDM.getRoleService(roles[i].id);
      let resPermissions = get(res, "data.permissions", null);
      resPermissions &&
        resPermissions.map((perm) => {
          if (!permissions.includes(perm)) permissions.push(perm);
          return null;
        });
    }

    return permissions;
  };

  createPermissionMap = async (permissions) => {
    if (!permissions) return;
    const map = {};
    permissions.map((permission) => {
      let val = map[permission.resource];
      if (!val) val = [];
      val.push(permission.action);
      map[permission.resource] = val;
      return null;
    });
    // console.log(map);
    this.props.setPermissions(map);
  };

  handleUsersNotifications = async () => {
    // Check for service worker
    if ("serviceWorker" in navigator) {
      await this.sendUserSubscriptionToBackEnd().catch((err) =>
        console.error(err),
      );
    }
  };

  // Register SW, Register Push, Send Push
  sendUserSubscriptionToBackEnd = async () => {
    // Register Service Worker
    // console.log("Registering service worker...");
    const register = await navigator.serviceWorker.register("/worker.js", {
      scope: "/",
    });

    await navigator.serviceWorker.ready; // <---------- WAIT
    // console.log("Service Worker Registered...");

    // Register Push
    // console.log("Registering Push...");
    const subscription = await register.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_PUBLIC_VAPID_KEY,
        ),
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log("Push Registered...");

    // Send Push Notification
    // console.log("Sending Push...");

    return await DM.userNotificationSubscibeService(subscription)
      .then((res) => {
        // console.log("Push Sent...");
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onFinish = (values) => {};

  onFinishFailed = (errorInfo) => {};

  render() {
    const { isLoader, successShowModal, errorShowModal, errObj } = this.state;
    return (
      <>
        <PageTitle title="Login" />
        <div className="login-form-container">
          <Row className="row-container">
            <Col xs={24} sm={24} lg={10}>
              <div className="form-section">
                <div className="logo-sec">
                  <img src={logo} alt="" className="logo-primary" />
                </div>

                <div className="heading-section">
                  <h3 className="title3"> Sign in</h3>
                </div>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}>
                  <div className="input-item">
                    <p className="lable">Email</p>
                    <InputPrimary
                      placeholder="Enter email"
                      onChange={(e) => {
                        this.setStateValue(e.target.value, "email");
                      }}
                      errorText={get(errObj, "email")}
                    />
                  </div>

                  <div className="input-item">
                    <p className="lable">Password</p>
                    <InputPrimary
                      type="password"
                      placeholder="Enter password"
                      onChange={(e) => {
                        this.setStateValue(e.target.value, "password");
                      }}
                      errorText={get(errObj, "password")}
                      onPressEnter={this.handleLogin}
                    />
                  </div>

                  <div className="input-item">
                    <ReCAPTCHA
                      className="captcha"
                      // size="invisible"
                      size={
                        window.screen.availWidth > 767 ? "normal" : "compact"
                      }
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(response) =>
                        this.setStateValue(response, "recaptchaResponse")
                      }
                    />
                  </div>

                  <Form.Item className="mt--40">
                    <ButtonPrimary
                      btnPrimaryStyle="width--full"
                      btnText="Sign In"
                      onClick={() => {
                        this.handleLogin();
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col sm={24} lg={14} className="illustration-bg-section">
              <div className="info-section">
                <h1 className="title1 quote">
                  Education is the passport to the future, for tomorrow belongs
                  to those who prepare for it today.
                </h1>
                <div className="text-right quote-by">– Malcolm X</div>
              </div>
            </Col>
          </Row>

          {isLoader && <Loader />}

          {successShowModal && (
            <SuccessModal
              isModalVisible={successShowModal ? true : false}
              onClose={() => {
                this.setState({ successShowModal: null });
              }}
              title={
                successShowModal.title ? successShowModal.title : "Success!"
              }
              description={successShowModal.msg}
            />
          )}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  login,
  setPermissions,
})(LoginScreen);
