import React, { Component } from "react";
import { Popover, Tooltip } from "antd";

// images
import moreIcon from "../../assets/more.svg";
import arrowDown from "../../assets/arrow-down.svg";

// Style
import "./styles.scss";

class ToggleMenu extends Component {
  render() {
    const { disabled, btnText, isToggleBtnActive, children } = this.props;
    return (
      <div className="toggle-section">
        {disabled ? (
          <Tooltip title="Please select learner" placement="left">
            <button
              className={`${disabled ? "disabled-style" : ""}`}
              disabled>
              {btnText}
              <img
                src={arrowDown}
                width="10"
                alt="more icon"
                className="down-arrow"
              />
            </button>
          </Tooltip>
        ) : (
            <Popover
              getPopupContainer={(trigger) => trigger.parentNode}
              overlayClassName={
                isToggleBtnActive === true ? "" : "popver-right-align"
              }
              placement="bottomRight"
              content={children}
              trigger="click">
              {isToggleBtnActive ? (
                <button className="btn-toggle-style">
                  {btnText}{" "}
                  <img
                    src={arrowDown}
                    width="10"
                    alt="more icon"
                    className="down-arrow"
                  />
                </button>
              ) : (
                  <a href="/#" className="ant-dropdown-icon">
                    <img src={moreIcon} width="16" alt="more icon" />
                  </a>
                )}
            </Popover>
          )}
      </div>
    );
  }
}

export default ToggleMenu;
