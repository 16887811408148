import { TYPES } from "./Constants";

export const setAllSchoolProjects = (data) => (dispatch) => {
    dispatch({
        type: TYPES.SET_ALL_SCHOOL_PROJECTS,
        payload: data,
    });
};

export const editSchoolProject = (data) => (dispatch) => {
    dispatch({
        type: TYPES.EDIT_SCHOOL_PROJECTS,
        payload: data,
    })
};