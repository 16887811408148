import React, { Component } from 'react';
import { Modal } from 'antd';

// Style
import "./styles.scss"
import ButtonPrimary from '../../buttons/ButtonPrimary';
import ButtonDanger from '../../buttons/ButtonDanger';

class RemoveModal extends Component {
  state = {
    top: 'topLeft',
    bottom: 'bottomRight',
  };

  render() {
    const { isModalVisible, dontRemoveHandler, removeHandler, title, description, removeBtnText, dontRemoveBtnText } = this.props;

    return (
      <div className="remove-modal-container">
        <Modal visible={isModalVisible} centered footer={null} width="350" className="remove-modal-style">
          <div className="remove-modal">
            <h3 className="title3">{title}</h3>
            <h6 className="title6">{description}</h6>
            <div className="footer-section">
              <ButtonDanger onClick={removeHandler} btnText={removeBtnText} btnDangerStyle="mr--20" />
              <ButtonPrimary onClick={dontRemoveHandler} btnText={dontRemoveBtnText} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RemoveModal;
