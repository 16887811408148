import * as SERVICES from "./services";

export const postCreatePackageService = async (payload) => {
    return await SERVICES.createPackage(payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getAllPackagesService = async (params) => {
    return await SERVICES.getAllPackages(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getSpecificPackageService = async (id, params) => {
    return await SERVICES.getPackage(id, params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdatePackageService = async (id, payload) => {
    return await SERVICES.updatePackage(id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deletePackageService = async (id) => {
    return await SERVICES.deletePackage(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};


export const getAllCurrenciesService = async (params) => {
    return await SERVICES.getAllCurrencies(params)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};