export const DASHBOARD = { text: "Dashboard", link: "/dashboard" };
export const MODULES = { text: "Modules", link: "/modules" };
export const SCHOOLPROJECTS = { text: "School Projects", link: "/school-projects" };
export const USERS = { text: "Users", link: "/user/learners" };
export const LEARNERS = { text: "Learners", link: "/user/learners" };
export const MENTORS = { text: "Mentors", link: "/user/mentors" };
export const SUPERMENTORS = { text: "Super Mentors", link: "/user/super-mentors" };
export const ADMINS = { text: "Admins", link: "/user/admin" };
export const COLLECTIONS = { text: "Collections", link: "/collections" };
export const CAREERS = { text: "Careers", link: "/careers" };
export const ROLE_MANAGEMENT = { text: "Role Management", link: "/role-management" };
export const CATEGORIES = { text: "Categories", link: "/categories" };
export const SUBCATEGORIES = { text: "Sub-categories", link: "/sub-categories" }
export const TAGCATEGORIES = { text: "Tag category", link: "/tag-category" };
export const TAGS = { text: "Tags", link: "/tags" };
export const BLOGCATEGORIES = { text: "Blog category", link: "/blog-category" };
export const BLOGS = { text: "Blog Management", link: "/blog" }
export const FEATUREQUESTIONS = { text: "Featured question", link: "/feature-questions" };
export const ENQUIRIES = { text: "Enquiries", link: "/enquiries" };
export const PACKAGE = { text: "Packages", link: "/packages" }
