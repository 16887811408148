import React, { Component } from "react";
import { Row, Col } from "antd";
import { get } from "lodash";
import { connect } from "react-redux";

// Custom Components
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import PrimaryPagination from "../../components/PrimaryPagination";
import LearnersCard from "../../components/LearnersCard";
import PrimaryBreadcrumb from "../../components/PrimaryBreadcrumb";
import ButtonOutline from "../../components/buttons/ButtonOutline";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/PrimaryLoader";
import ErrorModal from "../../components/modal/ErrorModal";
import SuccessModal from "../../components/modal/SuccessModal";
import RemoveModal from "../../components/modal/RemoveModal";
import ButtonDanger from "../../components/buttons/ButtonDanger";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

//Constants
import { ROUTES, ACTIONS } from "../../constants";

// Style
import "./styles.scss";
import { PageTitle } from "../../components/PageTitle";

//Helpers
import { convertCase } from "../../helpers";

//Services
import * as CDM from "../../common/dataManager";
import * as DM from "./dataManager";

//Actions
import { getAllRoles } from "./actions";

class RoleManagement extends Component {
  state = {
    isLoader: false,
    meta: {
      page: 1,
      limit: 10,
    },
    removeShowModal: false,
    errorModal: null,
    succModal: null,
    selected_id: "",
  };

  componentDidMount() {
    this.getAllRolesData();
  }

  handlePageChange = (page) => {
    let meta = this.state.meta;
    meta.page = page;
    this.setState({ meta: meta }, () => {
      this.getAllRolesData();
    });
  };

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  errorToggleModal = () => {
    this.setState({
      errorModal: this.state.errorModal ? null : {},
    });
  };

  successToggleModal = () => {
    this.setState(
      {
        succModal: this.state.succModal ? null : {},
      },
      () => {
        if (!this.state.succModal)
          this.props.history.push(ROUTES.ROLE_MANAGEMENT);
      },
    );
  };

  removeToggleModal = (id) => {
    this.setState({
      selected_id: typeof id === "string" ? id : "",
      removeShowModal: !this.state.removeShowModal,
    });
  };

  getAllRolesData = () => {
    window.scrollTo(0, 0);
    this._startLoader();
    const { page, limit } = this.state.meta;
    const queryObject = {
      limit: limit,
      page: page,
    };
    CDM.getAllRolesService(queryObject)
      .then((res) => {
        this.props.getAllRoles(res.data);
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
      });
  };

  removeRole = () => {
    const id = this.state.selected_id;
    DM.deleteRoleService(id)
      .then((res) => {
        this._stopLoader();
        this.setState({
          succModal: {
            title: "Success",
            description: "Role deleted successfully.",
          },
        });
        this.removeToggleModal();
        this.getAllRolesData();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorModal: { description: get(err, "response.data.message", "") },
        });
        this.removeToggleModal();
      });
  };

  render() {
    const {
      isLoader,
      meta,
      errorModal,
      succModal,
      removeShowModal,
    } = this.state;
    const permissions = get(this.props, "auth.permissions");
    const isClassActive = !(
      permissions.ROLES.includes(ACTIONS.UPDATE) ||
      permissions.ROLES.includes(ACTIONS.DELETE)
    );
    const dataColumns = get(this.props, "roles.allRoles.results", null);
    const totalRoles = get(
      this.props,
      "roles.allRoles.meta.pagination.total",
      0,
    );

    let cardCol1 = [];
    let cardCol2 = [];
    if (dataColumns && Array.isArray(dataColumns)) {
      dataColumns.map((data, index) => {
        if (index % 2 === 0) {
          cardCol1.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(data, "name")}</h5>
                </div>
              </div>
              <div
                className={`right-box fx fx--ai-c ${
                  isClassActive ? "text-right" : ""
                }`}>
                <div className="img-heading">
                  <p className="title6">{convertCase(get(data, "type"))}</p>
                </div>
                <div className="right-sec">
                  {permissions.ROLES &&
                    permissions.ROLES.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() => {
                          this.props.history.push(
                            ROUTES.EDITROLE.replace(":id", data.id),
                          );
                        }}
                      />
                    )}
                  {permissions.ROLES &&
                    permissions.ROLES.includes(ACTIONS.DELETE) && (
                      <div className="right-section">
                        <ButtonDanger
                          type="link"
                          btnText={<DeleteOutlined />}
                          btnDangerStyle="btn delete btn-link"
                          onClick={() => this.removeToggleModal(data.id)}>
                          Remove
                        </ButtonDanger>
                      </div>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        } else {
          cardCol2.push(
            <LearnersCard>
              <div className="left-box">
                <div className="img-heading">
                  <h5 className="title5">{get(data, "name")}</h5>
                </div>
              </div>
              <div
                className={`right-box fx fx--ai-c ${
                  isClassActive ? "text-right" : ""
                }`}>
                <div className="img-heading">
                  <p className="title6">{convertCase(get(data, "type"))}</p>
                </div>
                <div className="right-sec">
                  {permissions.ROLES &&
                    permissions.ROLES.includes(ACTIONS.UPDATE) && (
                      <ButtonOutline
                        btnText={<EditOutlined />}
                        // btnStyle="btn-sm"
                        btnStyle="btn edit square-edit mr--10"
                        clickHandler={() => {
                          this.props.history.push(
                            ROUTES.EDITROLE.replace(":id", data.id),
                          );
                        }}
                      />
                    )}
                  {permissions.ROLES &&
                    permissions.ROLES.includes(ACTIONS.DELETE) && (
                      <div className="right-section">
                        <ButtonDanger
                          type="link"
                          btnText={<DeleteOutlined />}
                          btnDangerStyle="btn delete btn-link"
                          onClick={() => this.removeToggleModal(data.id)}>
                          Remove
                        </ButtonDanger>
                      </div>
                    )}
                </div>
              </div>
            </LearnersCard>,
          );
        }
        return null;
      });
    }

    return (
      <>
        <PageTitle title="Role Management" />
        <div className="role-management-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb breadcrumbs={[{ text: "Role Management" }]} />
              <h4 className="title4"> List of all Roles</h4>
            </div>
            <div className="right-section">
              {permissions.ROLES && permissions.ROLES.includes(ACTIONS.CREATE) && (
                <ButtonSecondary
                  btnText="Add new role"
                  onClick={() => {
                    this.props.history.push(ROUTES.NEWROLE);
                  }}
                />
              )}
            </div>
          </div>

          {!dataColumns || dataColumns.length === 0 ? (
            <EmptyState title="Oops!" description="No Record Found &#128533;" />
          ) : (
            <>
              <div className="list-style list-card-style mt--30 border-top-style">
                <Row gutter={[20]}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="title-section">
                      <div className="left-box">
                        <p className="lead">Role name</p>
                      </div>
                      <div
                        className={`right-box fx fx--ai-c ${
                          isClassActive ? "fx--jc-fe-imp mr--10" : ""
                        }`}>
                        <p className="lead">Role Type</p>
                        {permissions.ROLES &&
                          (permissions.ROLES.includes(ACTIONS.UPDATE) ||
                            permissions.ROLES.includes(ACTIONS.DELETE)) && (
                            <p className="lead width-heading">Action</p>
                          )}
                      </div>
                    </div>
                    {cardCol1}
                  </Col>

                  {cardCol2.length > 0 && (
                    <Col xs={24} sm={24} md={12}>
                      <div className="title-section">
                        <div className="left-box">
                          <p className="lead">Role name</p>
                        </div>
                        <div
                          className={`right-box fx fx--ai-c ${
                            isClassActive ? "fx--jc-fe-imp mr--10" : ""
                          }`}>
                          <p className="lead">Role Type</p>
                          {permissions.ROLES &&
                            (permissions.ROLES.includes(ACTIONS.UPDATE) ||
                              permissions.ROLES.includes(ACTIONS.DELETE)) && (
                              <p className="lead width-heading">Action</p>
                            )}
                        </div>
                      </div>
                      {cardCol2}
                    </Col>
                  )}
                </Row>
              </div>

              <div className="page-section">
                <PrimaryPagination
                  pageSize={meta.limit}
                  current={meta.page}
                  total={totalRoles}
                  onChange={this.handlePageChange}
                />
              </div>
            </>
          )}
          {isLoader && <Loader />}
          {errorModal && (
            <ErrorModal
              isModalVisible={errorModal}
              onClose={this.errorToggleModal}
              title={get(errorModal, "title")}
              description={get(errorModal, "description")}
            />
          )}
          {succModal && (
            <SuccessModal
              isModalVisible={succModal}
              onClose={this.successToggleModal}
              title={get(succModal, "title")}
              description={get(succModal, "description")}
            />
          )}
          {removeShowModal && (
            <RemoveModal
              isModalVisible={removeShowModal}
              onClose={this.removeToggleModal}
              title="Delete this role"
              description="Are you sure you want to delete this role?"
              // Action button event here
              removeHandler={this.removeRole}
              dontRemoveHandler={this.removeToggleModal}
              // Action button text here
              removeBtnText="Yes, delete"
              dontRemoveBtnText="Don't delete"
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return store;
};

export default connect(mapStateToProps, {
  getAllRoles,
})(RoleManagement);
