import { API } from "./Constants";
import { axiosInstance } from "../../tools";

export const createTagCategory = async (payload) => {
    let url = API.POST_CREATE_TAG_CATEGORY;
    return await axiosInstance.post(url, payload);
};

export const getTagCategory = async (id) => {
    let url = API.GET_TAG_CATEGORY.replace(":id", id);
    return await axiosInstance.get(url);
};

export const updateTagCategory = async (id, payload) => {
    let url = API.PATCH_UPDATE_TAG_CATEGORY.replace(":id", id);
    return await axiosInstance.patch(url, payload);
};

export const deleteTagCategory = async (id) => {
    let url = API.DELETE_TAG_CATEGORY.replace(":id", id);
    return await axiosInstance.delete(url);
};

