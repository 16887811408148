import * as SERVICES from "./services";

export const postCreateBlogCategoryService = async (payload) => {
    return await SERVICES.createBlogCategory(payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getBlogCategoryService = async (id) => {
    return await SERVICES.getBlogCategory(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const patchUpdateBlogCategoryService = async (id, payload) => {
    return await SERVICES.updateBlogCategory(id, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const deleteBlogCategoryService = async (id) => {
    return await SERVICES.deleteBlogCategory(id)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

