import React from "react";
import { connect } from "react-redux";
import { ROUTES, PERMISSIONS } from "../../constants";
import { get } from "lodash";

export function requiredAuthentication(Component) {
  class AuthenticationComponent extends React.Component {
    constructor(props) {
      super();
      this.state = {
        hasPermission: false,
      };
    }

    componentDidMount() {
      this.checkAuth();
    }

    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }

    checkAuth = () => {
      const { auth, history } = this.props;
      if (!auth.isAuthenticated) {
        history.push(ROUTES.LOGIN);
      } else {
        this.checkPermission(get(this.props, "location.pathname"));
      }
    };

    checkPermission = (route) => {
      let allowedPermission = PERMISSIONS.map[route];
      if (!allowedPermission) {
        this.setState({ hasPermission: true });
        return;
      }
      let {
        resource,
        // action
      } = allowedPermission;
      // if (!action) {
      //   this.setState({ hasPermission: true })
      //   return;
      // }

      let userPermissions = this.props.auth.permissions;
      if (resource in userPermissions) {
        // if (Array.isArray(action)) {
        //   for (let i = 0; i < action.length; i++) {
        //     if (userPermissions[resource].includes(action[i])) {
        this.setState({ hasPermission: true });
        return;
        //     }
        //   }
        //   this.props.history.push(ROUTES.FORBIDDEN);
        // } else {
        //   if (userPermissions[resource].includes(action)) this.setState({ hasPermission: true })
        //   else this.props.history.push(ROUTES.FORBIDDEN);
        // }
      } else {
        this.props.history.push(ROUTES.FORBIDDEN);
      }
    };

    render() {
      return (
        <div>
          {this.props.auth.isAuthenticated && this.state.hasPermission && (
            <Component {...this.props} />
          )}
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

  return connect(mapStateToProps)(AuthenticationComponent);
}
