export function objectToFormdataFileUpload(obj) {
    var form_data = new FormData();

    for (var key in obj) {
        if (key === 'schoolProjectDocuments') {
            let data = obj[key];
            if (data) {
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        form_data.append(`${key}`, data[i]);
                    }
                } else {
                    form_data.append(key, obj[key]);
                }
            }
        }
        else {
            let data = obj[key];
            if (data) {
                if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        form_data.append(`${key}[${i}]`, data[i]);
                    }
                } else {
                    form_data.append(key, obj[key]);
                }
            }
        }
    }

    return form_data;
}
