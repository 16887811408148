import { TYPES } from "../../containers/Login/Constants";
import { TYPES as PROFILE_TYPE } from '../../containers/MyProfile/Constants';

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: { ...action.payload },
        error: {},
      };
    case TYPES.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
        error: {}
      };

    case PROFILE_TYPE.UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        error: {}
      }
    default:
      return state;
  }
};
