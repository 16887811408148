export const getCardFlipView = (editor) => {
  //Get Component Object
  const comps = editor.DomComponents;

  //Get Default Componet Objec
  var defaultType = comps.getType("default");
  return defaultType.view.extend({
    events: {
      click: "flip",
    },
    initialize: function (o) {
      defaultType.view.prototype.initialize.apply(this, arguments);
      this.listenTo(this.model, "click active", this.flip);
    },

    flip: function (event) {
      var element = event.currentTarget;
      if (element.className === "card") {
        if (element.style.transform === "rotateY(180deg)") {
          element.style.transform = "rotateY(0deg)";
        } else {
          element.style.transform = "rotateY(180deg)";
        }
      }
    },
  });
};
