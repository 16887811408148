import { get } from "lodash";

export const validate = (data) => {
  let errorObj = {};
  if (!data.question) {
    errorObj["question"] = "Question is Required";
  }
  if (!data.module) {
    errorObj["module"] = "Module is required";
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};

export const parseError = (Error) => {
  const err = get(Error, "response.data.error");
  let errorObj = {};

  if (err && err.question) {
    errorObj["question"] = get(err, "question[0]");
  }
  if (err && err.module) {
    errorObj["module"] = get(err, "module[0]");
  }
  if (err && err.status) {
    errorObj["status"] = get(err, "status[0]");
  }

  if (Object.keys(errorObj).length) return errorObj;
  else return null;
};
