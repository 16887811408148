//Service
import * as SERVICE from "./services";

//Parsers
import * as PARSER from "./parser";

export const createModuleTabService = async (id, payload) => {
  return await SERVICE.postCreateModuleTabs(id, payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const patchUpdateModuleTabsService = async (
  module_id,
  tabs_id,
  payload,
) => {
  return await SERVICE.patchUpdateModuleTabs(module_id, tabs_id, payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getModuleDetailsService = async (id) => {
  return SERVICE.getSpecificModule(id)
    .then((result) => {
      return result.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const modulesTabFileUploadService = async (id, body) => {
  return SERVICE.postTabFileUpload(id, body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getWebScrappingDataService = async (params) => {
  return SERVICE.getWebScrapingData(params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteModuleService = async (id) => {
  return await SERVICE.deleteModule(id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateModuleService = async (prevValues, values, id) => {
  const payload = await PARSER._updateModuleParser(prevValues, values);
  return await SERVICE.patchUpdateModule(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postCreateModuleService = async (values) => {
  const payload = PARSER._updateModuleParser(values, {});

  return await SERVICE.postCreateModule(payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postCreateProjectService = async (id, payload) => {
  return await SERVICE.postCreateProject(id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllProjectService = async (id, param) => {
  return await SERVICE.getAllProject(id, param)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const patchUpdateProjectService = async (id, project_id, payload) => {
  return await SERVICE.patchUpdateProject(id, project_id, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteProjectService = async (id, project_id) => {
  return await SERVICE.deleteProject(id, project_id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const exportModulesList = async () => {
  return await SERVICE.exportModulesList()
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postSimilarityIndex = async (body) => {
  return await SERVICE.postSimilarityIndex(body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const postSeedKeyword = async (body) => {
  return await SERVICE.postSeedKeyword(body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const postSeedSIKeyword = async (body) => {
  return await SERVICE.postSeedSIKeyword(body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    })
}

export const getMyViewedModulesService = async (params) => {
  return SERVICE.getMyViewedModules(params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
