import { API } from "./Constants";
import { axiosInstance } from "../../tools";
import { makeQueryParam, objectToFormdata } from "../../helpers";

export const getAllEnquiries = async (params) => {
  return await axiosInstance.get(
    `${API.GET_ALL_ENQUIRIES}${makeQueryParam(params)}`,
  );
};

export const getSpecificEnquiries = async (id) => {
  let url = API.GET_SPECIFIC_ENQUIRIES.replace(":id", id);
  return await axiosInstance.get(url);
};

export const postCreateEnquiries = async (payload) => {
  return await axiosInstance.post(
    API.POST_CREATE_ENQUIRIES,
    objectToFormdata(payload),
  );
};

export const patchUpdateEnquiries = async (id, payload) => {
  let url = API.PATCH_UPDATE_ENQUIRIES.replace(":id", id);
  return await axiosInstance.patch(url, payload);
};

export const deleteEnquiries = async (id) => {
  let url = API.DELETE_ENQUIRIES.replace(":id", id);
  return await axiosInstance.delete(url);
};
