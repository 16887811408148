import { TYPES } from "../../containers/Payments/Constants";

export default (state = {}, action) => {
    switch (action.type) {
        case TYPES.GET_ALL_PAYMENTS:
            return {
                ...state,
                allPayments: action.payload,
                error: {},
            };
        default:
            return state;
    }
};
