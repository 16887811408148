import React, { Component } from "react";
import { Row, Col } from "antd";
import { PageTitle } from "../../../components/PageTitle";
import PrimaryBreadcrumb from "../../../components/PrimaryBreadcrumb";
import ErrorModal from "../../../components/modal/ErrorModal";
import Loader from "../../../components/PrimaryLoader";

import "./styles.scss";

import { BREADCRUMBS } from "../../../constants";

//Services
import * as DM from "../dataManager";
import { get } from "lodash";

class ViewModule extends Component {
  constructor(props) {
    super();
    this.state = {
      packageDetails: null,
      errorShowModal: null,
      isLoader: false,
    };
  }

  //Life Cycle Methods

  async componentDidMount() {
    const editableID = this.props.match.params.id;

    if (editableID) {
      await this.getSpecificPackageDetials(editableID);
    }
  }

  //API Call Functions

  getSpecificPackageDetials = async (id) => {
    this._startLoader();
    await DM.getSpecificPackageService(id)
      .then((res) => {
        this.setState({ packageDetails: res.data });
        this._stopLoader();
      })
      .catch((err) => {
        this._stopLoader();
        this.setState({
          errorShowModal: {
            msg: get(err, "response.data.message"),
          },
        });
      });
  };

  //Helper Functions

  _startLoader = () => {
    this.setState({ isLoader: true });
  };

  _stopLoader = () => {
    this.setState({ isLoader: false });
  };

  render() {
    const { packageDetails, isLoader, errorShowModal } = this.state;
    const noOfModules = get(packageDetails, "number_of_accessable_module");
    const noOfSessions = get(packageDetails, "number_of_session");
    return (
      <>
        <PageTitle title="View Package" />
        <div className="view-package-style">
          <div className="header-style">
            <div className="title-header">
              <PrimaryBreadcrumb
                breadcrumbs={[
                  BREADCRUMBS.PACKAGE,
                  { text: "View Package" }]}
              />
              <h4 className="title4">View package</h4>
            </div>
          </div>
          <div className="view-package-content mt--30">
            <div className="table-card-style">
              <div className="table-style module-list-style">
                <Row gutter={30}>
                  <Col xs={24} sm={24} md={12}>
                    <table className="table-custom-style">
                      <thead>
                        <tr>
                          <th>Heading</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Package Name</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "name", "---")}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Status</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "status", "---")}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Duration</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "duration", "---")} days
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Access to modules</p>
                          </td>
                          <td>
                            <div className="value">
                              {noOfModules === -1 ? "Unlimited" : noOfModules}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Access to projects</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "can_access_to_project") ? "Yes" : "No"}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">AI guided recommendation</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "ai_guided_recommendation") ? "Yes" : "No"}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Access to Collections</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "can_access_collection") ? "Yes" : "No"}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">AI based profile building support</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "ai_based_profiile_building_support") ? "Yes" : "No"}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">Project submission & feedback</p>
                          </td>
                          <td>
                            <div className="value">
                              {get(packageDetails, "can_submit_project") ? "Yes" : "No"}
                            </div>
                          </td>
                        </tr>
                        <tr className="data-row">
                          <td>
                            <p className="lead">1-1 ACadru Mentor guidance</p>
                          </td>
                          <td>
                            <div className="value">
                              {noOfSessions} sessions
                        </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <table className="table-custom-style">
                      <tr>
                        {/* <th>Price Name</th> */}
                        <th>Currency</th>
                        <th className="text-right">Price</th>
                      </tr>
                      {get(packageDetails, "prices") && get(packageDetails, "prices").map((data, index) => {
                        return (
                          <tr className="data-row">
                            <td>{get(data, "currency.name")}</td>
                            <td align="right">{get(data, "price")}</td>
                          </tr>
                        )
                      })}
                      {/* <tr className="data-row"> */}
                      {/* rowspan depend on the no of rows in the table */}
                      {/* <td rowspan="6">
                          <p className="lead">PREMIUM</p>
                        </td>
                        <td>Indian Ruppee</td>
                        <td>₹ 2,999/yr</td>
                      </tr> */}
                    </table>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {isLoader && <Loader />}

          {errorShowModal && (
            <ErrorModal
              isModalVisible={errorShowModal ? true : false}
              onClose={() => {
                this.setState({ errorShowModal: null });
              }}
              title={errorShowModal.title ? errorShowModal.title : "Error!"}
              description={errorShowModal.msg}
            />
          )}
        </div>
      </>
    );
  }
}

export default ViewModule;
