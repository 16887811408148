import { omit, get } from 'lodash'
import { TYPES as BLOG_TYPE } from "../../containers/BlogManagement/Constants";
import { TYPES as MODULE_TYPE } from '../../containers/Modules/Constants';
import { TYPES as SCHOOL_PROJECTS_TYPE } from '../../containers/SchoolProject/Constants';

export default (state = {}, action) => {
    switch (action.type) {
        case BLOG_TYPE.SAVE_NEW_BLOG:
            return {
                ...state,
                newBlog: action.payload,
                error: {},
            };
        case BLOG_TYPE.CLEAR_NEW_BLOG:
            return {
                ...state,
                newBlog: {},
                error: {},
            };
        case BLOG_TYPE.EDIT_BLOG:
            return {
                ...state,
                editBlog: {
                    ...state.editBlog,
                    [action.payload.uuid]: action.payload
                },
                error: {},
            };
        case BLOG_TYPE.CLEAR_EDIT_BLOG:
            return {
                ...state,
                editBlog: omit(state.editBlog, `${action.payload.id}`),
                error: {}
            };

        case MODULE_TYPE.SAVE_NEW_MODULE:
            return {
                ...state,
                newModule: action.payload,
                error: {},
            };
        case MODULE_TYPE.CLEAR_NEW_MODULE:
            return {
                ...state,
                newModule: {},
                error: {},
            };
        case MODULE_TYPE.EDIT_MODULE:
            let moduleObj = get(state, `editModule.${action.payload.id}`);
            return {
                ...state,
                editModule: {
                    ...state.editModule,
                    [action.payload.id]: {
                        ...moduleObj,
                        ...action.payload
                    }
                },
                error: {},
            };
        case MODULE_TYPE.CLEAR_EDIT_MODULE:
            return {
                ...state,
                editModule: omit(state.editModule, `${action.payload.id}`),
                error: {}
            };
        case MODULE_TYPE.NEW_MINI_PROJECT:
            return {
                ...state,
                newMiniProject: action.payload,
                error: {},
            };
        case MODULE_TYPE.NEW_SKILL_BUILDING_PROJECT:
            return {
                ...state,
                newSkillBuildingProject: action.payload,
                error: {},
            }
        case MODULE_TYPE.EDIT_PROJECT:
            return {
                ...state,
                editProject: {
                    ...state.editProject,
                    [action.payload.id]: action.payload
                },
                error: {},
            };
        case MODULE_TYPE.CLEAR_NEW_MINI_PROJECT:
            return {
                ...state,
                newMiniProject: omit(state.newMiniProject, `${action.payload.id}`),
                error: {}
            }
        case MODULE_TYPE.CLEAR_NEW_SKILL_BUILDING_PROJECT:
            return {
                ...state,
                newSkillBuildingProject: omit(state.newSkillBuildingProject, `${action.payload.id}`),
                error: {}
            }
        case MODULE_TYPE.CLEAR_EDIT_PROJECT:
            return {
                ...state,
                editProject: omit(state.editProject, `${action.payload.id}`),
                error: {}
            };
        case SCHOOL_PROJECTS_TYPE.EDIT_PROJECT:
            return {
                ...state,
                editSchoolProject: omit(state.editSchoolProject, `${action.payload.id}`),
                error: {}
            }
        default:
            return state;
    }
};
