import * as ACTIONS from './actions';
import * as ROUTES from './routes';

export const map = {

    [ROUTES.MODULES]: { resource: "MODULES", action: "" },
    [ROUTES.VIEWMODULE]: { resource: "MODULES", action: ACTIONS.READ },
    [ROUTES.NEWMODULE]: { resource: "MODULES", action: ACTIONS.CREATE },
    [ROUTES.EDITMODULE]: { resource: "MODULES", action: ACTIONS.UPDATE },

    [ROUTES.FEATUREQUESTIONS]: { resource: "MODULES", action: "" },
    [ROUTES.NEWFEATUREQUESTION]: { resource: "MODULES", action: ACTIONS.CREATE },
    [ROUTES.EDITFEATUREQUESTION]: { resource: "MODULES", action: ACTIONS.UPDATE },

    [ROUTES.LEARNERS]: { resource: "USERS", action: [ACTIONS.READ_LEARNERS, ACTIONS.READ_ALL, ACTIONS.CREATE, ACTIONS.DELETE, ACTIONS.UPDATE] },
    [ROUTES.VIEWLEARNER]: { resource: "USERS", action: [ACTIONS.READ_LEARNERS, ACTIONS.READ_ALL] },
    [ROUTES.NEWLEARNER]: { resource: "USERS", action: ACTIONS.CREATE },
    [ROUTES.EDITLEARNER]: { resource: "USERS", action: ACTIONS.UPDATE },

    [ROUTES.MENTORS]: { resource: "USERS", action: [ACTIONS.READ_MENTORS, ACTIONS.READ_ALL, ACTIONS.CREATE, ACTIONS.DELETE, ACTIONS.UPDATE] },
    [ROUTES.VIEWMENTOR]: { resource: "USERS", action: [ACTIONS.READ_MENTORS, ACTIONS.READ_ALL] },
    [ROUTES.NEWMENTOR]: { resource: "USERS", action: ACTIONS.CREATE },
    [ROUTES.EDITMENTOR]: { resource: "USERS", action: ACTIONS.UPDATE },

    [ROUTES.ADMINS]: { resource: "USERS", action: [ACTIONS.READ_ADMINS, ACTIONS.READ_ALL, ACTIONS.CREATE, ACTIONS.DELETE, ACTIONS.UPDATE] },
    [ROUTES.VIEWADMIN]: { resource: "USERS", action: [ACTIONS.READ_ADMINS, ACTIONS.READ_ALL] },
    [ROUTES.NEWADMIN]: { resource: "USERS", action: ACTIONS.CREATE },
    [ROUTES.EDITADMIN]: { resource: "USERS", action: ACTIONS.UPDATE },

    [ROUTES.COLLECTIONS]: { resource: "COLLECTIONS", action: "" },
    [ROUTES.NEWCOLLECTION]: { resource: "COLLECTIONS", action: ACTIONS.CREATE },
    [ROUTES.EDITCOLLECTION]: { resource: "COLLECTIONS", action: ACTIONS.UPDATE },

    [ROUTES.CAREERS]: { resource: "CAREERS", action: "" },
    [ROUTES.NEWCAREERES]: { resource: "CAREERS", action: ACTIONS.CREATE },
    [ROUTES.EDITCAREERS]: { resource: "CAREERS", action: ACTIONS.UPDATE },

    [ROUTES.ROLE_MANAGEMENT]: { resource: "ROLES", action: "" },
    [ROUTES.NEWROLE]: { resource: "ROLES", action: ACTIONS.CREATE },
    [ROUTES.EDITROLE]: { resource: "ROLES", action: ACTIONS.UPDATE },

    [ROUTES.CATEGORIES]: { resource: "CATEGORIES", action: "" },
    [ROUTES.NEWCATEGORY]: { resource: "CATEGORIES", action: ACTIONS.CREATE },
    [ROUTES.EDITCATEGORY]: { resource: "CATEGORIES", action: ACTIONS.UPDATE },
    [ROUTES.SUBCATEGORIES]: { resource: "CATEGORIES", action: "" },
    [ROUTES.NEWSUBCATEGORY]: { resource: "CATEGORIES", action: ACTIONS.CREATE },
    [ROUTES.EDITSUBCATEGORY]: { resource: "CATEGORIES", action: ACTIONS.UPDATE },

    [ROUTES.TAGCATEGORY]: { resource: "TAGS", action: "" },
    [ROUTES.NEWTAGCATEGORY]: { resource: "TAGS", action: ACTIONS.CREATE },
    [ROUTES.EDITTAGCATEGORY]: { resource: "TAGS", action: ACTIONS.UPDATE },
    [ROUTES.TAGS]: { resource: "TAGS", action: "" },
    [ROUTES.NEWTAG]: { resource: "TAGS", action: ACTIONS.CREATE },
    [ROUTES.EDITTAG]: { resource: "TAGS", action: ACTIONS.UPDATE },

    [ROUTES.BLOGCATEGORY]: { resource: "BLOG", action: "" },
    [ROUTES.NEWBLOGCATEGORY]: { resource: "BLOG", action: ACTIONS.CREATE },
    [ROUTES.EDITBLOGCATEGORY]: { resource: "BLOG", action: ACTIONS.UPDATE },
    [ROUTES.BLOGS]: { resource: "BLOG", action: "" },
    [ROUTES.NEWBLOG]: { resource: "BLOG", action: ACTIONS.CREATE },
    [ROUTES.EDITBLOG]: { resource: "BLOG", action: ACTIONS.UPDATE },

    [ROUTES.PAYMENTS]: { resource: "PAYMENTS", action: ACTIONS.READ },

    [ROUTES.ENQUIRIES]: { resource: "ENQUIRIES", action: ACTIONS.READ },
    [ROUTES.ENQUIRIESDETAILS]: { resource: "ENQUIRIES", action: ACTIONS.READ },
}

