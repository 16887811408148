import React, { Component } from "react";
import { Card, Row, Col, Avatar } from "antd";
import { get } from "lodash";

import { UserOutlined } from "@ant-design/icons";

// Style
import "./styles.scss";

//Default profile-pic
import defaultImg from "../../assets/user-grey.svg";

//Helpers
import { convertCase, calculateAge, breakTimestamp } from "../../helpers";

class ProfileInfoCard extends Component {
  constructor(props) {
    super();
  }

  render() {
    let learner = this.props.learner;
    let profileImg = get(learner, "media.path", null);

    return (
      <div className="profile-info-section">
        <Card>
          <h5 className="title5 mb--20">Profile Information:</h5>
          <Row gutter={40}>
            <Col span={5}>
              <div className="user-image">
                <img
                  src={profileImg || defaultImg}
                  className={profileImg ? "img-item" : "img-item default"}
                  alt="user"
                />
              </div>
            </Col>
            <Col span={19}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title"> Learner Name</div>
                    <div className="avatar-section">
                      <Avatar size={38} icon={<UserOutlined />} />
                      <div>
                        <p className="desciption trim-name"> {learner.full_name}</p>
                        <div className="title">
                          Age: {learner.dob ? calculateAge(learner.dob) : "--"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={5}>
                  <div className="info-box">
                    <div className="title">Learner ID</div>
                    <p className="desciption">
                      {get(learner, "rawid") || "---"}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={5}>
                  <div className="info-box">
                    <div className="title"> Package Type</div>
                    <p className="desciption">
                      {convertCase(
                        get(learner, "userpackages.packages.name", null),
                      )}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={6}>
                  <div className="info-box">
                    <div className="title">Gender</div>
                    <p className="desciption">
                      {convertCase(get(learner, "gender")) || "---"}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                className="mt--20">
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title"> Interested in:</div>
                    <p className="desciption">
                      {learner.user_interest
                        ? learner.user_interest.map((interest, index) => {
                          if (index === learner.user_interest.length - 1)
                            return interest.name;
                          return interest.name + ", ";
                        })
                        : "---"}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={5}>
                  <div className="info-box">
                    <div className="title"> Date of birth</div>
                    <p className="desciption">
                      {learner.dob
                        ? breakTimestamp(get(learner, "dob")).date
                        : "---"}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={5}>
                  <div className="info-box">
                    <div className="title">Phone</div>
                    <p className="desciption">{learner.phone || "---"}</p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={6}>
                  <div className="info-box">
                    <div className="title">Email</div>
                    <p className="desciption">{learner.email || "---"}</p>
                  </div>
                </Col>
              </Row>

              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                className="mt--20">
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Signup date:</div>
                    <p className="desciption">
                      {breakTimestamp(get(learner, "created_at")).date || "---"}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Your current school/university</div>
                    <p className="desciption">
                      {learner.current_school_uni || "---"}
                    </p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={24} md={8}>
                  <div className="info-box">
                    <div className="title">Grade/Class level</div>
                    <p className="desciption">{learner.grade_level || "---"}</p>
                  </div>
                </Col>
              </Row>
              <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }} className="mt--20">
                <Col className="gutter-row" xs={24} md={24}>
                  <div className="info-box">
                    <div className="title">Organization</div>
                    <p className="desciption trim-org">{learner.organization || "---"}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default ProfileInfoCard;
